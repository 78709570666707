import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget";
import star from "../../../../../src/assets/moneybarn/img/rating-star.png";

const Trustpilot = ({ onClick }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <section className="py-5 bottom-sec">
      <div className="container">
        <h1 className="text-center mb-lg-5 mb-sm-3 title">
          Honest, Independent Reviews from our clients on Trustpilot
        </h1>
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 mb-4 mb-lg-1 mb-md-4 mb-sm-4">
            <h3 className="h3_cl">
              <Link to="/" onClick={onClick} target="_blank">
                {" "}
                <div className="row px-5 align-items-center">
                  <div className="col-9 ps-3">
                    <img src={star} className="img-star" alt="" />
                  </div>
                  <div className="col-3 p-0 d-flex flex-row">
                    <div className="d-flex align-items-center">
                      <p className="m-0">
                        <b className="rate-num">4.8/5 </b>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </h3>
            <div className="trust-sec">
              <TrustpilotReviews
                language="en"
                culture="US"
                theme="light"
                width="100%"
                height="52px"
                template="5419b6a8b0d04a076446a9ad"
                business="5ee0ec97b8f1fa0001e57983"
                username="USERNAME"
              />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 position-relative">
            <div className="swiper review_slide">
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={1200}
                keyBoardControl={true}
                transitionDuration={1000}
                containerclassName="swiper-wrapper"
                // dotListclassName="custom-dot-list-style"
                itemclassName="carousel-item-padding-40-px"
                activeIndex={index}
                onSelect={handleSelect}
                arrows={true}
              >
                <div className="swiper-slide text-center px-5 px-lg-3">
                  <Link
                    onClick={onClick}
                    target="_blank"
                    className="slide-item"
                  >
                    <h3>
                      {" "}
                      <img src={star} alt="" /> &nbsp;
                    </h3>
                    <h4>So helpful and straight forward</h4>
                    <p>
                      So helpful and straight forward. Updates as soon as they
                      receive them. Response time to queries are sufficient.{" "}
                    </p>
                    <p className="time">Victoria Shaw - 4 days ago</p>
                  </Link>
                </div>
                <div className="swiper-slide text-center px-5 px-lg-3">
                  <Link
                    onClick={onClick}
                    target="_blank"
                    className="slide-item"
                  >
                    <h3>
                      {" "}
                      <img src={star} alt="" /> &nbsp;
                    </h3>
                    <h4>Would highly recommend- nothing negative to say</h4>
                    <p>
                      I put off engaging for a while but when I did finally
                      complete the paperwork everything went so smoothly.
                    </p>
                    <p className="time">Sharon Oakey - 2 days ago</p>
                  </Link>
                </div>
                <div className="swiper-slide text-center px-5 px-lg-3">
                  <Link
                    onClick={onClick}
                    target="_blank"
                    className="slide-item"
                  >
                    <h3>
                      {" "}
                      <img src={star} alt="" /> &nbsp;
                    </h3>
                    <h4>Excellent service</h4>
                    <p>
                      Excellent service, great communication all the way
                      through, very fast service
                    </p>
                    <p className="time">Tracy Olsson - 3 days ago</p>
                  </Link>
                </div>
                {/* <div className="swiper-slide text-center px-5 px-lg-3">
                  <Link onClick={onClick} target="_blank" className="slide-item">
                    <h3>
                      {" "}
                      <img
                        src={star}
                        alt=""
                      />{" "}
                      &nbsp;
                    </h3>
                    <h4>Excellent service</h4>
                    <p>Excellent service, great communication kept me in the loop everytime their was a change highly recommend</p>
                    <p className="time">Amanda Reid - 4 days ago</p>
                  </Link>
                </div> */}
                {/* <div className="swiper-slide text-center px-5 px-lg-3">
                  <Link onClick={onClick} target="_blank" className="slide-item">
                    <h3>
                      {" "}
                      <img
                        src={star}
                        alt=""
                      />{" "}
                      &nbsp;
                    </h3>
                    <h4>So helpful and straight forward</h4>
                    <p>So helpful and straight forward. Updates as soon as they receive them. Response time to queries are sufficient. </p>
                    <p className="time">Victoria Shaw - 4 days ago</p>
                  </Link>
                </div> */}
                <div className="swiper-slide text-center px-5 px-lg-3">
                  <Link
                    onClick={onClick}
                    target="_blank"
                    className="slide-item"
                  >
                    <h3>
                      {" "}
                      <img src={star} alt="" /> &nbsp;
                    </h3>
                    <h4>I saw an advert by Allegient saying I…</h4>
                    <p>
                      I saw an advert by Allegient saying I may be entitled to
                      compensation, so I applied,
                    </p>
                    <p className="time">Colin Herdman - 5 days ago</p>
                  </Link>
                </div>
                <div className="swiper-slide text-center px-5 px-lg-3">
                  <Link
                    onClick={onClick}
                    target="_blank"
                    className="slide-item"
                  >
                    <h3>
                      {" "}
                      <img src={star} alt="" /> &nbsp;
                    </h3>
                    <h4>Brilliant service"</h4>
                    <p>
                      Brilliant service , kept me well informed every step of
                      the way ! Thanks so much ...
                    </p>
                    <p className="time">JP - 7 days ago</p>
                  </Link>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Trustpilot;
