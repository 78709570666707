import { Api } from "../api/Api";
export const followUpUserInfo = () => {
  const getfollowUpUserInfo = async (token) => {
    const response = await Api.get(
      "v1/followup/user-info?user_token=" + token,
      {}
    );
    return response;
  };
  return { getfollowUpUserInfo };
};
