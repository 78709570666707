import React, { Fragment } from "react";
import questions from "../../../../../Constants/moneybarnQuestions";

const RadioQuestionnaireFlp = (props) => {
  const mainQuestion = props.questionnaire.question;
  const question_id = props.questionnaire.questionId;
  const radioName = `question_${props.questionnaire.questionId}`;

  const options = props.questionnaire.answers.map((option) => ({
    id: option.label + "_" + props.questionnaire.questionId,
    label: option.label,
    value: option.value,
  }));
  if (radioName == 'question_169'){
    const bank_name = localStorage.getItem('question_168');
    const bnk_name = bank_name ? questions[13].options[bank_name].label : "";
    var question_169 = "Is "+ bnk_name + " bank account still open?";
}

  return (
    <>
      <div
        className={`radio-box question5  animated fadeInUp ${props.showQuestionSlide}`}
      >
        <h3 style={{ color: "#444" }}>
          {radioName == 'question_169' ? question_169 :mainQuestion }
          {props.vehicleSecureYear && <span>{props.vehicleSecureYear} ?</span>}
        </h3>
        {question_id == "152" && <h2>{props.vehicleModel}</h2>}
        {question_id == "145" && (
          <ul>
            <li>
              Direct Debits or Standing Order payments were bouncing and being
              rejected
            </li>
            <li>Late payment or overlimit fees were being applied</li>
            <li>Money spent was more than money received in any one month</li>
          </ul>
        )}
        {question_id == "149" && (
          <ul>
            <li>
              Declared bankrupt or are currently subject to a bankruptcy
              petition
            </li>
            <li>Subject to a debt relief order</li>
            <li>Had an adverse County Court Debt Judgment or Sequestration</li>
          </ul>
        )}

        {options.map((option, i) => (
          <Fragment key={radioName + option.value}>
            <input
              type="radio"
              id={option.id}
              name={radioName}
              value={option.value}
              data-answertext={option.label}
              className=" "
              data-question_id={question_id}
              onClick={(e) => {
                props.slideChange(e, radioName);
              }}
              style={{ display: "none" }}
            />
            <label
              htmlFor={option.id}
              className="br radioa l_g next3 d-flex align-items-center"
              style={{
                lineHeight: 2,
                minHeight: "calc(30px + 3vmin)",
              }}
            >
              {option.label}
              <span className="">{String.fromCharCode(65 + i)}</span>
            </label>
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default RadioQuestionnaireFlp;
