import { useState } from "react";
import { Api } from "../api/Api";

export const useUnqualified = () =>{
    const [loading ,isLoading] = useState(false);
    const unQualifiedUser = async(message,uuid) =>{
        isLoading(true);
        const response = await Api.post('v1/unqualified-user',{
            message,
            uuid
        });
        isLoading(false);
        return response;
    }
    return( {unQualifiedUser,loading });
}