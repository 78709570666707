import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";
import RadioButton from "../../../UI/TextField";
import Button from "../../../UI/Button";
import car_front_fill from "../../../../assets/img/car-front-fill.png"


const RadioQuestiannaire = (props) => {
  const mainQuestion = props.questionnaire.question;
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + props.questionnaire.questionId;

  const radioId1 = props.questionnaire.answers[0].label + '_' + props.questionnaire.questionId;
  const radioId2 = props.questionnaire.answers[1].label + '_' + props.questionnaire.questionId;

  const radioValue1 = props.questionnaire.answers[0].value;
  const radioValue2 = props.questionnaire.answers[1].value;

  const radioLabel1 = props.questionnaire.answers[0].label;
  const radioLabel2 = props.questionnaire.answers[1].label;


  return (
    <>
      <section className={`question_block slide-top  slideSeptember ${props.showQuestionSlide}`}>
        <div className="row" >
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block d-flex align-items-center justify-content-center justify-content-lg-left">
            <div>
              <h1>
                {mainQuestion}{props.vehicleSecureYear && <span>{props.vehicleSecureYear} ?</span>}
              </h1>

              {question_id == '21' &&
                <div className="car-type mb-2"> <i className="bi bi-car-front-fill"><img src={car_front_fill} alt=""/></i>{props.vehicleModel}</div>
              }

              {question_id == '33' &&
                <ul class="list_ck mb-3  ms-4">
                  <li>Direct Debits or Standing Order payments were bouncing and being rejected</li>
                  <li>Late payment or overlimit fees were being applied</li>
                  <li>Money spent was more than money received in any one month</li>
                </ul>}

              {question_id == '35' &&
                <ul class="list_ck mb-3   ms-4 ">
                  <li>Declared bankrupt or are currently subject to a bankruptcy petition</li>
                  <li>Subject to a debt relief order</li>
                  <li> Had an adverse County Court Debt Judgment or Sequestration</li>
                </ul>}

            </div>
          </div>
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
            <div className="  d-flex align-items-center inner-text justify-content-center">
              <div className="text-center">
                <div className="mb-3 " >
                  <div className="btn-date " >
                    <input type="radio" id={radioId1} name={radioName} value={radioValue1} data-answertext={radioLabel1} className=" " data-question_id={question_id} onClick={(e) => { props.slideChange(e, radioName) }} />
                    <label htmlFor={radioId1} className=" ">{radioLabel1}</label>
                  </div>
                </div>
                <div className="mb-3 " id=" ">
                  <div className="btn-date">
                    <input type="radio" id={radioId2} name={radioName} value={radioValue2} data-answertext={radioLabel2} className=" " data-question_id={question_id} onClick={(e) => { props.slideChange(e, radioName) }} />
                    <label htmlFor={radioId2} className=" ">{radioLabel2} </label>
                  </div>
                </div>
                <div className="mb-3 mt-4" id=" ">
                  <div className="">
                    <button type="button" className="btn-back" id={`back_${question_id}`} name={`back_${question_id}`} onClick={props.previousSlide} >Back</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RadioQuestiannaire;
