import React, { useState } from "react";
import { NumberValidation } from "../../../../../Utility/NumberValidation";

const InputQuestionnaireFlp = (props) => {
  const mainQuestion = props.questionnaire.question;
  const inputName = "question_" + props.questionnaire.questionId;
  const question_id = props.questionnaire.questionId;
  const [inputValue, setInputValue] = useState();
  const [showError, setShowError] = useState("d-none");
  const validatePage = (evt) => {
    inputValue ? props.slideChange(evt, inputName) : setShowError("d-block");
  };
  const setValue = (e) => {
    setInputValue(e.target.value);
    e.target.value != "" ? setShowError("d-none") : setShowError("d-block");
  };

  const setNumberFormat = (e) => {
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value = e.target.value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const isCurrency = mainQuestion.toLowerCase().includes("amount");

  return (
    <>
      <div
        className={`radio-box question7  animated fadeInUp ${props.showQuestionSlide}`}
        id="breakdown5"
      >
        <h3 style={{ color: "#444" }}>{mainQuestion}</h3>
        <input
          type="tel"
          className="form-control input-box currency "
          placeholder={props?.placeholder ?? "Amount"}
          name={inputName}
          id={inputName}
          data-question_id={question_id}
          defaultValue=""
          onKeyPress={isCurrency ? NumberValidation : () => {}}
          onChange={(e) => setValue(e)}
          onKeyUp={(e) => (isCurrency ? setNumberFormat(e) : () => {})}
          maxLength={props?.maxLength ?? "15"}
        />
        <span
          className={`error_msg error_msg_custom ${showError}`}
          id="email_err"
        >
          Please enter {props?.placeholder ?? "Amount"}
        </span>
        <button
          type="button"
          className="btn-next next5"
          name={`next_${question_id}`}
          data-question_id={question_id}
          onClick={validatePage}
        >
          {" "}
          NEXT
        </button>
      </div>
    </>
  );
};

export default InputQuestionnaireFlp;
