import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  ConsoleView,
  MobileView,
  deviceType,
} from "react-device-detect";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useSignature } from "../../../../Hooks/useSignature";
import TextField from "../../../UI/TextField";
const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
  canvasValue: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }

    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const Signature = ({
  validation,
  validationMsg,
  trigger,
  clearErrors,
  setError,
  getValues,
}) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const [signatureData, setSignatureData] = useState("");
  const [isSigned, setIsSigned] = useState(false);

  const signValidate = async () => {
    try {
      const signPad = signPadRef.current;
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        var signData = signPad.getCanvas().toDataURL("image/png");
        setSignatureData(signData);
        clearErrors("signature_pad");
        return dispatch({ type: "validSignature", payload: { value: false } });
      } else {
        dispatch({ type: "validSignature", payload: { value: true } });
        dispatch({ type: "signPadChange", payload: { value: false } });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (state.isCanvasEmpty) {
      signValidate();
    }
  }, [state.isCanvasEmpty, isSigned]);

  const clearSign = () => {
    setSignatureData("");
    dispatch({ type: "signPadChange", payload: { value: false } });
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
      <p className="mt-2 mb-3">
        Please sign your signature in the box below to agree with the above
        statement.
      </p>
      <TextField
        type="hidden"
        className="form-control"
        name="signature_data"
        id="signature_data"
        value={signatureData}
        validation={validation({})}
      ></TextField>
      <div className="signature-div">
        <div className="">
          {(deviceType === "mobile" || deviceType === "tablet") && (
            <MobileView>
              <SignatureCanvas
                canvasProps={{
                  width: 300,
                  height: 100,
                  name: "signature_pad",
                  className: `signature-pad d-lg-block ${
                    !!validationMsg?.signature_pad?.message && !state.isValid
                      ? "anim_bt efct_filed"
                      : ""
                  }`,
                  id: "signature-pad",
                }}
                ref={signPadRef}
                onEnd={() => {
                  setIsSigned(!isSigned);
                  dispatch({
                    type: "signPadChange",
                    payload: { value: true },
                  });
                }}
              />
            </MobileView>
          )}
          {deviceType === "browser" && (
            <BrowserView>
              <SignatureCanvas
                canvasProps={{
                  height: 100,
                  width: 300,
                  name: "signature_pad",
                  className: `signature-pad d-lg-block ${
                    !!validationMsg?.signature_pad?.message && !state.isValid
                      ? "anim_bt efct_filed"
                      : ""
                  }`,
                  id: "signature-pad",
                }}
                ref={signPadRef}
                onEnd={() => {
                  setIsSigned(!isSigned);
                  dispatch({
                    type: "signPadChange",
                    payload: { value: true },
                  });
                }}
              />
            </BrowserView>
          )}
        </div>
      </div>

      <div className="signature-error-div">
        {!validationMsg?.signature_pad?.message && state.isValid && (
          <span className="error_msg " id="email_err">
            Draw valid signature!
          </span>
        )}

        {validationMsg.signature_pad && (
          <span className="error_msg " id="email_err">
            {validationMsg.signature_pad.message}
          </span>
        )}
      </div>

      <div className=" col-lg-12 pb-4">
        {/* <span id="sign_error" className="error_msg error"></span> */}

        <button
          type="button"
          className="button-clear"
          id="clear"
          onClick={clearSign}
        >
          Clear
        </button>
      </div>
    </>
  );
};

export default Signature;
