import React from 'react'

export default function TopSection() {
  return (
   <>
    <div className="top_fix_stcik py-lg-1 py-md-1  py-sm-0 ">
      <div className="text-center">
        <div className="inner">
          <p> ⓘ Good to know: We are a Claims Management Company (CMC). You do not need to use a CMC to make your complaint to your lender. </p>
          <p>If your complaint is not successful you can refer it to the Financial Ombudsman Service yourself for free. </p>
        </div>
      </div>
    </div>
    <div className="clearfix"></div>
   </>
  )
}
