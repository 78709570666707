import React from "react";
import investigation1 from "../../../../assets/moneybarn/img/investigation-1.svg";
import stepicon1 from "../../../../assets/moneybarn/img/step-icon-1.svg";
import negotiation1 from "../../../../assets/moneybarn/img/negotiation-1.svg";
import cashsvg from "../../../../assets/moneybarn/img/cash.svg";
const StageProcess = () => {
    return (
        <>
            <section className="claim-process">
                <div className="container text-center">
                    <div className="heading-sect">
                        <h2>Our Unaffordable Lending Claims Process</h2>
                        <p>
                            We realise that claiming against a high cost loan lender can seem
                            daunting. We aim to make the claiming process as simple as possible. We
                            specialise in affordability claims. Our experienced team will
                            communicate with the lender (and where required, the Ombudsman service)
                            on your behalf. We use bespoke technology to ensure efficient claims
                            handling. Throughout the process, we inform you of claim progress using
                            a “stage process”, so you can track your progress easily. Please
                            remember though, that you do not need to use a claims management company
                            to make your complaint to your lender, and if your complaint is not
                            successful you can refer it to the Financial Ombudsman Service yourself
                            for free.
                        </p>
                    </div>
                    <div className="steps-wrapper pt-5">
                        <ul>
                            <li className="icon">
                                <div className="single-step">
                                    <div className="step-icon">
                                        <img src={investigation1} alt="" />
                                    </div>
                                    <h3>Step 1</h3>
                                    <p>Pre complaint investigation and analysis</p>
                                </div>
                            </li>
                            <li className="icon">
                                <div className="single-step">
                                    <div className="step-icon step-2">
                                        <img src={stepicon1} alt="" />
                                    </div>
                                    <h3>Step 2</h3>
                                    <p>Formal mis-selling complaint made</p>
                                </div>
                            </li>
                            <li className="icon">
                                <div className="single-step">
                                    <div className="step-icon step-3">
                                        <img src={negotiation1} alt="" />
                                    </div>
                                    <h3>Step 3</h3>
                                    <p>Lender responds with a Final Response Letter</p>
                                </div>
                            </li>
                            <li className="icon4">
                                <div className="single-step">
                                    <div className="step-icon4">
                                        <img src={cashsvg} alt="" />
                                    </div>
                                    <h3>Step 4</h3>
                                    <p>
                                        If appropriate resolution cannot be reached with the lender,
                                        referral to Financial Ombudsman Service.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

        </>
    )
}
export default StageProcess;