import { useState } from "react";
import * as EnvConstants from "../Constants/EnvConstants";
import { CommonPhoneValidation } from "../api/Api";

export const usePhoneValidation = () => {
  const [isPending, setIsPending] = useState(false);

  const phoneValidation = async (phone) => {
    setIsPending(true);
    let response = null;

    try {
      response = await CommonPhoneValidation.post("/api/validate", {
        ProjectCode: EnvConstants.AppAdtopiaUPID,
        Environment: EnvConstants.AppEnv,
        Phone: phone,
      });
    } catch (error) {
      console.log(error);
    }

    setIsPending(false);

    return response;
  };

  return { phoneValidation, isPending };
};
