import {useState} from 'react';
import {Api} from '../api/Api';

export const useQuestionnaire = () => {
    const [isLoading,setIsLoading] = useState(false);
    const SaveQuestionnaire = async (visitor_parameters,question_data,message_type,data,query_string,form_data) => {
      
        setIsLoading(true);
        const response = await Api.post("v1/data-ingestion-pipeline", {
            visitor_parameters,
            question_data,
            message_type,
            data,
            query_string,
            form_data
        });
        setIsLoading(false);
        return response;
    }
    const storeVehicleDetails = async (visitor_parameters,data,form_data,query_string, vehicle_data, message_type) => {
        const response = await Api.post(
          "v1/data-ingestion-pipeline",
          {
            visitor_parameters,
            data,
            form_data,
            query_string,
            vehicle_data,
            message_type
          });
        return response;
    };
    return {
        isLoading,
        SaveQuestionnaire,
        storeVehicleDetails
    }
}
