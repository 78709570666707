import React, { useEffect, useState } from "react";
import logo from "../../../src/assets/img/logo-white1.png";
import { useUnqualified } from "../../Hooks/useUnqualified";
import Footer from "../Includes/Layouts/Moneybarn/Footer";
import { userInfo } from "../../Hooks/userInfo";

const Unqualified = () => {
  const [fName, setfName] = useState();
  const query = new URLSearchParams(window.location.search);
  const uuid = query.get("uuid");
  const { unQualifiedUser } = useUnqualified();
  const { getUserInfo } = userInfo();
  useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem("formData"));
    if (formdata) {
      const fName = formdata.txtFName ? Capitalize(formdata.txtFName) : "";
      setfName(fName);
    }else{
    (async () => {
      const response = await getUserInfo(uuid);
      let dataLength = response.data.response.length;
      if (dataLength > 0) {
        const fName = response.data.response[0].first_name
          ? Capitalize(response.data.response[0].first_name)
          : "";
          setfName(fName);
        }
        
    })();
  }
    (async () => {
      await unQualifiedUser("sent-success", uuid);
    })();
  }, []);
  // localStorage.clear();
  function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return (
    <>
      <div className="unqualified_success">
        <div class="clearfix"></div>
        <header class="header_success">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 text-center py-3">
                <a href="" class="logo">
                  <img src={logo} alt="" />
                </a>
              </div>
            </div>
          </div>
        </header>
        <div class="clearfix"></div>
        <section className="py-4 py-lg-5 py-md-5 py-sm-5 mb-lg-2 mb-md-2 mb-sm-0 ">
          <div className="container ">
            <div className="row">
              <div
                className="col-lg-8 offset-lg-2"
                style={{ backgroundColor: "rgb(249 249 249)" }}
              >
                <p className="mb-3" >Hi {fName},</p>
                <p className="mb-3">
                  Thank you for your enquiry about making an Unaffordable Lending claim. We have checked the information you have provided to see if we can help you, and unfortunately our criteria has not been met.
                </p>
                <p className="mb-2 note-head-dng">What this means</p>
                <p className="mb-3">
                  We are sorry to say this means we won’t be representing you or
                  taking this claim any further for you.
                </p>
                <p className="mb-3">
                  There can be various reasons why we are unable to help. Here
                  are a few examples:
                </p>
                <ul className="mb-3 points-ul" style={{ paddingLeft: "40px" }}>
                  <li style={{ listStyleType: "circle" }}>
                    If the information provided does not strongly suggest the
                    credit was unaffordable, we cannot take on the claim.
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    The lender needs to be UK-based and still trading for a
                    claim to be made against it.
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    We cannot represent customers who are in an insolvency
                    arrangement, such as an Individual Voluntary Arrangement
                    (IVA).
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    You cannot make a claim about the same borrowing more than
                    once.
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    Customers who want to borrow from a lender in the future
                    probably should not make an Unaffordable Lending claim
                    against that lender.
                  </li>
                </ul>
                <p>
                  Allegiant is only able to continue to provide our service to
                  our customers through the fees we charge on any compensation
                  payment won. We have seen examples of credit being given
                  irresponsibly, but there not being any compensation payment
                  due because the customer has an outstanding debt with the
                  lender. In these circumstances, Allegiant has to think
                  carefully about taking on claims where there will likely be no
                  fee as a result of our work.
                </p>

                <h3 className="mb-2 note-head-dng"> What to do next</h3>
                <p className="mb-3">
                  If you still wish to pursue your Unaffordable Lending claim
                  against your lender, you may like to consider your other
                  options, as Allegiant will not be assisting you with this
                  claim.
                </p>
                <p className="mb-3">
                  As a transparent claims management company, we only want to
                  help customers who have thought about the different ways of
                  claiming and decided to use a claims management service they
                  are happy to pay for. So, it is important we tell you this:-
                </p>
                <p className="mb-3">
                  <i>
                    You do not need to use a claims management company to make
                    your complaint to your lender. If your complaint is
                    unsuccessful, you can refer it to the Financial Ombudsman
                    Service (FOS) for free.{" "}
                  </i>
                </p>
                <p className="mb-3">
                  If you decide that you want to use a claims management
                  company, it is always a good idea to compare different
                  companies to work out the best fit for you.{" "}
                </p>
                <p className="mb-3">
                  Another way of claiming is by using a solicitor. Solicitors
                  differ from claims management companies as solicitors can
                  bring claims in court (a more formal process than FOS). They
                  can also use FOS too. If this interests you, we recommend that
                  you seek independent legal advice from a solicitor. If you
                  have legal expenses insurance, solicitor costs may be covered
                  (which may mean that you keep more of your money than using
                  us).
                </p>
                <p className="mb-3">
                  You may also like to read the blogs on our website at{" "}
                  <a href="https://allegiant.co.uk/insights/">
                    https://allegiant.co.uk/insights/
                  </a>
                  . These articles provide insight into a variety of topics,
                  including: unaffordable lending, financial difficulties and
                  scams.
                </p>

                <p className="tq-end-here text-center mb-0">
                  Thank you for your interest in our service.
                </p>
                <p className="tq-end-here text-center mb-0">
                  Allegiant Finance Services Limited
                </p>
              </div>
            </div>
          </div>
        </section>
        <div class="clearfix"></div>
        <Footer />
      </div>
    </>
  );
};
export default Unqualified;
