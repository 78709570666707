import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";
import RadioButton from "../../../UI/TextField";
import Button from "../../../UI/Button";


const CheckBoxQuestionnaire = (props) => {
  const mainQuestion = props.questionnaire.question;
  const question_id = props.questionnaire.questionId;
  const checkboxName = 'question_' + props.questionnaire.questionId + '[]';

  const checkboxId0 = (props.questionnaire.answers[0]) ? 'question_' + props.questionnaire.questionId + '_' + props.questionnaire.answers[0].value : '';
  const checkboxId1 = (props.questionnaire.answers[1]) ? 'question_' + props.questionnaire.questionId + '_' + props.questionnaire.answers[1].value : '';
  const checkboxId2 = (props.questionnaire.answers[2]) ? 'question_' + props.questionnaire.questionId + '_' + props.questionnaire.answers[2].value : '';
  const checkboxId3 = (props.questionnaire.answers[3]) ? 'question_' + props.questionnaire.questionId + '_' + props.questionnaire.answers[3].value : '';

  const checkboxValue0 = (props.questionnaire.answers[0]) ? props.questionnaire.answers[0].value : '';
  const checkboxValue1 = (props.questionnaire.answers[1]) ? props.questionnaire.answers[1].value : '';
  const checkboxValue2 = (props.questionnaire.answers[2]) ? props.questionnaire.answers[2].value : '';
  const checkboxValue3 = (props.questionnaire.answers[3]) ? props.questionnaire.answers[3].value : '';

  const checkboxLabel0 = (props.questionnaire.answers[0]) ? props.questionnaire.answers[0].label : '';
  const checkboxLabel1 = (props.questionnaire.answers[1]) ? props.questionnaire.answers[1].label : '';
  const checkboxLabel2 = (props.questionnaire.answers[2]) ? props.questionnaire.answers[2].label : '';
  const checkboxLabel3 = (props.questionnaire.answers[3]) ? props.questionnaire.answers[3].label : '';

  const [checkboxValues, setCheckboxValues] = useState([]);
  const [initError, setInitError] = useState()
  const [showError, setShowError] = useState('d-none');

  const insertValues = (e) => {
    var values = e.target.value;
    if (values == '402') {
      (checkboxValues.includes(values)) ? setCheckboxValues(checkboxValues.filter(item => item !== values)) : setCheckboxValues([values]);
      document.getElementById(checkboxId0).checked = false;
      document.getElementById(checkboxId1).checked = false;
      document.getElementById(checkboxId2).checked = false;
    } else {
      if (checkboxValues.includes(values)) {
        setCheckboxValues(checkboxValues.filter(item => item !== values));
      } else {
        if (checkboxValues.includes('402')) {
          setCheckboxValues([values]);
          document.getElementById(checkboxId3).checked = false;
        } else {
          setCheckboxValues([...checkboxValues, values]);
        }
      }
    }
    (checkboxValues != '') ? setShowError('d-none') : setShowError('d-block');
    setInitError('1');
  };

  const pageValidate = (e) => {
    (checkboxValues != '') ? props.slideChange(e, checkboxValues) : setShowError('d-block');
  }

  useEffect(() => {
    (checkboxValues == '' && initError == '1') ? setShowError('d-block') : setShowError('d-none');
  }, [checkboxValues]);

  return (
    <>
      <section className={`question_block slide-top  slideSeptember ${props.showQuestionSlide}`}>
        <div className="row" >
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block d-flex align-items-center justify-content-center justify-content-lg-left">
            <div>
              <h1>
                {mainQuestion}
              </h1>
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
            <div className="d-flex align-items-center inner-text justify-content-center">
              <div className="text-center">

                {checkboxId0 &&
                  <div className="mb-2 mb-lg-3">
                    <div className="btn-date-checkbox" >
                      <input type="checkbox" id={checkboxId0} name={checkboxName} value={checkboxValue0} className="custom-checkbox" onClick={(e) => { insertValues(e) }} />
                      <label htmlFor={checkboxId0} className=" btn-cml">{checkboxLabel0}</label>
                    </div>
                  </div>}

                {checkboxId1 &&
                  <div className="mb-2 mb-lg-3 " id=" ">
                    <div className="btn-date-checkbox">
                      <input type="checkbox" id={checkboxId1} name={checkboxName} value={checkboxValue1} className="custom-checkbox" onClick={(e) => { insertValues(e) }} />
                      <label htmlFor={checkboxId1} className=" btn-cml">{checkboxLabel1}</label>
                    </div>
                  </div>}

                {checkboxId2 &&
                  <div className="mb-2 mb-lg-3 " id=" ">
                    <div className="btn-date-checkbox">
                      <input type="checkbox" id={checkboxId2} name={checkboxName} value={checkboxValue2} className="custom-checkbox" onClick={(e) => { insertValues(e) }} />
                      <label htmlFor={checkboxId2} className=" btn-cml">{checkboxLabel2}</label>
                    </div>
                  </div>}

                {checkboxId3 &&
                  <div className="mb-2 mb-lg-3 " id=" ">
                    <div className="btn-date-checkbox">
                      <input type="checkbox" id={checkboxId3} name={checkboxName} value={checkboxValue3} className="custom-checkbox" onClick={(e) => { insertValues(e) }} />
                      <label htmlFor={checkboxId3} className=" btn-cml">{checkboxLabel3}</label>
                    </div>
                  </div>}

                <span className={`error_msg error_msg_custom ${showError}`} id="email_err">Please select an option</span>

                <div className="mb-2 mb-lg-3  mt-4">
                  <button type="button" className="btn com_bnt " data-question_id={question_id} id={`next_${question_id}`} name={`next_${question_id}`} onClick={(e) => { pageValidate(e) }}> Next</button>
                </div>
                <div className="mb-2 mb-lg-3" id=" ">
                  <div className="">
                    <button type="button" className="btn-back " id={`back_${question_id}`} name={`back_${question_id}`} onClick={props.previousSlide}>Back</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckBoxQuestionnaire;
