export const Salutation = [
  {
    label: "Select Title",
    value: "",
  },
  {
    label: "Dr.",
    value: "Dr",
  },
  {
    label: "Mr.",
    value: "Mr",
  },
  {
    label: "Mrs.",
    value: "Mrs",
  },
  {
    label: "Ms.",
    value: "Ms",
  },
  {
    label: "Miss.",
    value: "Miss",
  },

  {
    label: "Prof.",
    value: "Prof",
  },
];

export const DobDay = [{ label: "Day", value: "" }];
const startDay = 1;
const endDay = 31;
for (var i = startDay; i <= endDay; i++) {
  var objDobDay = {};
  objDobDay["label"] = i;
  objDobDay["value"] = i;
  DobDay.push(objDobDay);
}

export const DobMonth = [
  { label: "Month", value: "" },
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DobYear = [{ label: "Select Year of Birth", value: "" }];
const startYear = 1989;
const endYear = 1910;
for (var k = startYear; k >= endYear; k--) {
  var objDob = {};
  objDob["label"] = k;
  objDob["value"] = k;
  DobYear.push(objDob);
}
const BannerYearAdv1 = [];
const start = 1926;
const end = 1989;
for (var i = start; i <= end; i++) {
  if (start === i) {
    BannerYearAdv1.push([-i]);
  } else {
    BannerYearAdv1.push([i]);
  }
}
export { BannerYearAdv1 };
export const signCheckText = `By signing and by pressing “Submit” below, you agree to Check for SSB Group <a href="https://www.ssblaw.co.uk/uploads/files/website-terms-of-use-and-privacy-policy-may-19.pdf" target="_blank">Terms and Conditions</a> and that we may use your signature on the <a style="cursor:pointer; color:#00aab1 !important;" href=""> Letter of Authority</a> that we send to your bank.  A copy of the <a href="https://www.ssblaw.co.uk/uploads/files/website-terms-of-use-and-privacy-policy-may-19.pdf" target="_blank">Terms and Conditions</a> and <a style="cursor:pointer; color:#00aab1 !important" href="">Letter of Authority</a> will be sent to you via email.
`;

export const FinanceYear = [{ label: "Year", value: "" }];
const financeStartYear = 2008;
const financeEndYear = 2020;
for (var k = financeStartYear; k <= financeEndYear; k++) {
  var objDob = {};
  objDob["label"] = k;
  objDob["value"] = k;
  FinanceYear.push(objDob);
}
export const FinanceMonth = [{ label: "Month", value: "" }];
const financeStartMonth = 1;
const financeEndMonth = 12;
for (var k = financeStartMonth; k <= financeEndMonth; k++) {
  var objDob = {};
  objDob["label"] = Months[k - 1];
  objDob["value"] = Months[k - 1];
  FinanceMonth.push(objDob);
}
export const faq = [
  {
    id: 1,
    head: "What is a Packaged Bank Account (PBA)?",
    content:
      "<p>Customers have been charged a monthly fee for an account which has a bundle of extra insurances such as travel, smart phone and breakdown cover. However, it has been found that these insurances are not always suitable for the customer or that the customer might not always be able to make a claim on the policy, for example, if their health would prevent them from making a claim. It can sometimes be difficult for customers to make a claim on these policies due to restrictions in the small print. Some customers have found that they have no use for some or all of insurances sold but the bank failed to draw this to their attention.</p>",
  },
  {
    id: 2,
    head: "How do I know if I have a Packaged Bank Account?",
    content:
      "<p>A good place to start would be to look at your monthly bank statements to see if you have been paying a fee for your account. Different providers of Packaged Bank Accounts use different names to market this product so your statement will not necessarily always make it clear as to the nature of the account. If in any doubt over a charge you can clarify it with your bank.  If you previously held an account and have downgraded or closed it, you can still claim.</p",
  },
  {
    id: 3,
    head: "Will making a claim affect my relationship with the bank?",
    content:
      "<p>The claim will not affect the relationship you have with the bank. The bank has a duty of care to treat you fairly and therefore you would not be treated any differently for having made a claim.</p>",
  },
  {
    id: 4,
    head: "What fee is charged for the service?",
    content:
      "<p>The first point to make clear is that no fee is payable unless we are successful in winning compensation for you. Therefore, we work on a strictly no win, no fee basis. In the event that we secure you a positive outcome leading to compensation, our fee will be a 39.99% of any redress payment inclusive of VAT.</p>",
  },
  {
    id: 5,
    head: "How much compensation can I expect if the Packaged Bank Account was mis-sold?",
    content:
      "<p>If the Packaged Bank Account is established as mis-sold normal practice would be to refund either all or part of the premiums you have made for the account and add 8% interest on top.</p>",
  },
  {
    id: 1,
    head: "Can I make a claim if my Packaged Bank Account is closed?",
    content:
      "<p>Yes, just because an account is closed, or downgraded to a free account does not prevent you from claiming.</p>",
  },
  {
    id: 1,
    head: "How long will the claim take?",
    content:
      "<p>This depends of the provider of the account and the work load they are currently experiencing. Their regulator gives 8 weeks to give a response to your claim. If a response is not forthcoming within this time frame, the claim can be referred to the Financial Ombudsman Service.</p>",
  },
  {
    id: 1,
    head: "Can I make a claim myself?",
    content:
      "<p>Yes, you can go directly to your provider and lodge a claim if you wish. However, our service has been specifically designed to take the hassle and stress out of doing this by yourself. Our process will help you to present a claim that reflects all your key claim points to aid you win any potential redress. The aim is to make sure that your claim is taken seriously. Please note that the Financial Ombudsman Scheme could be used or any other mechanism such as legal expenses insurance to pursue a claim.</p>",
  },
];
