
export const CatalogueClaimsQualificationCheck = () => {
    const localQuestionnaire = JSON.parse(localStorage.getItem("questionData"));
  
    var qualified_124 = 1;
    var qualified_126 = 1;
    var qualified_127 = 1;
    var qualified_128 = 1;
    var qualified_133 = 1;
    var qualified_124_126 = 1;
    var qualified_129_130 = 1;
    var income = '';
    var expence = '';
    var answer_129 = '';
    var answer_130 = '';
    var answer_133 = '';
    var answer_124 = '';
    var answer_126 = '';
    var answer_127 = '';

    if (localQuestionnaire[124]) {
        answer_124 = localQuestionnaire[124];
        if ((localQuestionnaire[124] == '2007 or earlier' || localQuestionnaire[124] == '2022')) {
            qualified_124 = 0;
        }
    }
    if (localQuestionnaire[126]) {
        answer_126 = localQuestionnaire[126];
        if (localQuestionnaire[126] == '2007 or earlier') {
            qualified_126 = 0;
        }
    }
    if ((localQuestionnaire[127])) {
        if (localQuestionnaire[127] == 'More than £3000') {
            answer_127 = 3000;
        } else {
            answer_127 = parseInt(localQuestionnaire[127].split('-')[1].split('£')[1]);
        }
        if(answer_127 <= 500){
            qualified_127 = 0;
        }
    }
    if ((localQuestionnaire[128]) && (localQuestionnaire[128] == '233')) {
        qualified_128 = 0;
    }
    if (localQuestionnaire[129]) {
        answer_129 = localQuestionnaire[129];
    }
    if (localQuestionnaire[130]) {
        answer_130 = localQuestionnaire[130];
    }
    if (localQuestionnaire[131]) {
        if (localQuestionnaire[131] == 'More than £3000') {
            income = 3000;
        }else{
            income = parseInt(localQuestionnaire[131].split('-')[0].split('£')[1]);
        }
    }
    if (localQuestionnaire[132]) {
        if (localQuestionnaire[132] == 'More than £3000') {
            expence = 3000;
        }else{
            expence = parseInt(localQuestionnaire[132].split('-')[0].split('£')[1]);
        }
    }
    if (localQuestionnaire[133]) {
        answer_133 = localQuestionnaire[133];
    }
    if(answer_126 != ""){
        if(parseInt(answer_126) - parseInt(answer_124) <= 1){
            qualified_124_126 = 0;
        }
    }
    if((answer_129 == '240' || answer_129 == '241') && (answer_130 == '244' || answer_130 == '245')){
        qualified_129_130 = 0;
    }
    if(income > 0 && expence > 0){
        var avg = (expence/income) * 100;
        if(avg < 20){
            if(answer_133 == '249'){
                qualified_133 = 0;
            }
        }
    }
    if (qualified_124 && qualified_126 && qualified_127 && qualified_128 && qualified_124_126 && qualified_129_130 && qualified_133) {
        return true;
    } else {
        return false;
    }

}