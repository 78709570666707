import React from "react";
import { Salutation } from "../../../../Constants/ConstantsMoneybarn";
import { DateOfBirth } from "../../../../Utility/DateOfBirthMB";
import SelectBox from "../../../UI/SelectBox";
import TextField from "../../../UI/TextField";
import PostCode from "./MoneybarnPostCode";

const PersonalDetails = ({
  className,
  validation,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  splitForm,
  setError,
}) => {
  const form = splitForm.current;
  const question_3 = getValues();

  return (
    <div id="slide-5" className={`slidetop_adjust ${className}`}>
      <div className="form-group">
        <div className="mb-3">
          <SelectBox
            className="form-control wid_title"
            OptionValue={Salutation}
            name="lstSalutation"
            id="lstSalutation"
            onChange={() => clearErrors("lstSalutation")}
            clearErrors={clearErrors}
            myRef={validation({ required: "Please Select Title" })}
            validationMsg={
              validationMsg.lstSalutation && validationMsg.lstSalutation.message
            }
          ></SelectBox>
        </div>
        <div className="mb-3">
          <TextField
            type="text"
            className="form-control capital"
            placeholder="First Name*"
            name="txtFName"
            id="txtFName"
            onBlur={() => trigger("txtFName")}
            validation={validation({
              required: "Please Enter First Name",
              minLength: {
                value: 3,
                message: "Please Enter a Valid First Name",
              },
              pattern: {
                value: /[^0-9]+$/,
                message: "Your Name is Invalid. Please Recheck",
              },
            })}
            onKeyPress={(e) => {
              const alphabetsRegex = /[^0-9]/;
              if (alphabetsRegex.test(e.key)) {
                return true;
              }

              e.preventDefault();
              return false;
            }}
          ></TextField>
          <div className="mb-3">
            {validationMsg.txtFName && (
              <span className="error" id="email_err">
                {validationMsg.txtFName.message}
              </span>
            )}
          </div>
        </div>
        <div className="mb-3">
          <TextField
            type="text"
            className="form-control capital"
            placeholder="Surname*"
            name="txtLName"
            id="txtLName"
            onBlur={() => trigger("txtLName")}
            validation={validation({
              required: "Please Enter Last Name",
              minLength: {
                value: 3,
                message: "Please Enter a Valid Last Name",
              },
              pattern: {
                value: /[^0-9]+$/,
                message: "Your Name is Invalid. Please Recheck",
              },
            })}
            onKeyPress={(e) => {
              const alphabetsRegex = /[^0-9]/;
              if (alphabetsRegex.test(e.key)) {
                return true;
              }

              e.preventDefault();
              return false;
            }}
          ></TextField>
          <div className="mb-3">
            {validationMsg.txtLName && (
              <span className="error" id="email_err">
                {validationMsg.txtLName.message}
              </span>
            )}
          </div>
        </div>

        <PostCode
          validation={validation}
          validationMsg={validationMsg}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          getValues={getValues}
          splitForm={splitForm}
          showButton={"show"}
          showButtonAdditional={"d-none"}
        />

        <DateOfBirth
          validation={validation}
          validationMsg={validationMsg}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          getValues={getValues}
          splitForm={splitForm}
        />
      </div>
    </div>
  );
};
export default PersonalDetails;
