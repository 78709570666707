import {Api} from "../api/Api";
import {useState} from 'react';

export const useFollowupUserQuestions = () => {
    const [isLoading,setIsLoading] = useState(false);
    const saveFollowupUserQuestions = async (question_data,followup_data,page_name,message_type) => {
        const questionnaireSubmit = await Api.post("/v1/data-ingestion-pipeline", {
            question_data,
            followup_data,
            page_name,
            message_type
        });
        setIsLoading(false);
        return questionnaireSubmit;
    }
    const storeFollowupVehicleDetails = async (vehicle_data, followup_data, message_type) => {
        const response = await Api.post(
          "v1/data-ingestion-pipeline",
          {
            vehicle_data,
            followup_data,
            message_type
          });
        return response;
    };
    return { 
        isLoading,
        saveFollowupUserQuestions,
        storeFollowupVehicleDetails
    }
}