import React ,{useContext,useRef,useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import Header from "../../Includes/Layouts/0602UFBH_A1/Header";
import TopSection from "../../Includes/Layouts/Common/TopSection";
import sms from "../../../assets/img/send_sms.jpg";
import Footer from "../../Includes/Layouts/0602UFBH_A1/Footer";
import TextField from "../../UI/TextField";
import "../../../assets/css/otp.scss";
import logo from '../../../assets/img/logo.png';
import { CheckUUID } from "../../../Utility/CheckUUID";
import { useOtp } from "../../../Hooks/useOtp";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import OtpField from "../../Includes/Layouts/Otp/OtpField";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useLocation } from "react-router-dom";
import { userInfo } from "../../../Hooks/userInfo";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";

const OTP_BH = () =>{

  const {getValues,register,trigger,validation,ref,state,setValue,reset } =
  useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const otpForm = useRef(null);
  const history = useHistory();
  const { isCheck } = CheckUUID();
  const { sendOtp,saveOtp,regenerateOtp,saveMilestone, isLoading } = useOtp();
  const [sendto,setSendto]    = useState();
  const [reSendto,setReSendto]= useState(null);
  const [errMsg,setErrMsg]    = useState('d-none');
  const [msgSend,setMsgSend]  = useState('d-none');
  const [phoneBtn,setPhoneBtn]= useState(false);
  const [emailBtn,setEmailBtn]= useState(false);
  const [invalidError,setInvalidError]= useState(false);
  const [submitBtn,setSubmitBtn]= useState(false);
  const [btnName,setBtnName]  = useState('');
  const [fName, setfName] = useState();
  const [userPhone, setUserPhone] = useState();
  const [userMail, setUserMail] = useState();
  const location = useLocation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const {DynamicRouteSplitName,DynamicRouteNextPage}   = DynamicRoutingSplit('UNFR_0602UFBH_A1','otp_blackhorse');
  let bank_id = 132;
  let customUuid = '';
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const local_uuid = localStorage.getItem('uuid');
  customUuid = (query_uuid)? query_uuid : local_uuid;
  const { getUserInfo } = userInfo();
  
  useEffect(() => {
    if(formParameters ===  undefined || formParameters === null || formParameters == ''){
        (async () => {
            const response = await getUserInfo(
                customUuid
            );
            let dataLength = (response.data.response).length;
            if(dataLength > 0) {
              const userId = (response.data.response[0].id) ? response.data.response[0].id : '';
              const fName = (response.data.response[0].first_name) ? Capitalize(response.data.response[0].first_name) : '';
              const phone = (response.data.response[0].telephone) ? response.data.response[0].telephone : '';
              const userMail = (response.data.response[0].email) ? response.data.response[0].email : '';
              if(userId == '' || customUuid === undefined || customUuid === null || customUuid == ''){
                history.push("/404");
              }
              setfName(fName);
              setUserPhone(phone);
              setUserMail(userMail);
              sentOtp(fName,phone,userMail);
            }else{
              history.push("/404");
            }
      })();
    }
    else
    {
      const fName = formParameters.txtFName;
      const phone = formParameters.txtPhone;
      const userMail = formParameters.txtEmail;
      setfName(fName);
      setUserPhone(phone);
      setUserMail(userMail);
      sentOtp(fName,phone,userMail);
    }
  }, []);
  const sentOtp = async(fName,userPhone,userMail) => {
    try {
        const response = await sendOtp(   
          userMail,
          userPhone,
          customUuid,
          fName,
          bank_id
        );
        let otpCount = response.data.Email_count + response.data.Phone_count;
        if(otpCount > 6) {
          setSubmitBtn(true);
          setEmailBtn(true);
          setPhoneBtn(true);
        }
       if(response.data.phoneValid == 1 ) {
         setSendto(response.data.user_phone);
       } else {
        setSendto(response.data.user_email);
       }
      } 
    catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [location]);

  const handleBoxChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("_");
    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(
          `input[name=otp_${fieldIntIndex + 1}]`
        );
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  }
  const validNumber = (evt) =>{
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
        return false;
    }
    else {
        return true;
    } 
  }

  const handleSubmit = async (e) => {
    const form = otpForm.current;
    const values = getValues();
    const formData = values; 
    let customUuid = '';
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_uuid = localStorage.getItem('uuid');
    customUuid = (query_uuid)? query_uuid : local_uuid;
    try {
        const response = await saveOtp(   
          customUuid,
          formData,
          bank_id
        );
        if (response.data.msg === "Success") {
          setErrMsg('d-none');
          const response = await saveMilestone(   
            customUuid,
            
          );
          history.push("/"+ DynamicRouteNextPage +"?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + DynamicRouteSplitName);
        } else {
          setErrMsg('show');
          let customUuid = '';
          const query = new URLSearchParams(window.location.search);
          const query_uuid = query.get("uuid");
          const local_uuid = localStorage.getItem('uuid');
          customUuid = (query_uuid)? query_uuid : local_uuid;

          try {
            const response = await regenerateOtp(  
              null,
              customUuid,
              null,
              'InvalidRegenerate',
              fName,
              bank_id
            );
            let emailOtpCount = (response.data.otpCount.Email) ? parseInt(response.data.otpCount.Email) : 0;
            let phoneOtpCount = (response.data.otpCount.Phone) ? parseInt(response.data.otpCount.Phone) : 0;
            let otpCount = emailOtpCount + phoneOtpCount;

            if (response.data.status === "success") {
              if((response.data.resend == 1) && (otpCount > 6) ){
                setSubmitBtn(true);
              }
              setMsgSend('show');
              reset();
            }
          } 
         catch (e) {
          console.warn(e);
        }
        }
      } 
     catch (e) {
      console.warn(e);
    }
  };
  const handleClick = (e) =>{
    let name = e.target.name;
    setValue(name,'');
  }

  const resendOtp = async (e) =>{
    const form = otpForm.current;
    let value  = form[e.target.dataset.otpmode].value;
    let sendTo = "";
    let otpMode = "";
    if(e.target.name == "btnphone"){
      setBtnName("btnphone");
      setPhoneBtn(true);
      sendTo = (reSendto == null) ? value : reSendto;
      otpMode = "Phone";
    } else if(e.target.name == "btnemail"){
      setBtnName("btnemail");
      setEmailBtn(true);
      sendTo = (reSendto == null) ? value : reSendto;
      otpMode = "Email";
    }
    let customUuid = '';
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_uuid = localStorage.getItem('uuid');
    customUuid = (query_uuid)? query_uuid : local_uuid;
    try {
      const response = await regenerateOtp(  
        sendTo,
        customUuid,
        otpMode,
        null,
        fName,
        bank_id
      );
      setTimeout(() => {
        (((response.data.resend == 1) && phoneBtn) || ((response.data.resend == 1) && (otpMode == "Phone"))) ? setPhoneBtn(true) : setPhoneBtn(false) ;
        (((response.data.resend == 1) && emailBtn) || ((response.data.resend == 1) && (otpMode == "Email"))) ? setEmailBtn(true) : setEmailBtn(false) ;
        setBtnName('');
      }, 3000);
      if (response.data.status === "success") {
        setErrMsg('d-none');
        setMsgSend('show');
        setSendto(sendTo);
        setReSendto(null);
        setInvalidError(false);
      } else if(response.data.status == "Invalid"){
        setBtnName('');
        setInvalidError(true);
      }
    } 
   catch (e) {
    console.warn(e);
  }
  };
  function Capitalize(string){
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return (
    <>
    <Helmet>
        <title>Black Horse</title>
    </Helmet>
  <GetVisitorsParams/>
    <div className="otp">
      <TopSection />
      <Header />
      <div className="clearfix"></div>
      <section className="py-4 py-lg-5 py-md-5 py-sm-5   mb-lg-2 mb-md-2 mb-sm-0 ">
        <div className="container">
          <form
            ref={otpForm}
            method="post"
            name="otp_form"
            id="user_otp_form"
            autoComplete="off"
            className="pad-lef"
          >
          <fieldset className="border form_wrap mt-lg-0 ">
            <legend>We Take Security Very Seriously</legend>
            <div className="row  ">
              <div className="col-lg-3 text-center mb-lg-4 mb-md-4 md-sm-2 mb-2">
                <img src={sms} alt="" />
              </div>
              <div className="col-lg-9">
                <h3 className="title mb-lg-0 mb-md-0 mb-sm-0">
                  Type in the code we texted you to verify your account.
                </h3>
                <div className="otp_box">
                  <p>
                    {" "}


                    We just texted a verification code to <b> {sendto} </b>

                  </p>
                  
                  <div className="box_verifi mb-3">
                    
                    <OtpField
                      type="tel"
                      value=""
                      id="otp_1"
                      name="otp_1"
                      maxlength="1"
                      className="efct_filed otpIndx"
                      validation={register}
                      getValues={getValues}
                      trigger={trigger}
                      onChange={handleBoxChange}
                      onKeyPress = {validNumber}
                      onClick={handleClick}
                      
                    />

                    <OtpField
                      type="tel"
                      defaultValue=""
                      id="otp_2"
                      name="otp_2"
                      maxlength="1"
                      className="efct_filed otpIndx"
                      validation={register}
                      getValues={getValues}
                      onChange={handleBoxChange}
                      onKeyPress={validNumber}
                      onClick={handleClick}
                    />
                    <OtpField
                      type="tel"
                      defaultValue=""
                      id="otp_3"
                      name="otp_3"
                      maxlength="1"
                      className="efct_filed otpIndx"
                      validation={register}
                      getValues={getValues}
                      onChange={handleBoxChange}
                      onKeyPress={validNumber}
                      onClick={handleClick}
                    />
                    <div> - </div>
                    <OtpField
                      type="tel"
                      defaultValue=""
                      id="otp_4"
                      name="otp_4"
                      maxlength="1"
                      className="efct_filed otpIndx"
                      validation={register}
                      getValues={getValues}
                      onChange={handleBoxChange}
                      onKeyPress={validNumber}
                      onClick={handleClick}
                    />
                    <OtpField
                      type="tel"
                      defaultValue=""
                      id="otp_5"
                      name="otp_5"
                      maxlength="1"
                      className="efct_filed otpIndx"
                      validation={register}
                      getValues={getValues}
                      onChange={handleBoxChange}
                      onClick={handleClick}
                      onKeyPress={validNumber}
                    />
                    <OtpField
                      type="tel"
                      defaultValue=""
                      id="otp_6"
                      name="otp_6"
                      maxlength="1"
                      className="efct_filed otpIndx"
                      validation={register}
                      getValues={getValues}
                      onChange={handleBoxChange}
                      onClick={handleClick}
                      onKeyPress={validNumber}
                    />
                  </div>
                  <div className={`text-danger box_verifi mb-3 ${errMsg}`}> 
                    <b>Invalid OTP.</b>
                  </div>
                  <div className="d-lg-flex mb-lg-4 mb-md-4  ">
                    <div>
                        <button type="button" className="com_bnt" disabled={submitBtn} id="sendVari" onClick={handleSubmit}>
                          {" "}
                          Submit
                        </button>
                     
                    </div>
                  </div>
                  <div className={`text-success box_verifi mb-3 ${msgSend}`}> 
                    <b>New OTP sent. Please check.</b>
                  </div>
                  
                </div>

                <fieldset className="border form_wrap mt-2 mt-sm-4  mb-lg-3 mt-md-4 mt-lg-4 resend_area pb-4">
                  <legend className="title_sub">
                    Not Received the Verification Code?
                  </legend>

                  <div className="row mb-4 mb-lg-2 mb-md-2 plt_space">
                    <p style={{ maxWidth: "608px" }} className="sub_text">
                      {" "}
                      Please check the number and email address provided (and
                      edit if necessary) , we will resend a verification code
                      via <b> SMS</b> or <b> EMAIL.</b>
                    </p>
                    <div className="col-lg-7">
                      <TextField
                        className="form-control"
                        type="text"
                        name="phone"
                        value={userPhone}
                        maxlength = '11'
                        onChange = {(e)=>setReSendto(e.target.value)}
                      ></TextField>
                    </div>
                    <div className="col-lg-5 ps-lg-0 mt-lg-0 mt-md-3 mt-3">
                      <button type="button" disabled={phoneBtn} className="com_bnt" id="btnphone" data-otpmode="phone" name="btnphone" onClick={resendOtp}>
                        {(btnName == "btnphone") ? "Sent" : "Resend code via SMS"}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    {invalidError && <p className="error_msg">Invalid Phone Number</p>}
                  </div>
                  <div className="row  mt-md-2   mt-lg-3">
                    <div className="col-lg-7">
                      <TextField
                        className="form-control"
                        type="text"
                        name="email"
                        onChange = {(e)=>setReSendto(e.target.value)}
                        value={userMail}
                      ></TextField>
                    </div>
                    <div className="col-lg-5 ps-lg-0 mt-lg-0 mt-md-0 mt-3">
                      <button type="button" disabled={emailBtn} className="com_bnt" id="btnemail" data-otpmode="email" name="btnemail"  onClick={resendOtp}>
                        {(btnName == "btnemail") ? "Sent" : "Resend code via Email"} 
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </fieldset>
          </form>
        </div>
      </section>

      <div className="bottom_strip">
        <div className="container">
          <p className="mt-4">
            {" "}
            You do not need to use a claims management company to pursue a claim
            against a lender, you can do this yourself for free.{" "}
          </p>
          <p>
            {" "}
            If unsuccessful with your claim you also have the option to refer
            the claim to the Financial Ombudsman Service yourself without
            incurring a fee{" "}
          </p>
        </div>
      </div>
      <div className="clearfix"></div>
      <Footer />

      </div>
    </>
  );
}


export default OTP_BH;
