import React, { useState, useEffect, useRef, useReducer } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import { useFollowupUserQuestions } from "../../../../../Hooks/useFollowupUserQuestions";
import UKVDFlp from './UKVDFlp';
import RadioQuestionnaireFlp from './RadioQuestionnaireFlp';
import SelectQuestiannaireFlp from './SelectQuestiannaireFlp';
import InputQuestionnaireFlp from "./InputQuestionnaireFlp";
import RadioQuestiannaireC2Flp from "./RadioQuestiannaireC2Flp";
import CheckBoxQuestionnaireFlp from "./CheckBoxQuestionnaireFlp";
import QuestionnaireModal from "../../Common/QuestionnaireModal";
import Loader from "../../Common/Loader";


import {
  questionnaire21,
  questionnaire22,
  questionnaire23,
  questionnaire24,
  questionnaire25,
  questionnaire26,
  questionnaire27,
  questionnaire28,
  questionnaire29,
  questionnaire30,
  questionnaire31,
  questionnaire32,
  questionnaire33,
  questionnaire34,
  questionnaire35,
  questionnaire36,
  questionnaire37
} from "../../../../../Constants/BlackhorseQuestions";

const initialState = {
  showQuestionSlide1: "d-none",
  showQuestionSlide2: "d-none",
  showQuestionSlide_21: "d-none",
  showQuestionSlide_22: "d-none",
  showQuestionSlide_23: "d-none",
  showQuestionSlide_24: "d-none",
  showQuestionSlide_25: "d-none",
  showQuestionSlide_26: "d-none",
  showQuestionSlide_27: "d-none",
  showQuestionSlide_28: "d-none",
  showQuestionSlide_29: "d-none",
  showQuestionSlide_30: "d-none",
  showQuestionSlide_31: "d-none",
  showQuestionSlide_32: "d-none",
  showQuestionSlide_33: "d-none",
  showQuestionSlide_34: "d-none",
  showQuestionSlide_35: "d-none",
  showQuestionSlide_36: "d-none",
  showQuestionSlide_37: "d-none"

}
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case 'showSlideChange': {
      if (action.payload.selectedQuest == "1") {
        return {
          ...state,
          showQuestionSlide1: "d-block"
        }
      }
      else if (action.payload.selectedQuest == "2") {
        return {
          ...state,
          showQuestionSlide1: "d-none",
          showQuestionSlide2: "d-block",
        };
      }
      else if (action.payload.selectedQuest == "21") {
        return {
          ...state,
          showQuestionSlide_21: "d-block",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none"

        };
      }
      else if (action.payload.selectedQuest  == "22") {
        return {
          ...state,
          showQuestionSlide_22: "d-block",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "23") {
        return {
          ...state,
          showQuestionSlide_23: "d-block",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "24") {
        return {
          ...state,
          showQuestionSlide_24: "d-block",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "25") {
        return {
          ...state,
          showQuestionSlide_25: "d-block",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "26") {
        return {
          ...state,
          showQuestionSlide_26: "d-block",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "27") {
        return {
          ...state,
          showQuestionSlide_27: "d-block",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "28") {
        return {
          ...state,
          showQuestionSlide_28: "d-block",
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "29") {
        return {
          ...state,
          showQuestionSlide_29: "d-block",
          showQuestionSlide_28: "d-none",
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "30") {
        return {
          ...state,
          showQuestionSlide_30: "d-block",
          showQuestionSlide_29: "d-none",
          showQuestionSlide_28: "d-none",
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "31") {
        return {
          ...state,
          showQuestionSlide_31: "d-block",
          showQuestionSlide_30: "d-none",
          showQuestionSlide_29: "d-none",
          showQuestionSlide_28: "d-none",
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "32") {
        return {
          ...state,
          showQuestionSlide_32: "d-block",
          showQuestionSlide_31: "d-none",
          showQuestionSlide_30: "d-none",
          showQuestionSlide_29: "d-none",
          showQuestionSlide_28: "d-none",
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "33") {
        return {
          ...state,
          showQuestionSlide_33: "d-block",
          showQuestionSlide_32: "d-none",
          showQuestionSlide_31: "d-none",
          showQuestionSlide_30: "d-none",
          showQuestionSlide_29: "d-none",
          showQuestionSlide_28: "d-none",
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "34") {
        return {
          ...state,
          showQuestionSlide_34: "d-block",
          showQuestionSlide_37: "d-none",
          showQuestionSlide_36: "d-none",
          showQuestionSlide_33: "d-none",
          showQuestionSlide_32: "d-none",
          showQuestionSlide_31: "d-none",
          showQuestionSlide_30: "d-none",
          showQuestionSlide_29: "d-none",
          showQuestionSlide_28: "d-none",
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "35") {
        return {
          ...state,
          showQuestionSlide_35: "d-block",
          showQuestionSlide_37: "d-none",
          showQuestionSlide_36: "d-none",
          showQuestionSlide_34: "d-none",
          showQuestionSlide_33: "d-none",
          showQuestionSlide_32: "d-none",
          showQuestionSlide_31: "d-none",
          showQuestionSlide_30: "d-none",
          showQuestionSlide_29: "d-none",
          showQuestionSlide_28: "d-none",
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "36") {
        return {
          ...state,
          showQuestionSlide_36: "d-block",
          showQuestionSlide_33: "d-none",
          showQuestionSlide_32: "d-none",
          showQuestionSlide_31: "d-none",
          showQuestionSlide_30: "d-none",
          showQuestionSlide_29: "d-none",
          showQuestionSlide_28: "d-none",
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else if (action.payload.selectedQuest == "37") {
        return {
          ...state,
          showQuestionSlide_37: "d-block",
          showQuestionSlide_36: "d-none",
          showQuestionSlide_34: "d-none",
          showQuestionSlide_33: "d-none",
          showQuestionSlide_32: "d-none",
          showQuestionSlide_31: "d-none",
          showQuestionSlide_30: "d-none",
          showQuestionSlide_29: "d-none",
          showQuestionSlide_28: "d-none",
          showQuestionSlide_27: "d-none",
          showQuestionSlide_26: "d-none",
          showQuestionSlide_25: "d-none",
          showQuestionSlide_24: "d-none",
          showQuestionSlide_23: "d-none",
          showQuestionSlide_22: "d-none",
          showQuestionSlide_21: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide1: "d-none",
        };
      }
      else {
        return {
          ...state
        }
      }
    }
  }
}
const QuestionsModal = ({
  questionsPop,
  allFollowupData,
  setPendingStatus,
  pendingQuestionnaire,
  uuid,
  vehicleDetails
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const { saveFollowupUserQuestions,storeFollowupVehicleDetails,isLoading } = useFollowupUserQuestions();
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const [vehicleModel, setVehicleModel] = useState('');
  const [selectedKeeperDate, setSelectedKeeperDate] = useState("");
  const [vehicleSecureYear, setVehicleSecureYear] = useState('');
  const [currentKeeperDate, setCurrentKeeperDate] = useState('');
  const [quest22, setQuest22] = useState('');
  const [quest26, setQuest26] = useState('');
  const [quest36, setQuest36] = useState('');
  const [quest34_35, setQuest34_35] = useState('');
  const [carRegNo, setCarRegNo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const splitForm = useRef(null);
  const [loader, showLoader] = useState("d-none");

  useEffect(() => {
    setShow(questionsPop);
      setVehicleSecureYear(vehicleDetails);
  }, [questionsPop])
 
  const slideChange = async (e, fieldName) => {

    var selectedQuest = e.target.dataset.question_id;
    var currentSlide = e.target.name;
    const form = splitForm.current;
    if (e.target.value == '405' || e.target.value == '407') {
      setShowModal(true);
      setShow(false)
      setQuest34_35(e.target.value);
      return false;
    }
    if (selectedQuest == 22) {
        setCompletedQuestArr([...completedQuestArr, '22']);
        setQuest22(e.target.value);
    }else if(selectedQuest == 23 || selectedQuest == 24){
      setCompletedQuestArr([...completedQuestArr, '23','24']);
      setQuest22('');
    }else if(selectedQuest == 26){
      setCompletedQuestArr([...completedQuestArr, '26']);
      setQuest26(e.target.value);
    }else if(selectedQuest == 27 || selectedQuest == 28){
      setCompletedQuestArr([...completedQuestArr, '27','28']);
      setQuest26(e.target.value);
    }else if(selectedQuest == 36){
      setCompletedQuestArr([...completedQuestArr, '36']);
      setQuest36(e.target.value);
    }else if(selectedQuest == 37){
      setCompletedQuestArr([...completedQuestArr, '37']);
      setQuest36('');
    }else if(selectedQuest == 34){
      setCompletedQuestArr([...completedQuestArr, '34','37']);
      setQuest36('');
    }
    else{
      setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    }
    if (e.target.value == '382' ||  e.target.value == '383') {
      let vehicle_data = {
        keeper_date: selectedKeeperDate,
        current_keeper_date: currentKeeperDate,
        yourVehicle: e.target.dataset.answertext,
        carRegNo: carRegNo,
        pageName: '0602UFBH_A1'
      };
      const response = await storeFollowupVehicleDetails(
        vehicle_data,
        allFollowupData.flpData.followup_data,
        "store-followup-vehicle-details",
      )
      var vehicle_details = {
        selecte_keeper_date: selectedKeeperDate,
        current_keeper_date: currentKeeperDate,
        your_vehicle: e.target.dataset.answertext,
        car_reg_no: carRegNo
      }
      localStorage.setItem('vehicleDetails', JSON.stringify(vehicle_details));
      if (e.target.value == '383') {
        history.push("/blackhorse/followup/thankyou-unqualified?uuid=" + uuid);
        return false;
      }
    }else if (currentSlide != 'lets_begin' && currentSlide != 'btnNext2' && currentSlide != 'keepDate') {
      var question_id = e.target.dataset.question_id;
      var input_questions = ['23', '24', '30', '31'];
      var optionId = (input_questions.includes(question_id)) ? '' : ((question_id == '32') ? fieldName : form[fieldName].value);
      var inputAnswer = (input_questions.includes(question_id)) ? form[fieldName].value : '';
      let question_data = {
        question_id: question_id,
        option_id: optionId,
        input_answer: inputAnswer,
        bankId: '132'
      };
      if (!(question_id == '34' && optionId == '405') && !(question_id == '35' && optionId == '407')) {
        const response = await saveFollowupUserQuestions(
          question_data,
          allFollowupData.flpData.followup_data,
          '0602UFBH_A1',
          "black-horse-followup-question-store",
        )
      }
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = {};
      var newEntries = (existingEntries == null) ? existingEntries = {} : existingEntries;
      Object.assign(newEntries, { [question_id]: (optionId) ? optionId : inputAnswer });
      localStorage.setItem('questionData', JSON.stringify(newEntries));
    }
  }
  const handleCloseModal = () => {
    setShowModal(false);
    setShow(true);
    if(quest34_35 == '405'){
      dispatch({ type: 'showSlideChange', payload:  34  });
    }else if(quest34_35 == '407'){
      dispatch({ type: 'showSlideChange', payload: 35 });
    }
  };
  useEffect(() => {

    if (pendingQuestionnaire.length != 0) {
      const totalQuestArr = pendingQuestionnaire.pendingQuestions;
      var questIntersection = totalQuestArr.filter(x => !completedQuestArr.includes(x));
      console.log("inter section");
      console.log(questIntersection[0]);
      if (questIntersection[0] == undefined) {
        setPendingStatus({ status_btn: 'pending_question', status_flag: 'showTick' });
        setShow(false);
      }
      var selectedQuest = '';
      if(quest22 == '385'){
        selectedQuest = '23';
      }else if(quest22 == '384'){
        selectedQuest = '24';
      }else if(quest26 == '388'){
        selectedQuest = '28';
      }else if(quest26 == '389'){
        selectedQuest = '27';
      }else if(quest36 == '409'){
        selectedQuest = '37';
      }else if(quest36 == '410'){
        selectedQuest = '34';
      }else{
        selectedQuest = questIntersection[0];
      }
      if(selectedQuest)
      {
        dispatch({ type: 'showSlideChange', payload: { selectedQuest } });
      }
    }else{
      console.log("no pending questions");
    }
  }, [pendingQuestionnaire, completedQuestArr])

  return (
    <>
    <Modal show={show} onHide={handleClose} dialogClassName="followupPage modal-content-q" backdrop="static"
      keyboard={false}
    >
      <div className="modal-body sign-sec">
        <form ref={splitForm}>
        <Loader show={loader} />
           <UKVDFlp
           slideChange = {slideChange}
           showQuestionSlide1= {state.showQuestionSlide1}
           showQuestionSlide2={state.showQuestionSlide2}
           uuid={uuid}
           page_name="0602UFBH_A1"
           setVehicleModel={setVehicleModel}
           setSelectedKeeperDate = {setSelectedKeeperDate}
           setVehicleSecureYear = {setVehicleSecureYear}
           setCurrentKeeperDate = {setCurrentKeeperDate}
           setCarRegNo={setCarRegNo}
           showLoader={showLoader}
           />
           <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_21}
            questionnaire={questionnaire21}
            slideChange={slideChange}
            vehicleModel = {vehicleModel}
           />
            <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_22}
            questionnaire={questionnaire22}
            slideChange={slideChange}
            vehicleSecureYear={vehicleSecureYear}
           />
           <SelectQuestiannaireFlp
            showQuestionSlide={state.showQuestionSlide_23}
            questionnaire={questionnaire23}
            slideChange={slideChange}
          />
          <InputQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_24}
            questionnaire={questionnaire24}
            slideChange={slideChange}
          />
          <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_25}
            questionnaire={questionnaire25}
            slideChange={slideChange}
          />
          <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_26}
            questionnaire={questionnaire26}
            slideChange={slideChange}
          />
          <RadioQuestiannaireC2Flp
            showQuestionSlide={state.showQuestionSlide_27}
            questionnaire={questionnaire27}
            slideChange={slideChange}
          />
          <RadioQuestiannaireC2Flp
            showQuestionSlide={state.showQuestionSlide_28}
            questionnaire={questionnaire28}
            slideChange={slideChange}
          />
          <RadioQuestiannaireC2Flp
            showQuestionSlide={state.showQuestionSlide_29}
            questionnaire={questionnaire29}
            slideChange={slideChange}
          />
          <SelectQuestiannaireFlp
            showQuestionSlide={state.showQuestionSlide_30}
            questionnaire={questionnaire30}
            slideChange={slideChange}
          />
          <SelectQuestiannaireFlp
            showQuestionSlide={state.showQuestionSlide_31}
            questionnaire={questionnaire31}
            slideChange={slideChange}
          />
          <CheckBoxQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_32}
            questionnaire={questionnaire32}
            slideChange={slideChange}
          />
          <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_33}
            questionnaire={questionnaire33}
            slideChange={slideChange}
          />
          <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_34}
            questionnaire={questionnaire34}
            slideChange={slideChange}
          />
          <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_35}
            questionnaire={questionnaire35}
            slideChange={slideChange}
          />
          <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_36}
            questionnaire={questionnaire36}
            slideChange={slideChange}
          //Did you arrange finance via the dealer or a broker?
          />
          <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_37}
            questionnaire={questionnaire37}
            slideChange={slideChange}
          //Would you like us to investigate and, where appropriate, claim for unfair/hidden commission inappropriately charged to you?
          />
        </form>
      </div>
    </Modal>
    <QuestionnaireModal popDisplay={showModal} handleClose={handleCloseModal} content="Sorry we cannot accept your application. This is because damages,
          compensation or settlement money might, in certain circumstances, be
          set-off against your outstanding liabilities, meaning that you will
          not receive a cash benefit."/>
    </>
  
  )
}
export default QuestionsModal;