import React, { useState } from "react";
import TextField from "../../../../UI/TextField";
import Button from "../../../../UI/Button";
import { useCarRegisterValidation } from "../../../../../Hooks/useCarRegisterValidation";

const UKVDFlp = ({
  slideChange,
  showQuestionSlide1,
  showQuestionSlide2,
  uuid,
  page_name,
  setVehicleModel,
  setSelectedKeeperDate,
  setVehicleSecureYear,
  setCurrentKeeperDate,
  setCarRegNo,
  showLoader
}) => {
  const [carRegistrationNumber, setCarRegistrationNumber] = useState();
  const { registrationvalidation } = useCarRegisterValidation();
  const [keeperDates, setKeeperDates] = useState([]);
  const [error, setError] = useState("");
  const [showError, setshowError] = useState("d-none");

  const UKVD_Validation = (e) => {
    e.preventDefault();
    let registration_no = carRegistrationNumber;
    if (registration_no) {
      var carRegNo = registration_no.replace(/\s+/g, "");
      setCarRegNo(carRegNo);
      CarRegisterValidationApi(e, carRegNo);
    } else {
      document.getElementById("question_1").focus();
      setshowError("d-block");
      setError("Please Enter Car Registration Number");
      return false;
    }
  };
  const CarRegisterValidationApi = async (e, carRegNo) => {
    showLoader("d-block");
    const getRegisterValidation = await registrationvalidation(
      carRegNo,
      uuid,
      page_name
    );
    if (getRegisterValidation.data.validity == "1") {
      let vehicleResponse = getRegisterValidation.data.vehicle_api_response;
      let vehicleDetails = JSON.parse(vehicleResponse);
      let vehicle_make = getRegisterValidation.data.vehicle_make;
      let fuel_type = vehicleDetails.DataItems.VehicleRegistration.FuelType;
      let vehicle = getRegisterValidation.data.keeper_date;
      let keeperData = JSON.parse(vehicle);
      keeperData = keeperData.keeper_dates;
      keeperCurrentDate(keeperData);
      setVehicleModel(getRegisterValidation.data.vehicle_model);
      setTimeout(() => {
        showLoader("d-none");
        slideChange(e);
      }, 2000);
      setshowError("d-none");
      setKeeperDates(keeperData);
    } else {
      setshowError("d-block");
      setError("Your Vehicle Registration is Invalid. Please Recheck!");
      showLoader("d-none");
      return false;
    }
  };
  const selectKeeperData = (e) => {
    let s_keeperDate = e.target.getAttribute("data-keeper_data");
    let split_keeperDate = s_keeperDate.split(/(\s+)/);
    let keeper_year = split_keeperDate[4];
    setSelectedKeeperDate(s_keeperDate);
    slideChange(e);
    setVehicleSecureYear(keeper_year);
  };
  const clickCarRegNo = (e) => {
    setCarRegistrationNumber(e.target.value);
    setshowError("d-none");
  };
  const responseDates = keeperDates.map((value, key) => {
    return (
      <div className="mb-3 ">
        <div className="btn-date" id="btnNext3">
          <input
            type="button"
            className="btn-next next2"
            id="yes2"
            data-question_id="2"
            value={value}
            name="keepDate"
            data-keeper_data={value}
            onClick={selectKeeperData}
          />
        </div>
      </div>
    );
  });

  const keeperCurrentDate = (keeperDates) => {
    var currentDate = keeperDates[0],
      newCurrentDate = new Date(keeperDates[0]);
    keeperDates.forEach(function (dt, index) {
      if (new Date(dt) > newCurrentDate) {
        currentDate = dt;
        newCurrentDate = new Date(dt);
      }
    });
    setCurrentKeeperDate(currentDate);
  };
  return (
    <>
      <section
        className={`question_block slide-top slide2 ${showQuestionSlide1}`}
      >
        <div className="row">
          <div className="">
            <div className="">
              <div
                className="radio-box question3 animated fadeInUp"
                id="breakdown1"
              >
                <h3 style={{ color: "#444" }} className="mb-2">
                  Please add your Vehicle Registration
                </h3>
                <TextField
                  type="text"
                  className="car-reg"
                  placeholder="Enter Car Registration Number"
                  name="question_1"
                  id="question_1"
                  value={carRegistrationNumber}
                  onChange={clickCarRegNo}
                ></TextField>
                <span
                  className={`error_msg error_msg_custom ${showError}`}
                  id="email_err"
                >
                  {error}
                </span>
                <Button
                  className="btn-next next1"
                  id="btnNext2"
                  name="btnNext2"
                  question_id="1"
                  buttonText="Start My Free Check &gt;"
                  onClick={UKVD_Validation}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className={`question_block slide-top slide2 ${showQuestionSlide2}`}
      >
        <div className="radio-box question4 animated fadeInUp" id="breakdown2">
          <h3 style={{ color: "#444" }}>Select the date the car was registered to you?</h3>
          {responseDates}
        </div>
      </section>
    </>
  );
};

export default UKVDFlp;
