import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";


const SelectQuestiannaireCC = ({
  showQuestionSlide,
  slideChange,
  previousSlide,
  questionnaire,
  backBtn
}) => {

  const questionnaireValue = "questionnaire" + questionnaire.questionId;
  const question_id = questionnaire.questionId;
  const [inputValue, setInputValue] = useState();
  const [showError, setShowError] = useState('d-none');
  const selectName = 'question_' + questionnaire.questionId;
  let OptionData = "";
  if(question_id == 126){
    const questionData  = JSON.parse(localStorage.getItem("questionData"));
    if(questionData != null){
      if((questionData[125] == "232")){
        const accountstart = questionData[124];
        OptionData =  questionnaire.answers[0].options.map((dobData, index) => {
          if(dobData.value >= accountstart || dobData.value == ''){
            return (
              <option value={dobData.value} key={index}>
                {dobData.label}
              </option>
            );
          }
        });
      }
   }
  } else {
    OptionData =  questionnaire.answers[0].options.map((dobData, index) => {
      return (
        <option value={dobData.value} key={index}>
          {dobData.label}
        </option>
      );
    });
  }


  const setValue = (e) => {
    setInputValue(e.target.value);
    (e.target.value != '') ? setShowError('d-none') : setShowError('d-block');
  }

  const validatePage = (evt) => {
    (inputValue) ? slideChange(evt, selectName) : setShowError('d-block');
  }

  return (
    <>
      <div className={`animated fadeInUp questionnaire_slides ${showQuestionSlide}`}>
        <div className="row text-div">
          <div className="col-xl-6 col-lg-6 col-md-12 col-12 text-center white_bg mb-0 mb-md-auto my-auto">
            <h3>{questionnaire.question}</h3>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-12 text-center white_bg my-auto mrgin_top">
            <span className="align-middle">
              <select className="form-select" name={selectName} onChange={(e) => { setValue(e) }} id={selectName}>
                {OptionData}
              </select>
                  <span className={`err-msg mb-2 ${showError}`}>Please Select</span>
            </span>
            <button type="button"
              className="btn-home slide_next mb-3" id={`next_${question_id}`}
              name={`next_${question_id}`} data-question_id={question_id} value={question_id}
              onClick={(e) => { validatePage(e) }} > Next &gt;&gt;</button>

            {(questionnaireValue != "questionnaire124" && backBtn) && <div >
              <input type="radio" id={`back_${question_id}`} name={`back_${question_id}`} onClick={previousSlide} />
              <label
                htmlFor={`back_${question_id}`}
                className="btn-upload btn_back"
                data-slide={questionnaireValue}
                data-back_slide={questionnaireValue}
              >
                &lt;&lt; Back
              </label>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectQuestiannaireCC;
