import React from 'react'
import loader from "../../../../assets/img/unnamed.gif";

export default function LoaderCC() {
  return (
   <> <div className="questionnaire" id="main-dv">
   <section className="loading_section ">
     <div className="questionspart">
       <div className="container">
         <div className="row">
           <div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 offset-md-2 col-md-8">
             <div className="space text-center">
               <img src={loader} width="100px;" alt="" />
               <h3 className="htext">Analysing your application....</h3>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>
 </div>
   </>
  )
}
