import React ,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Anchor from '../../../UI/Anchor';

const Footer = () => {
  return (
    <>
 
          <footer >
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <p className="mb-4">2023 Allegiant Finance Services Ltd.</p>
                     <p>Registered Address: 400 Chadwick House, Warrington Road, Birchwood Park, Warrington, WA3 6AE A Limited Company Registered in England and Wales No. 07474972
                     </p>
                     <p>Allegiant Finance Services Limited is a Claims Management Company that is Authorised and Regulated by the Financial Conduct Authority. Firm Reference Number: 836810. Registered with the Information Commissioners Office: Z2569335. VAT Registration No. 274837662.</p>
                     <p>We offer a professional claims management service. You do not need to use a claims management company to make your complaint to your lender, and if your complaint is not successful you can refer it to the Financial Ombudsman Service yourself for free.</p>
                     <p>All calls are recorded for training and monitoring purposes.</p>
                     <p>© 2023 Allegiant Finance Services Ltd. All Rights Reserved.</p>
                     <ul className="ft_menu mt-4">
                        <li><a href="https://allegiant.co.uk/compliance/complaints-regulated"  target="_blank">Complaints Resolution</a> </li>
                        <li><a href="https://allegiant.co.uk/compliance/privacy-policy/"  target="_blank"> Privacy Policy</a></li>
                        <li><a href="https://allegiant.co.uk/compliance/cookies" target="_blank">Cookies Declaration</a></li>
                        <li><a href="https://allegiant.co.uk/compliance/terms-of-engagement/toe-ulc/" target="_blank">Terms of Engagement</a></li>
                        <li><a href="https://allegiant.co.uk/compliance/services-summary/service-summary-ulc/" target="_blank" className="">Service Summary</a></li>
                        <li><a href="https://allegiant.co.uk/compliance/pre-contract-information/pre-contract-information-ulc/" target="_blank" className="">Pre-Contract Information</a></li>
                        <li className="last_li"><a href="https://allegiant.co.uk/compliance/cancellation/" target="_blank" className="">Cancellation Form</a></li>
                        <li className="last_li"><a href="https://allegiant.co.uk/unaffordable-lending-claim-fees/" target="_blank" className="">Fees</a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </footer>

    </>
  );
}

export default Footer;