import React ,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Footer from '../../../Includes/Layouts/0602UFBH_A1/Footer';
import logo from '../../../../../src/assets/img/logo-white1.png';
import tick from '../../../../../src/assets/img/success-tick.gif';

const BlackHorseFollowupThankyou = () => {
  window.scrollTo(0, 0);
  const user_email = localStorage.getItem('user_email');
  let email='';
  if(user_email) {
    email = user_email;
  } else {
    const urlParams = new URLSearchParams(document.location.search);
    email = urlParams.get("email");
  }
  localStorage.clear();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const modalClick = (clickValue) => {
    switch (clickValue) {
     
      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showCookie": {
        setShowCookie(true);
        break;
      }
      case "exitCookie": {
        setShowCookie(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
    }
  };
  return (
    <>
    <div className='success'>
      <div class="clearfix"></div>
      <header class="header_success">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 text-center py-3">
              <a href="" class="logo">
                <img src={logo} alt="" />
              </a>
            </div>
      
          </div>
        </div>
      </header>
      <div class="clearfix"></div>
      <section class="py-4 py-lg-5 py-md-5 py-sm-5   mb-lg-2 mb-md-2 mb-sm-0 ">
        <div class="container text-center">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                <div class="mb-1 mt-4 ">
                  <img width="100px"  src={tick} alt="" />
                </div>
              <h1 class="mt-4 fw-bold text-mesg">Thank you, your query has been successfully submitted.</h1>
              <h3>You will shortly receive an acknowledgement email to {email}. Please check your spam or junk folder if you cannot locate this. </h3>
              <h4>If you do not receive this acknowledgement, your email address may be incorrect or your spam filter may be filtering our communications. If this is the case, please email helpdesk@allegiant-finance.co.uk so we can assist.</h4>
              <h4>Thank you for your interest in our service.</h4>
            </div>
              </div>
            </div>
      </section>
    
      <div class="clearfix"></div>          
      <Footer/>
    </div>
    </>
  )
}

export default BlackHorseFollowupThankyou;
