import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";
import RadioButton from "../../../UI/TextField";
import Button from "../../../UI/Button";


const SelectQuestiannaire = (props) => {
  const mainQuestion = props.questionnaire.question;
  const selectName = 'question_' + props.questionnaire.questionId;
  const question_id = props.questionnaire.questionId;
  const [inputValue, setInputValue] = useState();
  const [showError, setShowError] = useState('d-none');

  const creditCardstartYear = [];
  const questionData = JSON.parse(localStorage.getItem("questionData"));
  if ((questionData) && ((questionData[155]))) {
    const startYearFrom =  ( questionData[155] == "2007 or earlier")? 2007:questionData[155];
    const endYearTo = 2022
    for (var i = startYearFrom; i <= endYearTo; i++) {
      var objYearStart = {};
      objYearStart["label"] = i;
      objYearStart["value"] = i;
      creditCardstartYear.push(objYearStart);
    }
  }
  const dropValues = (question_id == 157)?creditCardstartYear:props.questionnaire.answers[0].options;
  const setValue = (e) => {
    setInputValue(e.target.value);
    (e.target.value != '') ? setShowError('d-none') : setShowError('d-block');
  }

  const validatePage = (evt) => {
    let selectValue = document.getElementById("question_"+evt.target.dataset.question_id).value;
    console.log(selectValue);
    (inputValue || selectValue != "") ? props.slideChange(evt, selectName) : setShowError('d-block');
  }
  return (
    <>
      <section class={`question_block slide-top slide33 ${props.showQuestionSlide}`}>
        <div class="row text-div">
          <div class="col-lg-6 ps-lg-5 pe-lg-5 my-auto pe-4 ps-4 text-block d-flex align-items-center justify-content-center justify-content-lg-left">
            <div>
              <h1>
                {mainQuestion}
              </h1>
              {question_id == '31' &&
                <div className="light-bg mb-2">
                  Please do not include any mortgage or rental payment within your answer.
                </div>}
            </div>

          </div>
          <div class="col-lg-6 ps-lg-5 pe-lg-5 my-auto pe-4 ps-4 ">
            <div class="  d-flex  align-items-center inner-text justify-content-center d-in-block">
              <div class="text-center">
                <div class="mb-3">
                  <select class="form-select input-box input-box-1a" aria-label=" " name={selectName} id={selectName} onChange={(e) => { setValue(e) }}>

                    {dropValues.map((dropValue, index) => (
                      <option key={index} value={dropValue.value}>{dropValue.label}</option>
                    ))}
                  </select>
                  <span className={`error_msg error_msg_custom error_msg_custom-1a ${showError}`} id="email_err">{(question_id == "155") ? 'Please select year' : 'Please select amount'} </span>
                </div>

                <div class="mb-3">
                  <button type="button" class="btn com_bnt nxt-btn-1a" id={`next_${question_id}`}
                    name={`next_${question_id}`} data-question_id={question_id} onClick={(e) => { validatePage(e) }}> Next</button>
                </div>
                {props.backButton && <div class="mb-3 mt-4">
                  <div class="">
                    <button type="button" class="btn-back " id={`back_${question_id}`} name={`back_${question_id}`} onClick={props.previousSlide}>Back</button>

                  </div>
                </div>}

              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default SelectQuestiannaire;
