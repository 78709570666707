import React from 'react';
import logo from '../../../src/assets/img/logo01.png'
import signIn from '../../../src/assets/img/signInWIthGoogleIcon.png'
import serviceIcon1 from '../../../src/assets/img/service-icon-1.svg'
import serviceIcon2 from '../../../src/assets/img/service-icon-2.svg'
import serviceIcon3 from '../../../src/assets/img/service-icon-3.svg'

const VerificationPage = () => {
    return (
    <div className='Verfication'>
    <header>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12 text-lg-start text-center">
            <img src={logo} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </header>

    <section className="content-section">
      <div className="container">
        <div className="row">
          <div className=" col-lg-6 col-xl-6  col-12 text-center">
            <h1>One More Step to Register Your Refund with <b>SafetyNet Credit</b>
            </h1>
            <h3>
              <b> Allegiant Finance Services verifies your account with SafetyNet Credit in patnership with Google.</b> Login to the GMAIL ADDRESS where you registered your account with SafetyNet Credit.
            </h3>
            <a href="/0602UFSN_A1_7/questionnaire">
            <img src={signIn}  alt="" /></a>
            <p>By continuing, you agree to our <br/>
              <a href="" target="_blank" rel="noopener noreferrer">Terms of Service</a> &amp; <a href="" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </p>
          </div>
          <div className=" offset-lg-1 offset-xl-1 col-lg-5 col-xl-5 col-12">
            <div className="box-content row m-auto align-items-center">
              <div className="col-xl-2 col-lg-2  col-3 my-0">
                <img src={serviceIcon1} alt="" />
              </div>
              <div className="col-xl-10 col-lg-10  col-9 ">
                <h3>A Trusted Name</h3>
                <p>A Trusted Name. We’ve processed over 100,000 claims since 2013.</p>
              </div>
              <div className="col-xl-2 col-lg-2  col-3 my-0">
                <img src={serviceIcon2} alt="" />
              </div>
              <div className="col-xl-10 col-lg-10  col-9 ">
                <h3>Friendly &amp; Experienced Advisors</h3>
                <p>Whilst we are powered by cutting edge technology, it’s the human touch that makes our service stand out.</p>
              </div>
              <div className="col-xl-2 col-lg-2  col-3  my-0">
                <img src={serviceIcon3} alt="" />
              </div>
              <div className="col-xl-10 col-lg-10  col-9">
                <h3>Confidential &amp; Discreet</h3>
                <p>We will only correspond with you and keep your details secure. We’ll never sell your data.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-md-12 col-12 text-center">
            <ul>
              <li>
                <a href="/home-contact" target="_blank">CONTACT</a>
              </li>
              <li>
                <a href="" target="_blank">TERMS & CONDITIONS</a>
              </li>
              <li>
                <a href="" target="_blank"> PRIVACY POLICY</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
        </div>
    )

}

export default VerificationPage;
