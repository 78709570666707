import React from "react";

export default function Loader({show}) {
  return (
    <>
    <div className={show}>
      <div className="overlayc1">
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    </>
  );
}
