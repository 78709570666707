import { Helmet } from "react-helmet";
import info from "../../../../../src/assets/moneybarn/img/info.jpg";
import star from "../../../../../src/assets/moneybarn/img/rating-star.png";
import trustp from "../../../../../src/assets/moneybarn/img/trustp.png";
import AdtopiaLoadLP from "../../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../../Utility/FbViewPixel";
import Form_0602UFMB_A1_6_2 from "../../../Forms/moneybarn/Form_0602UFMB_A1_6_2";
import TopSection from "../../../Includes/Layouts/Common/TopSection";
import Footer from "../../../Includes/Layouts/Moneybarn/Footer";
import Header from "../../../Includes/Layouts/Moneybarn/Header";
import PreFooter from "../../../Includes/Layouts/Moneybarn/PreFooter";
import Trustpilot from "../../../Includes/Layouts/Trustpilot/Trustpilot";
import TrustBox from "../../../UI/TrustBox";
import { DynamicRoutingSplit } from "../../../../Utility/DynamicRoutingSplit";
import StageProcess from "../../../Includes/Layouts/Moneybarn/StageProcess";

const SPLIT_NAME = "0602UFMB_A1_6_2";
const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
  "UNFR_0602UFMB_A1_6_2",
  "0602UFMB_A1_6_2"
);
console.log("it is ",DynamicRouteNextPage);
const Split_0602UFMB_A1_6_2 = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Helmet>
        <title>Moneybarn</title>
      </Helmet>

      <AdtopiaLoadLP pageType="LP" splitName={SPLIT_NAME} />

      <FbViewPixel />

      <div className="moneybarn">
        <TopSection />

        <Header />

        <section className="py-3 pt-lg-4 py-lg-5 py-md-5 py-sm-5   mb-lg-2 mb-md-2 mb-sm-0  ">
          <div className="container">
            <div className="mn_wraper">
              <div className="col-lg-12">
                <h1>
                  Taken Out a High-Cost Credit Agreement from MoneyBarn to
                  Finance your Vehicle?
                </h1>
                <p className="hig-blu">If you found the credit to be unaffordable and struggled to make your repayments, you could make a claim to see if you are owed a refund of any interest and charges.</p>
                <p className="mb-30 fw-semibold">
                  Starting your claim is easy and 100% online. You could be owed
                  thousands if you were mis-sold!
                </p>
              </div>
              <div className="col-lg-12 mt-4">
                <div className="form-bg p-4">
                  <h3 className="mb-3">Register Your Claim Now</h3>
                  <ul className="pb-0">
                    <li>
                      Our Average Refund for MoneyBarn as of 04/01/2023 is
                      £4,876.20. Average compensation and debt deductions benefit in won claims. Claim success dependant on merit and not guaranteed.
                    </li>
                    <li>
                    Over £67m recovered for unaffordable lending claims since 2013
                    </li>
                    <li>Over 49,000 successful unaffordable lending claims since 2013</li>
                    <li>Our <a href="https://allegiant.co.uk/unaffordable-lending-claim-fees/" target="_blank" >fees </a>range from 18% to 36% (including VAT). Fees only apply if you receive a compensation refund.</li>
                  </ul>
                  <div className=" col-lg-12 col-12 trust_pi mb-5">
                    <a
                      href="https://www.trustpilot.com/review/allegiant.co.uk"
                      target="_blank"
                    >
                      <div className="trust-sec-widget">
                        <TrustBox />
                      </div>

                      <div className="row box-a">
                        <div className="col-7 p-0">
                          <img src={star} className="img-star" alt="" />
                        </div>
                        <div className="col-3 p-0 d-flex flex-row align-items-center">
                          <div>
                            <p>
                              <b className="rate-num px-2"> 4.8</b>
                            </p>
                          </div>
                          <div>
                            <img
                              src={info}
                              className="info_img"
                              alt="The TrustScore isn’t just a simple average of all reviews. It’s based on multiple factors like the age and number of reviews.
                                            Whether or not a business actively asks customers to write reviews also impacts the TrustScore."
                            />
                          </div>
                        </div>
                      </div>
                    </a>
                    <p className="ms-4">
                      Independent Service Rating based on verified reviews.
                    </p>
                    <a
                      href="https://www.trustpilot.com/review/allegiant.co.uk"
                      target="_blank"
                    >
                      <img
                        src={trustp}
                        className="img-trust2 ms-4"
                        alt=""
                        title=""
                      />
                    </a>
                  </div>

                  <Form_0602UFMB_A1_6_2
                    splitName={DynamicRouteSplitName}
                    nextPage={DynamicRouteNextPage}
                    showSign={true}
                    registerClaim={true}
                  />

                  <div className="d-lg-block d-md-block d-none">
                    <p className="text-center mt-3">
                      Unfairfees.co.uk is a trading style of Allegiant Finance
                      Services Limited, a Claims Management Company authorised
                      and regulated by the Financial Conduct Authority. Firm
                      Reference Number: 836810. We offer a professional claims
                      management service. To see how we will handle your data,
                      please see our
                      <a
                        href="https://allegiant.co.uk/compliance/privacy-policy/"
                        target="_blank"
                      >
                        {" "}
                        Privacy Policy.
                      </a>
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Trustpilot />
        <PreFooter />
        <StageProcess/>
        <Footer />
      </div>
    </>
  );
};

export default Split_0602UFMB_A1_6_2;
