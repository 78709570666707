import React, { useState } from "react";
import { FormControl, ListGroup } from 'react-bootstrap';

const SelectQuestiannaireFlp = (props) => {
  const mainQuestion = props.questionnaire.question;
  const selectName = "question_" + props.questionnaire.questionId;
  const dropValues = props.questionnaire.options;
  const question_id = props.questionnaire.questionId;
  const [inputValue, setInputValue] = useState();
  const [showError, setShowError] = useState("d-none");
  const [filteredSuggestions,setFilteredSuggestions] = useState([]);
  const [suggestValue,setSuggestValue] = useState('');

  const setValue = (e) => {
    setInputValue(e.target.value);
    e.target.value != "" ? setShowError("d-none") : setShowError("d-block");
  };

  const handleChange = (e) =>{
    const input = e.target.value;
    const containsParentheses = /\(|\)/.test(input);

    if (containsParentheses) {
      const modifiedInput = input.replace(/[()]/, '');
      setSuggestValue(modifiedInput);
    }
    else{
    const regex = new RegExp(`^${input}`, 'i');
    const filtered = dropValues.filter(suggestion => regex.test(suggestion.label));
    setSuggestValue(input);
    setInputValue(input);
    input === "" ? setFilteredSuggestions('') : setFilteredSuggestions(filtered);
    }
  }
  const handleClick =(e,suggestionValue,suggestionLabel) =>{
    localStorage.setItem('question_168',JSON.parse(suggestionValue));
    setSuggestValue(suggestionLabel);
    setFilteredSuggestions([]);
    setInputValue(suggestionValue);
    setShowError('d-none');
  }

  const validatePage = (evt) => {
    if(selectName == "question_150"){
      inputValue ? props.slideChange(evt, selectName) : setShowError("d-block");
    }
    if (selectName == "question_138"){
      inputValue ? props.slideChange(evt, selectName) : setShowError("d-block");
    }
    if (selectName == "question_139"){
      inputValue ? props.slideChange(evt, selectName) : setShowError("d-block");
}
if(selectName == 'question_168'){
console.log(inputValue);
    const filter = dropValues.find(option =>option.value == inputValue);
    if(filter == undefined && inputValue){
      console.log("inside");
      setInputValue(false);
      setShowError("d-block");
    }
    else{
       inputValue ? props.slideChange(evt, selectName) : setShowError("d-block");
    }  
  }
  };

  return (
    <>
      <div
        className={`radio-box question13  animated fadeInUp ${props.showQuestionSlide}`}
        id="breakdown11"
      >
        <h3 style={{ color: "#444" }}>{mainQuestion}</h3>
        { selectName == 'question_168' ?
          <><FormControl
          type="text"
          className="form-select input-box "
          placeholder="Please type Bank Name..."
          aria-label=" "
          value={suggestValue}
          name={selectName}
          id={selectName}
          onChange={(e) => {
            handleChange(e);
          }}
        /> 
        {filteredSuggestions.length > 0 && (
          <ListGroup>
            {filteredSuggestions.map((suggestion,index) => (
              <ListGroup.Item
                key={index}
                name={selectName}
                id={selectName}
                value={suggestion.value}
                onClick={(e) => {handleClick(e,suggestion.value,suggestion.label)}}
              >
                {suggestion.label}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
        </>
        :
        <select
          className="form-select carRegNo"
          aria-label="Default select example"
          name={selectName}
          id={selectName}
          onChange={(e) => {
            setValue(e);
          }}
        >
          {dropValues.map((dropValue, index) => (
            <option key={index} value={dropValue.value}>
              {dropValue.label}
            </option>
          ))}
        </select>
       }
        {selectName == 'question_168' ? 
         <span
         className={`error_msg error_msg_custom ${showError}`}
         id="email_err"
       >
         Please search to select Bank
       </span>
        :
        <span
          className={`error_msg error_msg_custom ${showError}`}
          id="email_err"
        >
          Please select amount
        </span>}
        <button
          type="button"
          className="btn-next next11"
          id={`next_${question_id}`}
          name={`next_${question_id}`}
          data-question_id={question_id}
          onClick={(e) => {
            validatePage(e);
          }}
        >
          {" "}
          NEXT
        </button>
      </div>
    </>
  );
};

export default SelectQuestiannaireFlp;
