import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";


const RadioQuestionnaireFlp = (props) => {
  const mainQuestion = props.questionnaire.question;
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + props.questionnaire.questionId;

  const radioId1 = props.questionnaire.answers[0].label + '_' + props.questionnaire.questionId;
  const radioId2 = props.questionnaire.answers[1].label + '_' + props.questionnaire.questionId;

  const radioValue1 = props.questionnaire.answers[0].value;
  const radioValue2 = props.questionnaire.answers[1].value;

  const radioLabel1 = props.questionnaire.answers[0].label;
  const radioLabel2 = props.questionnaire.answers[1].label;


  return (
    <>
        <div class={`radio-box question5  animated fadeInUp" id="breakdown3 ${props.showQuestionSlide}`}>
        <h3 style={{color: '#444'}}>{mainQuestion}{props.vehicleSecureYear && <span>{props.vehicleSecureYear} ?</span>}</h3>
        {question_id == '21' &&
            <h2>{props.vehicleModel}</h2>
        }
        {question_id == '33' &&
            <ul>
            <li>Direct Debits or Standing Order payments were bouncing and being rejected</li>
            <li>Late payment or overlimit fees were being applied</li>
            <li>Money spent was more than money received in any one month</li>
          </ul>
        }
         {question_id == '35' &&
            <ul>
            <li>Declared bankrupt or are currently subject to a bankruptcy petition</li>
            <li>Subject to a debt relief order</li>
            <li>Had an adverse County Court Debt Judgment or Sequestration</li>
          </ul>
        }
        <input type="radio" id={radioId1} name={radioName} value={radioValue1} data-answertext={radioLabel1} className=" " data-question_id={question_id} onClick={(e) => { props.slideChange(e, radioName) }} style={{display:"none"}}/>
        <label htmlFor={radioId1} className="br radioa l_g next3">{radioLabel1}
        <span class="">a</span>
        </label>

        <input type="radio" id={radioId2} name={radioName} value={radioValue2} data-answertext={radioLabel2} className=" " data-question_id={question_id} onClick={(e) => { props.slideChange(e, radioName) }} style={{display:"none"}}/>
        <label htmlFor={radioId2} className="br radioa l_g next3">{radioLabel2}
        <span class="">b</span>
        </label>
        </div>
    </>
  );
};

export default RadioQuestionnaireFlp;
