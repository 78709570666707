import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as EnvConstants from "../../../Constants/EnvConstants";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import ContactDetails from "../../Includes/Layouts/Common/ContactDetailsMB";
import SubmitModel from "../../Includes/Layouts/Common/MoneybarnSubmitModel";
import PersonalDetails from "../../Includes/Layouts/Common/PersonalDetailsMB";
import Signature from "../../Includes/Layouts/Common/SignatureMB";
import ExtraQuestions from "../../Includes/Layouts/Moneybarn/ExtraQuestions_0602UFMB_A1_6";
import InputButton from "../../UI/InputButton";

const SPLIT_NAME = "0602UFMB_A1_6_2";

const Form_0602UFMB_A1_6_2 = (props) => {
    console.log(props);
  const splitForm = useRef(null);
  const scrollfrmv2div = useRef([]);
  const [emailCheck, setEmailCheck] = useState();
  const [phoneCheck, setPhoneCheck] = useState();
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const handleClose = () => {
    setShow(false);
    setShowRegisterPop(false);
  };
  const [show, setShow] = useState(false);
  const [showRegisterPop, setShowRegisterPop] = useState(false);
  const [showSign, setShowSign] = useState(props.showSign);
  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const formSubmit = async () => {
    const form = splitForm.current;
    const values = getValues();

    const { financeYear } = values;
    const questionData = JSON.parse(localStorage.getItem("questionData")) ?? {};
    questionData[150] = financeYear;
    localStorage.setItem("questionData", JSON.stringify(questionData));

    const formData = values;
    if(formData.txtFName){
      let FirstName  = capitalizeFirst(formData.txtFName);
      let LastName =  capitalizeFirst(formData.txtLName);
      formData.txtFName = FirstName;
      formData.txtLName = LastName;
    }
    let customData = {
      previous_address_line1: form["txtPrevHouseNumber"].value
        ? form["txtPrevHouseNumber"].value
        : "",
      previous_address_postcode: form["txtPostCode2"].value
        ? form["txtPostCode2"].value
        : "",
      previous_address_town: form["txtPrevTown"].value
        ? form["txtPrevTown"].value
        : "",
      previous_address_country: form["txtPrevCountry"].value
        ? form["txtPrevCountry"].value
        : "",
      previou_address_county: form["txtPrevCounty"].value
        ? form["txtPrevCounty"].value
        : "",
      previous_address_line3: form["txtPrevStreet"].value
        ? form["txtPrevStreet"].value
        : "",
    };
    let customPreData = JSON.stringify(customData);
    localStorage.setItem("customPreviousData", customPreData);
    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }
    formData.page_name = SPLIT_NAME;
    formData.bank_id = "123";
    formData.pCode_manual = form["pCode_EntryType"].value;
    console.log("it is visitors data",visitorParameters.data);
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem("formData", JSON.stringify(formData));
      const full_url = window.location.href;
      const query_string_arr = full_url.split("?");
      var queryString = "";
      if (query_string_arr != null) {
        queryString = query_string_arr[1];
      }

      localStorage.setItem("queryString", queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        SPLIT_NAME,
        queryString,
        visitorParameters.data
      );
      console.log("it is form_data",formSUbmitResult.data);
      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {
          console.log("first if");
          console.log(queryString);
          if (queryString.indexOf("&" + field + "=") !== -1) {
            console.log("s");
            if (EnvConstants.AppConversionPixel === "true") {
              console.log("if fb");
              setShow(true);
              history.push(
                `/fbpixel?split_name=${props.splitName}&next_page=${props.nextPage}`
              ); // fb fixel firing
              window.scrollTo(0, 0);
            } else {
              console.log("else fb");
              window.scrollTo(0, 0);
              history.push(
                `/${props.nextPage}?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${props.splitName}`
              );
            }
          } else {
            console.log("another else fb");
            window.scrollTo(0, 0);
            history.push(
              `/${props.nextPage}?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${props.splitName}`
            );
          }
        } else {
          console.log("laset else  fb");
          window.scrollTo(0, 0);
          console.log(props.nextPage);
          history.push(
            `/${props.nextPage}?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${props.splitName}`
          );
        }
      }
    }
  };
  const basicDetailsValidation = async (e) => {
    e.preventDefault();
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    let errorFlag = 0;
    var lstSalutationResult = await trigger("lstSalutation");
    var txtFNameResult;
    var txtLNameResult;
    var txtDobDay;
    var txtDobMonth;
    var txtDobYear;
    var emailVal;
    var phoneVal;
    var postcode;
    var address1;
    var financeYear;
    var ivaOrBankrupt;
    var signature_padResult = formData.signature_data;

    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }

    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }

    if (txtLNameResult) {
      postcode = await trigger("txtPostCode");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }

    if (postcode) {
      address1 = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }

    if (address1) {
      txtDobDay = await trigger("DobDay");
    } else {
      document.getElementById("address1").focus();
      return false;
    }

    if (txtDobDay) {
      txtDobMonth = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }

    if (txtDobMonth) {
      txtDobYear = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }

    if (txtDobYear) {
      phoneVal = await trigger("txtPhone");
    } else {
      document.getElementById("DobYear").focus();
      return false;
    }

    if (phoneVal) {
      emailVal = await trigger("txtEmail");
    } else {
      document.getElementById("txtPhone").focus();
      return false;
    }

    if (emailVal) {
      financeYear = await trigger("financeYear");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }

    if (financeYear) {
      ivaOrBankrupt = await trigger("ivaOrBankrupt");
    } else {
      document.getElementById("financeYear").focus();
      return false;
    }

    if (ivaOrBankrupt) {
      if (signature_padResult === "") {
        setError("signature_pad", {
          type: "manual",
          message: "Invalid Signature",
          shouldFocus: true,
        });

        document
          .getElementById("signature-pad")
          .scrollIntoView({ behavior: "smooth", block: "center" });

        return false;
      } else {
        signature_padResult = true;
      }
    } else {
      document
        .getElementById("ivaOrBankrupt")
        .scrollIntoView({ behavior: "smooth", block: "center" });
      return false;
    }

    if (emailCheck == 0) {
      document.getElementById("txtEmail").focus();
      setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
        shouldFocus: true,
      });
    }

    if (phoneCheck == 0) {
      document.getElementById("txtPhone").focus();
      setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !postcode ||
      !txtDobDay ||
      !txtDobMonth ||
      !txtDobYear ||
      !phoneVal ||
      !emailVal ||
      !signature_padResult ||
      emailCheck == 0 ||
      phoneCheck == 0
    ) {
      errorFlag = 1;
    }

    if (errorFlag == 0) {
      setShowRegisterPop(true);
    } else {
      console.log("error");
    }
  };

  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <div ref={scrollfrmv2div} className="form-body mt-5">
          <PersonalDetails
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            getValues={getValues}
            splitForm={splitForm}
            setError={setError}
          />

          <ContactDetails
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            getValues={getValues}
            formSubmit={formSubmit}
            showSign={showSign}
            setEmailCheck={setEmailCheck}
            setPhoneCheck={setPhoneCheck}
          >
            <ExtraQuestions
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              clearErrors={clearErrors}
              getValues={getValues}
              splitForm={splitForm}
              setError={setError}
              setValue={setValue}
            />
          </ContactDetails>
        </div>
        <Signature
          trigger={trigger}
          setShow={setShow}
          show={show}
          handleClose={handleClose}
          getValues={getValues}
          showSign={showSign}
          formSubmit={formSubmit}
          registerClaim={props.registerClaim}
          validation={register}
          validationMsg={errors}
          clearErrors={clearErrors}
          setError={setError}
        />
        <SubmitModel
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          getValues={getValues}
          show={showRegisterPop}
          handleClose={handleClose}
          formSubmit={formSubmit}
        />
        <div className="form-group mt-3">
          <InputButton
            name="formSubmitBtn"
            className="button-submit"
            id="formSubmitBtn"
            value="START"
            btnType="button"
            style={{ float: "none" }}
            onClick={(e) => {
              setTimeout(() => {
                basicDetailsValidation(e);
              }, 100);
            }}
          />
        </div>
      </form>
    </>
  );
};

export default Form_0602UFMB_A1_6_2;
