import React, { useState } from "react";
import { NumberValidation } from "../../../../Utility/NumberValidation";

const InputQuestiannaire = (props) => {
  const mainQuestion = props.questionnaire.question;
  const inputName = "question_" + props.questionnaire.questionId;
  const question_id = props.questionnaire.questionId;
  const [inputValue, setInputValue] = useState();
  const [showError, setShowError] = useState("d-none");

  const validatePage = (evt) => {
    inputValue ? props.slideChange(evt, inputName) : setShowError("d-block");
  };
  const setValue = (e) => {
    setInputValue(e.target.value);
    e.target.value != "" ? setShowError("d-none") : setShowError("d-block");
  };

  const setNumberFormat = (e) => {
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value = e.target.value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const isCurrency = mainQuestion.toLowerCase().includes("amount");

  return (
    <>
      <section
        className={`question_block slide-top  slide8 ${props.showQuestionSlide}`}
      >
        <div className="row questionnaire_row">
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block  d-flex align-items-center  justify-content-center justify-content-lg-left">
            <div>
              <h1>{mainQuestion}</h1>

              {props.description}
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
            <div className="  d-flex align-items-center inner-text justify-content-center">
              <div className="text-center">
                <div className="mb-lg-5 mb-4 ">
                  <input
                    type={props?.type ?? "tel"}
                    className={`form-control input-box ${
                      isCurrency ? "currency" : ""
                    }`}
                    placeholder={props?.placeholder ?? "Amount"}
                    name={inputName}
                    id={inputName}
                    data-question_id={question_id}
                    defaultValue=""
                    onKeyPress={isCurrency ? NumberValidation : () => {}}
                    onChange={(e) => setValue(e)}
                    onKeyUp={(e) =>
                      isCurrency ? setNumberFormat(e) : () => {}
                    }
                    maxLength={props?.maxLength ?? "7"}
                  />
                  <span
                    className={`error_msg error_msg_custom ${showError}`}
                    id="email_err"
                  >
                    Please enter {props?.placeholder ?? "Amount"}
                  </span>
                </div>

                <div className="mb-3">
                  <button
                    type="button"
                    className="btn com_bnt nextbtnApprox "
                    name={`next_${question_id}`}
                    data-question_id={question_id}
                    onClick={validatePage}
                  >
                    {" "}
                    Next
                  </button>
                </div>
                <div className="mb-3 mt-4" id=" ">
                  <div className="">
                    <button
                      type="button"
                      className="btn-back "
                      name={`back_${question_id}`}
                      onClick={props.previousSlide}
                    >
                      Back
                    </button>
                  </div>
                </div>

                {props.children}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InputQuestiannaire;
