import React, { useState, useEffect, useRef, useReducer } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFollowupUserQuestions } from "../../../../../Hooks/useFollowupUserQuestions";
import RadioQuestionnaireFlp from './RadioQuestionnaireFlp';
import SelectQuestiannaireFlp from './SelectQuestiannaireFlp';
import RadioQuestiannaireC2Flp from "./RadioQuestiannaireC2Flp";
import QuestionnaireModal from "../../Common/QuestionnaireModal";
import Loader from "../../Common/Loader";


import {
  questionnaire124,
  questionnaire125,
  questionnaire126,
  questionnaire127,
  questionnaire128,
  questionnaire129,
  questionnaire130,
  questionnaire131,
  questionnaire132,
  questionnaire133,
  questionnaire134,
  questionnaire135,
} from "../../../../../Constants/CatalogueClaim_Question";

const initialState = {
  showQuestionSlide_124: "d-none",
  showQuestionSlide_125: "d-none",
  showQuestionSlide_126: "d-none",
  showQuestionSlide_127: "d-none",
  showQuestionSlide_128: "d-none",
  showQuestionSlide_129: "d-none",
  showQuestionSlide_130: "d-none",
  showQuestionSlide_131: "d-none",
  showQuestionSlide_132: "d-none",
  showQuestionSlide_133: "d-none",
  showQuestionSlide_134: "d-none",
  showQuestionSlide_135: "d-none"
}
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case 'showSlideChange': {
      if (action.payload.selectedQuest == "124") {
        return {
          ...state,
          showQuestionSlide_124: "d-block",
        };
      }
      else if (action.payload.selectedQuest  == "125") {
        return {
          ...state,
          showQuestionSlide_125: "d-block",
          showQuestionSlide_124: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "126") {
        return {
          ...state,
          showQuestionSlide_126: "d-block",
          showQuestionSlide_125: "d-none",
          showQuestionSlide_124: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "127") {
        return {
          ...state,
          showQuestionSlide_127: "d-block",
          showQuestionSlide_126: "d-none",
          showQuestionSlide_125: "d-none",
          showQuestionSlide_124: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "128") {
        return {
          ...state,
          showQuestionSlide_128: "d-block",
          showQuestionSlide_127: "d-none",
          showQuestionSlide_126: "d-none",
          showQuestionSlide_125: "d-none",
          showQuestionSlide_124: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "129") {
        return {
          ...state,
          showQuestionSlide_129: "d-block",
          showQuestionSlide_128: "d-none",
          showQuestionSlide_127: "d-none",
          showQuestionSlide_126: "d-none",
          showQuestionSlide_125: "d-none",
          showQuestionSlide_124: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "130") {
        return {
          ...state,
          showQuestionSlide_130: "d-block",
          showQuestionSlide_129: "d-none",
          showQuestionSlide_128: "d-none",
          showQuestionSlide_127: "d-none",
          showQuestionSlide_126: "d-none",
          showQuestionSlide_125: "d-none",
          showQuestionSlide_124: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "131") {
        return {
          ...state,
          showQuestionSlide_131: "d-block",
          showQuestionSlide_130: "d-none",
          showQuestionSlide_129: "d-none",
          showQuestionSlide_128: "d-none",
          showQuestionSlide_127: "d-none",
          showQuestionSlide_126: "d-none",
          showQuestionSlide_125: "d-none",
          showQuestionSlide_124: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "132") {
        return {
          ...state,
          showQuestionSlide_132: "d-block",
          showQuestionSlide_131: "d-none",
          showQuestionSlide_130: "d-none",
          showQuestionSlide_129: "d-none",
          showQuestionSlide_128: "d-none",
          showQuestionSlide_127: "d-none",
          showQuestionSlide_126: "d-none",
          showQuestionSlide_125: "d-none",
          showQuestionSlide_124: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "133") {
        return {
          ...state,
          showQuestionSlide_133: "d-block",
          showQuestionSlide_132: "d-none",
          showQuestionSlide_131: "d-none",
          showQuestionSlide_130: "d-none",
          showQuestionSlide_129: "d-none",
          showQuestionSlide_128: "d-none",
          showQuestionSlide_127: "d-none",
          showQuestionSlide_126: "d-none",
          showQuestionSlide_125: "d-none",
          showQuestionSlide_124: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "134") {
        return {
          ...state,
          showQuestionSlide_134: "d-block",
          showQuestionSlide_133: "d-none",
          showQuestionSlide_132: "d-none",
          showQuestionSlide_131: "d-none",
          showQuestionSlide_130: "d-none",
          showQuestionSlide_129: "d-none",
          showQuestionSlide_128: "d-none",
          showQuestionSlide_127: "d-none",
          showQuestionSlide_126: "d-none",
          showQuestionSlide_125: "d-none",
          showQuestionSlide_124: "d-none"
        };
      }
      else if (action.payload.selectedQuest == "135") {
        return {
          ...state,
          showQuestionSlide_135: "d-block",
          showQuestionSlide_134: "d-none",
          showQuestionSlide_133: "d-none",
          showQuestionSlide_132: "d-none",
          showQuestionSlide_131: "d-none",
          showQuestionSlide_130: "d-none",
          showQuestionSlide_129: "d-none",
          showQuestionSlide_128: "d-none",
          showQuestionSlide_127: "d-none",
          showQuestionSlide_126: "d-none",
          showQuestionSlide_125: "d-none",
          showQuestionSlide_124: "d-none"
        };
      }
      else {
        return {
          ...state
        }
      }
    }
  }
}
const QuestionsModal = ({
  questionsPop,
  allFollowupData,
  setPendingStatus,
  pendingQuestionnaire,
  userBank
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const { saveFollowupUserQuestions} = useFollowupUserQuestions();
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const [quest125, setQuest125] = useState('');
  const [quest34_35, setQuest34_35] = useState('');
  const [showModal, setShowModal] = useState(false);
  const splitForm = useRef(null);
  const [loader] = useState("d-none");

  useEffect(() => {
    setShow(questionsPop);
  }, [questionsPop])
 
  const slideChange = async (e, fieldName) => {
    let selectedQuest = e.target.dataset.question_id;
    const form = splitForm.current;
    if (e.target.value == '250' || e.target.value == '252') {
      setShowModal(true);
      setShow(false)
      setQuest34_35(e.target.value);
      return false;
    }
    if (selectedQuest == 125 && e.target.value == 231) {
        setCompletedQuestArr([...completedQuestArr, '125','126']);
        setQuest125(e.target.value);
    }else if(selectedQuest == 125 && e.target.value == 232){
      setCompletedQuestArr([...completedQuestArr, '125']);
    }else if(selectedQuest == 127){
      setCompletedQuestArr([...completedQuestArr, '127']);
      setQuest125('');
    }else{
      setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    }
    let question_id = e.target.dataset.question_id;
    let input_questions = ['124', '126', '127', '131', '132'];
    let optionId = (input_questions.includes(question_id)) ? '' :form[fieldName].value;
    let inputAnswer = (input_questions.includes(question_id)) ? form[fieldName].value : '';
    let question_data = {
        question_id: question_id,
        option_id: optionId,
        input_answer: inputAnswer,
        bankId: userBank
    };
    if (!(question_id == '134' && optionId == '250') && !(question_id == '135' && optionId == '252')) {
        await saveFollowupUserQuestions(
          question_data,
          allFollowupData.flpData.followup_data,
          '0602UFVCC_A1',
          "catalogue-claims-followup-question-store",
        )
    }
    let existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (existingEntries == null) existingEntries = {};
    let newEntries = (existingEntries == null) ? existingEntries = {} : existingEntries;
    Object.assign(newEntries, { [question_id]: (optionId) ? optionId : inputAnswer });
    localStorage.setItem('questionData', JSON.stringify(newEntries));
  }
  const handleCloseModal = () => {
    setShowModal(false);
    setShow(true);
    if(quest34_35 == '250'){
      dispatch({ type: 'showSlideChange', payload:  134  });
    }else if(quest34_35 == '252'){
      dispatch({ type: 'showSlideChange', payload: 135 });
    }
  };
  useEffect(() => {

    if (pendingQuestionnaire.length != 0) {
      const totalQuestArr = pendingQuestionnaire.pendingQuestions;
      let questIntersection = totalQuestArr.filter(x => !completedQuestArr.includes(x));
      console.log("inter section");
      console.log(questIntersection);
      if (questIntersection[0] == undefined) {
        setPendingStatus({ status_btn: 'pending_question', status_flag: 'showTick' });
        setShow(false);
      }
      let selectedQuest = '';
      if(quest125 == '231'){
        selectedQuest = '127';
      }else if(quest125 == '232'){
        selectedQuest = '126';
      }else{
        selectedQuest = questIntersection[0];
      }
      if(selectedQuest)
      {
        dispatch({ type: 'showSlideChange', payload: { selectedQuest } });
      }
    }else{
      console.log("no pending questions");
    }
  }, [pendingQuestionnaire, completedQuestArr])

  return (
    <>
    <Modal show={show} onHide={handleClose} dialogClassName="catalogueClaimsfollowupPage modal-content-q" backdrop="static"
      keyboard={false}
    >
      <div className="modal-body sign-sec">
        <form ref={splitForm}>
          <Loader show={loader} />
          <SelectQuestiannaireFlp
            showQuestionSlide={state.showQuestionSlide_124}
            questionnaire={questionnaire124}
            slideChange={slideChange}
          />
          <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_125}
            questionnaire={questionnaire125}
            slideChange={slideChange}
           />
            <SelectQuestiannaireFlp
            showQuestionSlide={state.showQuestionSlide_126}
            questionnaire={questionnaire126}
            slideChange={slideChange}
          />
          <SelectQuestiannaireFlp
            showQuestionSlide={state.showQuestionSlide_127}
            questionnaire={questionnaire127}
            slideChange={slideChange}
          />
          <RadioQuestiannaireC2Flp
            showQuestionSlide={state.showQuestionSlide_128}
            questionnaire={questionnaire128}
            slideChange={slideChange}
          />
          <RadioQuestiannaireC2Flp
            showQuestionSlide={state.showQuestionSlide_129}
            questionnaire={questionnaire129}
            slideChange={slideChange}
          />
          <RadioQuestiannaireC2Flp
            showQuestionSlide={state.showQuestionSlide_130}
            questionnaire={questionnaire130}
            slideChange={slideChange}
          />
          <SelectQuestiannaireFlp
            showQuestionSlide={state.showQuestionSlide_131}
            questionnaire={questionnaire131}
            slideChange={slideChange}
          />
          <SelectQuestiannaireFlp
            showQuestionSlide={state.showQuestionSlide_132}
            questionnaire={questionnaire132}
            slideChange={slideChange}
          />
          <RadioQuestiannaireC2Flp
            showQuestionSlide={state.showQuestionSlide_133}
            questionnaire={questionnaire133}
            slideChange={slideChange}
          />
          <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_134}
            questionnaire={questionnaire134}
            slideChange={slideChange}
          />
          <RadioQuestionnaireFlp
            showQuestionSlide={state.showQuestionSlide_135}
            questionnaire={questionnaire135}
            slideChange={slideChange}
          />
        </form>
      </div>
    </Modal>
    <QuestionnaireModal popDisplay={showModal} handleClose={handleCloseModal} content="Sorry we cannot accept your application."/>
    </>
  
  )
}
export default QuestionsModal;