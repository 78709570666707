import React, { useEffect, useState } from "react";
import { userInfo } from "../../../Hooks/userInfo";
import { UseUnsubEmail } from "../../../Hooks/UseUnsubEmail";
import logoblue from "../../../assets/img/logo-blue.64398c73.png";

const UnsubscribeSmsEmail = () => {

    const [showemail, setShowEmail] = useState();
    const [showUserId, setShowUserId] = useState()
    const [showText,setShowText] = useState(false);
    const query = new URLSearchParams(window.location.search);
    const uuid = query.get('uuid');
    const { saveSubEmail } = UseUnsubEmail();
    const { getUserInfo } = userInfo();

    useEffect(async () => {
        const response = await getUserInfo(uuid);
        const email = response.data.response[0].email;
        const userId = response.data.response[0].id;
        setShowEmail(email);
        setShowUserId(userId)
    }, [])
    const handleSubmit = async () => {
        
        const response = await saveSubEmail(
            showUserId,
            uuid,
            1,
            1,
            " ",
            "cancel enquiry"
        );
            if(response.data.response.status == "success"){
            setShowText(true);
            }
    }
    return (
        <div className="unsub">
            <div className="success">
                <header className="header_success">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 text-center pt-2">
                                <a href="" className="logo">
                                    <img src={logoblue}alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="pt-0 pt-lg-2 pt-md-2 pt-sm-2 mb-lg-2 mb-md-2 mb-sm-0 ">
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <h2 className="note-head-dng text-center">Unsubscribe</h2>
                                 <p className="sub-tl-note">
                                    If you wish to unsubscribe, please enter your Phone Number or Email Address
                                </p>
                                <form action="">
                                    <fieldset>
                                        <legend>Email or Phone Number
                                        </legend>
                                        <input type="text" name="txtEmail" className="email_address required" value={showemail} placeholder="Email or Phone Number" id="txtEmail" />
                                    </fieldset>
                                   { showText && <p className="sub-tl-done">You have successfully unsubscribed!</p>}
                                    <div className="w-100 btn-click-unsub text-center">
                                        <input type="button" name="submit" onClick={handleSubmit} id="nxt-firstBtn" value="unsubscribe" className="button button-mat1 btn--5"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
export default UnsubscribeSmsEmail;