import React, { Fragment, useState } from "react";
import questions from "../../../../Constants/moneybarnQuestions";
import QuestionnaireModal from "../../../Includes/Layouts/Common/QuestionnaireModal";
import FinanceYearQuestionnaireModal from "../Common/FinanceYearQuestionnaireModal";
import SelectBox from "../../../UI/SelectBox";

const Q1 = questions.find((q) => q.questionId == 150);
const Q2 = questions.find((q) => q.questionId == 148);

function ExtraQuestions({
  validation,
  validationMsg,
  setError,
  setValue,
  clearErrors,
  trigger,
}) {
  const [showDisqualifiedModal, setShowDisqualifiedModal] = useState(false);
  const [
    showFinanceYearDisqualifiedModal,
    setShowFinanceYearDisqualifiedModal,
  ] = useState(false);
  const ivaOrBankruptName = `ivaOrBankrupt`;
  const financeyearName = "financeYear";

  const closeDisqualifiedModal = () => {
    setValue(ivaOrBankruptName, null);
    setShowDisqualifiedModal(false);
    trigger(ivaOrBankruptName);
  };
  const closeFinanceYearDisqualifiedModal = () => {
    setValue(financeyearName, null);
    setShowFinanceYearDisqualifiedModal(false);
    trigger(financeyearName);
  };

  return (
    <div>
      <QuestionnaireModal
        popDisplay={showDisqualifiedModal}
        handleClose={closeDisqualifiedModal}
      />
      <FinanceYearQuestionnaireModal
        finanacePopDisplay={showFinanceYearDisqualifiedModal}
        financeHandleClose={closeFinanceYearDisqualifiedModal}
      />

      <div className="mb-3 mt-4">
        <fieldset className="scheduler-border">
          <legend>What year did you secure finance with Moneybarn?*</legend>
          <div className="row">
            <div className="mb-3">
              <SelectBox
                className="form-control wid_title"
                OptionValue={Q1.options}
                name={financeyearName}
                id="financeYear"
                onChange={(e) => {
                  clearErrors("financeYear");
                  console.log("TEST");
                  console.log(e.target.value);
                  if (e.target.value <= 2007) {
                    setShowFinanceYearDisqualifiedModal(true);
                  }
                }}
                clearErrors={clearErrors}
                myRef={validation({ required: "Please Select a Year" })}
                validationMsg={
                  validationMsg.financeYear && validationMsg.financeYear.message
                }
              />
            </div>
          </div>
        </fieldset>
      </div>

      <div className="mb-3 mt-4">
        <fieldset className="scheduler-border">
          <legend>
            Have you ever been in or are proposed to be in an Individual
            Voluntary Arrangement (IVA), Debt Relief Order (CRO) Declared
            Bankrupt or something similar?*
          </legend>
          <div className="row">
            <div id={ivaOrBankruptName}>
              <div className="radio-box">
                {Q2.answers.map((ans, i) => (
                  <Fragment key={ans.label}>
                    <input
                      type="radio"
                      className="radio popInp"
                      name={ivaOrBankruptName}
                      id={`${ivaOrBankruptName}${ans.label}`}
                      value={ans.value}
                      ref={validation({ required: "Please Select an Option" })}
                      onChange={() => trigger(ivaOrBankruptName)}
                      onClick={(e) => {
                        if (
                          e.target.value ==
                          Q2.answers.find((a) => a.label == "Yes").value
                        ) {
                          setShowDisqualifiedModal(true);
                        } else {
                          const signPad =
                            document.getElementById("signature-pad");
                          signPad.classList.add("efct_filed");
                          signPad.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                        }
                      }}
                    />
                    <label
                      for={`${ivaOrBankruptName}${ans.label}`}
                      className="radio"
                    >
                      {ans.label}
                    </label>
                  </Fragment>
                ))}
              </div>

              {validationMsg.ivaOrBankrupt && (
                <span id="active_account_err" className="error_msg error">
                  {validationMsg.ivaOrBankrupt.message}
                </span>
              )}
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
}

export default ExtraQuestions;
