import { Api,CommonEmailValidateApi } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

export const useEmailValidation = () => {
  const emailValidation = async (email) => {
    const getValidation = await CommonEmailValidateApi.post(
      "api/validate?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&Email='+email,
      {}
    );
    return getValidation;
  };
  return { emailValidation };
};
