import {Api} from '../api/Api';

export const useTotalCarCheckLookUp = () => {
  const totalCarCheckLookUp = async (visitor_parameters, carRegNo, is_qualified, current_keeper_date, selected_keeper_date,  page_name) => {
          const getValidation = await Api.post(
            "/v1/totalcar-check-lookup",{
              visitor_parameters,
              carRegNo,
              is_qualified,
              current_keeper_date,
              selected_keeper_date,
              page_name
            }
          );
        return totalCarCheckLookUp;
  }
  return { totalCarCheckLookUp }
}