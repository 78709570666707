export const split_Flow = {
    default:{
        1:'Split_1', 
        2:'Signature',
        3:'Proceed',
        4:'Thankyou'
    },
    UNFR_0602UFBH_A1:{
        1:'0602UFBH_A1', 
        2:'otp_blackhorse',
        3:'0602UFBH_A1/questionnaire',
        4:'blackhorse/confirmation',
        5:'blackhorse/signature-preview',
        6:'blackhorse/preview',
        7:'thankyou',
    
    },
    UNFR_0602UFCO_A1:{
        1:'0602UFCO_A1', 
        2:'0602UFCO_A1/questionnaire',
        3:'0602UFCO_A1/confirmation',
        4:'0602UFCO_A1/signature-preview',
        5:'0602UFCO_A1/preview',
        6:'0602UFCO_A1/thankyou'
    
    },
    UNFR_0602UFVCC_A1:{
        1:'0602UFVCC_A1', 
        2:'0602UFVCC_A1/questionnaire',
        3:'catalogue-claims/confirmation',
        4:'catalogue-claims/signature-preview',
        5:'catalogue-claims/preview',
        6:'thankyou',
    },
    UNFR_0602UFVCC_A2:{
        1:'0602UFVCC_A2', 
        2:'0602UFVCC_A2/questionnaire',
        3:'catalogue-claims/confirmation',
        4:'catalogue-claims/signature-preview',
        5:'catalogue-claims/preview',
        6:'thankyou',
    },
    UNFR_0602UFMB_A1_1:{
        1:'0602UFMB_A1_1', 
        2:'otp_0602UFMB_A1_1',
        3:'0602UFMB_A1_1/questionnaire',
        4:'0602UFMB_A1_1/confirmation',
        5:'0602UFMB_A1_1/signature-preview',
        6:'0602UFMB_A1_1/preview',
        7:'0602UFMB_A1_1/thankyou',
    },
    UNFR_0602UFMB_A1_5:{
        1:'0602UFMB_A1_5', 
        2:'otp_0602UFMB_A1_5',
        3:'0602UFMB_A1_5/questionnaire',
        4:'0602UFMB_A1_5/confirmation',
        5:'0602UFMB_A1_5/signature-preview',
        6:'0602UFMB_A1_5/preview',
        7:'0602UFMB_A1_5/thankyou',
    },
    UNFR_0602UFMB_A1_6:{
        1:'0602UFMB_A1_6', 
        2:'0602UFMB_A1_6/questionnaire',
        3:'0602UFMB_A1_6/confirmation',
        4:'0602UFMB_A1_6/signature-preview',
        5:'0602UFMB_A1_6/preview',
        6:'0602UFMB_A1_6/thankyou',
    },
    Followup_BH:{
        1:'blackhorse/followup', 
        2:'blackhorse/followup/confirmation',
        3:'blackhorse/followup/signature-preview',
        4:'blackhorse/followup/preview',
        5:'blackhorse/followup/thankyou',
    },
    Followup_CC:{
        1:'catalogue-claims/followup', 
        2:'catalogue-claims/followup/confirmation',
        3:'catalogue-claims/followup/signature-preview',
        4:'catalogue-claims/followup/preview',
        5:'catalogue-claims/followup/thankyou',
    },
    Followup_MB:{
        1:'moneybarn/followup', 
        2:'moneybarn/followup/confirmation',
        3:'moneybarn/followup/signature-preview',
        4:'moneybarn/followup/preview',
        5:'moneybarn/followup/thankyou',
    },
    UNFR_0602UFMB_A1_6_2:{
        1:'0602UFMB_A1_6_2', 
        2:'0602UFMB_A1_6_2/questionnaire',
        3:'0602UFMB_A1_6_2/confirmation',
        4:'0602UFMB_A1_6_2/signature-preview',
        5:'0602UFMB_A1_6_2/preview',
        6:'0602UFMB_A1_6_2/thankyou',
    }

}