import React, { useEffect, useReducer, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import questions from "../../../../../Constants/moneybarnQuestions";
import { useFollowupUserQuestions } from "../../../../../Hooks/useFollowupUserQuestions";
import Loader from "../../Common/Loader";
import QuestionnaireModal from "../../Common/QuestionnaireModal";
import CheckBoxQuestionnaireFlp from "../Blackhorse/CheckBoxQuestionnaireFlp";
import InputQuestionnaireFlp from "./InputQuestionnaireFlp";
import RadioQuestiannaireC2Flp from "./RadioQuestiannaireC2Flp";
import RadioQuestionnaireFlp from "./RadioQuestionnaireFlp";
import SelectQuestiannaireFlp from "./SelectQuestiannaireFlp";
import UKVDFlp from "./UKVDFlp";

const PAGE_NAME = "moneybarn";

const YES = "Yes";
const NO = "No";

const firstQuestionId = questions[0].questionId;

const valueOf = (id, label) => {
  const q = questions.find((q) => q.questionId === id);
  const answer = q.answers.find((o) => o.label === label);

  return answer.value;
};

const initialState = {
  showLetsBeginSlide: "d-none",
  showQuestionSlide1: "d-none",
  showQuestionSlide2: "d-none",
};

questions.forEach((question) => {
  initialState[`showQuestionSlide_${question.questionId}`] = "d-none";
});
const QuestionnaireReducer = (state, action) => {

  switch (action.type) {
   
    case "showSlideChange": {
      const { selectedQuest } = action?.payload;

      if (selectedQuest == "1") {
        return {
          ...state,
          showQuestionSlide1: "d-block",
        };
      }

      if (selectedQuest == "2") {
        return {
          ...state,
          showQuestionSlide1: "d-none",
          showQuestionSlide2: "d-block",
        };
      }

      const newState = { ...state };

      // make all d-block d-none
      Object.keys(state).forEach((key) => {
        if (state[key] == "d-block") newState[key] = "d-none";
      });

      newState[`showQuestionSlide_${selectedQuest}`] = "d-block";

      return newState;
    }
    default: {
      return state;
    }
  }
};

const QuestionsModal = ({
  questionsPop,
  allFollowupData,
  setPendingStatus,
  pendingQuestionnaire,
  uuid,
  vehicleDetails,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const { saveFollowupUserQuestions, storeFollowupVehicleDetails, isLoading } =
    useFollowupUserQuestions();
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const [vehicleModel, setVehicleModel] = useState("");
  const [selectedKeeperDate, setSelectedKeeperDate] = useState("");
  const [vehicleSecureYear, setVehicleSecureYear] = useState("");
  const [currentKeeperDate, setCurrentKeeperDate] = useState("");
  const [quest34_35, setQuest34_35] = useState("");
  const [carRegNo, setCarRegNo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const splitForm = useRef(null);
  const [loader, showLoader] = useState("d-none");

  useEffect(() => {
    setShow(questionsPop);
    setVehicleSecureYear(vehicleDetails);
  }, [questionsPop]);

  const slideChange = async (e, fieldName) => {
    const selectedQuest = e.target.dataset.question_id;
    const currentSlide = e.target.name;

    const form = splitForm.current;


    if (
      completedQuestArr.length == 2 &&
      !!completedQuestArr.find((q) => q == 2)
    ) {
      let vehicle_data = {
        keeper_date: selectedKeeperDate,
        current_keeper_date: currentKeeperDate,
        yourVehicle: e.target.dataset.answertext,
        carRegNo: carRegNo,
        pageName: PAGE_NAME,
      };
      const response = await storeFollowupVehicleDetails(
        vehicle_data,
        allFollowupData.flpData.followup_data,
        "store-moneybarn-followup-vehicle-details"
      );

      var vehicle_details = {
        selecte_keeper_date: selectedKeeperDate,
        current_keeper_date: currentKeeperDate,
        your_vehicle: e.target.dataset.answertext,
        car_reg_no: carRegNo,
      };

      localStorage.setItem("vehicleDetails", JSON.stringify(vehicle_details));

      if (e.target.value == valueOf(firstQuestionId, NO)) {
        history.push(`/thankyou-unqualified?uuid=${uuid}`);
        return false;
      }
    }

    const isBasicQuestion = [
      "lets_begin",
      "btnSkip",
      "btnNext2",
      "keepDate",
    ].includes(currentSlide);

    if (!isBasicQuestion) {
      const question_id = parseInt(e.target.dataset.question_id);
      const input_questions = questions
        .filter((q) => !q.answers.length)
        .map((q) => parseInt(q.questionId));
      const optionId = input_questions.includes(question_id)
        ? ""
        : question_id == 144
        ? fieldName
        : form[fieldName].value;
      const inputAnswer = input_questions.includes(question_id)
        ? form[fieldName].value
        : "";

      let question_data = {
        question_id: question_id,
        option_id: optionId,
        input_answer: inputAnswer,
        bankId: "123",
      };

      const response = await saveFollowupUserQuestions(
        question_data,
        allFollowupData.flpData.followup_data,
        PAGE_NAME,
        "moneybarn-followup-question-store"
      );

      if (selectedQuest == 148 || selectedQuest == 149) {
        const isYes = e.target.dataset.answertext == YES;

        if (isYes) {
          setShowModal(true);
          setShow(false);
          setQuest34_35(e.target.value);
          return false;
        }
      }

      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = {};
      var newEntries =
        existingEntries == null ? (existingEntries = {}) : existingEntries;
      Object.assign(newEntries, {
        [question_id]: optionId ? optionId : inputAnswer,
      });
      localStorage.setItem("questionData", JSON.stringify(newEntries));
    }

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShow(true);

    if (quest34_35 == "405") {
      dispatch({ type: "showSlideChange", payload: 34 });
    } else if (quest34_35 == "407") {
      dispatch({ type: "showSlideChange", payload: 35 });
    }
  };

  useEffect(() => {
    const answers = JSON.parse(localStorage.getItem("questionData"));

    if (!pendingQuestionnaire?.pendingQuestions?.length) {
      return console.log("no pending questions");
    }

    const totalQuestArr = pendingQuestionnaire.pendingQuestions;
    const splitName = localStorage.getItem("split_name");
    if(answers){
      const q139 = answers?.['139'];
      if((splitName == '0602UFMB_A1_6_2') && (q139 !=undefined)){
       const q138 =answers?.[138].split('-')?.[0].split('£')?.[1];
       const q139 =answers?.[139].split('-')?.[0].split('£')?.[1];
        const percentage = ( q139 /q138 ) * 100 ;
        if(percentage >=20){
          let remove = ['144'];
         // totalQuestArr = totalQuestArr.filter(item => !remove.includes(item));
         let totalQuestArrs = totalQuestArr.filter(item => !remove.includes(item));
          completedQuestArr.push(144,145);
        }
      }
    }
    var questIntersection = totalQuestArr.filter(
      (x) => !completedQuestArr.find((y) => y == x)
    );
    
    if (questIntersection[0] == undefined) {
      setPendingStatus({
        status_btn: "pending_question",
        status_flag: "showTick",
      });
      setShow(false);
    }

    let selectedQuest = questIntersection[0];
    if (selectedQuest == 150) {
      const value = answers?.[140];

      if (value == valueOf(140, YES)) {
        // Skip question 150
        setCompletedQuestArr([...completedQuestArr, selectedQuest]);
        selectedQuest = questIntersection[1];
      }
    }

    if (selectedQuest == 141) {
      const value = answers?.[142];

      if (value == valueOf(142, NO)) {
        // Skip question 141
        setCompletedQuestArr([...completedQuestArr, selectedQuest]);
        selectedQuest = questIntersection[1];
      }
    }

    if (selectedQuest == 153) {
      const value = answers?.[142];

      if (value == valueOf(142, YES)) {
        // Skip question 153
        setCompletedQuestArr([...completedQuestArr, selectedQuest]);
        selectedQuest = questIntersection[1];
      }
    }

    if (selectedQuest == 147) {
      const value = answers?.[146];

      if (value == valueOf(146, NO)) {
        // Skip question 147
        setCompletedQuestArr([...completedQuestArr, selectedQuest]);
        selectedQuest = questIntersection[1];
      }
    }

    if (selectedQuest == 141) {
      const value = answers?.[137];

      if (value == valueOf(137, NO)) {
        // Skip question 141
        setCompletedQuestArr([...completedQuestArr, selectedQuest]);
        selectedQuest = questIntersection[1];
      }
    }
    if (selectedQuest == 153) {
      const value = answers?.[137];

      if (value == valueOf(137, NO)) {
        // Skip question 153
        setCompletedQuestArr([...completedQuestArr, selectedQuest]);
        selectedQuest = questIntersection[1];
      }
    }

    if (selectedQuest) {
      dispatch({ type: "showSlideChange", payload: { selectedQuest } });
    }
  }, [pendingQuestionnaire, completedQuestArr]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="followupPage modal-content-q"
        backdrop="static"
        keyboard={false}
      >
        <div className="modal-body sign-sec">
          <form ref={splitForm}>
            <Loader show={loader} />
            <UKVDFlp
              slideChange={slideChange}
              showQuestionSlide1={state.showQuestionSlide1}
              showQuestionSlide2={state.showQuestionSlide2}
              uuid={uuid}
              page_name={PAGE_NAME}
              setVehicleModel={setVehicleModel}
              setSelectedKeeperDate={setSelectedKeeperDate}
              setVehicleSecureYear={setVehicleSecureYear}
              setCurrentKeeperDate={setCurrentKeeperDate}
              setCarRegNo={setCarRegNo}
              showLoader={showLoader}
            />

            {questions
              .map((q) => {
                if (q.question.includes("YYYY")) {
                  q.question = q.question.replace(
                    "YYYY",
                    !!vehicleSecureYear && !!vehicleSecureYear.length
                      ? vehicleSecureYear
                      : "YYYY"
                  );
                }

                return q;
              })
              .filter((q) => !q.question.includes("YYYY"))
              .map((question) => {
                if (question.questionId == 21) {
                  return (
                    <RadioQuestiannaireC2Flp
                      key={`question_${question.questionId}`}
                      showQuestionSlide={
                        state[`showQuestionSlide_${question.questionId}`]
                      }
                      questionnaire={question}
                      slideChange={slideChange}
                      vehicleModel={vehicleModel}
                      //Is this your Vehicle?
                    />
                  );
                }

                if (question.questionId == 151) {
                  return (
                    <InputQuestionnaireFlp
                      key={`question_${question.questionId}`}
                      showQuestionSlide={
                        state[`showQuestionSlide_${question.questionId}`]
                      }
                      questionnaire={question}
                      slideChange={slideChange}
                      placeholder="Agreement Number"
                      maxLength="10"
                    />
                  );
                }

                if (question.questionId == 144) {
                  return (
                    <CheckBoxQuestionnaireFlp
                      key={`question_${question.questionId}`}
                      showQuestionSlide={
                        state[`showQuestionSlide_${question.questionId}`]
                      }
                      questionnaire={question}
                      slideChange={slideChange}
                    />
                  );
                }

                if (!!question.options?.length) {
                  return (
                    <SelectQuestiannaireFlp
                      key={`question_${question.questionId}`}
                      showQuestionSlide={
                        state[`showQuestionSlide_${question.questionId}`]
                      }
                      questionnaire={question}
                      slideChange={slideChange}
                    />
                  );
                }

                if (!!question.answers.length) {
                  return (
                    <RadioQuestionnaireFlp
                      key={`question_${question.questionId}`}
                      showQuestionSlide={
                        state[`showQuestionSlide_${question.questionId}`]
                      }
                      questionnaire={question}
                      slideChange={slideChange}
                      vehicleModel={vehicleModel}
                      size={question.answers.length > 2 ? "sm" : null}
                    />
                  );
                }

                return (
                  <InputQuestionnaireFlp
                    key={`question_${question.questionId}`}
                    showQuestionSlide={
                      state[`showQuestionSlide_${question.questionId}`]
                    }
                    questionnaire={question}
                    slideChange={slideChange}
                  />
                );
              })}
          </form>
        </div>
      </Modal>
      <QuestionnaireModal
        popDisplay={showModal}
        handleClose={handleCloseModal}
      />
    </>
  );
};
export default QuestionsModal;
