const questionnaire124 = {
      question: "Which year was your Catalogue Credit account opened?",
      questionId: 124,
      answers: [
        {
          label: "",
          value: "",
          options: [
            { value: "", label: "Please select the year" },
            { value: "2007 or earlier", label: "2007 or earlier" },
            { value: "2008", label: "2008" },
            { value: "2009", label: "2009" },
            { value: "2010", label: "2010" },
            { value: "2011", label: "2011" },
            { value: "2012", label: "2012" },
            { value: "2013", label: "2013" },
            { value: "2014", label: "2014" },
            { value: "2015", label: "2015" },
            { value: "2016", label: "2016" },
            { value: "2017", label: "2017" },
            { value: "2018", label: "2018" },
            { value: "2019", label: "2019" },
            { value: "2020", label: "2020" },
            { value: "2021", label: "2021" },
            { value: "2022", label: "2022" },
          ]
        },
      ],
    };
    const questionnaire125 = {
      question: "Is your Catalogue Credit account still open?",
      questionId: 125,
      answers: [
        {
          label: "Yes",
          value: "231",
        },
        {
          label: "No",
          value: "232",
        },
      ],
    };
    const questionnaire126 = {
      question: "Which year was your Catalogue Credit account closed?",
      questionId: 126,
      answers: [
        {
          label: "",
          value: "",
          options: [
            { value: "", label: "Please select the year" },
            { value: "2008", label: "2008" },
            { value: "2009", label: "2009" },
            { value: "2010", label: "2010" },
            { value: "2011", label: "2011" },
            { value: "2012", label: "2012" },
            { value: "2013", label: "2013" },
            { value: "2014", label: "2014" },
            { value: "2015", label: "2015" },
            { value: "2016", label: "2016" },
            { value: "2017", label: "2017" },
            { value: "2018", label: "2018" },
            { value: "2019", label: "2019" },
            { value: "2020", label: "2020" },
            { value: "2021", label: "2021" },
            { value: "2022", label: "2022" },
          ]
        },
      ],
    };
    const questionnaire127 = {
      question:
        "Approximately what was the highest balance you ever had on this Catalogue Credit account?",
      questionId: 127,
      answers: [
        {
          label: "",
          value: "",
          options: [
            { value: "", label: "Please select" },
            { value: "£100 - £200", label: "£100 - £200" },
            { value: "£200 - £300", label: "£200 - £300" },
            { value: "£300 - £400", label: "£300 - £400" },
            { value: "£400 - £500", label: "£400 - £500" },
            { value: "£500 - £600", label: "£500 - £600" },
            { value: "£600 - £700", label: "£600 - £700" },
            { value: "£700 - £800", label: "£700 - £800" },
            { value: "£800 - £900", label: "£800 - £900" },
            { value: "£900 - £1000", label: "£900 - £1000" },
            { value: "£1000 - £1500", label: "£1000 - £1500" },
            { value: "£1500 - £2000", label: "£1500 - £2000" },
            { value: "£2000 - £2500", label: "£2000 - £2500" },
            { value: "£2500 - £3000", label: "£2500 - £3000" },
            { value: "More than £3000", label: "More than £3000" },

          ]
        },
      ],
    };
    const questionnaire128 = {
      question:
        "Approximately how many times was your credit limit increased on this account? Include every increase, whether you requested it or it happened automatically",
      questionId: 128,
      answers: [
        {
          label: "My credit limit never increased",
          value: "233",
        },
        {
          label: "Once",
          value: "234",
        },
        {
          label: "Twice",
          value: "235",
        },
        {
          label: "Three or four times",
          value: "236",
        },
        {
          label: "five times or more",
          value: "237",
        },
      ],
    };
    const questionnaire129 = {
      question:
        "Did you have a balance which was close to, or exceeded, your credit limit?",
      questionId: 129,
      answers: [
        {
          label: "Always",
          value: "238",
        },
        {
          label: "Often",
          value: "239",
        },
        {
          label: "Sometimes",
          value: "240",
        },
        {
          label: "Never",
          value: "241",
        },
      ],
    };
    const questionnaire130 = {
      question:
        "Did you ever only make the minimum payment due on the monthly account statement (rather than pay off a larger amount)?",
      questionId: 130,
      answers: [
        {
          label: "Always",
          value: "242",
        },
        {
          label: "Often",
          value: "243",
        },
        {
          label: "Sometimes",
          value: "244",
        },
        {
          label: "Never",
          value: "245",
        },
      ],
    };
    const questionnaire131 = {
      question:
        "Approximately what was your average or usual monthly income (after tax) during the time your Catalogue Credit account was open",
      questionId: 131,
      answers: [
        {
          label: "",
          value: "",
          options: [
            { value: "", label: "Please select" },
            { value: "£100 - £200", label: "£100 - £200" },
            { value: "£200 - £300", label: "£200 - £300" },
            { value: "£300 - £400", label: "£300 - £400" },
            { value: "£400 - £500", label: "£400 - £500" },
            { value: "£500 - £600", label: "£500 - £600" },
            { value: "£600 - £700", label: "£600 - £700" },
            { value: "£700 - £800", label: "£700 - £800" },
            { value: "£800 - £900", label: "£800 - £900" },
            { value: "£900 - £1000", label: "£900 - £1000" },
            { value: "£1000 - £1500", label: "£1000 - £1500" },
            { value: "£1500 - £2000", label: "£1500 - £2000" },
            { value: "£2000 - £2500", label: "£2000 - £2500" },
            { value: "£2500 - £3000", label: "£2500 - £3000" },
            { value: "More than £3000", label: "More than £3000" },

          ]
        },
      ]
    };
    const questionnaire132 = {
      question:
        "During the time your Catalogue Credit account was open, approximately how much per month were you typically paying towards other credit commitments (loans and card accounts, etc)?",
      questionId: 132,
      answers: [
        {
          label: "",
          value: "",
          options: [
            { value: "", label: "Please select" },
            { value: "£100 - £200", label: "£100 - £200" },
            { value: "£200 - £300", label: "£200 - £300" },
            { value: "£300 - £400", label: "£300 - £400" },
            { value: "£400 - £500", label: "£400 - £500" },
            { value: "£500 - £600", label: "£500 - £600" },
            { value: "£600 - £700", label: "£600 - £700" },
            { value: "£700 - £800", label: "£700 - £800" },
            { value: "£800 - £900", label: "£800 - £900" },
            { value: "£900 - £1000", label: "£900 - £1000" },
            { value: "£1000 - £1500", label: "£1000 - £1500" },
            { value: "£1500 - £2000", label: "£1500 - £2000" },
            { value: "£2000 - £2500", label: "£2000 - £2500" },
            { value: "£2500 - £3000", label: "£2500 - £3000" },
            { value: "More than £3000", label: "More than £3000" },

          ]
        },
      ]
    };
    const questionnaire133 = {
      question:
        "During the time your Catalogue Credit account was open, were you..",
      questionId: 133,
      answers: [
        {
          label:
            "Regularly using 'bad credit' lending (payday, guarantor, doorstep and high-interest)",
          value: "246",
        },
        {
          label: "Using a lot of your income for gambling or online betting",
          value: "247",
        },
        {
          label:
            "Receiving a very low and unreliable income (e.g. due to job loss, state benefits, being a student, etc)",
          value: "248",
        },
        {
          label:
            "Managing your income and outgoings ok, but still believe the account was unaffordable for me",
          value: "249",
        },
      ],
    };
    const questionnaire134 = {
      question:
        "Are you subject to or have you ever been subject to an Individual Voluntary Arrangement (IVA) or have you proposed an IVA yet to be approved or rejected by creditors?",
      questionId: 134,
      answers: [
        {
          label: "Yes",
          value: "250",
        },
        {
          label: "No",
          value: "251",
        },
      ],
    };
    const questionnaire135 = {
      question: "Do any of these scenarios apply to you?",
      questionId: 135,
      answers: [
        {
          label: "Yes",
          value: "252",
        },
        {
          label: "No",
          value: "253",
        },
      ],
    };
    
    export {
      questionnaire124,
      questionnaire125,
      questionnaire126,
      questionnaire127,
      questionnaire128,
      questionnaire129,
      questionnaire130,
      questionnaire131,
      questionnaire132,
      questionnaire133,
      questionnaire134,
      questionnaire135,
    };
    