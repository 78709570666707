import React from "react";

const Button = ({ type, className,name, id, onClick, buttonText, disabled, value, question_id }) => {
  return (
    <button
      type={type}
      className={className}
      name={name}
      id={id}
      onClick={onClick}
      disabled={disabled}
      value={value}
      data-question_id={question_id}
    >
      {buttonText}
    </button>
  );
};
export default Button;

