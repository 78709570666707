import { Api } from "../api/Api";

export const useSkipCarRegisterValidation = () => {
  const skipRegistrationValidation = async (uuid, page_name) => {
    return await Api.post("v1/ukvd-delete-regno-on-skip", {
      uuid,
      page_name,
    });
  };

  return { skipRegistrationValidation };
};
