import React from "react";
import Modal from "react-bootstrap/Modal";

const DEFAULT_CONTENT = `Sorry we cannot accept your application.`;

const FinanceYearQuestionnaireModal = ({
  finanacePopDisplay,
  financeHandleClose,
  content = DEFAULT_CONTENT,
}) => {
  return (
    <Modal show={finanacePopDisplay} dialogClassName="modalReact">
      <div className="">
        <Modal.Body>
          <p>{content}</p>
        </Modal.Body>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={financeHandleClose}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FinanceYearQuestionnaireModal;
