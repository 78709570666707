import React, { useState } from "react";

import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";

const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  trigger,
  getValues,
  formSubmit,
  showSign,
  setEmailCheck,
  setPhoneCheck,
  children = null,
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const [isChecked, setIsChecked] = useState(false);

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];

    if (phone !== "") {
      setPhoneCheck(0); // assuming invalid

      const getPhoneValidation = await phoneValidation(phone);
      // console.log(getPhoneValidation.data);

      setPhoneCheck(getPhoneValidation.data.status_code);

      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      }

      if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      }

      if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }

      return 1;
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];

    if (email !== "") {
      setEmailCheck(0); // assuming invalid

      const getEmailValidation = await emailValidation(email);
      // console.log(getEmailValidation.data);

      setEmailCheck(getEmailValidation.data.status_code);

      if (getEmailValidation.data.status_code === 1) {
        // document.getElementById("formSubmitBtn").focus();
        if (document.getElementById("financeYear")) {
          // document.getElementById("financeYear").focus();
        } else {
          document.getElementById("signature-pad").classList.add("efct_filed");
        }
        return 1;
      }

      setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
      });
    }
  };

  return (
    <div id="slide-7" className={`slidetop_adjust ${className}`}>
      <div className="input-section">
        <div className="mb-3">
          <Telephone
            name="txtPhone"
            id="txtPhone"
            className="form-control nme_inpu"
            placeholder="Mobile Number*"
            onBlur={() =>
              trigger("txtPhone").then(
                (isValid) => isValid && phoneValidCheck()
              )
            }
            onKeyPress={(e) => !/[0-9]+$/.test(e.key) && e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) =>
              /[^0-9]/i.test(e.clipboardData.getData("Text"))
                ? e.preventDefault()
                : null
            }
            maxlength="11"
            validation={validation({
              pattern: {
                value: /^[0-9]*$/i,
                message: "Please Enter Valid Phone Number",
              },
              required: "Please Enter Phone Number",
            })}
          ></Telephone>

          {validationMsg.txtPhone && (
            <span className="error" id="phone_err">
              {validationMsg.txtPhone && "Please Enter Valid Phone Number"}
            </span>
          )}
        </div>
        <div className="mb-3">
          <Email
            name="txtEmail"
            className="form-control"
            id="txtEmail"
            placeholder="Email Address*"
            onBlur={() =>
              trigger("txtEmail").then(
                (isValid) => isValid && emailValidCheck()
              )
            }
            validation={validation({
              required: "Please Enter Valid Email Address",
            })}
            onKeyPress={(e) => e.key === " " && e.preventDefault()}
          ></Email>
          {validationMsg.txtEmail && (
            <span className="error" id="email_err">
              {validationMsg.txtEmail.message}
            </span>
          )}
        </div>

        {children}

        {showSign && (
          <div className="mb-3 text-center important_know">
            <h2 className="mb-2">IMPORTANT TO KNOW</h2>
            <h5>ADM Processing</h5>
            <p className="text_adi">
            I understand that by clicking “Start”, the information I provide in my claim enquiry will be assessed by automated technology to determine if my claim is eligible. I understand that I can request human intervention as set out in the{" "}
              <a
                href="https://allegiant.co.uk/compliance/privacy-policy/"
                target="_blank"
              >
                Privacy Policy.
              </a>
            </p>
            <h5 className="mt-1">Claiming Yourself For Free</h5>
            <p className="text_adi">
              You do not need to use a claims management company to make your
              complaint to your lender, and if your complaint is not successful
              you can refer it to the Financial Ombudsman Service yourself for
              free.
            </p>
          </div>
        )}

        {!showSign && (
          <div className="form-check mb-2 mt-4 ">
            <input
              className="form-check-input"
              ref={validation({ required: "Please Enter Last Name" })}
              onChange={(e) => {
                setIsChecked(!isChecked);
                trigger("chkPrvcy");
              }}
              type="checkbox"
              name="chkPrvcy"
              value={isChecked}
              id="chkPrvcy"
            />
            <label className="form-check-label" htmlFor="4">
              I understand that by clicking "Start My Claim", my eligibility
              will be assessed by automated technology. I understand I have a
              right to object to this as set out in the
              <a
                href="https://allegiant.co.uk/compliance/privacy-policy/"
                target="_blank"
              >
                {" "}
                Privacy Policy.
              </a>
            </label>
            {validationMsg.chkPrvcy && (
              <span className="error_msg " id="email_err">
                Please Tick Checkbox
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default ContactDetails;
