import questions from "../Constants/questions_0602UFMB_A1_6";

const YES = "Yes";
const NO = "No";

const valueOf = (id, label) => {
  // console.log("id", id, "label", label);
  const q = questions.find((q) => q.questionId === id);
  const answer = q.answers.find((o) => o.label === label);
  // console.log("answer", answer);

  return answer.value;
};

export const QualificationCheck = () => {
  const answers = JSON.parse(localStorage.getItem("questionData"));
  //console.log("------------MoneyBarn Qualification Check-----------------");
  //console.log(answers);

  let qualified_150 = 1;
  let qualified_143 = 1;
  let qualified_144 = 1;
  let qualified_145 = 1;
  let qualified_138_139 = 0;
  let income = "";
  let payment = "";
  let percentage = 0;
  let qualified_137_142_141 = 1;
  let qualified_170_171_172 = 1;

  if (answers[150] && answers[150] <= 2007) {
    qualified_150 = 0;
  }

  // if (answers[143] && answers[143] == "424") {
  //   qualified_143 = 0;
  // }

  if (answers[138]) {
    let value = answers[138];
    const isRange = value.includes("-");

    if (isRange) {
      value = value.split("-")[0];
    }

    income = parseInt(value.split("£")[1]);
  }

  if (answers[139]) {
    let value = answers[139];
    const isRange = value.includes("-");

    if (isRange) {
      value = value.split("-")[0];
    }

    payment = parseInt(value.split("£")[1]);
  }

  if (answers[144] && answers[144].length == 1 && answers[144][0] == "428") {
    qualified_144 = 0;
  }

  // if (answers[145] && answers[145] == valueOf(145, NO)) {
  //   qualified_145 = 0;
  // }

  if (income && payment) {
    percentage = (payment / income) * 100;
  }
  // if (percentage >= 20) {
  //   qualified_144 = qualified_145 = 1;
  //   qualified_138_139 = 1;
  // }
  if (percentage >= 20) {
    qualified_144 = 1;
    qualified_138_139 = 1;
  }
  // ! NOTE: nothing happens if percentage is exactly 20
  //console.log("===========qualified_144===========");
  //console.log(qualified_144);
  //console.log("===========qualified_145===========");
  //console.log(qualified_145);
  //console.log("=============Percentage==============");
  //console.log(percentage);
  if ((percentage < 20) && (qualified_144 || qualified_145)) {
    //console.log("Leass than 20");
    qualified_144 = qualified_145 = 1;
    qualified_138_139 = 1;
  }

  if (
    answers?.[137] == valueOf(137, YES) &&
    answers?.[142] == valueOf(142, YES) &&
    answers?.[141] == "418"
  ) {
    qualified_137_142_141 = 0;
  }
  if( answers?.[170] == 477  ||
    answers?.[171] == 479  ||
    answers?.[172] == 482 
    ){
      qualified_170_171_172 = 0;
    }

  const checks = [
    qualified_150,
   // qualified_143,
    qualified_144,
   // qualified_145,
    qualified_138_139,
    qualified_137_142_141,
    qualified_170_171_172
  ];

  const isQualified = checks.every((check) => check == 1);

  //console.log({ checks, isQualified });

  return isQualified;
};
