import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useAgreeTermsIngestion } from "../../Hooks/useAgreeTermsIngestion";
import { userInfo } from "../../Hooks/userInfo";
import questionnaire from "../../Constants/questions_0602UFCO_A1_Preview";
import { Helmet } from "react-helmet";
import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";

const Preview = () => {
  const [show, setShow] = useState("show");
  const [userId, setUserId] = useState();
  const [title, setTitle] = useState();
  const [fName, setfName] = useState();
  const [lName, setlName] = useState();
  const [addressLine, setaddressLine] = useState();
  const [addressLine2, setaddressLine2] = useState();
  const [addressLine3, setaddressLine3] = useState();
  const [agreementNo, setAgreementNo] = useState();
  const [agreementType, setAgreementType] = useState();
  const [agreementTerm, setAgreementTerm] = useState();
  const [carRegNo, setCarRegNo] = useState();
  const [vehicleMake, setVehicleMake] = useState();
  const [vehicleModel, setVehicleModel] = useState();

  const [town, setTown] = useState();
  const [country, setCountry] = useState();
  const [postcode, setPostcode] = useState();
  const [county, setCounty] = useState();
  const [userPhone, setUserPhone] = useState();
  const [userMail, setUserMail] = useState();
  const [userDob, setUserDob] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [userSign, setUserSign] = useState();
  const [answers, setAnswers] = useState({});
  const [usersigndate, setUserSigndate] = useState();
  const { getUserInfo } = userInfo();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [currentUuid, setCurrentUuid] = useState();
  const [bankName, setBankName] = useState();
  const history = useHistory();
  const [showDiv4, setshowDiv4] = useState("d-none");
  const [hideDiv3, sethideDiv3] = useState("show");
  const [nextButton1, setnextButton1] = useState("show");
  const [nextButton2, setnextButton2] = useState("d-none");
  const [text, settext] = useState("Next");
  const signref_1 = useRef();
  const signref_2 = useRef();
  const signref_3 = useRef();
  const signref_4 = useRef();
  const signref_5 = useRef();
  const { saveAgreeTermsIngestion, savePreviewClicks } =
    useAgreeTermsIngestion();
  const [prevAddressLine1, setPrevAddressLine1] = useState("");
  const [prevAddressLine3, setPrevAddressLine3] = useState("");
  const [prevTown, setPrevTown] = useState("");
  const [prevCountry, setPrevCountry] = useState("");
  const [prevPostcode, setPrevPostcode] = useState("");
  const [prevCounty, setPrevCounty] = useState("");
  const [prevName, setPrevName] = useState("");
  const [questionFlag, setQuestionFlag] = useState(false);
  const [question21, setQuestion21] = useState("");
  const [keeperDateYear, setKeeperDateYear] = useState("");
  const [signShow, setSignShow] = useState(0);
  const location = useLocation();
  const [clickCount, setClickCount] = useState(1);
  const [sign01, setsign01] = useState("d-none");
  const [sign02, setsign02] = useState("d-none");
  const [sign03, setsign03] = useState("d-none");
  const [sign04, setsign04] = useState("d-none");
  const [button01, setbutton01] = useState("show");
  const [button02, setbutton02] = useState("show");
  const [button03, setbutton03] = useState("show");
  const [button04, setbutton04] = useState("show");
  const {DynamicRouteSplitName,DynamicRouteNextPage}   = DynamicRoutingSplit('UNFR_0602UFCO_A1','0602UFCO_A1/preview');
  const [sign, setSign] = useState(["sign01", "sign02", "sign03", "sign04"]);
  const signShowDiv = (e) => {
    if (e.target.name == "sign01") {
      handleRemoveItem("sign01");
      setsign01("show");
      setbutton01("d-none");
    } else if (e.target.name == "sign02") {
      handleRemoveItem("sign02");
      setsign02("show");
      setbutton02("d-none");
    } else if (e.target.name == "sign03") {
      handleRemoveItem("sign03");
      setsign03("show");
      setbutton03("d-none");
    } else if (e.target.name == "sign04") {
      handleRemoveItem("sign04");
      setsign04("show");
      setbutton04("d-none");
    }
  };
  const handleRemoveItem = (value) => {
    setSign(sign.filter((item) => item !== value));
  };
  useEffect(() => {
    (async () => {
      const visitorData = visitorParameters.visitor_parameters;
      if (visitorData) {
        const uuid = visitorData.uuid;
        setCurrentUuid(uuid);
      }
    })();
  }, [visitorParameters]);
  let customUuid = "";
  let customSource = "";
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const query_source = query.get("source");
  const local_uuid = localStorage.getItem("uuid");
  customUuid = query_uuid ? query_uuid : local_uuid;
  customSource = query_source ? query_source : "live";

  const showSign = async (e) => {
    let user_uuid = "";
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_uuid = localStorage.getItem("uuid");
    user_uuid = query_uuid ? query_uuid : local_uuid;
    let labelName = e.target.dataset.labelname;
    setClickCount(clickCount + 1);
    sethideDiv3("d-none");
    setshowDiv4("show");
    setnextButton1("d-none");
    setnextButton2("show");
    // let signDiv = (parseInt(signShow) + 1);
    // setSignShow(signDiv);
    // var scrollToRef = (signDiv == 1) ? signref_5 : (signDiv == 2) ? signref_1 : ((signDiv == 3) ? signref_2 : (signDiv == 4) ? signref_3 : (signDiv == 5) ? signref_4 : '');
    var scrollToRef = sign.includes("sign01")
      ? signref_1
      : sign.includes("sign02")
      ? signref_2
      : sign.includes("sign03")
      ? signref_3
      : sign.includes("sign04")
      ? signref_4
      : "";
    if (sign.length == 1) {
      settext("Finish");
    }
    console.log("scrollToRef", scrollToRef);
    if (scrollToRef != "") {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      pageChange();
    }
    if (clickCount <= 6) {
      const saveClick = await savePreviewClicks(userId, user_uuid, labelName);
    }
  };
  const pageChange = async () => {
    const previewstats = await saveAgreeTermsIngestion(
      userId,
      customUuid,
      "preview_submit",
      1,
      "live"
    );

    // if (location.pathname == "/0602UFCO_A1/preview") {
      history.push(`/${DynamicRouteNextPage}?uuid=${customUuid}&email=${userMail}&splitName=${DynamicRouteSplitName}`);
    // }
  };

  useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const customPreviousData = JSON.parse(
      localStorage.getItem("customPreviousData")
    );
    const questionData = JSON.parse(localStorage.getItem("questionData"));
    const vehicleDetails = JSON.parse(localStorage.getItem("vehicleDetails"));
    (async () => {
      const response = await getUserInfo(customUuid);
      let dataLength = response.data.response.length;
      if (dataLength > 0) {
        const title = response.data.response[0].title
          ? response.data.response[0].title
          : "";
        const userId = response.data.response[0].id
          ? response.data.response[0].id
          : "";
        const fName = response.data.response[0].first_name
          ? Capitalize(response.data.response[0].first_name)
          : "";
        const lName = response.data.response[0].last_name
          ? Capitalize(response.data.response[0].last_name)
          : "";
        const phone = response.data.response[0].telephone
          ? response.data.response[0].telephone
          : "";
        const userMail = response.data.response[0].email
          ? response.data.response[0].email
          : "";
        const usersign = response.data.response[0].signature_image
          ? response.data.response[0].signature_image
          : "";
        const usersigndate = response.data.response[0].created_at
          ? response.data.response[0].created_at
          : "";
        const userdob = response.data.response[0].user_dob
          ? response.data.response[0].user_dob
          : "";
        const questions = response.data.response[0].questionnaire
          ? response.data.response[0].questionnaire
          : "";

        const agreementNo = response.data.response[0].agreement_no
          ? response.data.response[0].agreement_no
          : "";
        const agreementType = response.data.response[0].agreement_type
          ? response.data.response[0].agreement_type
          : "";
        const agreementTerm = response.data.response[0].agreement_term
          ? response.data.response[0].agreement_term
          : "";
        const keeper_year = response.data.response[0].keeper_year
          ? response.data.response[0].keeper_year
          : "";
        const carRegNo = response.data.response[0].car_reg_no
          ? response.data.response[0].car_reg_no
          : "";
        const vehicleMake = response.data.response[0].vehicle_make
          ? response.data.response[0].vehicle_make
          : "";
        const vehicleModel = response.data.response[0].vehicle_model
          ? response.data.response[0].vehicle_model
          : "";
        let addressLine = "";
        let town = "";
        let country = "";
        let postcode = "";
        let county = "";
        let addressLine2 = "";
        let addressLine3 = "";
        if (response.data.response[0].address_line1 !== "") {
          addressLine = response.data.response[0].address_line1
            ? response.data.response[0].address_line1
            : "";
          addressLine2 = response.data.response[0].address_line2
            ? response.data.response[0].address_line2
            : "";
          addressLine3 = response.data.response[0].address_line3
            ? response.data.response[0].address_line3
            : "";
          town = response.data.response[0].town
            ? response.data.response[0].town
            : "";
          country = response.data.response[0].country
            ? response.data.response[0].country
            : "";
          postcode = response.data.response[0].postcode
            ? response.data.response[0].postcode
            : "";
          county = response.data.response[0].county
            ? response.data.response[0].county
            : "";
        }
        if (response.data.response[0].previous_address) {
          let prevaddressLine1 = response.data.response[0].previous_address
            .address_line1
            ? response.data.response[0].previous_address.address_line1
            : "";
          let prevaddressLine3 = response.data.response[0].previous_address
            .address_line3
            ? response.data.response[0].previous_address.address_line3
            : "";
          let prevtown = response.data.response[0].previous_address.town
            ? response.data.response[0].previous_address.town
            : "";
          let prevcountry = response.data.response[0].previous_address.country
            ? response.data.response[0].previous_address.country
            : "";
          let prevpostcode = response.data.response[0].previous_address.postcode
            ? response.data.response[0].previous_address.postcode
            : "";
          let prevcounty = response.data.response[0].previous_address.county
            ? response.data.response[0].previous_address.county
            : "";
          setPrevAddressLine1(prevaddressLine1);
          setPrevTown(prevtown);
          setPrevCountry(prevcountry);
          setPrevPostcode(prevpostcode);
          setPrevCounty(prevcounty);
          setPrevAddressLine3(prevaddressLine3);
        }
        const bankname = response.data.response[0].bank_name
          ? response.data.response[0].bank_name
          : null;
        var previousName = response.data.response[0].previous_name
          ? response.data.response[0].previous_name
          : "";
        setBankName(bankname);
        setUserId(userId);
        setTitle(title);
        setfName(fName);
        setlName(lName);
        setaddressLine(addressLine);
        setaddressLine2(addressLine2);
        setaddressLine3(addressLine3);
        setTown(town);
        setKeeperDateYear(keeper_year);
        if (questionData) {
          if (Object.keys(questionData).length < 12) {
            setQuestionFlag(false);
            setAnswers(questions);
          }
        } else {
          setQuestionFlag(false);
          setAnswers(questions);
        }
        setCountry(country);
        setPostcode(postcode);
        setCounty(county);
        setUserSign(usersign);
        setUserSigndate(usersigndate);
        setUserDob(userdob);
        setUserPhone(phone);
        setUserMail(userMail);
        setPrevName(previousName);
        let today = new Date();
        let timeNow =
          String(today.getDate()).padStart(2, "0") +
          "/" +
          String(today.getMonth() + 1).padStart(2, "0") +
          "/" +
          today.getFullYear();
        setCurrentDate(timeNow);
        setAgreementNo(agreementNo);
        setAgreementType(agreementType);
        setAgreementTerm(agreementTerm);
        setCarRegNo(carRegNo);
        setVehicleMake(vehicleMake);
        setVehicleModel(vehicleModel);
      }
      
      if (location.pathname == "/0602UFCO_A1/preview") {
        const previewstats = await saveAgreeTermsIngestion(
          userId,
          customUuid,
          "preview_load",
          1,
          "live"
        );
      }
    })();
    if (formdata) {
      const fName = formdata.txtFName ? Capitalize(formdata.txtFName) : "";
      const lName = formdata.txtLName ? Capitalize(formdata.txtLName) : "";
      const title = formdata.lstSalutation
        ? Capitalize(formdata.lstSalutation)
        : "";
      const addressLine = formdata.txtHouseNumber
        ? formdata.txtHouseNumber
        : "";
      const addressLine2 = formdata.txtAddress2 ? formdata.txtAddress2 : "";
      const addressLine3 = formdata.txtStreet ? formdata.txtStreet : "";
      const town = formdata.txtTown ? formdata.txtTown : "";
      const country = formdata.txtCountry ? formdata.txtCountry : "";
      const postcode = formdata.txtPostCode ? formdata.txtPostCode : "";
      const county = formdata.txtCounty ? formdata.txtCounty : "";
      const usersign = formdata ? formdata.signature_data : "";
      const userdob = formdata
        ? formdata.DobDay + "/" + formdata.DobMonth + "/" + formdata.DobYear
        : "";
      const phone = formdata.txtPhone ? formdata.txtPhone : "";
      const userMail = formdata.txtEmail ? formdata.txtEmail : "";
      const previousName = formdata.txtPreName ? formdata.txtPreName : "";
      let today = new Date();
      let timeNow =
        String(today.getDate()).padStart(2, "0") +
        "/" +
        String(today.getMonth() + 1).padStart(2, "0") +
        "/" +
        today.getFullYear();
      setCurrentDate(timeNow);
      setfName(fName);
      setlName(lName);
      setTitle(title);
      setaddressLine(addressLine);
      setaddressLine2(addressLine2);
      setaddressLine3(addressLine3);
      setTown(town);
      setCountry(country);
      setPostcode(postcode.toUpperCase());
      setCounty(county);
      setUserSign(usersign);
      setUserDob(userdob);
      setUserPhone(phone);
      setUserMail(userMail);
      setPrevName(previousName);
    }
    if (customPreviousData) {
      const prevaddressLine1 = customPreviousData.previous_address_line1
        ? customPreviousData.previous_address_line1
        : "";
      const prevpostcode = customPreviousData.previous_address_postcode
        ? customPreviousData.previous_address_postcode
        : "";
      const prevtown = customPreviousData.previous_address_town
        ? customPreviousData.previous_address_town
        : "";
      const prevcountry = customPreviousData.previous_address_country
        ? customPreviousData.previous_address_country
        : "";
      const prevcounty = customPreviousData.previou_address_county
        ? customPreviousData.previou_address_county
        : "";
      const prevaddressLine3 = customPreviousData.previous_address_line3
        ? customPreviousData.previous_address_line3
        : "";
      setPrevAddressLine1(prevaddressLine1);
      setPrevAddressLine3(prevaddressLine3);
      setPrevPostcode(prevpostcode);
      setPrevTown(prevtown);
      setPrevCountry(prevcountry);
      setPrevCounty(prevcounty);
    }
    if (questionData) {
      if (Object.keys(questionData).length >= 12) {
        setQuestionFlag(true);
        setAnswers(questionData);
      }
    }
    if (vehicleDetails) {
      const question21 = vehicleDetails.your_vehicle
        ? vehicleDetails.your_vehicle
        : "";
      var selectk_keep_date = vehicleDetails.selecte_keeper_date;
      var keeper_date_year = selectk_keep_date.split(" ")[2];
      setKeeperDateYear(keeper_date_year);
      setQuestion21(question21);
    }
  }, []);
  function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return (
    <>
      <Helmet>
        <title>Capital One</title>
      </Helmet>
      
      <div className={`moneybarn_preview confirm-modal mo  ${show}`} ref={signref_5}>
        <div className="container-fluid">
          <section className={`privacy my-3 ${hideDiv3}`} id="myDiv3">
            <div className="px-lg-5 m-3">
              <div className="row m-0 spl_font">
                <div id="sec-1">
                  <div className="col-lg-12 col-12 p-0 contentsection pt-0">
                    <h2 className="text-center title">
                      Service Summary : Capital One
                    </h2>
                    <div className="table-responsive">
                      <table className="table table-bordered  border-secondary mt-3 ">
                        <tbody>
                          <tr>
                            <td>
                              <h5 className="text-center ">Services</h5>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Allegiant Finance Services Limited (the{" "}
                              <b>company</b>) will advise, investigate and
                              represent you in order to investigate (and where
                              relevant pursue) a complaint on your behalf
                              against Capitalone No. 1 Limited Trading as Capitalone
                              (the <b>claims management service</b>). When
                              providing the <b>claims management service</b> the{" "}
                              <b>company</b> will take steps to establish a
                              lending relationship, obtain relevant information
                              to investigate the merits of your claim and, where
                              merit is established, submit a claim to Capitalone
                              on your behalf. The <b>company</b> will keep you
                              updated on the progress of your claim by written
                              correspondence (email / letter / SMS / website
                              portal as appropriate) and/or orally by telephone.
                              We will update you whenever there is a meaningful
                              update, but in any event at least (i) when we
                              submit a claim; and (ii) when Capitalone provides a
                              final response. If the matter is referred to the
                              Financial Ombudsman Service, we will update you:
                              (i) when the claim is acknowledged; and (ii) when
                              there is a meaningful development. We will also
                              update you in line with regulatory requirements
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h5 className="text-center">
                                Client Obligations
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              You must provide the company with all information
                              and/or documentation likely to be needed to pursue
                              your claim(s), complete the necessary paperwork
                              required and promptly communicate all relevant
                              information to the company throughout the claims
                              process. This will include details of your
                              financial circumstances at the time of borrowing
                              and lending history.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h5 className="text-center">Success Fee</h5>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Our fees are owed upon a successful claim and will
                              depend on the amount of redress you receive in
                              cash in hand compensation. This means we charge on
                              what you actually receive, not debt or tax
                              deductions. If income tax is deducted from 8%
                              statutory interest received and sent to HRMC, we
                              do not charge you on this deduction.
                              <p>
                                If successful, your fee will be calculated based
                                on which band your redress falls into and will
                                be charged by whichever is the lowest of:
                              </p>
                              <ol type="a" className="px-5 mb-2">
                                <li>
                                  {" "}
                                  the maximum percentage rate of charge for that
                                  band, or
                                </li>
                                <li>
                                  {" "}
                                  the maximum total charge for that band.
                                </li>
                              </ol>
                              The below table outlines the redress bands, the
                              maximum percentage rate of charge and the maximum
                              total charge is for each band
                              <div className="table-responsive">
                                <table className=" table table-bordered border-secondary  text-center ">
                                  <tbody>
                                    <tr>
                                      <td>Band</td>
                                      <td>Redress</td>
                                      <td>% Charge (with VAT)</td>
                                      <td>Maximum charge (with VAT) (£)</td>
                                      <td>Maximum charge (without VAT) (£)</td>
                                    </tr>
                                    <tr>
                                      <td>1</td>
                                      <td>1 - 1499</td>
                                      <td>36%</td>
                                      <td>504</td>
                                      <td>420</td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>1,500 - 9,999 </td>
                                      <td>33.6%</td>
                                      <td>3000</td>
                                      <td>2500</td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>10,000 - 24,999</td>
                                      <td>30%</td>
                                      <td>6000</td>
                                      <td>5000</td>
                                    </tr>
                                    <tr>
                                      <td>4</td>
                                      <td>25,000 - 49,999</td>
                                      <td>24%</td>
                                      <td>9000</td>
                                      <td>7500</td>
                                    </tr>
                                    <tr>
                                      <td>5</td>
                                      <td>50,000+</td>
                                      <td>18%</td>
                                      <td>12000</td>
                                      <td>10000</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <p className="mb-2">
                                <u>
                                  Examples of low and high redress and fee for
                                  each band in the above table:
                                </u>
                              </p>
                              <ol className="px-4 mb-2">
                                <li>
                                  You receive £100 in redress; our fee would be
                                  £36. You receive £1499 in redress; our fee
                                  would be £504.
                                </li>
                                <li>
                                  You receive £1,600 in redress; our fee would
                                  be £537.60. You receive £9,999 in redress; our
                                  fee would be £3,000.
                                </li>
                                <li>
                                  {" "}
                                  You receive £12,000 in redress; our fee would
                                  be £3,600. You receive £24,999 in redress; our
                                  fee would be £6,000.
                                </li>
                                <li>
                                  You receive £30,350 in redress; our fee would
                                  be £7,284. You receive £49,999 in redress; our
                                  fee would be £9,000.
                                </li>
                                <li>
                                  You receive £55,000 in redress; our fee would
                                  be £9,900. You receive £100,000 in redress;
                                  our fee would be £12,000.
                                </li>
                              </ol>
                              <p className="mb-2">
                                If you owe your lender money (e.g., because you
                                are in arrears or have an active loan), they may
                                use some or all of your compensation to reduce
                                what you owe them. If this happens, our success
                                fee will be calculated on the actual cash in
                                hand compensation that you receive, for example:
                              </p>
                              <ol className="px-4 mb-2">
                                <li>
                                  You recover £1,000 but owe the lender £800 for
                                  an outstanding loan, so receive £200 cash in
                                  hand. Our fee would be 36% inc. VAT of £200
                                  which is £72.
                                </li>
                                <li>
                                  You recover £8,000 but owe the lender £2,000
                                  for an outstanding loan, so receive £6,000
                                  cash in hand. Our fee would be 33.6% inc. VAT
                                  of £6,000 which is £2,016.
                                </li>
                                <li>
                                  You recover £21,000 but owe the lender £9,000
                                  for an outstanding loan, so receive £12,000
                                  cash in hand. Our fee would be 30% inc. VAT of
                                  £12,000 which is £3,600.
                                </li>
                                <li>
                                  You recover £40,000 but owe the lender £10,000
                                  for an outstanding loan, so receive £30,000
                                  cash in hand. Our fee would be 24% inc. VAT of
                                  £30,000 which is £7,200.
                                </li>
                                <li>
                                  {" "}
                                  You recover £70,000 but owe the lender £17,000
                                  for an outstanding loan, so receive £53000
                                  cash in hand. Our fee would be 18% inc. VAT of
                                  £53,000 which is £9,540.
                                </li>
                              </ol>
                              <p>
                                You would pay us the fee once you receive your
                                cash in hand benefit from your lender, and your
                                outstanding loan will also have been paid off at
                                no additional charge.
                              </p>
                              <p>
                                <b>
                                  Please note, the above fee examples are for
                                  illustration purposes only. They are not an
                                  estimate of the likely outcome or fee you will
                                  need to pay. Each claim depends on its own
                                  merits.{" "}
                                </b>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h5 className="text-center">Cancellation</h5>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              You can cancel for free at any time within 14 days
                              without giving any reason and without incurring
                              any liability. Outside 14 days, you can cancel for
                              free but will need to pay a <b>success fee</b> if
                              a settlement proposal has already been made.
                              <ul className="mb-2">
                                <li>
                                  if there has been a settlement proposal but
                                  you have not accepted it prior to
                                  cancellation, the <b>success fee</b> will
                                  apply to the lowest of either (i) the
                                  settlement proposal with the highest
                                  compensation received prior to cancellation;
                                  or (ii) the compensation that you achieve
                                  independently of us.
                                </li>
                                <li>
                                  If you have already accepted a settlement
                                  proposal our <b>success fee</b> will apply in
                                  the usual way.{" "}
                                </li>
                              </ul>
                              <p>
                                The <b>success fee</b> would not be due for
                                payment until you receive cash in hand. You can
                                communicate your cancellation by telephone,
                                post, email or online as follows: By post:
                                Allegiant Finance Services Limited, Freepost
                                RTYU–XUTZ–YKJC, 400 Chadwick House, Warrington
                                Road, Birchwood Park, Warrington, WA3 6AE; (b)
                                by email:{" "}
                                <a href="#">
                                  helpdesk@allegiant-finance.co.uk;
                                </a>{" "}
                                (c) by telephone: 0345 544 1563; or (d) online
                                at
                                <a
                                  target="_blank"
                                  href="https://allegiant.co.uk/compliance/cancellation"
                                >
                                  {" "}
                                  https://allegiant.co.uk/compliance/cancellation.
                                </a>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h5 className="text-center">
                                Alternative Ways You Can Claim (Including Do It
                                Yourself)
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              You do not need to use the company’s
                              <b> claims management service</b> to lodge a
                              complaint against the lender(s) and if your
                              complaint is not successful you can refer it to
                              the Financial Ombudsman Service yourself for free.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className="mb-5">
                      Allegiant Finance Services Limited is a claims management
                      company that is Authorised and Regulated by the Financial
                      Conduct Authority. Firm Reference Number: 836810.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={`privacy my-1 ${showDiv4}`} id="myDiv4">
            <div className="px-lg-5 m-0">
              <div className="row m-0 px-5 spl_font">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 logosec my-2">
                  <div className="text-lg-end text-md-end text-sm-center text-center">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/logo.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div className="leftsec">
                    <p className="text-secondary">
                      <span className="fw-bold mb-1 underline-text">
                        Private &amp; Confidential
                      </span>
                      <br />
                    </p>
                    <p className="mt-2">
                      {title} {fName} {lName}
                      <br />
                      {addressLine ? (
                        <>
                          {addressLine}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {addressLine2 ? (
                        <>
                          {addressLine2}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {addressLine3 ? (
                        <>
                          {addressLine3}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {town ? (
                        <>
                          {town}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {country ? (
                        <>
                          {country}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {postcode ? (
                        <>
                          {postcode}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
                <input
                  type="hidden"
                  name="strThankyouPage"
                  id="strThankyouPage"
                  value="https://dev.allegiant.claims/web/upsell-thankyou?user_id=3659&amp;visitor_id=5806&amp;vu_id=NTgwNl89XzM2NTk"
                />
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-end">
                  <div className="rightsec fw-bold">
                    <p className="mb-0 ">Allegiant Finance Services Ltd</p>
                    <p className="mb-0 ">FREEPOST RTYU-XUTZ–YKJC</p>
                    <p className="mb-0">
                      400 Chadwick House, Warrington Road, Birchwood Park
                    </p>
                    <p>WARRINGTON WA3 6AE</p>
                    <p className="mb-0">Telephone 0345 544 1563</p>
                    <p className="mb-0">
                      Email helpdesk@allegiant-finance.co.uk
                    </p>
                    <p>Website: www.allegiant.co.uk</p>
                    <p className="mb-0 text-secondary">Contract ID: {userId}</p>
                    <p className="mb-0 text-secondary">
                      Pack generated on:{currentDate}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 mainsection">
                  <h2 className="mb-2  title">Your Car Finance Claims Pack</h2>
                  <p className="greentext mb-0">
                    <b>
                      Your claims against: Capitalone Limited Trading as
                      Capitalone
                    </b>
                  </p>
                  <p>
                    Thank you for enquiring about our Unaffordable Lending
                    claims service. We claim against a wide variety of lenders
                    across payday, short term loan, doorstep loan, guarantor
                    loan and car finance products for consumers who feel their
                    loans are unaffordable.
                  </p>
                  <p className="greentext mb-0">
                    <b>What does “unaffordable” mean?</b>
                  </p>
                  <p>
                    Broadly, if repaying your loan has ever caused you undue
                    difficulty getting through the month after the lender has
                    been paid, there is the potential that the loan was
                    mis-sold. Lenders have a duty to lend responsibly and to
                    conduct proportionate affordability checks before lending to
                    you. We mean ‘loan’ as any contractual agreement where you
                    have borrowed money.
                  </p>
                  <p className="greentext mb-0">
                    <b>Why us?</b>
                  </p>
                  <p>
                    We are a fully authorised FCA claims management company
                    specialising in unaffordable lending complaints. We have
                    successfully represented thousands of customers and
                    reclaimed millions on their behalf.
                  </p>
                  <p className="greentext mb-0">
                    <b>What’s enclosed?</b>
                  </p>
                  <p className="mb-0">
                    So that we can get started, you should read, check and
                    ensure you understand the information in this pack. If any
                    of your details are incorrect or you need assistance
                    understanding any aspect of our service, please contact us
                    on 0345 544 1563 or email helpdesk@allegiant-finance.co.uk.
                    Within this pack you will find:
                  </p>
                  <ul>
                    <li>Pre Contract Information</li>
                    <li>Terms of Engagement</li>
                    <li>Financial Ombudsman Complaint form</li>
                    <li>Letter of Authority</li>
                  </ul>
                  <p>
                    So that we can get started, you should read, check and
                    ensure you understand the information in this pack. If any
                    of your details are incorrect or you need assistance
                    understanding any aspect of our service, please contact us
                    on 0345 544 1563 or email helpdesk@allegiant-finance.co.uk.
                  </p>
                  <p className="greentext mb-0">
                    <b>Getting started</b>
                  </p>
                  <p className="mb-0">
                    Once you are ready to claim with us, simply sign where
                    prompted. Once you have signed up with us, we’ll
                    automatically email you to explain the next steps.
                  </p>
                  <p>We look forward to assisting you</p>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5 col-12 bannersec">
                  <p className="mb-0">Yours faithfully</p>
                  <img
                    src="https://dev.allegiant.claims/assets/moneybarn_preview/img/sign.png"
                    alt=""
                  />
                  <p>
                    <b>Allegiant Finance Services Ltd</b>
                  </p>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-7 col-12 rightsec text-end mt-5">
                  <p className="smlfnt">
                    <b>© Allegiant Finance Services Limited</b> <br />
                    A Limited Company Registered in England and Wales: No.
                    07474972 | Registered Office: 400
                    <br />
                    Chadwick House, Birchwood Park, Warrington, Cheshire, WA3
                    6AE <br />
                    A claims management company that is Authorised and |
                    Regulated by the Financial Conduct
                    <br />
                    Authority. Firm Reference Number: 836810. <br />
                    Registered with the Information Commissioner’s Office:
                    Z2569335. <br />
                    All our materials and processes are protected from imitation
                    by law.
                  </p>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                  <h2 className="text-center title">
                    Pre-Contract Information
                  </h2>
                  <p className="greentext mb-0">
                    <b>A. Who we are</b>
                  </p>
                  <p className="mb-0">
                    Allegiant Finance Services Limited is a claims management
                    company registered in England &amp; Wales, registration
                    number 07474972. Our contact details are as follows:
                  </p>
                  <p className="mb-0">
                    <b>Post:</b> Allegiant Finance Services, 400 Chadwick House,
                    Birchwood Park, Warrington, Cheshire, WA3 6AE.
                  </p>
                  <p className="mb-0">
                    <b>Telephone:</b> 0345 544 1563
                  </p>
                  <p className="mb-0">
                    <b>Email:</b>
                    <a href="#"> helpdesk@allegiant-finance.co.uk</a>
                  </p>
                  <p className="mb-3">
                    We are authorised and regulated by the Financial Conduct
                    Authority. Firm Reference Number: 836810
                  </p>
                  <p className="greentext mb-0">
                    <b>B. Service information</b>
                  </p>
                  <p className="mb-0">
                    <u>B.1 Nature of the service</u>
                  </p>
                  <p className="mb-2">
                    We provide a claims assistance service which is designed to
                    claim back interest and loan related charges applied by
                    financial lenders for customers who feel their loans were /
                    are unaffordable. These products typically include payday /
                    short-term loans, guarantor loans, high cost (bad credit)
                    loans, doorstep loans, catalogue credit and car finance (car
                    finance includes hire purchase, personal contract purchase
                    and personal contract hire).
                  </p>
                  <p className="mb-0">
                    <u>B.2 Investigations we will make with you</u>
                  </p>
                  <p className="mb-0">
                    We will take steps to establish your relationship with the
                    lender and to obtain information about your financial
                    circumstances at the time of lending. This will include
                    gathering information about your lending history.
                  </p>
                  <p className="mb-0">
                    We will investigate the merits of your claim by considering
                    the loan(s) affordability. Where we consider a claim has
                    merit, we will submit a claim to the lender on your behalf.
                  </p>
                  <p>
                    Whilst we will manage the claim, we will need input from
                    you. Below is a summary table of the input we will typically
                    need:
                  </p>
                  <div className="table-responsive">
                    <table className="table table-bordered border-secondary">
                      <tbody>
                        <tr>
                          <th>Paperwork you will need to provide</th>
                          <th>Evidence we will need (where you have it)</th>
                        </tr>
                        <tr>
                          <td>
                            <ul>
                              <li>
                                You will need to read, understand and sign our
                                Terms of Engagement.
                              </li>
                              <li>
                                You must read, check and understand the
                                Financial Ombudsman Service complaint form that
                                we fill in on your behalf.
                              </li>
                              <li>
                                Signed settlement form (this is sometimes
                                required before a lender releases money).
                              </li>
                              <li>
                                Any other documents we reasonably need to
                                progress your claim.
                              </li>
                            </ul>
                          </td>
                          <td>
                            <p>
                              We will ask for some or all of the following
                              information if it is available to you:
                            </p>
                            <ul>
                              <li>
                                Loan agreement paperwork (if you do not have
                                this, we can attempt to obtain it for you).
                              </li>
                              <li>
                                Bank statements (if you do not have these, we
                                can attempt to obtain these for you).
                              </li>
                              <li>Credit report.</li>
                              <li>
                                Any other documents we reasonably need to
                                progress your claim.
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="mb-0">
                    <u>B.3 Investigations we will make with your lender</u>
                  </p>
                  <p className="mb-2">
                    During the process, we will ask your lender for details
                    about your lending history with them and for information you
                    provided to them.
                  </p>
                  <p className="mb-0">
                    <u>B.4 Decision making</u>
                  </p>
                  <p className="mb-0">
                    We will assess whether your lender acted in a fair and
                    reasonable manner when lending to you. We will reach a
                    decision based on the laws in force at the time of lending
                    and decisions / guidance from the Financial Conduct
                    Authority and/or Financial Ombudsman Service. If we do not
                    think the lending was fair and reasonable, we will complain
                    on your behalf. We will ask for:
                  </p>
                  <ul className="mb-0">
                    <li>a refund of interest and charges;</li>
                    <li>
                      credit records to be updated to remove the mis-sold
                      loan(s); and
                    </li>
                    <li>
                      interest at 8% per year for payments you have already
                      made.
                    </li>
                  </ul>
                  <p className="mb-0">
                    <u>B.5 Keeping you informed</u>
                  </p>
                  <p>
                    We will keep you updated on the progress of your claim by
                    written correspondence (email / letter / SMS / website
                    portal as appropriate) or orally by telephone. We will
                    update you whenever there is a meaningful update. This will
                    include:
                  </p>
                  <ol className="px-4 mb-2">
                    <li>
                      confirmation that we have submitted your claim to your
                      lender
                    </li>
                    <li>
                      confirmation that the lender has acknowledged your claim
                    </li>
                    <li>
                      when we receive the lender final response (if there is no
                      response by the deadline we will tell you);
                    </li>
                    <li>
                      if the matter is referred to the Financial Ombudsman
                      Service:
                    </li>
                  </ol>
                  <div className="px-5">
                    <p className="mb-0">a. when the referral has been made</p>
                    <p className="mb-0">
                      b. when the Financial Ombudsman Service acknowledges the
                      claim
                    </p>
                    <p className="mb-0">
                      c. whenever there is a meaningful update
                    </p>
                    <p className="mb-3">
                      d. If there hasn’t been a meaningful update in 3 months,
                      we will still let you know what’s happening.
                    </p>
                  </div>
                  <p className="mb-0">
                    <u>B.6 Who will perform the work</u>
                  </p>
                  <p className="mb-2">
                    Our directly employed, trained staff at our office in
                    Warrington, Cheshire.
                  </p>
                  <p className="mb-0">
                    <u>B.7 The claims process</u>
                  </p>
                  <p className="mb-0">
                    We pursue all claims via the Financial Ombudsman Service. We
                    will never ask you to pursue legal action via the courts or
                    to give evidence in person. Summary of our claims procedure
                  </p>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-2">
                  <img
                    src="https://dev.allegiant.claims/assets/moneybarn_preview/img/phase1.png"
                    className="phase"
                    alt=""
                  />
                </div>
                <div className="col-xl-11 col-lg-11 col-md-11 col-10">
                  <p>
                    <b>Phase 1:</b> You will be asked to read and confirm that
                    you understand our Pre-Contract Information, Service
                    Summary, Privacy Policy and Terms of Engagement. If you
                    decide to proceed, you will need to return your signed Terms
                    of Engagement and to upload supporting documentation to our
                    online portal. We will generate a Complaint Bundle which
                    will form a part of the submitted complaint, together with a
                    suitably tailored Letter of Complaint. Depending on our
                    assessment of the claim, we may also ask you to send us
                    supporting documentation. At any stage, we may request
                    further information from your lender about your
                    relationship, as we consider appropriate.
                  </p>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-2">
                  <img
                    src="https://dev.allegiant.claims/assets/moneybarn_preview/img/phase2.png"
                    className="phase"
                    alt=""
                  />
                </div>
                <div className="col-xl-11 col-lg-11 col-md-11 col-10">
                  <p>
                    <b>Phase 2:</b> We will assess the information you have
                    given and consider whether your claim is one that (i) meets
                    our criteria (ii) is one we consider to have merit. We will
                    assess relevant information and make a written complaint to
                    your lender, where appropriate. If we do not consider the
                    claim has merit, we will close the claim and advise you that
                    no complaint has been made.
                  </p>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-2">
                  <img
                    src="https://dev.allegiant.claims/assets/moneybarn_preview/img/phase3.png"
                    className="phase"
                    alt=""
                  />
                </div>
                <div className="col-xl-11 col-lg-11 col-md-11 col-10">
                  <p>
                    <b>Phase 3:</b> We will consider the response from your
                    lender and take appropriate action. This may include seeking
                    instructions from you about a settlement offer, or
                    discussion over whether the claim should be continued or
                    closed.
                  </p>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-2">
                  <img
                    src="https://dev.allegiant.claims/assets/moneybarn_preview/img/phase4.png"
                    className="phase"
                    alt=""
                  />
                </div>
                <div className="col-xl-11 col-lg-11 col-md-11 col-10">
                  <p>
                    <b>Phase 4:</b> If your claim does not settle at Phase 3, we
                    will refer the claim to the Financial Ombudsman Service if
                    we consider there is appropriate merit. The Financial
                    Ombudsman Service will appoint an adjudicator to make a
                    settlement recommendation. If this is not acceptable to
                    either party, the claim can be escalated to an Ombudsman for
                    a legally binding decision. We will advise you on whether we
                    perceive there to be merit in escalating an adjudicator
                    decision.
                  </p>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  <p className="mb-0">
                    <u>B.8 Prospects of Success</u>
                  </p>
                  <p className="mb-2">
                    Agreement by us to pursue a claim for you does not guarantee
                    success. You may win or lose. Your chances of compensation
                    will depend upon the actual merit of your claim and level of
                    co-operation you provide. We do not claim to increase your
                    chances of obtaining compensation.
                  </p>
                  <p className="mb-0">
                    <u>B.9 Timescales</u>
                  </p>
                  <p className="mb-3">
                    It is very difficult for us to place a timescale on the
                    claims process. Much will depend upon whether your lender
                    agrees to your claim, or whether they defend it. If your
                    lender agrees to your claim following the submission of our
                    Letter of Complaint, we would expect for a compensation
                    offer to be received within approximately 8 to 12 weeks.
                    Payment of compensation can usually take a further 4 weeks.
                    If the claim needs to be referred to the Financial Ombudsman
                    Service, this may add anywhere between 3 to 24 months to the
                    claim (depending on the Financial Ombudsman Services’
                    capacity and what arguments the lender has made in defence).
                    In cases where there is severe and immediate hardship, the
                    Ombudsman may be able to fast track your claim. If this
                    applies to you, please tell us. Please note that we can
                    never guarantee timescales to resolution as much of the
                    process depends on third party co-operation. Using a claims
                    management company such as us does not mean your claim will
                    be any quicker than if you complain to your lender yourself.
                    We will however for our part use our best efforts to advance
                    your claim with reasonable promptness.
                  </p>
                  <p className="greentext mb-0">
                    <b>C. Considerations for you</b>
                  </p>
                  <p className="mb-2">
                    Before deciding to use our service, you should consider the
                    cost and risk of making a claim against your lender. Below
                    we have summarised key information you may wish to consider
                    as part of your decision making process:
                  </p>
                  <p className="mb-0">
                    <u>C.1 Risk &amp; Benefit</u>
                  </p>
                  <table className="table table-bordered border-secondary table-responsive">
                    <tbody>
                      <tr>
                        <th>Benefits of making a claim</th>
                        <th>Risks of making a claim</th>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <ul>
                            <li>
                              You may recover cash in hand compensation from
                              your lender.
                            </li>
                            <li>
                              You may reduce or extinguish outstanding
                              liabilities owed to your lender.
                            </li>
                            <li>
                              You may feel vindication where successful against
                              your lender.
                            </li>
                            <li>
                              The loan(s) may be removed from your credit
                              record, removing a potentially negative mark
                              against your credit score.
                            </li>
                          </ul>
                        </td>
                        <td style={{ width: "50%" }}>
                          <ul>
                            <li>
                              Your claim may not be successful resulting in lost
                              time pursuing the claim (see section B.2 in
                              relation to the input we will typically require)
                            </li>
                            <li>
                              You may feel uncomfortable sharing or discussing
                              financial information from a difficult period in
                              your life (however, our employees are familiar
                              with unaffordable lending issues – you’re in good
                              hands).
                            </li>
                            <li>
                              Your lender may refuse to lend to you in the
                              future.
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="mb-0">
                    We would always advise you to continue to make any
                    contractual payments to your lender, as we cannot guarantee
                    a successful outcome, and failure to pay could have a
                    negative impact on your credit file. In relation to car
                    finance repayments specifically, failure to pay could result
                    in the repossession of the car
                  </p>
                  <p className="mb-2">
                    If you are struggling to make repayments, we would advise
                    contacting your lender directly to discuss, or you can seek
                    independent financial advice
                  </p>
                  <p className="mb-0">
                    <u>C.2 Cost</u>
                  </p>
                  <p className="mb-0">
                    Our fees are owed upon a successful claim and will depend on
                    the amount of redress you receive in cash in hand
                    compensation. This means we charge on what you actually
                    receive, not debt or tax deductions. If income tax is
                    deducted from 8% statutory interest received and sent to
                    HRMC, we do not charge you on this deduction.
                  </p>
                  <p className="mb-2">
                    If successful, your fee will be calculated based on which
                    band your redress falls into and will be charged by
                    whichever is the lowest of:
                  </p>
                  <ol type="a" className="px-5 mb-2">
                    <li>
                      {" "}
                      the maximum percentage rate of charge for that band, or
                    </li>
                    <li> the maximum total charge for that band.</li>
                  </ol>
                  <p>
                    The below table outlines the redress bands, the maximum
                    percentage rate of charge and the maximum total charge is
                    for each band
                  </p>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered border-secondary text-center">
                    <tbody>
                      <tr>
                        <td style={{ width: "20%" }}>
                          <b>Band</b>
                        </td>
                        <td style={{ width: "20%" }}>
                          <b>Redress</b>
                        </td>
                        <td style={{ width: "20%" }}>
                          <b>% Charge (with VAT)</b>
                        </td>
                        <td style={{ width: "20%" }}>
                          <b>Maximum charge (with VAT) (£) </b>
                        </td>
                        <td style={{ width: "20%" }}>
                          <b>Maximum charge (without VAT) (£)</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">1</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">1 – 1499</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">36%</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">504</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">420</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">2</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">1,500 – 9,999</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">33.60%</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">3000</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">2500</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">3</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">10,000 – 24,999</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">30%</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">6000</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">5000</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">4</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">25,000 – 49,999</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">24%</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">9000</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0"> 7500</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">5</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">50,000+</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">18%</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">12000</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">10000</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="mb-10">
                  <u>
                    <b>Examples of how this would work in practice:</b>
                  </u>
                </p>

                <div className="table-responsive">
                  <table className="table table-bordered border-secondary text-center">
                    <tbody>
                      <tr>
                        <td style={{ width: "10%" }}>
                          <b>Band</b>
                        </td>
                        <td style={{ width: "20%" }}>
                          <b>Lower example</b>
                        </td>
                        <td style={{ width: "20%" }}>
                          <b>Higher example</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>
                          <p className="mb-0">1</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">
                            You receive <b>£100</b> in redress; our fee would be{" "}
                            <b>£36</b>
                          </p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">
                            You receive <b>£1499</b> in redress; our fee would
                            be <b>£504</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>
                          <p className="mb-0">2</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">
                            You receive <b>£1,600</b> in redress; our fee would
                            be <b>£537.60</b>
                          </p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">
                            You receive <b>£9,999</b> in redress; our fee would
                            be <b>£3,000</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>
                          <p className="mb-0">3</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">
                            You receive <b>£12,000</b> in redress; our fee would
                            be <b>£3,600</b>
                          </p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">
                            You receive <b>£24,999</b> in redress; our fee would
                            be <b>£6,000</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>
                          <p className="mb-0">4</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">
                            You receive <b>£30,350</b> in redress; our fee would
                            be <b>£7,284</b>
                          </p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">
                            You receive <b>£49,999</b> in redress; our fee would
                            be <b>£9,000</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>
                          <p className="mb-0">5</p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">
                            You receive <b>£55,000</b> in redress; our fee would
                            be <b>£9,900</b>
                          </p>
                        </td>
                        <td style={{ width: "20%" }}>
                          <p className="mb-0">
                            You receive <b>£100,000</b> in redress; our fee
                            would be <b>£12,000</b>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <p className="mb-2">
                    If you owe your lender money (e.g., because you are in
                    arrears or have an active loan), they may use some or all of
                    your compensation to reduce what you owe them. If this
                    happens, our success fee will be calculated on the actual
                    cash in hand compensation that you receive, for example:
                  </p>
                  <ol className="px-4 mb-2">
                    <li>
                      You recover £1,000 but owe the lender £800 for an
                      outstanding loan, so receive £200 cash in hand. Our fee
                      would be 36% inc. VAT of £200 which is £72.
                    </li>
                    <li>
                      You recover £8,000 but owe the lender £2,000 for an
                      outstanding loan, so receive £6,000 cash in hand. Our fee
                      would be 33.6% inc. VAT of £6,000 which is £2,016.
                    </li>
                    <li>
                      You recover £21,000 but owe the lender £9,000 for an
                      outstanding loan, so receive £12,000 cash in hand. Our fee
                      would be 30% inc. VAT of £12,000 which is £3,600.
                    </li>
                    <li>
                      You recover £40,000 but owe the lender £10,000 for an
                      outstanding loan, so receive £30,000 cash in hand. Our fee
                      would be 24% inc. VAT of £30,000 which is £7,200.
                    </li>
                    <li>
                      {" "}
                      You recover £70,000 but owe the lender £17,000 for an
                      outstanding loan, so receive £53000 cash in hand. Our fee
                      would be 18% inc. VAT of £53,000 which is £9,540.
                    </li>
                  </ol>
                  <p className="mb-0">
                    You would pay us the fee once you receive your cash in hand
                    benefit from your lender, and your outstanding loan will
                    also have been paid off at no additional charge
                  </p>
                  <p className="mb-2">
                    <b>
                      Please note, the above fee examples are for illustration
                      purposes only. They are not an estimate of the likely
                      outcome or fee you will need to pay. Each claim depends on
                      its own merits.
                    </b>
                  </p>
                  <p className="mb-0">
                    <u>C.3 Pursuing the Claim Yourself</u>
                  </p>
                  <p className="mb-2">
                    Under our regulatory rules, we must remind you that you do
                    not need to use a claims management company to lodge a
                    complaint against your lender and that if your complaint is
                    not successful you can refer it to the Financial Ombudsman
                    Service for free. Please note the Financial Services
                    Compensation Scheme does not cover loan lenders at the time
                    of writing.
                  </p>
                  <p className="mb-0">
                    <u>C.4 Right to shop around</u>
                  </p>
                  <p className="mb-3">
                    If you do want to use a claims management company, you have
                    a right to shop around before deciding on which claims
                    management company to use. We would naturally be delighted
                    if you were to use us. Alternatively, you could engage a
                    solicitor who if they cannot reach amicable settlement may
                    refer the claim to a court (legal action) or like us, refer
                    the claim to the Ombudsman (the ombudsman route). If you
                    wish to instruct a solicitor, you should check whether an
                    insurance policy (for instance a household insurance policy
                    or packaged bank account) provides cover for solicitor
                    costs. Some lenders sign up to other methods of settling
                    disputes (ADR). The Financial Ombudsman Service is one such
                    scheme, but not always the only one. You can present a claim
                    to an ADR scheme yourself. Please see the Service Summary
                    for details of any ADR scheme your lender is a member of.
                    The courts and Financial Ombudsman Service have the power to
                    order that your lender compensates you (subject to the
                    lender meeting appropriate eligibility criteria). Other ADR
                    schemes usually possess this power as well (although you
                    should check this if pursuing the claim yourself).
                  </p>
                  <p className="greentext mb-0">
                    <b>D. Your Rights</b>
                  </p>
                  <p className="mb-0">
                    <u>D.1 Cancellation</u>
                  </p>
                  <p className="mb-0">
                    You can cancel the claims management service without charge
                    within the 14 day cooling off period. There is no reason to
                    give us a reason for cancelling.
                  </p>
                  <p className="mb-2">
                    After the expiry of the 14 day cooling off period, you may
                    ask us to cancel the claims management service. Whilst there
                    is no cancellation fee, a success fee may still apply
                    depending on whether you have received a settlement proposal
                    or not: -
                  </p>
                  <ul className="mb-0">
                    <li>
                      If there has been no settlement proposal(s) prior to
                      cancellation, no success fee will be due; or
                    </li>
                    <li>
                      If there has been settlement proposal(s) but no acceptance
                      prior to cancellation, the success fee will apply to the
                      lowest of: (a) the settlement proposal with the highest
                      compensation received prior to cancellation; or (b) the
                      compensation that you achieve independently of us; or
                    </li>
                    <li>
                      If you have accepted a settlement proposal and cancel
                      after acceptance, the success fee will be due in the
                      normal way.
                    </li>
                  </ul>
                  <p className="mb-2">
                    Where a success fee is payable, it is not due for payment
                    until you receive cash in hand from your lender.
                  </p>
                  <p className="mb-0">
                    <u>D.2 Complaints.</u>
                  </p>
                  <p className="mb-3">
                    We hope you will be delighted with our service. However, if
                    you do have cause to complain, you can do so by post, email
                    or telephone. We will acknowledge your complaint within 5
                    working days and provide you with a copy of the process we
                    will follow. Within 8 weeks we will issue a final response
                    letter or write to you to advise that we are not in a
                    position to do so. In either case, you will be advised of
                    your right to escalate the matter to the Financial Ombudsman
                    Service. Please see the complaints section of your Terms of
                    Engagement or
                    https://allegiant.co.uk/compliance/complaints-regulated/ for
                    full details.
                  </p>
                  <p className="greentext mb-0">
                    <b>E. Your Data</b>
                  </p>
                  <p>
                    A copy of our Privacy Policy is available at
                    https://allegiant.co.uk/privacy-policy or in writing or
                    other applicable mediums upon request.
                  </p>

                  <div className="col-xl-8 col-lg-8 col-md-8 offset-xl-2 offset-lg-2 offset-md-2 col-12">
                    <h2 className="text-center title">Terms of Engagement</h2>
                    <table
                      className="table table-bordered border-secondary table-responsive"
                      style={{ maxWidth: "600px", margin: "10px auto" }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ width: "30%" }}>
                            <b>Issue date</b>
                          </td>
                          <td style={{ width: "70%" }}>{currentDate}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%" }}>
                            <b>Contract ID:</b>
                          </td>
                          <td style={{ width: "70%" }}>{userId}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%" }}>
                            <b>Claims against</b>
                          </td>
                          <td style={{ width: "70%" }}>
                            <p>Capitalone Limited Trading as Capitalone</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="mb-3">
                    By signing <b>our terms of engagement</b>, <b>you</b> will
                    be entering into a legally binding contract. If <b>you</b>{" "}
                    change <b>your</b> mind, <b>you</b> have a{" "}
                    <b>14 day cooling off period</b> during which <b>you</b> can
                    cancel without charge. In entering into this contract,{" "}
                    <b>you</b> confirm that <b>you</b> have read <b>our</b>{" "}
                    Pre-Contractual Information. <b>You</b> also confirm that{" "}
                    <b>you</b> understand that <b>we</b> offer a professional
                    paid-for <b>claims management service</b> but that{" "}
                    <b>you</b> do not need to use a claims management company to
                    make <b>your</b> complaint to the <b>respondent</b>, and
                    that if <b>your</b> complaint is not successful that{" "}
                    <b>you</b> can refer it to the Financial Ombudsman Service
                    for free (see https://financial-ombudsman.org).
                  </p>
                  <p className="greentext mb-0">
                    <b>1. Definitions</b>
                  </p>
                  <p className="mb-2">
                    <b>14 day cooling off period</b> <b> means</b> the first to
                    the fourteenth day inclusive after <b>we</b> receive{" "}
                    <b>your</b> signed <b>terms of engagement</b>.
                    <b>cash in hand compensation</b> means the actual cash in
                    hand <b>compensation</b> that <b>you</b> receive from the{" "}
                    <b>respondent</b> after debt and tax deductions have been
                    applied by the <b>respondent</b>.
                  </p>
                  <p className="mb-2">
                    <b>Claim</b> means the pursuit of a complaint against the{" "}
                    <b>respondent</b> to bring about <b>compensation</b> arising
                    out of the actual or potential mis-selling of a{" "}
                    <b>high cost financial product</b>.
                  </p>
                  <p className="mb-2">
                    <b>Claims management service</b> means any and all of the
                    actions of the <b>company</b> in representing <b>you</b> to
                    make a <b>claim</b> during which the <b>company</b> will
                    undertake reasonable steps as may be necessary to progress
                    the <b>claim</b> from time to time. This may include (but is
                    not necessarily limited to) providing advice in writing or
                    orally, investigating the facts and merit of a <b>claim</b>,
                    presenting a <b>claim</b>, negotiating and relaying
                    settlement information about a <b>claim</b>.
                  </p>
                  <p className="mb-2">
                    <b>Compensation</b> means each and every financial benefit
                    to you from the respondent that is awarded or accrues as a
                    result of the performance of the{" "}
                    <b>claims management service</b> (we charge based on{" "}
                    <b>net compensation</b> only – please see below)
                    <b>company/us/we/our</b> means Allegiant Finance Services
                    Limited whose present address is 400 Chadwick House,
                    Warrington Road, Birchwood Park, Warrington, WA3 6AE, a
                    claims management company that is authorised and regulated
                    by the Financial Conduct Authority and whose Firm Reference
                    Number is 836810. Check https://register.fca.org.uk/ to
                    verify up to date details.
                  </p>
                  <p className="mb-2">
                    <b>Financial product</b> means each and every agreement you
                    have had with the <b>respondent</b> (whether past, present
                    or those taken out during the{" "}
                    <b>claims management service)</b> and relating to the{" "}
                    <b>product type</b> that you have instructed <b>us</b> to
                    pursue.
                  </p>
                  <p className="mb-2">
                    <b>Product type</b> is a defined category of{" "}
                    <b>high cost financial products</b> such as (a) payday loan
                    claims (b) doorstep loan claims (c) short term high cost
                    credit claims (d) guarantor loan claims (e) high cost (bad
                    credit) loans. (f) catalogue credit (g) car finance
                    (including loan, hire purchase, personal contract purchase,
                    personal contract hire).
                  </p>
                  <p className="mb-2">
                    <b>Respondent</b> means the Lender set out in the claim
                    Letter of Authority, its predecessors, successors,
                    assignees, servants and/or agents inclusive of any
                    insolvency practitioners appointed from time to time.
                  </p>
                  <p className="mb-2">
                    <b>Settlement proposal</b> means an offer from the{" "}
                    <b>respondent</b>, or a recommendation, adjudication or
                    decision made by the Financial Ombudsman Service or another
                    third-party organisation with binding authority to award{" "}
                    <b>compensation,</b> whether or not the <b>compensation</b>{" "}
                    due has yet been calculated or the offer yet communicated.
                  </p>
                  <p>
                    <b>Success fee</b> means the sum payable to us as regulated
                    by Section 4 of the <b>terms of engagement.</b>
                  </p>
                  <p className="mb-2">
                    <b>Terms of engagement</b> means the terms and conditions
                    set out within this document.
                  </p>
                  <p className="mb-3">
                    <b>you/your</b> means the person signing the{" "}
                    <b>terms of engagement,</b> or where acting as a power of
                    attorney, the person on whose behalf the claim is made.
                  </p>
                  <p className="mb-0 greentext">
                    <b>2. What we will do</b>
                  </p>
                  <p className="mb-2">
                    2.1 In performing <b>our</b> duties, <b>we</b> will exercise
                    the reasonable skill and care expected of a regulated claims
                    management company and maintain all relevant regulatory
                    permissions to enable <b>us</b> to perform <b>our</b> role
                    lawfully.
                  </p>
                  <p className="mb-2">
                    2.2 Once <b>we</b> are in receipt of appropriate information
                    and documentation from <b>you, we</b> will submit a{" "}
                    <b>claim</b> to the respondent. Should the <b>respondent</b>{" "}
                    not respond within appropriate timeframes or disagree with
                    the <b>claim, we</b> will reconsider the <b>claim</b> merits
                    in all of the circumstances and, where reasonable, escalate
                    the <b>claim</b> to the Financial Ombudsman Service or
                    another appropriate third party alternative dispute
                    resolution organisation with binding authority to resolve
                    the <b>claim</b> on <b>your</b> behalf.
                  </p>
                  <p className="mb-2">
                    2.3 <b>We</b> will communicate with <b>you</b> by email,
                    post, SMS, via <b>our</b> website portal and/or orally
                    (including by telephone).
                  </p>
                  <p className="mb-2">
                    2.4 <b>We</b> will update <b>you</b> when there is a
                    material <b>claim</b> update. This includes where the
                    respondent acknowledges the claim, requests further
                    information and/or makes a final decision. If{" "}
                    <b>your claim</b> is referred to the Financial Ombudsman
                    Service or another third party organisation with binding
                    authority, <b>we</b> will update <b>you</b> when the{" "}
                    <b>claim</b> is acknowledged and whenever there is a
                    subsequent material development. In any event, <b>we</b>{" "}
                    will update <b>you</b> in line with <b>our</b> governing
                    regulatory requirements as in force from time to time.
                  </p>
                  <p className="mb-2">
                    2.5 <b>We</b> agree to pay a <b>respondent</b> and any
                    relevant third parties any such reasonable fees as may be
                    necessary for <b>us</b>to access information required to
                    pursue a <b>claim.</b>
                  </p>
                  <p className="mb-2">
                    2.6 <b>We</b> will take instructions from <b>you</b> where
                    they are necessary and always in relation to{" "}
                    <b>settlement proposals</b> once <b>compensation</b> is
                    calculated.
                  </p>
                  <p className="mb-2">
                    2.7 <b>We</b> will reasonably assume that account
                    information and calculations provided by the{" "}
                    <b>respondent</b> are the best primary evidence of lending,
                    are accurate, not misleading and/or not provided in bad
                    faith unless you provide evidence to cast reasonable doubt
                    on accuracy of this information.
                  </p>
                  <p className="mb-2">
                    2.8 <b>We</b> will not provide financial or debt advice as{" "}
                    <b>we</b> are solely authorised and regulated to provide
                    claims management services
                  </p>
                  <p className="mb-0 greentext">
                    <b>3. Your commitments to us</b>
                  </p>
                  <p className="mb-2">
                    3.1 <b>You</b> confirm that <b>you</b>have not pursued the{" "}
                    <b>claim</b> previously, or where <b>you</b> have, that this
                    has been brought to <b>our</b> attention and that all
                    relevant correspondence and documentation will be passed to{" "}
                    <b>us</b> promptly.
                  </p>
                  <p className="mb-2">
                    3.2 <b>You</b> agree that <b>we</b> are appointed as{" "}
                    <b>your</b> exclusive representative for the purpose of
                    delivering the <b>claims management service,</b> meaning
                    that at no time during the <b>terms of engagement</b> will{" "}
                    <b>you</b> (i) have an ongoing contract with another
                    representative relating to the <b>claim;</b> or (ii) attempt
                    to pursue the <b>claim</b> yourself without cancellation
                    (see Section 5).
                  </p>
                  <p className="mb-2">
                    3.3 <b>You</b> agree not to contact the <b>respondent,</b>{" "}
                    the Financial Ombudsman Service or other relevant third
                    party organisation to withdraw <b>our</b> authority to
                    provide the <b>claims management service</b> without first
                    cancelling with <b>us.</b>
                  </p>
                  <p className="mb-2">
                    3.4 <b>You</b> confirm that <b>you</b> are not, or have
                    never been, declared bankrupt, subject to a bankruptcy
                    petition, subject to an IVA, have proposed an IVA yet to be
                    approved or rejected by creditors, subject to a DRO,
                    sequestration or any similar process or arrangement.{" "}
                    <b>You</b> agree to inform <b>us</b> immediately should
                    there become a reasonable likelihood that <b>you</b> may
                    formally enter into any such arrangement.
                  </p>
                  <p className="mb-2">
                    3.5 <b>You</b> agree to provide <b>us</b> with prompt
                    information about <b>your</b> personal details and financial
                    situation as <b>we</b> may reasonably require in order to
                    provide the <b>claims management service</b> and to
                    immediately inform <b>us</b> of any change of information,
                    such as a change of address, during the course of the{" "}
                    <b>claims management service</b>.
                  </p>
                  <p className="mb-2">
                    3.6 <b>You</b> agree to complete and sign all paperwork
                    necessary to provide <b>us</b> with authority to pursue the{" "}
                    <b>claims management service.</b>
                  </p>
                  <p className="mb-2">
                    3.7 <b>You</b> agree to provide <b>us</b> with the
                    documentary evidence reasonably required to investigate
                    and/or pursue the <b>claim</b> including (but not limited
                    to) bank statements, credit reports, relevant{" "}
                    <b>respondent</b> related documentation and correspondence.
                  </p>
                  <p className="mb-2">
                    3.8 <b>You</b> agree to inform <b>us</b> immediately should
                    a <b>respondent</b> contact <b>you</b> directly to discuss a{" "}
                    <b>claim</b> or make a payment to <b>you</b> directly.
                  </p>
                  <p className="mb-2">
                    3.9 In the circumstances where a <b>respondent</b> pays you
                    directly, <b>you</b> agree to pay the <b>success fee</b>{" "}
                    (see further Section 4).
                  </p>
                  <p className="mb-2">
                    3.10 <b>You</b> acknowledge that the making of a{" "}
                    <b>claim</b> may lead to the respondent withdrawing{" "}
                    <b>your</b> access to their financial products. <b>You</b>{" "}
                    agree to make <b>your</b> own back up arrangements for this
                    eventuality.
                  </p>
                  <p className="greentext mb-0">
                    <b>4. The success fee</b>
                  </p>
                  <p className="mb-2">
                    4.1 Our fees are owed upon a successful claim and will
                    depend on the amount of redress you receive in cash in hand
                    compensation. This means we charge on what you actually
                    receive, not debt or tax deductions. If income tax is
                    deducted from 8% statutory interest received and sent to
                    HRMC, we do not charge you on this deduction. If successful,
                    your fee will be calculated based on which band your redress
                    falls into and will be charged by whichever is the lowest
                    of:
                  </p>
                  <ol type="a" className="px-5 mb-2">
                    <li>
                      {" "}
                      the maximum percentage rate of charge for that band, or
                    </li>
                    <li> the maximum total charge for that band.</li>
                  </ol>
                  <p>
                    The below table outlines the redress bands and what the
                    maximum percentage rate of charge and the maximum total
                    charge is for each band.
                  </p>
                  <div className="table-responsive">
                    <table className="table table-bordered border-secondary ">
                      <tbody>
                        <tr>
                          <td style={{ width: "20%" }}>
                            <b>Band</b>
                          </td>
                          <td style={{ width: "20%" }}>
                            <b>Redress</b>
                          </td>
                          <td style={{ width: "20%" }}>
                            <b>% Charge (with VAT)</b>
                          </td>
                          <td style={{ width: "20%" }}>
                            <b>Maximum charge (with VAT) (£) </b>
                          </td>
                          <td style={{ width: "20%" }}>
                            <b>Maximum charge (without VAT) (£)</b>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">1</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">1 – 1499</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">36%</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">504</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">420</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">2</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">1,500 – 9,999</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">33.60%</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">3000</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">2500</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">3</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">10,000 – 24,999</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">30%</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">6000</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">5000</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">4</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">25,000 – 49,999</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">24%</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">9000</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0"> 7500</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">5</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">50,000+</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">18%</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">12000</p>
                          </td>
                          <td style={{ width: "20%" }}>
                            <p className="mb-0">10000</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="mb-2">
                    <u>
                      Examples of low and high redress and fee for each band in
                      the above table:
                    </u>
                  </p>
                  <ol className="px-4 mb-2">
                    <li>
                      You receive £100 in redress; our fee would be £36. You
                      receive £1499 in redress; our fee would be £504.
                    </li>
                    <li>
                      You receive £1,600 in redress; our fee would be £537.60.
                      You receive £9,999 in redress; our fee would be £3,000.
                    </li>
                    <li>
                      {" "}
                      You receive £12,000 in redress; our fee would be £3,600.
                      You receive £24,999 in redress; our fee would be £6,000.
                    </li>
                    <li>
                      You receive £30,350 in redress; our fee would be £7,284.
                      You receive £49,999 in redress; our fee would be £9,000.
                    </li>
                    <li>
                      You receive £55,000 in redress; our fee would be £9,900.
                      You receive £100,000 in redress; our fee would be £12,000.
                    </li>
                  </ol>
                  <p className="mb-2">
                    If you owe your lender money (e.g., because you are in
                    arrears or have an active loan), they may use some or all of
                    your compensation to reduce what you owe them. If this
                    happens, our success fee will be calculated on the actual
                    cash in hand compensation that you receive, for example:
                  </p>
                  <ol className="px-4 mb-2">
                    <li>
                      You recover £1,000 but owe the lender £800 for an
                      outstanding loan, so receive £200 cash in hand. Our fee
                      would be 36% inc. VAT of £200 which is £72.
                    </li>
                    <li>
                      You recover £8,000 but owe the lender £2,000 for an
                      outstanding loan, so receive £6,000 cash in hand. Our fee
                      would be 33.6% inc. VAT of £6,000 which is £2,016.
                    </li>
                    <li>
                      You recover £21,000 but owe the lender £9,000 for an
                      outstanding loan, so receive £12,000 cash in hand. Our fee
                      would be 30% inc. VAT of £12,000 which is £3,600.
                    </li>
                    <li>
                      You recover £40,000 but owe the lender £10,000 for an
                      outstanding loan, so receive £30,000 cash in hand. Our fee
                      would be 24% inc. VAT of £30,000 which is £7,200.
                    </li>
                    <li>
                      {" "}
                      You recover £70,000 but owe the lender £17,000 for an
                      outstanding loan, so receive £53000 cash in hand. Our fee
                      would be 18% inc. VAT of £53,000 which is £9,540.
                    </li>
                  </ol>
                  <p className="mb-0">
                    You would pay us the fee once you receive your cash in hand
                    benefit from your lender, and your outstanding loan will
                    also have been paid off at no additional charge
                  </p>
                  <p className="mb-2">
                    <b>
                      Please note, the above fee examples are for illustration
                      purposes only. They are not an estimate of the likely
                      outcome or fee you will need to pay. Each claim depends on
                      its own merits.
                    </b>
                  </p>
                  <p className="mb-2">
                    4.2 <b>You</b> agree to pay us the <b>success fee</b>{" "}
                    without undue delay upon receipt of funds paid to <b>you</b>{" "}
                    by the respondent. <b>You</b> accept that payment of the{" "}
                    <b>success fee</b> is not contingent on the completion of
                    any further action, such as a <b>respondent</b> making a
                    correction to a credit record. Where further settlement
                    actions remain outstanding that form part of <b>our</b>{" "}
                    claims management service obligations, <b>we</b> will
                    continue to pursue these on <b>your</b> behalf and{" "}
                    <b>our</b> contractual commitments to <b>you</b> will be
                    unaffected by the payment <b>you</b> have made.
                  </p>
                  <p className="mb-2">
                    4.3 Where <b>cash in hand compensation</b> is paid in
                    separate parts or part paid (for whatever reason), <b>we</b>{" "}
                    reserve the right to raise interim or modified invoices
                    relating to the amount received by <b>you.</b>
                  </p>
                  <p className="mb-2">
                    4.4 Where the <b>respondent</b> pays compensation to{" "}
                    <b>us</b>, <b>we</b> shall hold the funds in a dedicated
                    client account (as authorised by the FCA).<b>We</b> shall
                    provide <b>you</b> with an itemised invoice and ask{" "}
                    <b>you</b> to authorise the payment of compensation to{" "}
                    <b>you</b> (less<b> our success fee</b>).
                  </p>
                  <p className="mb-2">
                    4.5 <b>We</b> shall remit compensation received by <b>us</b>
                    , less <b>our success fee</b>, via bank transfer without
                    undue delay. Payments will be made by electronic transfer to
                    an account <b>you</b> nominate. Where <b>you</b> request a
                    cheque, <b>we</b> shall only send this to an address
                    registered with <b>the respondent</b> or <b>your</b>{" "}
                    electoral address. <b>We</b> reserve the right to ask for
                    proof of address and/or reasonable documentation to comply
                    with anti-money laundering regulations and <b>our</b> fraud
                    prevention obligations.
                  </p>
                  <p className="mb-2">
                    4.6 Where <b>we</b> receive compensation on <b>your</b>{" "}
                    behalf from the <b>respondent</b>, but <b>you</b> fail to
                    cash a cheque or provide valid bank details, <b>you</b>{" "}
                    agree that, where <b>we</b> have made reasonable endeavours
                    to contact <b>you</b> without success, <b>we</b> shall
                    proceed to distribute the funds as appropriate. The
                    distribution of funds will be arranged at <b>our</b>{" "}
                    discretion, based on individual circumstances and the status
                    of the <b>respondent</b>. <b>We</b> will either; return the
                    funds to the <b>respondent</b> after 12 months or pay the
                    balance to a charity after 6 years and that, upon <b>us</b>{" "}
                    making such a payment, <b>our</b> liability to pay{" "}
                    <b>you</b> the balance shall be wholly discharged.
                  </p>
                  <p className="greentext mb-0">
                    <b>5. Cancellation rights</b>
                  </p>
                  <p className="mb-2">
                    5.1 <b>You</b> may cancel the{" "}
                    <b>claims management service</b> without charge within the{" "}
                    <b>14 day cooling off period.</b>
                  </p>
                  <p className="mb-2">
                    5.2 After the expiry of the{" "}
                    <b>14 day cooling off period, you</b> may ask us to cancel
                    the <b>claims management service.</b> There is no
                    cancellation charge but a <b>success fee</b> may be payable:
                  </p>
                  <ul>
                    <li>
                      If there has been no settlement proposal(s) prior to
                      cancellation, no success fee will be due; or{" "}
                    </li>
                    <li>
                      If there has been <b>settlement proposal(s)</b> but no
                      acceptance prior to cancellation, the <b>success fee</b>{" "}
                      will apply to the lowest of: (a) the{" "}
                      <b>settlement proposal</b> with the highest{" "}
                      <b>compensation</b> received prior to cancellation; or (b)
                      the <b>compensation</b> that <b>you</b> achieve
                      independently of <b>us</b>; or
                    </li>
                    <li>
                      If <b>you</b> have accepted a <b>settlement proposal</b>{" "}
                      and cancel after acceptance, the <b>success fee</b> will
                      be due in the normal way.
                    </li>
                  </ul>
                  <p className="mb-2">
                    In all scenarios above, the <b>success fee</b> would not be
                    due for payment until <b>you</b> receive cash in hand.
                  </p>
                  <p className="mb-2">
                    5.3 Where we have a reasonable belief that <b>you</b> are
                    withholding information relating to a{" "}
                    <b>settlement proposal</b> or <b>claim</b> resolution
                    (including receipt of cash in hand) after cancellation,{" "}
                    <b>we</b> reserve the right to raise an invoice based on{" "}
                    <b>our</b> aggregated win statistics over the previous 12
                    month period relevant to the <b>product type. We</b> will
                    amend or cancel any such invoice as appropriate should{" "}
                    <b>we</b> subsequently learn the actual position.
                  </p>
                  <p className="mb-2">
                    5.4 <b>You</b> can serve notice of cancellation as follows:
                  </p>
                  <ul>
                    <li>
                      by post: Allegiant Finance Services Limited, Freepost
                      RTYU–XUTZ–YKJC, 400 Chadwick House, Warrington Road,
                      Birchwood Park, Warrington, WA3 6AE;
                    </li>
                    <li>by email: helpdesk@allegiant-finance.co.uk;</li>
                    <li>by telephone: 0345 544 1563; or</li>
                    <li>
                      online at{" "}
                      <a
                        href="https://allegiant.co.uk/compliance/cancellation/"
                        target="_blank"
                      >
                        https://allegiant.co.uk/compliance/cancellation/
                      </a>
                    </li>
                  </ul>
                  <p className="mb-2">
                    We may ask you why you are cancelling and use this
                    information for service improvement. However, you are under
                    no obligation to tell us why you cancelled.
                  </p>
                  <p className="mb-2">
                    5.5 Where <b>you</b> cancel an agreement with <b>us,</b> we
                    will ask you whether <b>you</b> wish for <b>us</b> to
                    withdraw the claim or whether <b>you</b> wish to continue it
                    yourself. <b>We</b> will then update the
                    <b>respondent</b> and where necessary the Financial
                    Ombudsman Service and/or other relevant third party
                    organisation as appropriate. Where <b>you</b> are continuing
                    the <b>claim</b>
                    yourself, it is solely <b>your</b> responsibility to make
                    enquiries of relevant deadlines and steps that are required
                    to be undertaken.
                  </p>
                  <p className="mb-2">
                    5.6 <b>We</b> may cancel the <b>terms of engagement</b>{" "}
                    where we do not consider a <b>claim</b> has appropriate
                    merit, where a <b>claim</b> is uneconomical for the{" "}
                    <b>company</b> or where an actual or potential regulatory or
                    commercial conflict of interest arises. Where <b>we</b>{" "}
                    cancel pursuant to this clause, there will be no charge.
                  </p>
                  <p className="mb-0 greentext">
                    <b>6. Complaints</b>
                  </p>
                  <p>6.1 Complaints may be made either:</p>
                  <ul>
                    <li>
                      by post: Allegiant Finance Services Limited, Freepost
                      RTYU–XUTZ–YKJC, 400 Chadwick House, Warrington Road,
                      Birchwood Park, Warrington, WA3 6AE;
                    </li>
                    <li>
                      by e-mail: allegiantcomplaints@allegiant-finance.co.uk; or
                    </li>
                    <li>by telephone: 0345 544 1563</li>
                  </ul>
                  <p className="mb-2">
                    6.2 <b>Our</b> full complaints procedure can be viewed at
                    https://allegiant.co.uk/compliance/complaints-regulated/ and
                    is available in other mediums on request.
                  </p>
                  <p>
                    6.3 If <b>you</b> are not satisfied with <b>our</b>{" "}
                    response, or if a complaint is not resolved after eight
                    weeks, <b>you</b> may refer the complaint to the Claims
                    Management Ombudsman (a part of the Financial Ombudsman
                    Service) to independently assess your complaint:
                  </p>
                  <ul>
                    <li>
                      by post: Financial Ombudsman Service, Exchange Tower,
                      Harbour Exchange, London, E14 9SR;
                    </li>
                    <li>
                      by email: complaint.info@financial-ombudsman.org.uk; or
                    </li>
                    <li>by telephone: 0800 023 4567</li>
                  </ul>
                  <p className="mb-2">
                    Further information about the Claims Management Ombudsman
                    can be found at: https://cmc.financial-ombudsman.org.uk.
                  </p>
                  <p className="mb-2">
                    6.4 Time limits apply to complaints. <b>You</b> have six
                    months to approach the Claims Management Ombudsman from the
                    date of <b>our</b> Final Response, or where <b>your</b>{" "}
                    complaint is not resolved by <b>us</b> after 8 weeks.
                  </p>
                  <p className="mb-0 greentext">
                    <b>7. Limitation of our liability</b>
                  </p>
                  <p>
                    We will not be liable for any costs or losses caused to you
                    as a result of:
                  </p>
                  <ul>
                    <li>your lack of co-operation</li>
                    <li>
                      your failure to read and understand the documentation that
                      we have provided to you where such information is
                      reasonably clear or where you could have taken steps to
                      clarify information
                    </li>
                    <li>
                      your failure to give us accurate or up to date information
                      in a timely manner
                    </li>
                    <li>
                      delays or loss of information caused by your or our use of
                      postal, telephony or email service providers
                    </li>
                    <li>matters that we cannot reasonably control</li>
                    <li>
                      losses which cannot be reasonably foreseen or which you
                      could have taken steps to mitigate
                    </li>
                    <li>
                      the expiry of limitation dates caused by previous
                      complaints unless we have specifically agreed to work to
                      those deadlines and you have provided us with all
                      reasonable information relating to the previous complaint.
                    </li>
                  </ul>
                  <p className="mb-0 greentext">
                    <b>8. Termination</b>
                  </p>
                  <p className="mb-3">
                    The <b>terms of engagement</b> will terminate upon
                    completion of the <b>claims management</b> service and upon
                    payment by <b>you</b> of any fees or charges due to{" "}
                    <b>us.</b> If <b>we</b> later become aware that{" "}
                    <b>your claim</b> has been potentially under compensated
                    (for example as a result of a regulatory change, court
                    order, audit, third-party error or concealment or some
                    similar basis), <b>you</b> agree that <b>we</b> may, at our
                    discretion, contact <b>you</b> offering to provide further
                    claims management services for the purpose of securing any
                    further compensation that <b>we</b> feel may be due to you
                    based on the underlying facts of the original{" "}
                    <b>claim. You</b> shall be under no obligation to use us and{" "}
                    <b>we</b> are under no obligation to contact <b>you.</b> Any
                    further work would require <b>you</b> to enter a newly
                    signed contract with <b>us.</b>
                  </p>
                  <p className="mb-0 greentext">
                    <b>9. General</b>
                  </p>
                  <p className="mb-2">
                    9.1 <b>We</b> reserve the right to recover the cost of
                    solicitor fees, tracing fees, court fees and/or enforcement
                    agent fees where it is necessary to enforce <b>our</b> right
                    to payment of the <b>success fee</b>. Where there is a need
                    for a replacement cheque or additional bank transfer attempt
                    which is not caused by <b>our</b> fault, we reserve the
                    right to deduct any cheque stopping fee or additional
                    transfer charge which <b>our</b> bank charges <b>us</b> from{" "}
                    <b>your refund</b>.
                  </p>
                  <p className="mb-2">
                    9.2 In the event of conflict, the provisions of this
                    agreement will be interpreted in line with prevailing
                    statutory and regulatory rules as in force from time to
                    time.
                  </p>
                  <p className="mb-2">
                    9.3 Nothing within this agreement is intended to confer a
                    benefit on a third party
                  </p>
                  <p className="mb-2">
                    9.4 The <b>company</b> may assign or subcontract any or all
                    of its rights and benefits arising from the{" "}
                    <b>terms of engagement</b> with 21 days prior written notice
                    (or shorter where required to comply with a legal or
                    regulatory obligation).
                  </p>
                  <p className="mb-2">
                    9.5 If any part of the <b>terms of engagement</b> is found
                    to be invalid or unenforceable, the other terms of this
                    agreement will not be affected and will be read to give
                    effect to the intended spirit of the overall agreement.
                  </p>
                  <p className="mb-2" id="sign011" ref={signref_1}>
                    9.6 The terms and conditions in these{" "}
                    <b>terms of engagement</b> will apply post termination where
                    necessary to give effect to the agreement, its purpose and
                    intention.
                  </p>
                  <p>
                    9.7 This agreement is subject to the laws and exclusive
                    jurisdiction of the courts of England and Wales.
                  </p>
                  <p className="mb-0 greentext">
                    <b>Your acceptance of our terms of engagement</b>
                  </p>
                  <div className="row m-3">
                    <div className="col-xl-1 col-lg-1 col-md-1 col-1 text-center">
                      <img
                        src="https://dev.allegiant.claims/assets/moneybarn_preview/img/danger.png"
                        alt=""
                      />
                    </div>
                    <div className="col-xl-11 col-lg-11 col-md-11 col-11 p-0">
                      <p className="mb-0">
                        <b>
                          If there is anything that you do not understand it is
                          important that you contact us before signing this
                          document.
                        </b>
                      </p>
                      <p className="mb-0">
                        I, {fName} {lName} confirm that I have read all pages
                        and I accept the Pre Contract Information, Service
                        Summary and these Terms of Engagement. I understand that
                        Allegiant’s Privacy Policy is available at:
                        https://allegiant.co.uk/privacy-policy and that I have
                        the opportunity to read it before signing these terms.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 col-6"
                    id="sign10"
                    ref={signref_2}
                  >
                    <button
                      type="button"
                      className={`efct_filed sign-btn sign01 ${button01}`}
                      onClick={signShowDiv}
                      name="sign01"
                    >
                      Click Here To Sign
                    </button>
                    <div className={`signbody1 ${sign01}`} name="sign01">
                      <p>
                        <img
                          className="efct_filed anim_ylw"
                          src={` ${userSign}`}
                          id="sign001"
                          alt=""
                        />
                      </p>
                      {currentDate}
                      <hr className="line" />
                    </div>
                  </div>
                  <div
                    className="rectbox border border border-2 border-dark p-3 mt-3"
                    id="sign01"
                  >
                    <p className="mb-0 greentext">
                      <b>Your acceptance of being aware of your options:</b>
                    </p>
                    <p className="mb-0">
                      <b>
                        I, {fName} {lName} am aware that I do not need to use
                        Claims Management Services to make my complaint, and can
                        make the claim to the relevant firm myself and if the
                        complaint is not successful, I can refer it to the
                        Financial Ombudsman Service myself for free. I wish to
                        use the services of Allegiant Finance Services Ltd to
                        make a claim on my behalf.
                      </b>
                    </p>

                    <div
                      className="col-xl-6 col-lg-6 col-md-6 col-6"
                      id="sign2"
                    >
                      <button
                        type="button"
                        className={`efct_filed sign-btn sign02 ${button02}`}
                        onClick={signShowDiv}
                        name="sign02"
                      >
                        Click Here To Sign
                      </button>
                      <div className={`signbody2 ${sign02}`} name="sign02">
                        <p>
                          <img
                            className="efct_filed anim_ylw"
                            src={` ${userSign}`}
                            alt=""
                            id="signatureimg01"
                          />
                        </p>
                        {currentDate}
                        <hr className="line" />
                      </div>
                    </div>
                  </div>
                  <h2 className="text-center my-4 title">
                    Claim Audit Survey Loan Claim
                  </h2>
                  <div
                    style={{ background: "#ccc" }}
                    className="rectbox2 border border border-2 border-dark p-3"
                  >
                    <p className="mb-0">
                      <b>File number:</b> [[______________]]
                    </p>
                    <p className="mb-0">
                      <b>Applicant Name: </b>
                      {title} {fName} {lName}
                    </p>
                    <p className="mb-0">
                      <b>Agreement Number: {agreementNo}</b>{" "}
                    </p>
                    <p className="mb-0">
                      <b>Agreement Term: {agreementTerm}</b>{" "}
                    </p>
                    <p className="mb-0">
                      <b>Agreement Type: {agreementType}</b>{" "}
                    </p>
                    <p className="mb-0">
                      <b>Complaint about all Applicant agreements with</b>{" "}
                      Capitalone Limited Trading as Capitalone
                    </p>
                    <p className="mb-0">
                      <b>Self-declaration of borrowing provided at sign up:</b>
                    </p>
                  </div>
                  {questionFlag ? (
                    <>
                      {/* Make dynamic version from quesions array */}
                      {Object.keys(answers).map((key) => {
                        const id = key;
                        if (!id) return <></>;

                        if (
                          [
                            // 21, 136, 138, 139, 140, 145, 146, 147, 148, 149,
                            155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
                          ].includes(+id)
                        )
                          return <></>;

                        const q = questionnaire.find((q) => q.questionId == id);

                        if (!q || !answers?.[key]) return <></>;

                        const aId = answers?.[key];
                        const answer = q.answers.length
                          ? q.answers?.find((a) => a.value == aId)
                          : aId;
                        if (!answer) return <></>;

                        return (
                          <p key={key}>
                            <b>{q?.question}</b>
                            <br />
                            {answer.label}
                            <br />
                          </p>
                        );
                      })}
                    </>
                  ) : (
                    <>

                      {/* Make dynamic version from quesions array */}
                      {Object.keys(answers).map((key) => {
                        const id = key.split("_Q")[1];
                        if (!id) return <></>;

                        if (
                          [
                            // 21, 136, 138, 139, 140, 145, 146, 147, 148, 149,
                            
                          ].includes(+id)
                        )
                          return <></>;

                        const q = questionnaire.find((q) => q.questionId == id);

                        if (!q || !answers?.[key]) return <></>;

                        return (
                          <p key={key}>
                            <b>{q?.question}</b>
                            <br />
                            {answers?.[key]}
                            <br />
                          </p>
                        );
                      })}
                    </>
                  )}
                  <div className="rectbox border border-dark border-2 row m-0 p-3 mb-5">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                      <img
                        src="https://dev.allegiant.claims/assets/moneybarn_preview/img/logo.png"
                        alt=""
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-6 text-end">
                      <h3 className="mt-3">
                        <span className="graytext text-secondary">
                          FCA FRN:
                        </span>{" "}
                        <b>836810</b>
                      </h3>
                    </div>
                    <h2 className="text-start title my-4">
                      Letter of Authority
                    </h2>
                    <p className="greentext">
                      <b>Genuine belief of accuracy</b>
                    </p>
                    <p>
                      I confirm that the Claim Testimony information above is
                      accurate to the best of my belief. I confirm that I
                      selected any pre-determined statements which most closely
                      fit my circumstances to the best of my knowledge. I
                      understand that Allegiant Finance Services Limited, the
                      Lender and The Financial Ombudsman Service shall each or
                      all rely upon the information set out within this document
                      when assessing my claim. I understand that if I need to
                      notify Allegiant Finance Services Limited of any changes
                      to the above Claim Testimony, I will do so promptly and
                      understand that any changes could impact on the prospects
                      of my claim being successful.
                    </p>
                    <p className="greentext">
                      <b>Authority to act</b>
                    </p>
                    <p>
                      I confirm I have entered into a contract with Allegiant
                      Finance Services Ltd (Allegiant) under which I authorise
                      Allegiant to represent me, investigate and pursue
                      compensation in respect of the mis-selling and/or
                      subsequent operation of payday loans and other such short
                      term credit taken with your company (including Hire
                      Purchase, Personal Contract Purchase and Personal Contract
                      Hire). I authorise Allegiant to refer the complaint to The
                      Financial Ombudsman Service if settlement cannot be
                      agreed. This appointment is made pursuant to FCA Rule Disp
                      2.7.2R. The appointment endures until cancellation in
                      writing or by settlement of any claim, whichever the
                      earlier. I hereby grant permission to Allegiant to obtain
                      any information it may deem necessary for the purposes of
                      establishing the existence of a claim and/or to assess the
                      level of redress that may be due. Such information
                      includes that information which must be provided pursuant
                      to ss. 77-79 of the Consumer Credit Act 1974 and/or s. 45
                      Data Protection Act 2018 as well as other confidential
                      information that Allegiant may reasonably require in
                      connection with a mis selling investigation / claim. I
                      hereby confirm that all correspondence, documentation and
                      offers of settlement should be directed or copied to
                      Allegiant. I have authorised Allegiant to communicate the
                      acceptance or declinature of any offer of compensation
                      received on the Applicant’s behalf. I hereby authorise
                      Allegiant to receive any payment made, arising out of the
                      complaint, into Allegiant's FCA regulated client bank
                      account (presently sort code 60-24-77 account number
                      28397177). I further authorise Allegiant to deduct its
                      fees as permitted within CMCOB rules (as in force).
                      Allegiant’s correspondence address is Allegiant Finance
                      Services Ltd, 400 Chadwick House, Warrington Road,
                      Birchwood Park, Warrington WA3 6AE.
                    </p>
                    <p>
                      <b>
                        Name:{fName} {lName}
                      </b>
                    </p>
                    <p id="ref3" ref={signref_3}>
                      <b>
                        Present Address:
                        {addressLine ? (
                          <>
                            {addressLine}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {addressLine2 ? (
                          <>
                            {addressLine2}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {addressLine3 ? (
                          <>
                            {addressLine3}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {town ? (
                          <>
                            {town}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {country ? (
                          <>
                            {country}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {postcode ? (
                          <>
                            {postcode}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </b>
                    </p>
                    <p>
                      <b>Date of Birth: {userDob}</b>
                    </p>
                    <p>
                      Applicant agreement details (if details have changed since
                      the Agreement was taken out)
                    </p>
                    <p id="sign031">
                      <b>Former Address:</b>
                      {prevAddressLine1 ? (
                        <>
                          {prevAddressLine1}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {prevAddressLine3 ? (
                        <>
                          {prevAddressLine3}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {prevTown ? (
                        <>
                          {prevTown}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {prevCountry ? (
                        <>
                          {prevCountry}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {prevPostcode ? (
                        <>
                          {prevPostcode}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                    <p>
                      <b>Former Name:</b> {prevName}
                    </p>
                    <p>
                      <b>Signature and date</b>
                    </p>
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 col-6"
                      id="sign03"
                    >
                      <button
                        type="button"
                        className={`efct_filed sign-btn sign03 ${button03}`}
                        name="sign03"
                        onClick={signShowDiv}
                      >
                        Click Here To Sign
                      </button>
                      <div className={`signbody3 ${sign03}`}>
                        <img
                          src={` ${userSign}`}
                          alt=""
                          className="efct_filed anim_ylw"
                          id="sign003"
                        />
                        {currentDate}
                        <hr className="line" />
                      </div>
                    </div>
                  </div>
                  <div className="qrsec text-end mb-4">
                    <p className="text-secondary"></p>
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/QR.png"
                      alt=""
                    />
                  </div>
                  <h2 className="title">Complaint form</h2>
                  <p className="para">
                    <b>
                      Please use this form to tell us about your complaint – so
                      we can help resolve it. If you’re not sure about anything,
                      or have difficulties filling in this form, just phone us
                      on 0800 023 4567. Please note all calls with our casework
                      teams are recorded.
                    </b>
                  </p>
                  <p className="mb-0">
                    If you have agreed to make this complaint on behalf of
                    someone else, you will need to ask them to check everything
                    is correct and sign and date this form in the declaration
                    section.
                  </p>
                  <h4 className="title">Your details</h4>
                  <p>
                    <b>Your details (the person complaining)</b>
                  </p>
                  <div className="table-responsive">
                    <table className="table  table-bordered">
                      <tbody>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Title</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}>
                            {title}
                          </td>
                          <td style={{ width: "13%" }}>
                            <p>First Name</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}>
                            {fName}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Surname</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}>
                            {lName}
                          </td>
                          <td style={{ width: "13%" }}>
                            <p> Date of Birth</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}>
                            {userDob}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Address for writing to you</p>
                            <p>Address line 1</p>
                            <p>Address line 2</p>
                            <p>Address line 3</p>
                          </td>
                          <td colSpan="37" style={{ width: "87%" }}>
                            <br />
                            <br />
                            {addressLine}
                            <br />
                            {addressLine2}
                            <br />
                            {addressLine3}
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>City</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}>
                            {town}
                          </td>
                          <td style={{ width: "13%" }}>
                            <p>County</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}>
                            {county}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Postcode</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}>
                            {postcode}
                          </td>
                          <td style={{ width: "13%" }}>
                            <p>Country</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}>
                            {country}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Phone number 1</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}>
                            {userPhone}
                          </td>
                          <td style={{ width: "13%" }}>
                            <p>Phone number 2</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Email</p>
                          </td>
                          <td colSpan="37" style={{ width: "87%" }}>
                            {userMail}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h3 className="fw-bold">
                    Details of anyone complaining with you (for example, a joint
                    policy/account holder)
                  </h3>

                  <div className="table-responsive">
                    <table className="table  table-bordered">
                      <tbody>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Title</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}></td>
                          <td style={{ width: "13%" }}>
                            <p>First name</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Surname</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}></td>
                          <td style={{ width: "13%" }}>
                            <p>{userDob}</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>D</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>D</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>M</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>M</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Address for writing to you</p>
                            <p>Address line 1</p>
                            <p>Address line 2</p>
                            <p>Address line 3</p>
                          </td>
                          <td colSpan="37" style={{ width: "87%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>City</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}></td>
                          <td style={{ width: "13%" }}>
                            <p>County</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Postcode</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}></td>
                          <td style={{ width: "13%" }}>
                            <p>County</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Phone number 1</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}></td>
                          <td style={{ width: "13%" }}>
                            <p>Phone number 2</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Email</p>
                          </td>
                          <td colSpan="37" style={{ width: "87%" }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="logosec1 text-end">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/finacial.png"
                      alt=""
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered mt-3 ">
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>
                            <p>How would you like us to contact you?</p>
                          </td>
                          <td style={{ width: "50%" }}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              checked="true"
                            />
                            &nbsp; phone &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              disabled="true"
                            />
                            &nbsp;Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault3"
                              disabled="true"
                            />
                            &nbsp;Post &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            <p>
                              There will be times when we need to write to you –
                              for example, to send you the outcome of your
                              complaint. When we do, would you prefer an email
                              or letter?
                            </p>
                          </td>
                          <td style={{ width: "50%" }}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault1"
                              id="flexRadioDefault4"
                              disabled="true"
                            />
                            &nbsp;Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault1"
                              id="flexRadioDefault5"
                              disabled=""
                              checked="true"
                            />
                            &nbsp;Post &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            <p>
                              Have you used our service before? (This is so we
                              can link our records)
                            </p>
                          </td>
                          <td style={{ width: "50%" }}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault4"
                              id="flexRadioDefault12"
                              disabled="disabled"
                            />
                            &nbsp;Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault4"
                              id="flexRadioDefault13"
                              disabled="disabled"
                            />
                            &nbsp;No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="20">
                            <p>
                              Do you have any practical needs where we could
                              help by making adjustments – like using large
                              print, Braille or a different language?
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="20" style={{ height: "80px" }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="para2 rightsec ">
                    <b>
                      If the complaint relates to a business account or you are
                      a small business, charity or trust you need to fill out
                      the following sections. Please make sure that you list the
                      names of all directors/partners/trustees authorised. The
                      person completing the form should be the director, partner
                      or trustee.
                    </b>
                  </p>
                  <p className="mb-0">
                    <b>
                      If you’re complaining on behalf of a business, charity or
                      trust
                    </b>
                  </p>
                  <p className="mb-0">Please fill in these details</p>
                  <table className="table table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <p>Its full official name</p>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <p>
                            What is the status of the business, charity or
                            trust?
                          </p>
                        </td>
                        <td style={{ width: "50%" }}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            id="flexRadioDefault6"
                            disabled="disabled"
                          />
                          &nbsp; Limited company &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            disabled="disabled"
                            id="flexRadioDefault7"
                          />
                          &nbsp;Sole proprietor &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <br />
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            disabled="disabled"
                            id="flexRadioDefault8"
                          />
                          &nbsp;Partnership &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            disabled="disabled"
                            id="flexRadioDefault9"
                          />
                          &nbsp;Charity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <br />
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            disabled="disabled"
                            id="flexRadioDefault10"
                          />
                          &nbsp;Trust &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            disabled="disabled"
                            id="flexRadioDefault11"
                          />
                          &nbsp;LLP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <p>
                            If your business, charity or trust is an
                            organisation, name all the directors/
                            partners/trustees of this organisation.
                          </p>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <p>
                            Name any staff members who you authorise to
                            represent the organisation (and provide their
                            contact details)
                          </p>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="text-secondary">
                    Financial Ombudsman Service - Complaint form
                  </p>
                  <div className="logosec1 text-end">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/finacial.png"
                      alt=""
                    />
                  </div>
                  <p className="mb-0">
                    <b>If you’re complaining on behalf of a business</b>
                  </p>
                  <p>
                    Please answer all the following questions – we may need to
                    ask you for evidence of this
                  </p>
                  <div className="table-responsive">
                    <table className="table border table-bordered border-2">
                      <tbody>
                        <tr>
                          <td style={{ width: "12%" }}>Number of employees</td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="3" style={{ width: "2%" }}></td>
                          <td colSpan="6" style={{ width: "12%" }}>
                            If a partnership, the number of partners
                          </td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "12%" }}>Its annual turnover</td>
                          <td style={{ width: "2%" }}>£</td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "5%" }}></td>
                          <td style={{ width: "3%" }}></td>
                          <td colSpan="2"></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td colSpan="5"></td>
                        </tr>
                        <tr>
                          <td style={{ width: "12%" }}>
                            <p>Balance sheet</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>£</p>
                          </td>
                          <td colSpan="2"></td>
                          <td colSpan="2"></td>
                          <td colSpan="2"></td>
                          <td colSpan="2"></td>
                          <td style={{ width: "3%" }}></td>
                          <td colSpan="2"></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td colSpan="5"></td>
                        </tr>
                        <tr>
                          <td>
                            <p>
                              Is this business linked to or partnered with
                              another business?
                            </p>
                          </td>
                          <td colSpan="22">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault5"
                              id="flexRadioDefault14"
                              disabled="disabled"
                            />
                            Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault5"
                              id="flexRadioDefault15"
                              disabled="disabled"
                            />
                            No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <p>
                              If yes, we’ll ask you for more information to
                              ensure we’re able to look into this complaint
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>List the businesses linked to or partnered</td>
                          <td colSpan="22"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="mb-0 mt-4">
                    <b>If you’re complaining on behalf of a charity or trust</b>
                  </p>
                  <p>Please answer the relevant following questions</p>
                  <table className="table border table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Annual income (if you’re complaining on behalf of a
                          charity)
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>£</p>
                        </td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Annual income (if you’re complaining on behalf of a
                          charity)
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>£</p>
                        </td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 className="mt-4 title">
                    The business you’re complaining about
                  </h4>
                  <p>Which financial business are you complaining about?</p>

                  <table className="table  border-2 border table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>
                          <p>Their name</p>
                        </td>
                        <td style={{ width: "60%" }}>
                          <p>Capitalone Limited Trading as Capitalone</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          <p className="pb-5">Their address</p>
                        </td>
                        <td style={{ width: "60%" }}>
                          Athena House, Bedford Road, Petersfield, Hampshire,
                          GU32 3LJ
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 className="mt-4 title">What’s happened so far</h4>
                  <div className="table-responsive">
                    <table className="table border border-2 table-bordered ">
                      <tbody>
                        <tr>
                          <td style={{ width: "60%" }}>
                            <p>Have you already complained to the business?</p>
                          </td>
                          <td colSpan="2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault6"
                              id="flexRadioDefault16"
                              defaultChecked=""
                              readOnly=""
                            />
                          </td>
                          <td colSpan="2">
                            <p>Yes</p>
                          </td>
                          <td colSpan="2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault6"
                              id="flexRadioDefault17"
                              disabled=""
                            />
                          </td>
                          <td colSpan="2">
                            <p>No</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "60%" }}>
                            <p>
                              If yes, See Letter of Complaint to the business?
                              (The business usually has up to eight weeks from
                              this date to send you its final written answer –
                              before we can investigate the complaint)
                            </p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>See</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>enc.</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>letter</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>of</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>complaint</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p></p>
                          </td>
                          <td style={{ width: "5%" }}></td>
                          <td style={{ width: "5%" }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p className="text-secondary">
                    Financial Ombudsman Service - Complaint form
                  </p>
                  <div className="logosec1 text-end">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/finacial.png"
                      alt=""
                    />
                  </div>
                  <table className="table border border-2 table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <p>
                            Has the business you’re complaining about sent you
                            its final written answer? If yes, please send us a
                            copy
                          </p>
                        </td>
                        <td colSpan="2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault7"
                            id="flexRadioDefault18"
                            disabled="disabled"
                          />
                        </td>
                        <td colSpan="2">
                          <p>Yes</p>
                        </td>
                        <td colSpan="2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault7"
                            id="flexRadioDefault19"
                            disabled="disabled"
                          />
                        </td>
                        <td colSpan="2">
                          <p>No</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <p>
                            Has there been any court action relating to your
                            complaint (or is any planned)? If yes, please
                            enclose copies of relevant paperwork
                          </p>
                        </td>
                        <td colSpan="2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault8"
                            id="flexRadioDefault20"
                            disabled="disabled"
                          />
                        </td>
                        <td colSpan="2">
                          <p>Yes</p>
                        </td>
                        <td colSpan="2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault8"
                            id="flexRadioDefault21"
                            disabled="disabled"
                          />
                        </td>
                        <td colSpan="2">
                          <p>No</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <p>
                            When did the advice, claim, service or transaction
                            you’re complaining about take place?
                          </p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>D</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>D</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>M</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>M</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>Y</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>Y</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>Y</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>Y</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 className="title">Your complaint</h4>
                  <p className="mb-0">
                    <b>
                      Please tell us about the product or service you would like
                      to complain about
                    </b>
                  </p>
                  <p>
                    (including the reference number of the
                    account/policy/product if you have one)
                  </p>
                  <table className="table border-2  border table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <p className="pb-5">
                            <b>Car Finance affordability claim</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <p>
                            Do you have a complaint reference number that the
                            business gave you? If yes, please provide
                          </p>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 className="my-3 fw-bold">
                    Tell us about your complaint – what happened?
                  </h5>
                  <div className="rectbox border border-2 border-dark p-4 pb-5">
                    <p>
                      Our client approached Allegiant to pursue an Unaffordable
                      Lending complaint against this lender.
                    </p>
                    <p>
                      We are pursuing this complaint with the Financial
                      Ombudsman Service because the lender has not provided a
                      satisfactory Final Response letter.
                    </p>
                    <p>
                      We find that many lenders do not carry out the
                      affordability checks they should have done. Or, whilst
                      proportionate checks were carried out, the lender chose to
                      overlook information which ought to have meant they
                      declined the lending. For example, this might be because a
                      customer was already in severe financial difficulty before
                      the lending decision was even made – so the lender ought
                      to have known that any additional borrowing was simply not
                      going to be sustainable. We’ve also seen examples of
                      lenders repeatedly lending to the same customer time and
                      time again – when the lender ought to have known that a
                      harmful pattern of repeat borrowing – and persistent
                      reliance on credit – had emerged.
                    </p>
                    <p className="mb-5 pb-5">
                      Any additional information our client has provided in
                      support of their complaint will be enclosed with this
                      submission.
                    </p>
                  </div>
                  <p className="text-secondary">
                    Financial Ombudsman Service - Complaint form
                  </p>
                  <div className="logosec1 text-end">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/finacial.png"
                      alt=""
                    />
                  </div>
                  <h5 className="my-3 fw-bold">
                    How have you been affected – financially or otherwise?
                  </h5>
                  <div className="rectbox border border-2 border-dark p-4 pb-5">
                    <p className="mb-5 pb-5">
                      Our client contends that, as a result of this unaffordable
                      lending, a financial situation (which was already poor)
                      has been made worse.
                    </p>
                  </div>
                  <h5 className="my-3 fw-bold">
                    How would you like the business to put things right for you?
                  </h5>
                  <div className="rectbox  border border-2 border-dark p-4 pb-5 mb-2">
                    <p className="mb-5 pb-5">
                      Refund of interest and charges, together with 8% interest,
                      and amendments to be made to our client’s credit file for
                      any negative information as a result of the unaffordable
                      lending. Any alternative offers, such as a write-off of
                      capital or lump sum cash awards will be communicated to
                      our client.
                    </p>
                  </div>
                  <p>Please continue on a separate sheet if needed.</p>
                  <p>
                    If your complaint is about the sale of payment protection
                    insurance (PPI) or a packaged bank account, you will also
                    need to complete a separate questionnaire. You may have done
                    this already – if you have already complained directly to
                    the business you think is responsible. If not, you can
                    download the questionnaire off our website – or phone us for
                    a copy on 0800 023 4567.
                  </p>
                  <p className="text-secondary">
                    Financial Ombudsman Service - Complaint form
                  </p>
                  <div className="logosec1 text-end" id="sign04">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/finacial.png"
                      alt=""
                    />
                  </div>
                  <h2 className="title">Declaration</h2>
                  <p className="mb-0 para2" id="5" ref={signref_4}>
                    Finally, please agree to this declaration. By signing below,
                    you are agreeing to it.
                  </p>
                  <p>
                    “I would like the Financial Ombudsman Service to look into
                    my complaint. I confirm to the best of my knowledge
                    everything I have told you is correct.”
                  </p>
                  <p>
                    <b>Your details (the person complaining)</b>
                  </p>
                  <div className=" table-responsive">
                    <table
                      className="mb-3 table border-2 border table-bordered "
                      id="tbldata"
                    >
                      <tbody id="tblbody">
                        <tr>
                          <td style={{ width: "10%" }}>
                            <p>Name</p>
                          </td>
                          <td style={{ width: "40%" }}>
                            {fName} {lName}
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>Job title*</p>
                          </td>
                          <td style={{ width: "40%" }}></td>
                        </tr>
                        <tr id="sign4">
                          <td style={{ width: "10%" }}>
                            <p>Signature</p>
                          </td>
                          <td style={{ width: "40%" }}>
                            <div id="sign04">
                              <button
                                type="button"
                                className={`efct_filed sign-btn sign04 ${button04}`}
                                name="sign04"
                                onClick={signShowDiv}
                              >
                                Click Here To Sign
                              </button>
                              <div className={`signbody4 ${sign04} `}>
                                <p>
                                  <img
                                    src={` ${userSign}`}
                                    alt=""
                                    className="efct_filed anim_ylw"
                                  />
                                </p>
                              </div>
                            </div>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>Date</p>
                          </td>
                          <td style={{ width: "40%" }}>{currentDate}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p>
                    <b>
                      Details of anyone complaining with you (for example, a
                      joint policy/account holder)
                    </b>
                  </p>
                  <div className="table-responsive">
                    <table
                      className="table  border-2 table border table-bordered table-responsive"
                      id="sign05"
                    >
                      <tbody>
                        <tr>
                          <td style={{ width: "10%" }}>
                            <p>Name</p>
                          </td>
                          <td style={{ width: "40%" }}></td>
                          <td style={{ width: "10%" }}>
                            <p>Job title*</p>
                          </td>
                          <td colSpan="8"></td>
                        </tr>
                        <tr>
                          <td style={{ width: "10%" }}>
                            <p>Signature</p>
                          </td>
                          <td style={{ width: "40%" }}>
                            <p>
                              <img src="" alt="" id="signatureimg05" />
                            </p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>Date</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>D</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>D</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>M</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>M</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p>
                    If someone is complaining on your behalf, you still need to
                    sign your agreement to the declaration above.
                  </p>
                  <p>
                    For complaints involving accounts or policies held jointly,
                    we usually need each person to sign – and we may share
                    details about the complaint with both signatories. Please
                    tell us if there’s any reason this might be a problem for
                    you.
                  </p>
                  <p>
                    If you have agreed to make this complaint on someone else’s
                    behalf, you will need to ask them to sign and date this form
                    in the space above. You will need to add your own details
                    and signature where prompted below. If the person
                    complaining can’t sign for any reason please let us know.
                  </p>
                  <p>
                    * If you’re complaining on behalf of a business, charity or
                    trust, please provide your job title.
                  </p>
                  <p className="mb-0">
                    <b>Representative information</b>
                  </p>
                  <p>
                    Please complete this section if you want to authorise
                    another person to act on your behalf. You could ask a
                    friend, relative, Claims Management Company or solicitor but
                    check first whether they will charge you for this. You can
                    change or cancel this authority at any time by contacting
                    us.
                  </p>
                  <div className="table-responsive ">
                    <table className="table table border-2 border table-bordered ">
                      <tbody>
                        <tr>
                          <td style={{ width: "15%" }} className="bg-pr">
                            <p>Their name</p>
                          </td>
                          <td colSpan="8">
                            <p>
                              <b>Allegiant Finance Services Ltd</b>
                            </p>
                          </td>
                          <td colSpan="2" className="td-bg">
                            <p>Their relationship to you</p>
                          </td>
                          <td colSpan="11">
                            <p>
                              <b>CMC</b>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan="4" className="td-bg">
                            <p>
                              Their address Address line 1<br />
                              Address line 2<br />
                              Address line 3<br /> City
                              <br />
                              County
                              <br />
                              Country
                            </p>
                          </td>
                          <td colSpan="8" rowSpan="4">
                            <p>
                              <b>
                                400 Chadwick House <br />
                                Birchwood Park
                                <br />
                                Warrington
                                <br />
                                Cheshire
                                <br />
                                England
                              </b>
                            </p>
                          </td>
                          <td style={{ width: "13%" }} className="td-bg">
                            <p>Their phone number 1</p>
                          </td>
                          <td style={{ width: "13%" }}></td>
                          <td style={{ width: "2%" }}>
                            <p>0</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>3</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>4</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>5</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>5</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>4</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>4</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>1</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>5</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>6</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>3</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }} className="td-bg">
                            <p>Their phone number 2</p>
                          </td>
                          <td style={{ width: "13%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                        </tr>
                        <tr>
                          <td colSpan="13" className="td-bg">
                            <p>Their email</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="13">
                            <a
                              href="mailto:helpdesk@allegiant-finance.co"
                              target="_blank"
                              className="text-dark"
                            >
                              <b>helpdesk@allegiant-finance.co.uk</b>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "15%" }} className="td-bg">
                            <p>Postcode</p>
                          </td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>W</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>A</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>3</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}></td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>6</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>A</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>E</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}></td>
                          <td style={{ width: "15%" }} className="td-bg">
                            <p>Their reference</p>
                          </td>
                          <td colSpan="12" style={{ textAlign: "center" }}>
                            [[______________]]
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row m-0">
                    <div className="col-xl-6 col-lg-6 col-md-6 para ">
                      <p className="mb-0">
                        <b>Post to:</b>
                      </p>
                      <p className="mb-0">Financial Ombudsman Service</p>
                      <p className="mb-0">Exchange Tower</p>
                      <p className="mb-0">London E14 9SR</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <p className="mb-0">You can also get in touch at</p>
                      <a
                        href="mailto:complaint.info@financial-ombudsman.org.uk"
                        target="_blank"
                      >
                        <u>complaint.info@financial-ombudsman.org.uk</u>
                      </a>
                    </div>
                  </div>
                  <h2 className="my-4  title">Complainant privacy notice</h2>
                  <p>
                    This privacy notice summarises what to expect us to do with
                    your personal information when you contact us or bring a
                    complaint to our service. A more detailed version of this
                    privacy notice is available on our website.
                  </p>
                  <p className="fonter mb-0">
                    <b>About us</b>
                  </p>
                  <p>
                    The Financial Ombudsman Service is a free and easy-to-use
                    service that settles complaints between complainants and
                    businesses that provide financial services. You can contact
                    us{" "}
                    <a href="https://www.financial-ombudsman.org.uk/contact-us"></a>
                    here.
                  </p>
                  <p>
                    <b>
                      We use your personal information to investigate and
                      resolve individual complaints and prevent unfairness. We
                      also analyse complaints data to make our services and
                      processes more effective for you and others. More detail
                      is set out in the ‘Why we process your personal
                      information’ section.
                    </b>
                  </p>
                  <p className="fonter mb-0">
                    <b>The personal information we use</b>
                  </p>
                  <p>
                    Personal information means information that is about an
                    individual or can identify them in some way. The amount and
                    type of personal information we process varies depending on
                    the individual circumstances of the complaint and why we are
                    processing personal information. Examples of the type of
                    information we process are your name, date of birth,
                    financial details, phone recordings and special category
                    data, such as health information.
                  </p>
                  <p className="fonter mb-0">
                    <b>How we collect personal data</b>
                  </p>
                  <p>
                    We have a range of channels that individuals can use to get
                    in contact with us. Generally, we receive personal
                    information from the individual directly, their
                    representative or from the financial business the complaint
                    is against. But sometimes, where it is necessary for
                    resolving a complaint or fulfilling our legal functions, we
                    may also gather information from other individuals or
                    organisations, such as a loss adjuster hired by an insurance
                    company, a medical expert or a credit reference agency.
                  </p>
                  <p className="fonter mb-0">
                    <b>Why we process personal information</b>
                  </p>
                  <p>
                    We primarily collect and process personal information to
                    investigate and resolve individual complaints brought to our
                    service and respond to enquiries and redirect individuals to
                    the correct organisation if necessary. In addition to this
                    we also process personal data in the following way:
                  </p>
                  <ul>
                    <li>Prevent complaints and unfairness</li>
                    <li>
                      Improve the effectiveness of our service and processes
                    </li>
                    <li>Meeting your needs and making adjustments</li>
                    <li>Work with the regulator and other bodies</li>
                    <li>
                      Dealing with contact you may make with us through social
                      media
                    </li>
                    <li>Complying with a legal duty.</li>
                  </ul>
                  <p>
                    We have a legal obligation to publish final decisions made
                    by our ombudsmen. These are published on our website. We
                    remove the name of the person making the complaint as well
                    as any other personal information that would be likely to
                    identify them.
                  </p>
                  <p>
                    We conduct regular surveys to understand your views on the
                    service we have provided to you. You can let your
                    Investigator know at any time if you do not want us to
                    contact you for our surveys.
                  </p>
                  <p className="fonter mb-0">
                    <b>Who we share personal information with?</b>
                  </p>
                  <p>
                    We will not share your information with anyone for the
                    purpose of direct marketing. We will not sell your data.
                    When an enquiry is brought to us, we need to contact the
                    financial business and make them aware that an enquiry has
                    been received and ask them what has happened so far. The
                    personal details of the complainant, any representative and
                    details of the complaint are shared during this initial
                    process.
                  </p>
                  <p className="text-secondary">
                    Financial Ombudsman Service - Complaint form
                  </p>
                  <p>
                    In order to investigate a complaint, we need to share
                    information with both parties of the complaint to get both
                    sides of the story. Sometimes, depending on the nature of
                    the complaint, we may also need to share relevant
                    information with other individuals or organisations, for
                    example: another financial business, medical experts or
                    credit reference agencies.
                  </p>
                  <p>
                    If you have a complaint about the standard of service we’ve
                    provided to you, and we’ve not been able to resolve this,
                    you can ask the Independent Assessor to investigate this
                    complaint. We will pass on relevant details to the
                    Independent Assessor so that they can investigate and
                    respond to your service complaint.
                  </p>
                  <p className="fonter mb-0">
                    <b>Lawful bases for processing personal information</b>
                  </p>
                  <p>
                    The law on data protection sets out a number of different
                    reasons for which an organisation may collect and process
                    your personal information.
                  </p>
                  <p>
                    Our lawful basis for processing personal information will
                    usually be because it’s necessary for our statutory
                    function, a reason of substantial public interest or
                    compliance with our legal obligations. On those occasions
                    where we are not relying on any of the above, we will ensure
                    that a suitable alternative lawful basis is used, which is
                    likely to be where the processing is in our legitimate
                    interests.
                  </p>
                  <p className="fonter mb-0">
                    <b>Where your data is processed and stored</b>
                  </p>
                  <p>
                    We store your personal information in the UK or the European
                    Union (EU). Our technical support teams in India may process
                    your information to provide technical advice and assistance.
                  </p>
                  <p>
                    Where we allow access to your information from countries
                    outside the UK, we ensure that we comply with all our data
                    protection obligations.
                  </p>
                  <p className="fonter mb-0">
                    <b>How we store personal information and for how long</b>
                  </p>
                  <p>
                    We know that data security is important to us all. When we
                    receive personal information, we take steps to ensure that
                    it is stored securely, both physically and electronically,
                    in accordance with the internal policies that we have in
                    place for the effective and secure processing of your
                    personal information. We will keep your case file for 6
                    years after your case closes (or 3 years if we did not go on
                    to fully investigate your case or if we transferred your
                    case to another organisation). Paper documents sent by post
                    are destroyed 6 months after the date they are scanned into
                    our system. More information is in Annex A{" "}
                    <a href="https://www.financial-ombudsman.org.uk/privacy-policy/consumer-privacy-notice">
                      here.
                    </a>
                  </p>
                  <p className="fonter mb-0">
                    <b>What are your rights over your personal information?</b>
                  </p>
                  <p>
                    Under data protection law, you have rights we need to make
                    you aware of. The rights available to you depend on our
                    reason for processing your information. Details of each of
                    these rights are set out in Annex B{" "}
                    <a href="https://www.financial-ombudsman.org.uk/privacy-policy/consumer-privacy-notice">
                      here.
                    </a>
                  </p>
                  <p className="fonter mb-0">
                    <b>
                      What to do if you’re unhappy with how we’ve handled your
                      personal information
                    </b>
                  </p>
                  <p>
                    If you have any questions about this notice or are concerned
                    about how we are processing your personal data, you can
                    contact our Data Protection Officer at:{" "}
                    <a
                      href="mailto:data.protection@financial-ombudsman.org.uk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      data.protection@financial-ombudsman.org.uk
                    </a>{" "}
                    Details of how to raise a complaint are available here.
                  </p>
                  <p>
                    We hope we’ll be able to resolve your concerns, but if you
                    still remain unhappy with our response, you can contact the
                    <a href="https://ico.org.uk/" target="_blank">
                      Information Commissioner’s Office
                    </a>{" "}
                    at{" "}
                    <a href="mailto:casework@ico.org.uk" target="_blank">
                      casework@ico.org.uk
                    </a>{" "}
                    or 01625 545 745.
                  </p>
                  <p className="fonter mb-0">
                    <b>Changes to this privacy notice</b>
                  </p>
                  <p>
                    We may change this privacy policy. In that case, the ‘last
                    updated’ date at the bottom of this page will also change.
                    Any changes to this privacy policy will apply to you and
                    your data immediately.
                  </p>
                  <p>Last updated April 2022</p>
                  <p
                    className="text-secondary"
                    style={{ paddingBottom: "90px" }}
                  >
                    Financial Ombudsman Service - Complaint form
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <footer
          className="fixed-bottom"
          id="myDiv2"
          style={{ display: "block" }}
        >
          <div className="container">
            <div className="row spl_font">
              <div
                className="col-lg-10 col-md-8 col-sm-12  text-center custom_content"
                style={{ padding: "5px 0" }}
              >
                <p className={`futp ft-text1 mb-0 ${nextButton1}`}>
                  {" "}
                  Please read the Service Summary and then click Next to
                  continue.
                </p>
                <p className={`futp ft-text2 mb-0 ${nextButton2}`}>
                  {fName} {lName} Please read through the document and when you
                  are happy, click where prompted to add your signature.{" "}
                </p>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12 text-center">
                <p className="mb-0">
                  <span>
                    <button
                      className="btn btn-success btn-next next-btn tocontent anim_ylw"
                      data-labelname={`click_${clickCount}`}
                      onClick={showSign}
                      id="sign111"
                    >
                      {text}
                    </button>
                  </span>
                </p>
                {/* <p className="mb-0"><span><a href="#sign011" className="btn btn-success  next-btn tocontent anim_ylw" id="sign2" style={{ display: "none" }}>Next</a></span></p> */}
                {/* <p className="mb-0"><span><a href="#sign01" className="btn btn-success  next-btn tocontent anim_ylw" id="sign3" style={{ display: "none" }}>Next</a></span></p> */}
                <p className="mb-0">
                  <span>
                    <a
                      href="#sign031"
                      className="btn btn-success  next-btn tocontent anim_ylw"
                      id="sign4"
                      style={{ display: "none" }}
                    >
                      Next
                    </a>
                  </span>
                </p>

                <p className="mb-0">
                  <span>
                    <a
                      href="#sign04"
                      className="btn btn-success  next-btn tocontent anim_ylw"
                      id="sign5"
                      style={{ display: "none" }}
                    >
                      Next
                    </a>
                  </span>
                </p>
                <p className="mb-0">
                  <span>
                    <a
                      href="#sign0310"
                      className="btn btn-success  next-btn tocontent anim_ylw"
                      id="sign6"
                      style={{ display: "none" }}
                    >
                      Next
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
export default Preview;
