import { useState } from "react";
import { Api } from "../api/Api";

export const UseUnsubEmail = () => {
const [isLoading,setIsLoading] = useState(false);
    const saveSubEmail = async (
        userId, 
        user_uuid, 
        unsubscribe_email, 
        unsubscribe_sms, 
        reason, 
        reason_short
    ) => {
        setIsLoading(true);
        const response = await Api.post("v1/unsubscribe-user", {
            userId, 
            user_uuid, 
            unsubscribe_email, 
            unsubscribe_sms, 
            reason, 
            reason_short
        });
        setIsLoading(false);
        return response;
    }
    return { saveSubEmail,isLoading };
}
