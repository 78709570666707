import React, { useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../../src/assets/moneybarn/img/logo-white1.png";
import tick from "../../../src/assets/moneybarn/img/success-tick.gif";
import Footer from "../Includes/Layouts/Moneybarn/Footer";

const Thankyou = () => {
  window.scrollTo(0, 0);
  const formdata = JSON.parse(localStorage.getItem("formData"));
  let email = "";
  if (formdata) {
    email = formdata?.txtEmail;
  } else {
    const urlParams = new URLSearchParams(document.location.search);
    email = urlParams.get("email");
  }
  localStorage.clear();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const modalClick = (clickValue) => {
    switch (clickValue) {
      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showCookie": {
        setShowCookie(true);
        break;
      }
      case "exitCookie": {
        setShowCookie(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>Moneybarn</title>
      </Helmet>

      <div className="moneybarn-success">
        <div className="clearfix"></div>
        <header className="header_success">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 text-center py-3">
                <a href="" className="logo">
                  <img src={logo} alt="" />
                </a>
              </div>
            </div>
          </div>
        </header>
        <div className="clearfix"></div>
        <section className="py-4 py-lg-5 py-md-5 py-sm-5   mb-lg-2 mb-md-2 mb-sm-0 ">
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="mb-1 mt-4 ">
                  <img width="100px" src={tick} alt="" />
                </div>
                <h1 className="mt-4 fw-bold text-mesg">
                  Thank you, your query has been successfully submitted.
                </h1>
                <h3>
                  You will shortly receive an acknowledgement email to {email}.
                  Please check your spam or junk folder if you cannot locate
                  this.{" "}
                </h3>
                <h4>
                  If you do not receive this acknowledgement, your email address
                  may be incorrect or your spam filter may be filtering our
                  communications. If this is the case, please email
                  helpdesk@allegiant-finance.co.uk so we can assist.
                </h4>
                <h4>Thank you for your interest in our service.</h4>
              </div>
            </div>
          </div>
        </section>

        <div className="clearfix"></div>
        <Footer />
      </div>
    </>
  );
};

export default Thankyou;
