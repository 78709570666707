import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";

const RadioQuestiannaireC2Flp = (props) => {
  const mainQuestion = props.questionnaire.question;
  const radioName = "question_" + props.questionnaire.questionId;
  const question_id = props.questionnaire.questionId;
  const radioId0 = props.questionnaire.answers[0]
    ? "question_" +
      props.questionnaire.questionId +
      "_" +
      props.questionnaire.answers[0].value
    : "";
  const radioId1 = props.questionnaire.answers[1]
    ? "question_" +
      props.questionnaire.questionId +
      "_" +
      props.questionnaire.answers[1].value
    : "";
  const radioId2 = props.questionnaire.answers[2]
    ? "question_" +
      props.questionnaire.questionId +
      "_" +
      props.questionnaire.answers[2].value
    : "";
  const radioId3 = props.questionnaire.answers[3]
    ? "question_" +
      props.questionnaire.questionId +
      "_" +
      props.questionnaire.answers[3].value
    : "";
  const radioId4 = props.questionnaire.answers[4]
    ? "question_" +
      props.questionnaire.questionId +
      "_" +
      props.questionnaire.answers[4].value
    : "";

  const radioValue0 = props.questionnaire.answers[0]
    ? props.questionnaire.answers[0].value
    : "";
  const radioValue1 = props.questionnaire.answers[1]
    ? props.questionnaire.answers[1].value
    : "";
  const radioValue2 = props.questionnaire.answers[2]
    ? props.questionnaire.answers[2].value
    : "";
  const radioValue3 = props.questionnaire.answers[3]
    ? props.questionnaire.answers[3].value
    : "";
  const radioValue4 = props.questionnaire.answers[4]
    ? props.questionnaire.answers[4].value
    : "";
  const radioLabel0 = props.questionnaire.answers[0]
    ? props.questionnaire.answers[0].label
    : "";
  const radioLabel1 = props.questionnaire.answers[1]
    ? props.questionnaire.answers[1].label
    : "";
  const radioLabel2 = props.questionnaire.answers[2]
    ? props.questionnaire.answers[2].label
    : "";
  const radioLabel3 = props.questionnaire.answers[3]
    ? props.questionnaire.answers[3].label
    : "";
  const radioLabel4 = props.questionnaire.answers[4]
    ? props.questionnaire.answers[4].label
    : "";
  return (
    <>
      <div
        className={`radio-box question10  animated fadeInUp ${props.showQuestionSlide}`}
        id="breakdown8"
      >
      <h3 style={{color: '#444'}}>{mainQuestion}</h3>
        {radioId0 && (
          <div className="mb-2 mb-lg-3">
            <div className="btn-date-radio nextgoBtn13" id="">
              <input
                type="radio"
                id={radioId0}
                name={radioName}
                value={radioValue0}
                className=""
                data-question_id={question_id}
                onClick={(e) => {
                  props.slideChange(e, radioName);
                }}
              />
              <label htmlFor={radioId0} className="br radioa radioa-multiple l_g radiola next8">
                {question_id == '29' ? 
              
                <span className="radioa-span2">a</span>
                :<span className="radioa-span">a</span>
                }
                {radioLabel0}
              </label>
            </div>
          </div>
        )}

        {radioId1 && (
          <div className="mb-2 mb-lg-3 nextgoBtn13" id="">
            <div className="btn-date-radio">
              <input
                type="radio"
                id={radioId1}
                name={radioName}
                value={radioValue1}
                className=""
                data-question_id={question_id}
                onClick={(e) => {
                  props.slideChange(e, radioName);
                }}
              />
              <label htmlFor={radioId1} className="br radioa radioa-multiple l_g radiola next8">
              <span className="radioa-span">b</span>
                {radioLabel1} 
              </label>
            </div>
          </div>
        )}

        {radioId2 && (
          <div className="mb-2 mb-lg-3 nextgoBtn13" id="">
            <div className="btn-date-radio">
              <input
                type="radio"
                id={radioId2}
                name={radioName}
                value={radioValue2}
                className=""
                data-question_id={question_id}
                onClick={(e) => {
                  props.slideChange(e, radioName);
                }}
              />
              <label htmlFor={radioId2} className="br radioa radioa-multiple l_g radiola next8">
              <span className="radioa-span">c</span>
                {radioLabel2}
              </label>
            </div>
          </div>
        )}

        {radioId3 && (
          <div className="mb-2 mb-lg-3">
            <div className="btn-date-radio nextgoBtn13" id="">
              <input
                type="radio"
                id={radioId3}
                name={radioName}
                value={radioValue3}
                className=""
                data-question_id={question_id}
                onClick={(e) => {
                  props.slideChange(e, radioName);
                }}
              />
              <label htmlFor={radioId3} className="br radioa radioa-multiple l_g radiola next8">
              <span className="radioa-span">d</span>
                {radioLabel3}
               </label>
            </div>
          </div>
        )}
      {radioId4 && (
          <div className="mb-2 mb-lg-3">
            <div className="btn-date-radio nextgoBtn13" id="">
              <input
                type="radio"
                id={radioId4}
                name={radioName}
                value={radioValue4}
                className=""
                data-question_id={question_id}
                onClick={(e) => {
                  props.slideChange(e, radioName);
                }}
              />
              <label htmlFor={radioId4} className="br radioa radioa-multiple l_g radiola next8">
                <span className="radioa-span">e</span> 
                {radioLabel4} 
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RadioQuestiannaireC2Flp;
