import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import questions from "../../../Constants/moneybarnQuestions";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useQuestionnaire } from "../../../Hooks/useQuestionnaire";
import { useSkipAgreementNumber } from "../../../Hooks/useSkipAgreementNumber";
import { useTotalCarCheckLookUp } from "../../../Hooks/useTotalCarCheckLookUp";
import { userInfo } from "../../../Hooks/userInfo";
import { FormData } from "../../../Utility/FormData";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { MoneyBarnQualificationCheck } from "../../../Utility/MoneyBarnQualificationCheck";
import { queryString } from "../../../Utility/QueryString";
import { UUID } from "../../../Utility/UUID";
import Loader from "../../Includes/Layouts/Common/Loader";
import CheckBoxQuestionnaire from "../../Includes/Layouts/Common/MoneybarnCheckBoxQuestionnaire";
import InputQuestiannaire from "../../Includes/Layouts/Common/MoneybarnInputQuestionnaire";
import SelectQuestiannaire from "../../Includes/Layouts/Common/MoneybarnSelectQuestiannaire";
import MoneybarnUKVD from "../../Includes/Layouts/Common/MoneybarnUKVD";
import QuestionnaireModal from "../../Includes/Layouts/Common/QuestionnaireModal";
import RadioQuestiannaire from "../../Includes/Layouts/Common/RadioQuestiannaireMB";
import TopSection from "../../Includes/Layouts/Common/TopSection";
import GTMDataLayer from "../../Includes/Layouts/GTMDataLayer";
import Button from "../../UI/Button";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import male from "../../../assets/videos/male.mp4";
const SPLIT_NAME = "0602UFMB_A1_1";

const YES = "Yes";
const NO = "No";

const FIRST_QUESTION_ID = questions[0].questionId;

const valueOf = (id, label) => {
  const q = questions.find((q) => q.questionId === id);
  const answer = q.answers.find((o) => o.label === label);

  return answer.value;
};

const initialState = {
  showLetsBeginSlide: "d-block",
  showQuestionSlide1: "d-none",
  showQuestionSlide2: "d-none",
};

questions.forEach((question) => {
  initialState[`showQuestionSlide_${question.questionId}`] = "d-none";
});

const QuestionReducer = (state, action) => {
  switch (action.type) {
    case "nextQuestionSlide": {
      const currentSlide = action.payload.clickedSlide.slide;
      // console.log("currentSlide", currentSlide);
      const currentId = action.payload.clickedSlide.slide.split("_")?.[1];
      const answers = JSON.parse(localStorage.getItem("questionData"));

      let skipCount = 0;
      let moveToIndex = null;

      const newState = { ...state };

      // hide all slides
      Object.keys(newState).forEach((key) => {
        if (newState[key] == "d-block") {
          newState[key] = "d-none";
        }
      });

      if (currentSlide == "lets_begin") {
        return {
          ...newState,
          showQuestionSlide1: "d-block",
        };
      }

      if (currentSlide == "btnSkip") {
        // * skipping registration number

        return {
          ...newState,
          showQuestionSlide_150: "d-block",
        };
      }

      if (currentSlide == "btnNext2") {
        return {
          ...newState,
          showQuestionSlide2: "d-block",
        };
      }

      if (currentSlide == "keepDate") {
        return {
          ...newState,
          [`showQuestionSlide_${FIRST_QUESTION_ID}`]: "d-block",
        };
      }

      if (currentId == 150) {
        const value = answers[140];

        if (value) skipCount = 1;
      }

      if (currentId == 140) {
        const value = answers[140];

        if (value == valueOf(140, YES)) skipCount = 2;
      }

      if (currentId == 146) {
        const value = answers[146];

        if (value == valueOf(146, NO)) skipCount = 1;
      }

      if (currentId == 142) {
        const value = answers[142];

        if (value && value == valueOf(142, NO)) {
          skipCount = 1;
        }
      }
      if (currentId == 142 && answers[137] == 414) {
        skipCount = 2;
      }
      if(currentId == 142 && answers[142] == 413){
        skipCount = 1;
      }
      // if (currentId == 142 && answers[137] == 414 && answers[142] == 420) {
      //   skipCount = 2;
      // }

      if (currentId == 137) {
        const value = answers[152];
        console.log(value);
      }
      if (currentId == 141) {
        const value = answers[142];

        if (value && value == valueOf(142, YES)) {
          skipCount = 1;
        }
      }

      const currentQuestionIndex = questions.findIndex(
        (question) => question.questionId == currentId
      );

      moveToIndex = moveToIndex
        ? moveToIndex
        : currentQuestionIndex + skipCount + 1;

      let nextQuestion = questions?.[moveToIndex];
      if (!nextQuestion) return newState;

      // find if there is other question
      if (!newState?.[`showQuestionSlide_${nextQuestion.questionId}`]) {
        nextQuestion = questions?.[moveToIndex + 1];
      }
      if (!nextQuestion) return newState;

      newState[`showQuestionSlide_${nextQuestion.questionId}`] = "d-block";

      return newState;
    }
    case "backQuestionSlide": {
      const currentSlide = action.payload.clickedSlide.slide;
      // console.log("currentSlide", currentSlide);
      const currentId = action.payload.clickedSlide.slide.split("_")?.[1];
      console.log(currentId);
      const answers = JSON.parse(localStorage.getItem("questionData"));

      let skipCount = 0;
      let moveToIndex = null;

      const newState = { ...state };

      // hide all slides
      Object.keys(newState).forEach((key) => {
        if (newState[key] == "d-block") {
          newState[key] = "d-none";
        }
      });

      if (currentId == 136) {
        // check if skipped flow
        const value = answers[140];

        if (value) {
          if (value == valueOf(140, NO)) {
            newState[`showQuestionSlide_${150}`] = "d-block";
            return newState;
          }

          newState[`showQuestionSlide_${140}`] = "d-block";
          return newState;
        }
      }
      if (currentId == 148) {
        const value = answers[146];

        if (value && value == valueOf(146, NO)) {
          skipCount = 1;
        }
      }

      if (currentId == 153) {
        const value = answers[142];

        if (value && value == valueOf(142, NO)) {
          skipCount = 1;
        }
      }

      if (currentId == 143) {
        const value = answers[142];

        if (value && value == valueOf(142, YES)) {
          skipCount = 1;
        }
      }
      if(currentId == 138){
        let skipQuestion = answers[137];
        if(skipQuestion == 414){
           skipCount = 2;
        } else{
        let skipQuestion = answers[137];
          let vehicleStatus = answers[142];
          if(skipQuestion == 413){ 
          if(vehicleStatus == 420){
            skipCount = 1;
          }else{
            skipCount = 0;
          }
        }else{
          skipCount = 2;
        }
      }
    }
  //   if(currentId == 138){
  //     let skipQuestion = answers[137];
  //     if(skipQuestion == 414 && answers[142] == 421 ){
  //        skipCount = 0;
  //     } else{
  //     let skipQuestion = answers[137];
  //       let vehicleStatus = answers[142];
  //       if(skipQuestion == 413){ 
  //       if(vehicleStatus == 420){
  //         skipCount = 1;
  //       }else{
  //         skipCount = 0;
  //       }
  //     }else{ 
  //       skipCount = 2;
  //     }
  //   }
  // }


      // * Un-skipping registration number
      if (currentId == 150) {
        const value = answers?.[140];

        if (!value) {
          newState[`showQuestionSlide1`] = "d-block";
          return newState;
        }
      }

     
      if (currentSlide == "back1") {
        newState[`showQuestionSlide1`] = "d-block";
        return newState;
      }

      const firstQuestionId = questions[0].questionId;
      const isFirstQuestion = firstQuestionId == currentId;
      if (isFirstQuestion) {
        newState[`showQuestionSlide2`] = "d-block";
        return newState;
      }

      const currentQuestionIndex = questions.findIndex(
        (question) => question.questionId == currentId
      );

      moveToIndex = moveToIndex
        ? moveToIndex
        : currentQuestionIndex - skipCount - 1;
        console.log(moveToIndex);
      let prevQuestion = questions?.[moveToIndex];
      if (!newState?.[`showQuestionSlide_${prevQuestion.questionId}`]) {
        prevQuestion = questions?.[moveToIndex - 1];
      }

      if (!prevQuestion) {
        newState[`showQuestionSlide2`] = "d-block";
      } else {
        newState[`showQuestionSlide_${prevQuestion.questionId}`] = "d-block";
      }

      return newState;
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const Questionnaire_Moneybarn = () => {
  const [clickedSlide, setClickedSlide] = useState();
  const [state, dispatch] = useReducer(QuestionReducer, initialState);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [loader, showLoader] = useState("d-none");
  const [selectedKeeperDate, setSelectedKeeperDate] = useState("");
  const [carRegNo, setCarRegNo] = useState("");
  const [prevSlide, setPrevSlide] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const { isLoading, SaveQuestionnaire, storeVehicleDetails } =
    useQuestionnaire();
  const { totalCarCheckLookUp } = useTotalCarCheckLookUp();
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleSecureYear, setVehicleSecureYear] = useState("");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const [showModal, setShowModal] = useState(false);
  const splitForm = useRef(null);
  const history = useHistory();
  const [currentKeeperDate, setCurrentKeeperDate] = useState("");
  const { getUserInfo } = userInfo();
  const { setFormData } = FormData();
  const { fetchUuid } = UUID();
  const { skipAgreementNumber } = useSkipAgreementNumber();
  const uuid = fetchUuid();
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('UNFR_0602UFMB_A1_1', '0602UFMB_A1_1/questionnaire');

  useEffect(() => {
    if (formdata === undefined || formdata === null || formdata == "") {
      (async () => {
        const response = await getUserInfo(uuid);
        const set_formdata = setFormData(response);
        let dataLength = response.data.response.length;
        if (dataLength > 0) {
          const firstName = response.data.response[0].first_name
            ? response.data.response[0].first_name
            : " ";
          const lastName = response.data.response[0].last_name
            ? response.data.response[0].last_name
            : " ";
          setFirstName(firstName);
          setLastName(lastName);
        }
      })();
    } else {
      const firstName = formdata.txtFName;
      const lastName = formdata.txtLName;
      setFirstName(firstName);
      setLastName(lastName);
    }
  }, [firstName, lastName]);

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (prevSlide) {
      dispatch({
        type: "backQuestionSlide",
        payload: { clickedSlide: prevSlide },
      });
    }
  }, [prevSlide]);

  const slideChange = async (e, fieldName) => {
    const currentSlide = e.target.name;
    // console.log("currentSlide", currentSlide);
    const currentId = e.target.name.split("_")?.[1];

    if (currentSlide == "btnSkipRegNo") {
      // skip 151
      return setClickedSlide({
        slide: "questionSlide_151",
      });
    }

    gtmDataLayer(e.target.name, e.target);
    const form = splitForm.current;

    // send basic question data to api

    if (currentId == FIRST_QUESTION_ID) {
      const vehicle_data = {
        keeper_date: selectedKeeperDate,
        current_keeper_date: currentKeeperDate,
        yourVehicle: e.target.dataset.answertext,
        carRegNo: carRegNo,
        pageName: SPLIT_NAME,
      };
      await storeVehicleDetails(
        visitorParameters.visitor_parameters,
        visitorParameters.data,
        formdata,
        queryString,
        vehicle_data,
        "store-vehicle-details"
      );
      const vehicle_details = {
        selecte_keeper_date: selectedKeeperDate,
        current_keeper_date: currentKeeperDate,
        your_vehicle: e.target.dataset.answertext,
        car_reg_no: carRegNo,
      };
      localStorage.setItem("vehicleDetails", JSON.stringify(vehicle_details));

      if (e.target.value == valueOf(FIRST_QUESTION_ID, NO)) {
        history.push(
          `/thankyou-unqualified?uuid=${visitorParameters.visitor_parameters.uuid}`
        );
        return false;
      }
    }

    // reset if initial question
    if (["lets_begin", "btnSkip"].includes(currentSlide)) {
      localStorage.removeItem("questionData");
    }

    // send each question data to api

    const isBasicQuestion = [
      "lets_begin",
      "btnSkip",
      "btnNext2",
      "keepDate",
      "btnSkipRegNo",
    ].includes(currentSlide);

    if (!isBasicQuestion) {
      const question_id = e.target.dataset.question_id;
      const input_questions = questions
        .filter((q) => !q.answers.length)
        .map((q) => q.questionId);
      let optionId = question_id == "144" ? fieldName : form[fieldName].value;
      if (!optionId) {
        optionId = input_questions.includes(+question_id)
          ? ""
          : form[fieldName].value;
      }
      const inputAnswer = input_questions.includes(+question_id)
        ? form[fieldName].value
        : "";

      const question_data = {
        question_id: question_id,
        option_id: optionId,
        input_answer: inputAnswer,
        bankId: "123",
      };

      const response = await SaveQuestionnaire(
        visitorParameters.visitor_parameters,
        question_data,
        "moneybarn_question_store",
        visitorParameters.data,
        queryString,
        formdata
      );

      if (currentId == 148 || currentId == 149) {
        const isYes = e.target.dataset.answertext == YES;

        if (isYes) {
          setShowModal(true);
          return;
        }
      }

      let existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = {};
      const newEntries =
        existingEntries == null ? (existingEntries = {}) : existingEntries;
      Object.assign(newEntries, {
        [question_id]: optionId ? optionId : inputAnswer,
      });
      localStorage.setItem("questionData", JSON.stringify(newEntries));
      // console.log("----------Question Length---------------");
      // console.log(questions.length);
      if (question_id == questions[questions.length - 1].questionId) {
        // console.log("------------------Question id checking-----------------");
        const isQualified = MoneyBarnQualificationCheck();
        let is_qualified = isQualified ? 1 : 0;
        // console.log("---------------------ISQualified------------------");
        // console.log(is_qualified);

        if (carRegNo) {
          const response = await totalCarCheckLookUp(
            visitorParameters.visitor_parameters,
            carRegNo,
            is_qualified,
            currentKeeperDate,
            selectedKeeperDate,
            SPLIT_NAME
          );
        }

        const uuid = visitorParameters.visitor_parameters.uuid;

        if (!is_qualified) {
          return history.push(`/thankyou-unqualified?uuid=${uuid}`);
        }

        history.push(`/${DynamicRouteNextPage}?uuid=${uuid}&splitName=${DynamicRouteSplitName}`);
      }
    }

    // change slide

    setClickedSlide({ slide: currentSlide });

  };

  const gtmDataLayer = (current_question, element = null) => {
    let question = "";
    let answer = "";
    switch (current_question) {
      case "lets_begin":
        question = "unique online service";
        break;
      case "btnNext2":
        question = "Vehicle Registration";
        break;
      case "keepDate":
        question = "Registration Date";
        break;
      case "question_152":
        question = "Is this your Vehicle?";
        break;

      case "question_140":
        question = "Secure Finance";
        break;
      case "next_136":
        question = "Approximate amount";
        break;
      case "question_137":
        question = "Finance still in place?";
        break;
      case "question_142":
        question = "Still have this vehicle?";
        break;
      case "question_141":
        question = "What happens to vehicle?";
        break;
      case "question_143":
        question = "Afford the monthly repayments?";
        break;
      case "next_138":
        question = "Usual monthly income";
        break;
      case "next_139":
        question = "Credit commitments";
        break;
      case "next_144":
        question = "Q1 Circumstances";
        break;

      case "question_145":
        question = "Q2 Circumstances";
        break;
      case "question_146":
        question = "dealer or broker";
        break;
      case "question_147":
        question = "commission inappropriately charged";
        break;
      case "question_148":
        question = "Subject to IVA?";
        break;
      case "question_149":
        question = "Scenarios";
        break;
      case "question_153":
        question = "What happens to vehicle?";
        break;

      default:
        question = current_question;
        //answer = element.value;
        break;
    }

    GTMDataLayer({
      question: question,
      answer: answer,
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const previousSlide = (e) => {
    const slide = e.target.name;
    setPrevSlide({ slide: slide });
  };

  return (
    <>
      <Helmet>
        <title>Moneybarn</title>
      </Helmet>

      <GetVisitorsParams />
      <div className="Questionnaire_moneybarn">
        <TopSection />
        <section className="d-lg-flex   wrap-question">
          <div className="col-video">
            <video autoPlay muted playsInline loop tabIndex="0">
              <source
                src={male}
                type="video/mp4"
              />
            </video>
          </div>

          <div className="right-form"></div>
        </section>
        <section
          className={`question_block slide-top slide1 text-center ${state.showLetsBeginSlide}`}
        >
          <div className="row questionnaire_row">
            <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block">
              <h1>
                {firstName} {lastName}, our experts have designed a unique
                online service to assess your claim swiftly and effectively.
              </h1>
              <ul className="list mb-4">
                <li>
                  Our Average Refund for MoneyBarn as of 04/01/2023 is £4,876.20<p style={{ fontSize: "12px", fontStyle: "italic" }}>Average compensation and debt deductions benefit in won claims. Claim success dependant on merit and not guaranteed.</p>
                </li>
                <li>
                  Over £67m recovered for unaffordable lending claims since 2013
                </li>
                <li>Over 49,000 successful unaffordable lending claims since 2013</li>
              </ul>
            </div>
            <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
              <div className="  d-flex align-items-center inner-text justify-content-center">
                <div>
                  <Button
                    className="btn com_bnt"
                    name="lets_begin"
                    id="btnNext1"
                    buttonText="LETS BEGIN >>"
                    onClick={slideChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Loader show={loader} />

        <QuestionnaireModal popDisplay={showModal} handleClose={handleClose} />

        <form ref={splitForm}>
          <MoneybarnUKVD
            key={`UKVD`}
            showQuestionSlide1={state.showQuestionSlide1}
            showQuestionSlide2={state.showQuestionSlide2}
            slideChange={slideChange}
            uuid={visitorParameters.visitor_parameters.uuid}
            page_name={SPLIT_NAME}
            showLoader={showLoader}
            setSelectedKeeperDate={setSelectedKeeperDate}
            setCarRegNo={setCarRegNo}
            previousSlide={previousSlide}
            setVehicleModel={setVehicleModel}
            setVehicleSecureYear={setVehicleSecureYear}
            setCurrentKeeperDate={setCurrentKeeperDate}
          />

          {questions
            .map((q) => {
              if (q.question.includes("YYYY")) {
                q.question = q.question.replace(
                  "YYYY",
                  !!vehicleSecureYear ? vehicleSecureYear : "YYYY"
                );
              }

              return q;
            })
            .filter((q) => !q.question.includes("YYYY"))
            .map((question) => {
              if (question.questionId == "152") {
                return (
                  <RadioQuestiannaire
                    key={`question_${question.questionId}`}
                    showQuestionSlide={
                      state[`showQuestionSlide_${question.questionId}`]
                    }
                    questionnaire={question}
                    slideChange={slideChange}
                    previousSlide={previousSlide}
                    vehicleModel={vehicleModel}
                  //Is this your Vehicle?
                  />
                );
              }
              if (question.questionId == "167") {
                return (
                  <RadioQuestiannaire
                    key={`question_${question.questionId}`}
                    showQuestionSlide={
                      state[`showQuestionSlide_${question.questionId}`]
                    }
                    questionnaire={question}
                    slideChange={slideChange}
                    previousSlide={previousSlide}


                  />
                );
              }
              if (question.questionId == 144) {
                return (
                  <CheckBoxQuestionnaire
                    key={`question_${question.questionId}`}
                    showQuestionSlide={
                      state[`showQuestionSlide_${question.questionId}`]
                    }
                    questionnaire={question}
                    slideChange={slideChange}
                    previousSlide={previousSlide}
                  //When my finance agreement began, I was…
                  />
                );
              }

              if (!!question.options?.length) {
                return (
                  <SelectQuestiannaire
                    key={`question_${question.questionId}`}
                    showQuestionSlide={
                      state[`showQuestionSlide_${question.questionId}`]
                    }
                    questionnaire={question}
                    slideChange={slideChange}
                    previousSlide={previousSlide}
                    description={
                      question.questionId == 139 && (
                        <div className="light-bg mb-2">
                          Please do not include any mortgage or rental payment
                          within your answer.
                        </div>
                      )
                    }
                  />
                );
              }

              if (!!question.answers.length) {
                return (
                  <RadioQuestiannaire
                    key={`question_${question.questionId}`}
                    showQuestionSlide={
                      state[`showQuestionSlide_${question.questionId}`]
                    }
                    questionnaire={question}
                    slideChange={slideChange}
                    previousSlide={previousSlide}
                    size={question.answers.length > 2 ? "sm" : null}
                    description={
                      (question.questionId == 141 && (
                        <div className="btn-wrap text-center text-lg-start pb-3">
                          <a
                            className="btn_link"
                            href="https://allegiant.co.uk/2022/07/your-car-and-your-unaffordable-finance-claim/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Unsure? See our helpful article here
                          </a>
                        </div>
                      )) ||
                      (question.questionId == 143 && (
                        <div className="light-bg mb-3">
                          'Disposable income’ is the amount of money left over
                          from a regular income, once regular living costs and
                          regular expenditure have been deducted.
                        </div>
                      )) ||
                      (question.questionId == 145 && (
                        <ul className="list_ck mb-3  ms-4">
                          <li>
                            Direct Debits or Standing Order payments were
                            bouncing and being rejected
                          </li>
                          <li>
                            Late payment or overlimit fees were being applied
                          </li>
                          <li>
                            Money spent was more than money received in any one
                            month
                          </li>
                        </ul>
                      )) ||
                      (question.questionId == 149 && (
                        <ul className="list_ck mb-3 ms-4">
                          <li>
                            Declared bankrupt or are currently subject to a
                            bankruptcy petition
                          </li>
                          <li>Subject to a debt relief order</li>
                          <li>
                            {" "}
                            Had an adverse County Court Debt Judgment or
                            Sequestration
                          </li>
                        </ul>
                      ))
                    }
                  />
                );
              }

              if (question.questionId == 151) {
                return (
                  <InputQuestiannaire
                    key={`question_${question.questionId}`}
                    showQuestionSlide={
                      state[`showQuestionSlide_${question.questionId}`]
                    }
                    questionnaire={question}
                    slideChange={slideChange}
                    previousSlide={previousSlide}
                    placeholder="Agreement Number"
                    maxLength="10"
                  >
                    <Button
                      type="button"
                      className="btn btn-link text-decoration-none btn-sm mt-2"
                      id="btnSkipRegNo"
                      name="btnSkipRegNo"
                      buttonText="Don't have to hand? Click Here to Skip >"
                      onClick={(e) => {
                        skipAgreementNumber(uuid, SPLIT_NAME).then((res) => {
                          slideChange(e);
                        });
                      }}
                    />
                  </InputQuestiannaire>
                );
              }

              return (
                <InputQuestiannaire
                  key={`question_${question.questionId}`}
                  showQuestionSlide={
                    state[`showQuestionSlide_${question.questionId}`]
                  }
                  questionnaire={question}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  description={
                    question.questionId == 139 && (
                      <div className="light-bg mb-2">
                        Please do not include any mortgage or rental payment
                        within your answer.
                      </div>
                    )
                  }
                />
              );
            })}
        </form>
      </div>
    </>
  );
};

export default Questionnaire_Moneybarn;
