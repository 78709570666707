import React from "react";
import logo from "../../../../assets/img/logo-white1.png";
import Footer from "../../../Includes/Layouts/Moneybarn/Footer";

const MoneybarnFollowupUnqualified = () => {
  localStorage.clear();

  return (
    <>
      <div className="success">
        <div className="clearfix"></div>
        <header className="header_success">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 text-center py-3">
                <a href="#logo" className="logo">
                  <img src={logo} alt="" />
                </a>
              </div>
            </div>
          </div>
        </header>

        <div className="clearfix"></div>

        <section className="py-4 py-lg-5 py-md-5 py-sm-5   mb-lg-2 mb-md-2 mb-sm-0 ">
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1 className="mt-4 fw-bold   text-mesg">
                  Thank you for your enquiry, having reviewed the information
                  you provided, we are sorry to inform you that we are unable to
                  act for you in relation to your claim.
                </h1>
              </div>
            </div>
          </div>
        </section>

        <div className="clearfix"></div>

        <Footer />
      </div>
    </>
  );
};
export default MoneybarnFollowupUnqualified;
