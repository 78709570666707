import React, { useState } from "react";
import { Form, FormControl, ListGroup } from 'react-bootstrap';
import questions from "../../../../Constants/moneybarnQuestions";

const SelectQuestiannaire = (props) => {

  const mainQuestion = props.questionnaire.question;
  const selectName = "question_" + props.questionnaire.questionId;
  const dropValues = props.questionnaire.options;
  const question_id = props.questionnaire.questionId;
  const [inputValue, setInputValue] = useState();
  const [showError, setShowError] = useState("d-none");
  const [filteredSuggestions,setFilteredSuggestions] = useState([]);
  const [suggestValue,setSuggestValue] = useState('');
 
  if( selectName == "question_168"){
    var suggestions = props.questionnaire.options.map((suggestions) =>({
          value:suggestions.value,
          label:suggestions.label
    }));
  }

  const setValue = (e) => {
    setInputValue(e.target.value);
    if (selectName == "question_138"){
    let ans_138 = e.target.value;
    let q_138 = ans_138.split('-')?.[0].split('£')?.[1];
    localStorage.setItem("question_138",JSON.parse(q_138));
    }
    e.target.value != "" ? setShowError("d-none") : setShowError("d-block");
  };
  const handleChange = (e) => {
    const input = e.target.value;
    const containsParentheses = /\(|\)/.test(input);

    if (containsParentheses) {
      const modifiedInput = input.replace(/[()]/, '');
      setSuggestValue(modifiedInput);
    }
    else{
    const regex = new RegExp(`^${input}`, 'i');
    const filtered = suggestions.filter(suggestion => regex.test(suggestion.label));
    setSuggestValue(input);
    setInputValue(input);
    input === "" ? setFilteredSuggestions('') : setFilteredSuggestions(filtered);
    }
  };
  const handleClick =(e,suggestionValue,suggestionLabel) =>{
    localStorage.setItem('question_168',JSON.parse(suggestionValue));
    setSuggestValue(suggestionLabel);
    setFilteredSuggestions([]);
    setInputValue(suggestionValue);
    setShowError('d-none');
  }

  const validatePage = (evt) => {
    console.log(evt, selectName);
      if(selectName == "question_150"){
        inputValue ? props.slideChange(evt, selectName) : setShowError("d-block");
      }
      if (selectName == "question_138"){
        inputValue ? props.slideChange(evt, selectName) : setShowError("d-block");
      }
      if (selectName == "question_139"){
      let ans_3839 = ''
      let ans_139 = inputValue;
      if(ans_139){
      var q_139 = ans_139.split('-')?.[0].split('£')?.[1];
    const question_138 =  localStorage.getItem("question_138");
    if (selectName == "question_139"){
         ans_3839 = ( q_139 /question_138 ) * 100 ;
    }
    if(ans_3839 >= 20){
        props.slideChange(evt, selectName,ans_3839); 
    }
  }
  inputValue ? props.slideChange(evt, selectName) : setShowError("d-block");
  }
  if(selectName == 'question_168'){
    if(!inputValue){
      setShowError("d-block");
    }
    else{
      const filter = dropValues.find(option =>option.value == inputValue);
      if(filter == undefined && inputValue){
        console.log(inputValue);
        console.log("inside");
        setInputValue(false);
        setShowError("d-block");
      }
      else if(inputValue == ''){
        setShowError("d-block");
      }
      else if(suggestValue.includes(filter.label)){
         inputValue ? props.slideChange(evt, selectName) : setShowError("d-block");
      }  
      else{
        setShowError("d-block");
      }
    }
  }
    };
  
  return (
    <>
      <section
        className={`question_block slide-top slide33 ${props.showQuestionSlide}`}
      >
        <div className="row questionnaire_row">
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block d-flex align-items-center justify-content-center justify-content-lg-left">
            <div>
              <h1>{mainQuestion}</h1>

              {props.description}

              {question_id == "31" && (
                <div className="light-bg mb-2">
                  Please do not include any mortgage or rental payment within
                  your answer.
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
            <div className="  d-flex align-items-center inner-text justify-content-center">
              <div className="text-center">
                <div className="mb-3">
                 { selectName =="question_168" ?
                 <>
                  <FormControl
                    type="text"
                    className="input-box"
                    placeholder="Please type Bank Name..."
                    aria-label=" "
                    value={suggestValue}
                    name={selectName}
                    id={selectName}
                    onKeyDown={(e) => {
                      const alphabetsRegex = /^[A-Za-z()]+$/;
                      if (alphabetsRegex.test(e.key)) {
                        return true;
                      }
                    
                      e.preventDefault();
                      return false;
                    }}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  /> 
                  {filteredSuggestions.length > 0 && (
                    <ListGroup>
                      {filteredSuggestions.map((suggestion,index) => (
                        <ListGroup.Item
                          key={index}
                          name={selectName}
                          id={selectName}
                          className="list-item-group"
                          value={suggestion.value}
                          onClick={(e) => {handleClick(e,suggestion.value,suggestion.label)}}
                        >
                          {suggestion.label}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                  </>
                    // {/* {dropValues.map((dropValue, index) => (
                    //   <option key={index} value={dropValue.value}>
                    //     {dropValue.label}
                    //   </option>
                    // ))} */}
                  :
                  <select
                  className="form-select input-box "
                  aria-label=" "
                  name={selectName}
                  id={selectName}
                  onChange={(e) => {
                    setValue(e);
                  }}
                >
                  {dropValues.map((dropValue, index) => (
                    <option key={index} value={dropValue.value}>
                      {dropValue.label}
                    </option>
                  ))}
                </select> 
                  }
                 {  selectName == "question_168"?
                 <span
                 className={`error_msg error_msg_custom ${showError}`}
                 id="email_err"
               >
                 Please select Bank
               </span>
                 : <span
                    className={`error_msg error_msg_custom ${showError}`}
                    id="email_err"
                  >
                    Please select amount
                  </span>}
                </div>

                <div className="mb-3">
                  <button
                    type="button"
                    className="btn com_bnt "
                    id={`next_${question_id}`}
                    name={`next_${question_id}`}
                    data-question_id={question_id}
                    onClick={(e) => {
                      validatePage(e);
                    }}
                  >
                    {" "}
                    Next
                  </button>
                </div>
                <div className="mb-3 mt-4">
                  <div className="">
                    <button
                      type="button"
                      className="btn-back "
                      id={`back_${question_id}`}
                      name={`back_${question_id}`}
                      onClick={props.previousSlide}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SelectQuestiannaire;
