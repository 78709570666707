import { useState } from "react";
import { Api } from "../api/Api";

export const useOtp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const saveOtp = async (
    uuid,
    form_data,
    bank_id
    
  ) => {
    setIsLoading(true);
    const response = await Api.post("v1/verify-otp", {
      uuid,
      form_data,
      bank_id
     
    });
    setIsLoading(false);
    return response;
  };
  const sendOtp = async (
    user_email,
    user_phone,
    uuid,
    user_firstname,
    bank_id
   
    
  ) => {
    setIsLoading(true);
    const response = await Api.post("v1/send-otp", {
      user_email,
      user_phone,
      uuid,
      user_firstname,
      bank_id
     
    });
    setIsLoading(false);
    return response;
  };

  const regenerateOtp = async (
    send_to,
    uuid,
    otp_mode,
    otpType,
    user_firstname,
    bank_id
    
  ) => {
    setIsLoading(true);
    const response = await Api.post("v1/regenerate-otp", {
      send_to,
      uuid,
      otp_mode,
      otpType,
      user_firstname,
      bank_id
     
    });
    setIsLoading(false);
    return response;
  };

  const saveMilestone = async (
    uuid,
    
  ) => {
    setIsLoading(true);
    const response = await Api.post("v1/milestone-update", {
      uuid,
    });
    setIsLoading(false);
    return response;
  };

  return {
    isLoading,
    saveOtp,
    sendOtp,
    regenerateOtp,
    saveMilestone
  };
};
