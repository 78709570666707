const questions = [
    {
      question: "Which year was your Credit Card account opened?",
      questionId: 155,
      answers: [
        {
          label: "",
          value: "",
          options: [
            { value: "", label: "Please select Year" },
            { value: "2007 or earlier", label: "2007 or earlier " },
            { value: "2008", label: "2008" },
            { value: "2009", label: "2009" },
            { value: "2010", label: "2010" },
            { value: "2011", label: "2011" },
            { value: "2012", label: "2012" },
            { value: "2013", label: "2013" },
            { value: "2014", label: "2014" },
            { value: "2015", label: "2015" },
            { value: "2016", label: "2016" },
            { value: "2017", label: "2017" },
            { value: "2018", label: "2018" },
            { value: "2019", label: "2019" },
            { value: "2020", label: "2020" },
            { value: "2021", label: "2021" },
            { value: "2022", label: "2022" }
          ]
        },
      ],
    },
    {
      question: "Is your Credit Card account still open? ",
      questionId: 156,
      answers: [
        {
          label: "Yes",
          value: 447,
        },
        {
          label: "No",
          value: 448,
        },
      ],
    },
    {
      question: "Which year was your Credit Card account closed?",
      questionId: 157,
      answers: [
        {
          label: "",
          value: "",
          options: [
            { value: "", label: "Please select Year" },
            { value: "2007 ", label: "2007 or earlier " },
            { value: "2008", label: "2008" },
            { value: "2009", label: "2009" },
            { value: "2010", label: "2010" },
            { value: "2011", label: "2011" },
            { value: "2012", label: "2012" },
            { value: "2013", label: "2013" },
            { value: "2014", label: "2014" },
            { value: "2015", label: "2015" },
            { value: "2016", label: "2016" },
            { value: "2017", label: "2017" },
            { value: "2018", label: "2018" },
            { value: "2019", label: "2019" },
            { value: "2020", label: "2020" },
            { value: "2021", label: "2021" },
            { value: "2022", label: "2022" }
          ]
        },
      ],
    },
    {
      question: "Approximately what was the highest balance you ever had on this Credit Card account?",
      questionId: 158,
      answers: [
        {
          label: "",
          value: "",
          options: [
            { value: "", label: "Please select" },
            { value: "£100 - £200", label: "£100 - £200" },
            { value: "£200 - £300", label: "£200 - £300" },
            { value: "£300 - £400", label: "£300 - £400" },
            { value: "£400 - £500", label: "£400 - £500" },
            { value: "£500 - £600", label: "£500 - £600" },
            { value: "£600 - £700", label: "£600 - £700" },
            { value: "£700 - £800", label: "£700 - £800" },
            { value: "£800 - £900", label: "£800 - £900" },
            { value: "£900 - £1000", label: "£900 - £1000" },
            { value: "£1000 - £1500", label: "£1000 - £1500" },
            { value: "£1500 - £2000", label: "£1500 - £2000" },
            { value: "£2000 - £2500", label: "£2000 - £2500" },
            { value: "£2500 - £3000", label: "£2500 - £3000" },
            { value: "More than £3000", label: "More than £3000" }
          ]
        },
      ],
    },
    {
      question: "Approximately how many times was your credit limit increased on this account? Include every increase, whether you requested it or it happened automatically",
      questionId: 159,
      answers: [
        {
          label: "My credit limit never increased",
          value: "449",
        },
        {
          label: "Once",
          value: "450",
        },
        {
          label: "Twice",
          value: "451",
        },
        {
          label: "Three or Four times",
          value: "452",
        },
        {
          label: "Five times or more",
          value: "453",
        },
      ],
    },
    {
      question: "I would have a balance which was close to, or exceeding, my credit limit",
      questionId: 160,
      answers: [
        {
          label: "Always",
          value: "454",
        },
        {
          label: "Often",
          value: "455",
        },
        {
          label: "Sometimes",
          value: "456",
        },
        {
          label: "Never",
          value: "457",
        },
      ],
    },
    {
      question: "I would make only the minimum payment due on the monthly account statement (rather than pay off a larger amount)",
      questionId: 161,
      answers: [
        {
          label: "Always",
          value: "458",
        },
        {
          label: "Often",
          value: "459",
        },
        {
          label: "Sometimes",
          value: "460",
        },
        {
          label: "Never",
          value: "461",
        },
      ],
    },
    {
      question: "Approximately what was your average or usual monthly income (after tax) during the time your Credit Card account was open",
      questionId: 162,
      answers: [
        {
          label: "",
          value: "",
          options: [
            { value: "", label: "Please select" },
            { value: "£100 - £200", label: "£100 - £200" },
            { value: "£200 - £300", label: "£200 - £300" },
            { value: "£300 - £400", label: "£300 - £400" },
            { value: "£400 - £500", label: "£400 - £500" },
            { value: "£500 - £600", label: "£500 - £600" },
            { value: "£600 - £700", label: "£600 - £700" },
            { value: "£700 - £800", label: "£700 - £800" },
            { value: "£800 - £900", label: "£800 - £900" },
            { value: "£900 - £1000", label: "£900 - £1000" },
            { value: "£1000 - £1500", label: "£1000 - £1500" },
            { value: "£1500 - £2000", label: "£1500 - £2000" },
            { value: "£2000 - £2500", label: "£2000 - £2500" },
            { value: "£2500 - £3000", label: "£2500 - £3000" },
            { value: "More than £3000", label: "More than £3000" }
          ]
        },
      ],
    },
    {
      question:
        "During the time your Credit Card account was open, approximately how much per month were you typically paying towards other credit commitments (loans and card accounts, etc)?",
      questionId: 163,
      answers: [
        {
          label: "",
          value: "",
          options: [
            { value: "", label: "Please select" },
            { value: "£100 - £200", label: "£100 - £200" },
            { value: "£200 - £300", label: "£200 - £300" },
            { value: "£300 - £400", label: "£300 - £400" },
            { value: "£400 - £500", label: "£400 - £500" },
            { value: "£500 - £600", label: "£500 - £600" },
            { value: "£600 - £700", label: "£600 - £700" },
            { value: "£700 - £800", label: "£700 - £800" },
            { value: "£800 - £900", label: "£800 - £900" },
            { value: "£900 - £1000", label: "£900 - £1000" },
            { value: "£1000 - £1500", label: "£1000 - £1500" },
            { value: "£1500 - £2000", label: "£1500 - £2000" },
            { value: "£2000 - £2500", label: "£2000 - £2500" },
            { value: "£2500 - £3000", label: "£2500 - £3000" },
            { value: "More than £3000", label: "More than £3000" }
          ],
        },
      ],
    },
    {
      question:
        "During the time my Credit Card account was open, I was…",
      questionId: 164,
      answers: [
        {
          label: "Regularly using ‘bad credit’ lending (payday, guarantor, doorstep and high-interest)",
          value: "462",
        },
        {
          label: "Using a lot of my income for gambling or online betting",
          value: "463",
        },
        {
          label: "Receiving a very low and unreliable income (e.g. due to job loss, state benefits, being a student, etc)",
          value: "464",
        },
        {
          label: "Managing my income and outgoings ok, but still believe the account was unaffordable for me",
          value: "465",
        },
      ],
    },
    {
      question:
        "Are you subject to or have you ever been subject to an Individual Voluntary Arrangement (IVA) or have you proposed an IVA yet to be approved or rejected by creditors?",
      questionId: 165,
      answers: [
        {
          label: "Yes",
          value: "466",
        },
        {
          label: "No",
          value: "467",
        },
      ],
    },
    {
      question:
        "Do any of these scenarios apply to you?",
      questionId: 166,
      answers: [
        {
          label: "Yes",
          value: "468",
        },
        {
          label: "No",
          value: "469",
        },
      ],
    },
  ]
  export default questions;