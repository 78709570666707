import React from "react";
const Email = ({
  placeholder,
  name,
  className,
  validation,
  validationMsg,
  onBlur,
  emailStyle,
  id,
  onChange,
  onKeyPress,
}) => {
  return (
    <>
      <input
        type="email"
        name={name}
        className={className}
        placeholder={placeholder}
        ref={validation}
        onBlur={onBlur}
        style={emailStyle}
        id={id}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      {validationMsg && (
        <>
          <span className="address1_error error_msg">{validationMsg}</span>
        </>
      )}
    </>
  );
};
export default Email;
