import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";


const RadioQuestiannaireCC = (props) => {

  const question_id = props.questionnaire.questionId;

  return (
    <>
      <div className={`animated fadeInUp questionnaire_slides ${props.showQuestionSlide}`}>
        <div className="row text-div">
          <div className="text_div1 col-xl-6 col-lg-6 col-md-12 col-12 text-center white_bg mb-0 mb-md-auto my-auto">
            <h3>{props.questionnaire.question}</h3>
            {props.questionnaire.questionId == "135" &&
              <ul className="ulsec">
                <li>You have ever been declared bankrupt; or</li>
                <li>
                  You are currently subject to a bankruptcy petition
                </li>
                <li>You have been subject to a debt relief order</li>
                <li>You have an adverse County Court Debt Judgement</li>
                <li>Sequestration</li>
              </ul>
            }
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-12 text-center white_bg my-auto mrgin_top">
            <span className="align-middle">
              {props.questionnaire.answers.map((item, index) => {
                return (
                  <div className="mb-3" key={index}>
                    <div className="btn-date">
                        <input type="radio" id={item.label + '_' + question_id} 
                        name={'question_' + question_id} 
                        value={item.value} data-answertext={item.label} 
                        data-question_id={question_id} 
                        onClick={(e) => { props.slideChange(e, 'question_' + question_id) }} />

                      <label htmlFor={item.label + '_' + question_id} 
                        className="btn-upload slide_next"
                      >
                        {item.label}
                      </label>
                    </div>
                  </div>
                );
              })}
              <div >
              <input type="radio" id={`back_${question_id}`} name={`back_${question_id}`} onClick={props.previousSlide} />
              <label
                htmlFor={`back_${question_id}`}
                className="btn-upload btn_back"
              >
                &lt;&lt; Back
              </label>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadioQuestiannaireCC;
