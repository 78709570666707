import React, { useState } from "react";
import TextField from "../../../UI/TextField";

const OtpField = ({
  className,
  slideChange,
  textHeading,
  validation,
  validationMsg,
  trigger,
  getValues,
  id,
  name,
  maxlength,
  onChange,
  keypress,
  onKeyPress,
  onClick
}) => {
 

  return (
    <>
                <TextField
                     type="tel"
                     value=""
                     id={id}
                     name={name}
                     maxlength={maxlength}
                     className={className}
                     onChange={onChange}
                     onKeyPress={onKeyPress}
                     onClick={onClick}
                     validation={validation({
                      minLength: {
                        value: 1,
                      },
                      pattern: {
                        value: /^[0-9]*$/i,
                      },              
                    })}
                  />

    </>
  );
};
export default OtpField;
