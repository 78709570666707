import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  questionnaire23,
  questionnaire24,
  questionnaire25,
  questionnaire26,
  questionnaire27,
  questionnaire28,
  questionnaire29,
  questionnaire30,
  questionnaire31,
  questionnaire32,
} from "../../Constants/BlackhorseQuestions";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useAgreeTermsIngestion } from "../../Hooks/useAgreeTermsIngestion";
import { userInfo } from "../../Hooks/userInfo";
import questionnaire from "../../Constants/moneybarnQuestions";
import { Helmet } from "react-helmet";
import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";
import warning from "../../../src/assets/moneybarn/img/warning.jpeg"

const Preview = () => {
  const [show, setShow] = useState("show");
  const [userId, setUserId] = useState();
  const [title, setTitle] = useState();
  const [fName, setfName] = useState();
  const [lName, setlName] = useState();
  const [addressLine, setaddressLine] = useState();
  const [addressLine2, setaddressLine2] = useState();
  const [addressLine3, setaddressLine3] = useState();
  const [agreementNo, setAgreementNo] = useState();
  const [agreementType, setAgreementType] = useState();
  const [agreementTerm, setAgreementTerm] = useState();
  const [carRegNo, setCarRegNo] = useState();
  const [vehicleMake, setVehicleMake] = useState();
  const [vehicleModel, setVehicleModel] = useState();

  const [town, setTown] = useState();
  const [country, setCountry] = useState();
  const [postcode, setPostcode] = useState();
  const [county, setCounty] = useState();
  const [userPhone, setUserPhone] = useState();
  const [userMail, setUserMail] = useState();
  const [userDob, setUserDob] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [userSign, setUserSign] = useState();
  const [answers, setAnswers] = useState({});
  const [usersigndate, setUserSigndate] = useState();
  const { getUserInfo } = userInfo();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [currentUuid, setCurrentUuid] = useState();
  const [bankName, setBankName] = useState();
  const [bank, setbank] = useState();
  const history = useHistory();
  const [showDiv4, setshowDiv4] = useState("d-none");
  const [hideDiv3, sethideDiv3] = useState("show");
  const [nextButton1, setnextButton1] = useState("show");
  const [nextButton2, setnextButton2] = useState("d-none");
  const [text, settext] = useState("Next");
  const signref_1 = useRef();
  const signref_2 = useRef();
  const signref_3 = useRef();
  const signref_4 = useRef();
  const signref_5 = useRef();
  const { saveAgreeTermsIngestion, savePreviewClicks } =
    useAgreeTermsIngestion();
  const [prevAddressLine1, setPrevAddressLine1] = useState("");
  const [prevAddressLine3, setPrevAddressLine3] = useState("");
  const [prevTown, setPrevTown] = useState("");
  const [prevCountry, setPrevCountry] = useState("");
  const [prevPostcode, setPrevPostcode] = useState("");
  const [prevCounty, setPrevCounty] = useState("");
  const [prevName, setPrevName] = useState("");
  const [questionFlag, setQuestionFlag] = useState(false);
  const [question21, setQuestion21] = useState("");
  const [keeperDateYear, setKeeperDateYear] = useState("");
  const [signShow, setSignShow] = useState(0);
  const location = useLocation();
  const [clickCount, setClickCount] = useState(1);
  const [sign01, setsign01] = useState("d-none");
  const [sign02, setsign02] = useState("d-none");
  const [sign03, setsign03] = useState("d-none");
  const [sign04, setsign04] = useState("d-none");
  const [button01, setbutton01] = useState("show");
  const [button02, setbutton02] = useState("show");
  const [button03, setbutton03] = useState("show");
  const [button04, setbutton04] = useState("show");
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('UNFR_0602UFMB_A1_6', '0602UFMB_A1_6/preview');
  const [sign, setSign] = useState(["sign01", "sign02", "sign03", "sign04"]);
  const signShowDiv = (e) => {
    if (e.target.name == "sign01") {
      handleRemoveItem("sign01");
      setsign01("show");
      setbutton01("d-none");
    } else if (e.target.name == "sign02") {
      handleRemoveItem("sign02");
      setsign02("show");
      setbutton02("d-none");
    } else if (e.target.name == "sign03") {
      handleRemoveItem("sign03");
      setsign03("show");
      setbutton03("d-none");
    } else if (e.target.name == "sign04") {
      handleRemoveItem("sign04");
      setsign04("show");
      setbutton04("d-none");
    }
  };
  const handleRemoveItem = (value) => {
    setSign(sign.filter((item) => item !== value));
  };
  useEffect(() => {
    (async () => {
      const visitorData = visitorParameters.visitor_parameters;
      if (visitorData) {
        const uuid = visitorData.uuid;
        setCurrentUuid(uuid);
      }
    })();
  }, [visitorParameters]);
  let customUuid = "";
  let customSource = "";
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const query_source = query.get("source");
  const local_uuid = localStorage.getItem("uuid");
  customUuid = query_uuid ? query_uuid : local_uuid;
  customSource = query_source ? query_source : "live";

  const showSign = async (e) => {
    let user_uuid = "";
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_uuid = localStorage.getItem("uuid");
    user_uuid = query_uuid ? query_uuid : local_uuid;
    let labelName = e.target.dataset.labelname;
    setClickCount(clickCount + 1);
    sethideDiv3("d-none");
    setshowDiv4("show");
    setnextButton1("d-none");
    setnextButton2("show");
    // let signDiv = (parseInt(signShow) + 1);
    // setSignShow(signDiv);
    // var scrollToRef = (signDiv == 1) ? signref_5 : (signDiv == 2) ? signref_1 : ((signDiv == 3) ? signref_2 : (signDiv == 4) ? signref_3 : (signDiv == 5) ? signref_4 : '');
    var scrollToRef = sign.includes("sign01")
      ? signref_1
      : sign.includes("sign02")
        ? signref_2
        : sign.includes("sign03")
          ? signref_3
          : sign.includes("sign04")
            ? signref_4
            : "";
    if (sign.length == 1) {
      settext("Finish");
    }
    console.log("scrollToRef", scrollToRef);
    if (scrollToRef != "") {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      pageChange();
    }
    if (clickCount <= 6) {
      const saveClick = await savePreviewClicks(userId, user_uuid, labelName);
    }
  };
  const pageChange = async () => {
    const previewstats = await saveAgreeTermsIngestion(
      userId,
      customUuid,
      "preview_submit",
      1,
      "live"
    );
    history.push(
      "/" + DynamicRouteNextPage + "?uuid=" + customUuid + "&email=" + userMail + "&splitName=" + DynamicRouteSplitName
    );
  };

  useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const customPreviousData = JSON.parse(
      localStorage.getItem("customPreviousData")
    );
    const questionData = "";
    const vehicleDetails = JSON.parse(localStorage.getItem("vehicleDetails"));
    (async () => {
      const response = await getUserInfo(customUuid);
      const bank_name = null;
      setbank(bank_name);
      //localStorage.setItem('question_168', bank_name);
      let dataLength = response.data.response.length;
      if (dataLength > 0) {
        const title = response.data.response[0].title
          ? response.data.response[0].title
          : "";
        const userId = response.data.response[0].id
          ? response.data.response[0].id
          : "";
        const fName = response.data.response[0].first_name
          ? Capitalize(response.data.response[0].first_name)
          : "";
        const lName = response.data.response[0].last_name
          ? Capitalize(response.data.response[0].last_name)
          : "";
        const phone = response.data.response[0].telephone
          ? response.data.response[0].telephone
          : "";
        const userMail = response.data.response[0].email
          ? response.data.response[0].email
          : "";
        const usersign = response.data.response[0].signature_image
          ? response.data.response[0].signature_image
          : "";
        const usersigndate = response.data.response[0].created_at
          ? response.data.response[0].created_at
          : "";
        const userdob = response.data.response[0].user_dob
          ? response.data.response[0].user_dob
          : "";
        const questions = response.data.response[0].questionnaire
          ? response.data.response[0].questionnaire
          : "";
        const agreementNo = response.data.response[0].agreement_no
          ? response.data.response[0].agreement_no
          : "";
        const agreementType = response.data.response[0].agreement_type
          ? response.data.response[0].agreement_type
          : "";
        const agreementTerm = response.data.response[0].agreement_term
          ? response.data.response[0].agreement_term
          : "";
        const keeper_year = response.data.response[0].keeper_year
          ? response.data.response[0].keeper_year
          : "";
        const carRegNo = response.data.response[0].car_reg_no
          ? response.data.response[0].car_reg_no
          : "";
        const vehicleMake = response.data.response[0].vehicle_make
          ? response.data.response[0].vehicle_make
          : "";
        const vehicleModel = response.data.response[0].vehicle_model
          ? response.data.response[0].vehicle_model
          : "";
        let addressLine = "";
        let town = "";
        let country = "";
        let postcode = "";
        let county = "";
        let addressLine2 = "";
        let addressLine3 = "";
        if (response.data.response[0].address_line1 !== "") {
          addressLine = response.data.response[0].address_line1
            ? response.data.response[0].address_line1
            : "";
          addressLine2 = response.data.response[0].address_line2
            ? response.data.response[0].address_line2
            : "";
          addressLine3 = response.data.response[0].address_line3
            ? response.data.response[0].address_line3
            : "";
          town = response.data.response[0].town
            ? response.data.response[0].town
            : "";
          country = response.data.response[0].country
            ? response.data.response[0].country
            : "";
          postcode = response.data.response[0].postcode
            ? response.data.response[0].postcode
            : "";
          county = response.data.response[0].county
            ? response.data.response[0].county
            : "";
        }
        if (response.data.response[0].previous_address) {
          let prevaddressLine1 = response.data.response[0].previous_address
            .address_line1
            ? response.data.response[0].previous_address.address_line1
            : "";
          let prevaddressLine3 = response.data.response[0].previous_address
            .address_line3
            ? response.data.response[0].previous_address.address_line3
            : "";
          let prevtown = response.data.response[0].previous_address.town
            ? response.data.response[0].previous_address.town
            : "";
          let prevcountry = response.data.response[0].previous_address.country
            ? response.data.response[0].previous_address.country
            : "";
          let prevpostcode = response.data.response[0].previous_address.postcode
            ? response.data.response[0].previous_address.postcode
            : "";
          let prevcounty = response.data.response[0].previous_address.county
            ? response.data.response[0].previous_address.county
            : "";
          setPrevAddressLine1(prevaddressLine1);
          setPrevTown(prevtown);
          setPrevCountry(prevcountry);
          setPrevPostcode(prevpostcode);
          setPrevCounty(prevcounty);
          setPrevAddressLine3(prevaddressLine3);
        }
        const bankname = response.data.response[0].bank_name
          ? response.data.response[0].bank_name
          : null;
        var previousName = response.data.response[0].previous_name
          ? response.data.response[0].previous_name
          : "";
        setBankName(bankname);
        setUserId(userId);
        setTitle(title);
        setfName(fName);
        setlName(lName);
        setaddressLine(addressLine);
        setaddressLine2(addressLine2);
        setaddressLine3(addressLine3);
        setTown(town);
        setKeeperDateYear(keeper_year);
        console.log({questionData})
        if (questionData) {
          console.log({len: Object.keys(questionData).length < 12})
          if (Object.keys(questionData).length < 12) {
            setQuestionFlag(false);
            setAnswers(questions);
          }
        } else {
          setQuestionFlag(false);
          setAnswers(questions);
        }
        setCountry(country);
        setPostcode(postcode);
        setCounty(county);
        setUserSign(usersign);
        setUserSigndate(usersigndate);
        setUserDob(userdob);
        setUserPhone(phone);
        setUserMail(userMail);
        setPrevName(previousName);
        let today = new Date();
        let timeNow =
          String(today.getDate()).padStart(2, "0") +
          "/" +
          String(today.getMonth() + 1).padStart(2, "0") +
          "/" +
          today.getFullYear();
        setCurrentDate(timeNow);
        setAgreementNo(agreementNo);
        setAgreementType(agreementType);
        setAgreementTerm(agreementTerm);
        setCarRegNo(carRegNo);
        setVehicleMake(vehicleMake);
        setVehicleModel(vehicleModel);
      }
      if (location.pathname == "/0602UFMB_A1_6/preview") {
        const previewstats = await saveAgreeTermsIngestion(
          userId,
          customUuid,
          "preview_load",
          1,
          "live"
        );
      }
    })();
    if (formdata) {
      const fName = formdata.txtFName ? Capitalize(formdata.txtFName) : "";
      const lName = formdata.txtLName ? Capitalize(formdata.txtLName) : "";
      const title = formdata.lstSalutation
        ? Capitalize(formdata.lstSalutation)
        : "";
      const addressLine = formdata.txtHouseNumber
        ? formdata.txtHouseNumber
        : "";
      const addressLine2 = formdata.txtAddress2 ? formdata.txtAddress2 : "";
      const addressLine3 = formdata.txtStreet ? formdata.txtStreet : "";
      const town = formdata.txtTown ? formdata.txtTown : "";
      const country = formdata.txtCountry ? formdata.txtCountry : "";
      const postcode = formdata.txtPostCode ? formdata.txtPostCode : "";
      const county = formdata.txtCounty ? formdata.txtCounty : "";
      const usersign = formdata ? formdata.signature_data : "";
      const userdob = formdata
        ? formdata.DobDay + "/" + formdata.DobMonth + "/" + formdata.DobYear
        : "";
      const phone = formdata.txtPhone ? formdata.txtPhone : "";
      const userMail = formdata.txtEmail ? formdata.txtEmail : "";
      const previousName = formdata.txtPreName ? formdata.txtPreName : "";
      let today = new Date();
      let timeNow =
        String(today.getDate()).padStart(2, "0") +
        "/" +
        String(today.getMonth() + 1).padStart(2, "0") +
        "/" +
        today.getFullYear();
      setCurrentDate(timeNow);
      setfName(fName);
      setlName(lName);
      setTitle(title);
      setaddressLine(addressLine);
      setaddressLine2(addressLine2);
      setaddressLine3(addressLine3);
      setTown(town);
      setCountry(country);
      setPostcode(postcode.toUpperCase());
      setCounty(county);
      setUserSign(usersign);
      setUserDob(userdob);
      setUserPhone(phone);
      setUserMail(userMail);
      setPrevName(previousName);
    }
    if (customPreviousData) {
      const prevaddressLine1 = customPreviousData.previous_address_line1
        ? customPreviousData.previous_address_line1
        : "";
      const prevpostcode = customPreviousData.previous_address_postcode
        ? customPreviousData.previous_address_postcode
        : "";
      const prevtown = customPreviousData.previous_address_town
        ? customPreviousData.previous_address_town
        : "";
      const prevcountry = customPreviousData.previous_address_country
        ? customPreviousData.previous_address_country
        : "";
      const prevcounty = customPreviousData.previou_address_county
        ? customPreviousData.previou_address_county
        : "";
      const prevaddressLine3 = customPreviousData.previous_address_line3
        ? customPreviousData.previous_address_line3
        : "";
      setPrevAddressLine1(prevaddressLine1);
      setPrevAddressLine3(prevaddressLine3);
      setPrevPostcode(prevpostcode);
      setPrevTown(prevtown);
      setPrevCountry(prevcountry);
      setPrevCounty(prevcounty);
    }
    if (questionData) {
      if (Object.keys(questionData).length >= 12) {
        setQuestionFlag(false);
        setAnswers(questionData);
      }
    }
    if (vehicleDetails) {
      const question21 = vehicleDetails.your_vehicle
        ? vehicleDetails.your_vehicle
        : "";
      var selectk_keep_date = vehicleDetails.selecte_keeper_date;
      var keeper_date_year = selectk_keep_date.split(" ")[2];
      setKeeperDateYear(keeper_date_year);
      setQuestion21(question21);
    }

    // const question_168 = localStorage.getItem('question_168');
    // const bank_name = questionnaire?.[16].options[question_168].label
    // setbank(bank_name);

  }, []);
  function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  console.log({answers})
  return (
    <>
      <Helmet>
        <title>Moneybarn</title>
      </Helmet>

      <div className={`moneybarn_preview  ${show}`} ref={signref_5}>
        <div className="container-fluid">
          <section className={`privacy my-3 ${hideDiv3}`} id="myDiv3">
            <div className="px-lg-5 m-3">
              <div className="row m-0 spl_font">
                <div id="sec-1">
                  <div className="col-lg-12 col-12 p-0 contentsection pt-0">
                    <h2 className="text-center title">
                      Service Summary : Moneybarn Limited Trading as Moneybarn
                    </h2>
                    <p className="text-center ">This sets out the information we are required (by the Financial Conduct Authority) to summarise on one page.</p>
                    <div className="table-responsive">
                      <table className="table table-bordered  border-secondary mt-3 ">
                        <tbody>
                          <tr>
                            <td>
                              <h5 className="text-center ">Services</h5>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              We, Allegiant Finance Services Limited, will provide our claims management service to advise on and investigate
                              your complaint and, if appropriate, make a claim against the lender on your behalf. When providing our service, we
                              will gather relevant information from you and your lender to investigate the merits of your complaint and make a
                              claim. We will keep you updated on the progress of your claim by email, letter or text message, by phone, or
                              through our website portal. We will update you when we make your claim when the lender provides a response,
                              and whenever there is an important development. If the matter is referred to the Financial Ombudsman Service, we
                              will update you when they acknowledge the complaint and whenever there is an important development. We will
                              also update you when required by regulation.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h5 className="text-center">
                                Your obligations
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              You must give us all the information and documents we need throughout the claims process to manage your claim.
                              This includes details of your financial circumstances at the time you took out the loan or credit and your lending
                              history.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h5 className="text-center">Our success fee</h5>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Our success fee will become payable when your lender makes a compensation payout.<br />
                              Our success fee will be based on which band your compensation payout falls into and will be the amount calculated
                              using the percentage rate of charge for that band but limited to that band’s maximum charge..
                              {/* <p>
                                If successful, your fee will be calculated based
                                on which band your redress falls into and will
                                be charged by whichever is the lowest of:
                              </p> */}
                              {/* <ol type="a" className="px-5 mb-2">
                                <li>
                                  {" "}
                                  the maximum percentage rate of charge for that
                                  band, or
                                </li>
                                <li>
                                  {" "}
                                  the maximum total charge for that band.
                                </li>
                              </ol>
                              The below table outlines the redress bands, the
                              maximum percentage rate of charge and the maximum
                              total charge is for each band */}
                              <div className="table-responsive">
                                <table className=" table table-bordered border-secondary  text-center ">
                                  <tbody>
                                    <tr>
                                      <td>Band</td>
                                      <td>Compensation Payout</td>
                                      <td>Percentage rate of charge(including VAT)</td>
                                      <td>Maximum charge (including VAT)</td>
                                      {/* <td>Maximum charge (without VAT) (£)</td> */}
                                    </tr>
                                    <tr>
                                      <td>1</td>
                                      <td>£1 to £1,499</td>
                                      <td>36%</td>
                                      <td>504</td>
                                      {/* <td>420</td> */}
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>£1,500 to £9,999</td>
                                      <td>33.6%</td>
                                      <td>3000</td>
                                      {/* <td>2500</td> */}
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>£10,000 to £24,999</td>
                                      <td>30%</td>
                                      <td>6000</td>
                                      {/* <td>5000</td> */}
                                    </tr>
                                    <tr>
                                      <td>4</td>
                                      <td>£25,000 to £49,999</td>
                                      <td>24%</td>
                                      <td>9000</td>
                                      {/* <td>7500</td> */}
                                    </tr>
                                    <tr>
                                      <td>5</td>
                                      <td>£50,000 or more</td>
                                      <td>18%</td>
                                      <td>12000</td>
                                      {/* <td>10000</td> */}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <p> Examples of low and high compensation payouts and the associated success fees are shown in the table below. </p>
                              <table className=" table table-bordered border-secondary  text-center" style={{ textAlign: "center" }}>
                                <tbody>
                                  <tr>
                                    <td> </td>
                                    <td colSpan={2}> Lower example </td>
                                    <td colSpan={2}> Higher example</td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "7%" }}> Band</td>
                                    <td style={{ width: "25%" }}> Compensation Payout </td>
                                    <td style={{ width: "25%" }}> Our success fee</td>
                                    <td style={{ width: "22%" }}> Compensation Payout</td>
                                    <td style={{ width: "21%" }}>
                                      {" "}
                                      Our success <br />
                                      fee
                                    </td>
                                  </tr>
                                  <tr>
                                    <td> 1 </td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £100</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £36</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £1,499 </td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £504</td>
                                  </tr>
                                  <tr>
                                    <td> 2</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £1,600</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £537.60</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £9,999</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £3,000</td>
                                  </tr>
                                  <tr>
                                    <td> 3</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £12,000 </td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £3,600</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £24,999 </td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £6,000</td>
                                  </tr>
                                  <tr>
                                    <td> 4</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £30,350 </td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £7,284</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £49,999</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £9,000</td>
                                  </tr>
                                  <tr>
                                    <td> 5</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £55,000 </td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £9,900</td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}> £100,000 </td>
                                    <td style={{ fontWeight: "bold", textAlign: "left" }}>£12,000</td>
                                  </tr>
                                </tbody>
                              </table>

                              {/* <p className="mb-2">
                                If you owe your lender money (e.g., because you
                                are in arrears or have an active loan), they may
                                use some or all of your compensation to reduce
                                what you owe them. If this happens, our success
                                fee will be calculated on the actual cash in
                                hand compensation that you receive, for example:
                              </p>
                              <ol className="px-4 mb-2">
                                <li>
                                  You recover £1,000 but owe the lender £800 for
                                  an outstanding loan, so receive £200 cash in
                                  hand. Our fee would be 36% inc. VAT of £200
                                  which is £72.
                                </li>
                                <li>
                                  You recover £8,000 but owe the lender £2,000
                                  for an outstanding loan, so receive £6,000
                                  cash in hand. Our fee would be 33.6% inc. VAT
                                  of £6,000 which is £2,016.
                                </li>
                                <li>
                                  You recover £21,000 but owe the lender £9,000
                                  for an outstanding loan, so receive £12,000
                                  cash in hand. Our fee would be 30% inc. VAT of
                                  £12,000 which is £3,600.
                                </li>
                                <li>
                                  You recover £40,000 but owe the lender £10,000
                                  for an outstanding loan, so receive £30,000
                                  cash in hand. Our fee would be 24% inc. VAT of
                                  £30,000 which is £7,200.
                                </li>
                                <li>
                                  {" "}
                                  You recover £70,000 but owe the lender £17,000
                                  for an outstanding loan, so receive £53000
                                  cash in hand. Our fee would be 18% inc. VAT of
                                  £53,000 which is £9,540.
                                </li>
                              </ol>
                              <p>
                                You would pay us the fee once you receive your
                                cash in hand benefit from your lender, and your
                                outstanding loan will also have been paid off at
                                no additional charge.
                              </p> */}
                              <p>
                                <br />
                                <p>Please note that the examples in the tables are for illustration purposes only. They are not an estimate of the
                                  likely outcome or success fee. </p>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h5 className="text-center">Cancellation</h5>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p> You can cancel for free within 14 days (cooling off) </p>
                              <p>
                                After 14 days, you can cancel for free but will need to pay us a success fee
                                if you have received a settlement offer which you later accept (or beat).
                              </p>{" "}
                              <p>
                                The success fee would not be due until you receive a compensation payout. You can cancel by phoning 0345
                                544 1563, emailing{" "}
                                <a href="#">
                                  helpdesk@allegiant-finance.co.uk;
                                </a>{" "}
                                or writing to Allegiant Finance Services Limited, Freepost
                                RTYU–XUTZ–YKJC, 400 Chadwick House, Warrington Road, Birchwood Park, Warrington, WA3 6AE, or online
                                at
                                <a
                                  target="_blank"
                                  href="https://allegiant.co.uk/compliance/cancellation"
                                >
                                  {" "}
                                  https://allegiant.co.uk/compliance/cancellation.
                                </a>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h5 className="text-center">
                                Claiming for yourself
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              You do not need to use a claims management company to make a complaint against your lender, and if your claim
                              is not successful, you can refer it to the Financial Ombudsman Service yourself for free.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className="mb-5">
                      We are a claims management company authorised and regulated by the Financial Conduct Authority, with firm
                      reference number 836810.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={`privacy my-1 ${showDiv4}`} id="myDiv4">
            <div className="px-lg-5 m-0">
              {/* <div className="row m-0 px-5 spl_font">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 logosec my-2">
                  <div className="text-lg-end text-md-end text-sm-center text-center">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/logo.png"
                      alt=""
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div className="leftsec">
                    <p className="text-secondary">
                      <span className="fw-bold mb-1 underline-text">
                        Private &amp; Confidential
                      </span>
                      <br />
                    </p>
                    <p className="mt-2">
                      {title} {fName} {lName}
                      <br />
                      {addressLine ? (
                        <>
                          {addressLine}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {addressLine2 ? (
                        <>
                          {addressLine2}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {addressLine3 ? (
                        <>
                          {addressLine3}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {town ? (
                        <>
                          {town}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {country ? (
                        <>
                          {country}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {postcode ? (
                        <>
                          {postcode}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
                <input
                  type="hidden"
                  name="strThankyouPage"
                  id="strThankyouPage"
                  value="https://dev.allegiant.claims/web/upsell-thankyou?user_id=3659&amp;visitor_id=5806&amp;vu_id=NTgwNl89XzM2NTk"
                />
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-end">
                  <div className="rightsec fw-bold">
                    <p className="mb-0 ">Allegiant Finance Services Ltd</p>
                    <p className="mb-0 ">FREEPOST RTYU-XUTZ–YKJC</p>
                    <p className="mb-0">
                      400 Chadwick House, Warrington Road, Birchwood Park
                    </p>
                    <p>WARRINGTON WA3 6AE</p>
                    <p className="mb-0">Telephone 0345 544 1563</p>
                    <p className="mb-0">
                      Email helpdesk@allegiant-finance.co.uk
                    </p>
                    <p>Website: www.allegiant.co.uk</p>
                    <p className="mb-0 text-secondary">Contract ID: {userId}</p>
                    <p className="mb-0 text-secondary">
                      Pack generated on:{currentDate}
                    </p>
                  </div>
                </div>
              </div>


              <div className="col-lg-12 mainsection">
                <h2 className="mb-2  title">Your Car Finance Claims Pack</h2>
                <p className="greentext mb-0">
                  <b>
                    Your claims against: Moneybarn Limited Trading as
                    Moneybarn
                  </b>
                </p>
                <p>
                  Thank you for enquiring about our Unaffordable Lending
                  claims service. We claim against a wide variety of lenders
                  across payday, short term loan, doorstep loan, guarantor
                  loan and car finance products for consumers who feel their
                  loans are unaffordable.
                </p>
                <p className="greentext mb-0">
                  <b>What does “unaffordable” mean?</b>
                </p>
                <p>
                  Broadly, if repaying your loan has ever caused you undue
                  difficulty getting through the month after the lender has
                  been paid, there is the potential that the loan was
                  mis-sold. Lenders have a duty to lend responsibly and to
                  conduct proportionate affordability checks before lending to
                  you. We mean ‘loan’ as any contractual agreement where you
                  have borrowed money.
                </p>
                <p className="greentext mb-0">
                  <b>Why us?</b>
                </p>
                <p>
                  We are a fully authorised FCA claims management company
                  specialising in unaffordable lending complaints. We have
                  successfully represented thousands of customers and
                  reclaimed millions on their behalf.
                </p>
                <p className="greentext mb-0">
                  <b>What’s enclosed?</b>
                </p>
                <p className="mb-0">
                  So that we can get started, you should read, check and
                  ensure you understand the information in this pack. If any
                  of your details are incorrect or you need assistance
                  understanding any aspect of our service, please contact us
                  on 0345 544 1563 or email helpdesk@allegiant-finance.co.uk.
                  Within this pack you will find:
                </p>
                <ul>
                  <li>Pre Contract Information</li>
                  <li>Terms of Engagement</li>
                  <li>Financial Ombudsman Complaint form</li>
                  <li>Letter of Authority</li>
                </ul>
                <p>
                  So that we can get started, you should read, check and
                  ensure you understand the information in this pack. If any
                  of your details are incorrect or you need assistance
                  understanding any aspect of our service, please contact us
                  on 0345 544 1563 or email helpdesk@allegiant-finance.co.uk.
                </p>
                <p className="greentext mb-0">
                  <b>Getting started</b>
                </p>
                <p className="mb-0">
                  Once you are ready to claim with us, simply sign where
                  prompted. Once you have signed up with us, we’ll
                  automatically email you to explain the next steps.
                </p>
                <p>We look forward to assisting you</p>
              </div>
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-5 col-12 bannersec">
                  <p className="mb-0">Yours faithfully</p>
                  <img
                    src="https://dev.allegiant.claims/assets/moneybarn_preview/img/sign.png"
                    alt=""
                  />
                  <p>
                    <b>Allegiant Finance Services Ltd</b>
                  </p>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-7 col-12 rightsec text-end mt-5">
                  <p className="smlfnt">
                    <b>© Allegiant Finance Services Limited</b> <br />
                    A Limited Company Registered in England and Wales: No.
                    07474972 | Registered Office: 400
                    <br />
                    Chadwick House, Birchwood Park, Warrington, Cheshire, WA3
                    6AE <br />
                    A claims management company that is Authorised and |
                    Regulated by the Financial Conduct
                    <br />
                    Authority. Firm Reference Number: 836810. <br />
                    Registered with the Information Commissioner’s Office:
                    Z2569335. <br />
                    All our materials and processes are protected from imitation
                    by law.
                  </p>
                </div>
              </div> */}

              <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                <h2 className="text-center title">
                  Pre-Contract Information
                </h2>
                {/* <p className="greentext mb-0">
                    <b>A. Who we are</b>
                  </p> */}
                <br />
                <p className="mb-0">
                  We are Allegiant Finance Services. We’re a claims management company. So you know, we’re regulated by the
                  Financial Conduct Authority. You can check our details at https://register.fca.org.uk.
                </p>
                {/* <p className="mb-0">
                    <b>Post:</b> Allegiant Finance Services, 400 Chadwick House,
                    Birchwood Park, Warrington, Cheshire, WA3 6AE.
                  </p>
                  <p className="mb-0">
                    <b>Telephone:</b> 0345 544 1563
                  </p>
                  <p className="mb-0">
                    <b>Email:</b>
                    <a href="#"> helpdesk@allegiant-finance.co.uk</a>
                  </p>
                  <p className="mb-3">
                    We are authorised and regulated by the Financial Conduct
                    Authority. Firm Reference Number: 836810
                  </p> */}
                <p className="greentext mb-0">
                  <b>What we do</b>
                </p>
                <p>
                  We provide a claims management service to customers who tell us that they were given loans or credit (we’ll call this “credit” for short) that they could not properly afford.
                </p>
                <p>
                  We gather information about a customer’s financial situation. If we think that a lender shouldn’t have lent to a customer (because they did not follow the guidance in place about affordable lending), we will make a complaint on behalf of our customer.
                </p>
                <p>When credit is mis-sold, we aim to get back the interest and charges our customer paid to a lender.</p>
                <p>
                  If a lender disagrees with the complaint and their response is unacceptable, we can ask the Financial Ombudsman Service to make an independent decision.
                </p>
                <p>
                  To make you aware - the Financial Ombudsman Service (also known as ‘FOS’ for short) - is an independent organisation that independently reviews disputes between borrowers and lenders (they also deal with other complaints too).
                </p>
                <p>
                  Where a customer and lender disagree on the correct outcome of a complaint, FOS can decide what should happen based on what they think should be a fair and reasonable outcome.
                </p>
                <p>
                  FOS will make a written decision – there are no hearings like a legal matter in court. You can learn more about FOS at <a href="https://www.financial-ombudsman.org.uk" target="_blank">https://www.financial-ombudsman.org.uk</a>.
                </p>
                <p className="greentext mb-0">
                  <u>Should I make a claim?</u>
                </p>
                <h5>
                  Things you might consider negative
                </h5>
                <ul>
                  <li>You may not win, resulting in lost time.</li>
                  <li>
                    You may feel uncomfortable giving or discussing your financial history with us (however, our team are very familiar with unaffordable lending issues – you’re in good hands).
                  </li>
                  <li>
                    Your lender may refuse to lend to you in the future. This may include its linked brands. Your lender may suspend or withdraw the credit you’re complaining about and any other credit you have with it whilst it investigates the complaint.
                  </li>
                  <li>
                    If your complaint relates to a guarantor loan and your guarantor has made payments, a potential outcome may be that the guarantor is refunded their payments resulting in an increased balance. If this happens, we will challenge this with the lender and/or FOS.
                  </li>
                  <li>
                    If your complaint is about a car finance agreement, and you still have your car, a potential outcome is that you may be asked to return the vehicle as part of the resolution (this would not happen if the finance agreement is already paid off).
                  </li>
                </ul>
                <p>
                  These are things we suggest you think about, but you should think about what claiming means for your situation.
                </p>
                <p className="greentext mb-0">
                  <u>A quick word about the cost of using us</u>
                </p>
                <p>
                  If you are paid a compensation payout because of a claim we make for you, we
                  will charge you a fee for our service. If you don’t receive a compensation
                  payout, you don't pay us anything.
                </p>
                <p>
                  Our charges start at 36%, including VAT and go as low as 18%, including VAT,
                  for bigger claims. You can learn more about our fees later in this document.
                  For now, the important bit we want you to take away is that we offer a
                  service you pay for, but only where your lender makes a compensation payout.
                </p>
                <h4 style={{ color: "#2b8e0a" }}>
                  You don’t have to use a claims management company.
                </h4>
                <p>
                  As a transparent claims management company, we only want to help customers
                  who have thought about the different ways of claiming and decided to use a
                  claims management service they are happy to pay for. So, it is important we
                  tell you this:-
                </p>
                <p>
                  <b>
                    You do not need to use a claims management company to make your complaint
                    to your lender. If your complaint is unsuccessful, you can refer it to the
                    Financial Ombudsman Service for free.
                  </b>
                </p>
                <p>
                  If you decide that you want to use a claims management company, it is always
                  a good idea to compare different companies to work out the best fit for you.
                </p>
                <p>
                  Another way of claiming is by using a solicitor. Solicitors differ from
                  claims management companies as solicitors can bring claims in court (a more
                  formal process than FOS). They can also use FOS too. If this interests you,
                  we recommend that you seek independent legal advice from a solicitor. If you
                  have legal expenses insurance, solicitor costs may be covered (which may
                  mean that you keep more of your money than using us).
                </p>
                <h4 style={{ color: "#2b8e0a" }}>What happens when you claim with us?</h4>
                <h5>What we will do</h5>
                <p>
                  If you decide to use our claims management service, you must sign a legally
                  binding contract with us.
                </p>
                <p>
                  Once you have a contract with us, we will get information about your
                  financial circumstances. This includes your borrowing history, before and
                  after the credit you're complaining about.
                </p>
                <p>
                  This often includes you giving us (or giving us consent to get) information
                  like:-{" "}
                </p>
                <ul>
                  <li>bank statements from when you were borrowing</li>
                  <li>your credit report</li>
                  <li>your lender’s file.</li>
                </ul>
                <p>
                  We will also use the information you provide to us in web forms or surveys.
                </p>
                <p>
                  Once we have the information we need, we will review whether your lender
                  acted fairly and reasonably when lending to you.
                </p>
                <p>
                  When we review your case, we will think about the rules relating to the type
                  of credit you had and your personal circumstances.
                </p>
                <p>
                  We will also think about decisions and guidance from the Financial Conduct
                  Authority (FCA) and Financial Ombudsman Service (FOS).
                </p>
                <p>
                  If we think your lender might have done something wrong when lending to you,
                  we will write to them explaining why we feel that their lending was not fair
                  and reasonable. We will usually ask the lender to fix this by:-
                </p>
                <ul>
                  <li>
                    giving you a refund of interest and charges you have paid on the amount of
                    the credit;
                  </li>
                  <li>
                    updating your credit records to remove details relating to the mis-sold
                    credit; and
                  </li>
                  <li>to add interest at 8% a year on payments you have already made. </li>
                </ul>
                <h5>Keeping you up to date.</h5>

                <p>
                  We will keep in touch about your claim, usually by email. We may also
                  contact you by phone or by other written methods (letter, text message or
                  website portal). This will often include telling you: -
                </p>
                <ul>
                  <li>that we have sent the claim to your lender;</li>
                  <li>that the lender has acknowledged your claim; and</li>
                  <li>
                    when we receive the lender’s final response or that we have not received a
                    response by the deadline.
                  </li>
                </ul>
                <p>
                  If we refer the matter to the Financial Ombudsman Service, we will also tell
                  you: -
                </p>
                <ul>
                  <li>when we have made the referral;</li>
                  <li>
                    when the Financial Ombudsman Service acknowledges receipt of the claim;
                    and
                  </li>
                  <li>whenever there is an important development or pause.</li>
                </ul>
                <p>
                  Sometimes there can be delays outside of our control. If this is the case,
                  we will still update you at least every 12 weeks.
                </p>
                <p>
                  <b>If we can’t sort things out with your lender directly</b>
                </p>
                <p>
                  If your lender’s response isn't good enough, we can involve the Financial
                  Ombudsman Service for an independent decision.
                </p>
                <p>
                  <b>
                    Claims are decided based on opinion, so we can't make you any promises
                    about the result
                  </b>
                </p>
                <p>
                  If we agree to make a claim for you, this does not mean the claim will be
                  won. So, it is important for you:-
                </p>
                <ul>
                  <li>to keep up payments with your lender (as best you can).</li>
                  <li>
                    not to make plans about receiving compensation and that you manage your
                    day-to-day finances without thinking about any compensation you may get in
                    the future.
                  </li>
                </ul>
                <h5>How long it will take</h5>
                <p>Typically, we would say:-</p>
                <ul>
                  <li>
                    for claims resolved directly with your lender, about 8 to 20 weeks (unless
                    the lender has a very big backlog).
                  </li>
                  <li>
                    for claims involving the Financial Ombudsman Service, cases can take a
                    further 8 to 52 weeks.
                  </li>
                </ul>
                <p>
                  These timescales are rules of thumb. Some cases can be resolved more
                  quickly, and rarely, some cases can take several years if there are very
                  complex issues.
                </p>
                <h5>Who will perform the work</h5>
                <p>
                  Our team here at Allegiant will look after your claims. The team is made up
                  of direct employees, and sometimes contractors, that we have fully trained.
                </p>
                <h4 style={{ color: "#2b8e0a" }}>Our Success Fee</h4>
                <p>
                  Our success fee is due only if your lender makes a compensation payout, and
                  is calculated on the amount of the compensation payout. We do not charge you
                  for any part of a settlement that results in the lender reducing your
                  outstanding balance – that is on the house!
                </p>
                <p>
                  The success fee amount is calculated using a band charging system. There are
                  five charging bands. Each band has a maximum amount that we will charge.
                </p>
                <h5>Success Fee Charging Table</h5>
                <table className=" table table-bordered border-secondary  text-center tablepdf" >
                  <tbody>
                    <tr>
                      <td style={{ width: "10%" }}>Band</td>
                      <td style={{ width: "15%" }}>Compensation Payout</td>
                      <td style={{ width: "25%" }}>
                        Percentage rate the Success Fee is calculated on (including VAT)
                      </td>
                      <td style={{ width: "50%" }}>
                        Maximum Success Fee in band (including VAT)
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%" }}>1</td>
                      <td style={{ width: "15%" }}>£1 to £1,499</td>
                      <td style={{ width: "25%" }}>36%</td>
                      <td style={{ width: "50%" }}>£504</td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%" }}>2</td>
                      <td style={{ width: "15%" }}>£1,500 to £9,999</td>
                      <td style={{ width: "25%" }}>33.60%</td>
                      <td style={{ width: "50%" }}>£3,000</td>
                    </tr>

                    <tr>
                      <td style={{ width: "10%" }}>3</td>
                      <td style={{ width: "15%" }}>£10,000 to £24,999</td>
                      <td style={{ width: "25%" }}>30%</td>
                      <td style={{ width: "50%" }}>£6,000</td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%" }}>4</td>
                      <td style={{ width: "15%" }}>£25,000 to £49,999</td>
                      <td style={{ width: "25%" }}>24%</td>
                      <td style={{ width: "50%" }}>£,9000</td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%" }}>5</td>
                      <td style={{ width: "15%" }}>£50,000 or more</td>
                      <td style={{ width: "25%" }}>18%</td>
                      <td style={{ width: "50%" }}>£12,000</td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ padding: "10px 0" }}>
                  Below are examples of how this would work in practice.
                </p>
                <table className="tablepdf">
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td colSpan={2}>Lower example</td>
                      <td colSpan={2}>Higher example</td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%" }}>Band</td>
                      <td style={{ width: "25%" }}>Compensation Payout</td>
                      <td style={{ width: "20%" }}>Success Fee</td>
                      <td style={{ width: "25%" }}>Compensation Payout</td>
                      <td style={{ width: "20%" }}>Success Fee</td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%" }}>1</td>
                      <td style={{ width: "25%" }}>
                        <b>£100</b>
                      </td>
                      <td style={{ width: "20%" }}>
                        <b>£36</b>
                      </td>
                      <td style={{ width: "25%" }}>
                        <b>£1,499</b>
                      </td>
                      <td style={{ width: "20%" }}>
                        <b>£504</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%" }}>2</td>
                      <td style={{ width: "25%" }}>
                        <b>£1,600</b>
                      </td>
                      <td style={{ width: "20%" }}>
                        <b>£537.60</b>
                      </td>
                      <td style={{ width: "25%" }}>
                        <b>£9,999</b>
                      </td>
                      <td style={{ width: "20%" }}>
                        <b>£3,000</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%" }}>3</td>
                      <td style={{ width: "25%" }}>
                        <b>£12,000</b>
                      </td>
                      <td style={{ width: "20%" }}>
                        <b>£3,600</b>
                      </td>
                      <td style={{ width: "25%" }}>
                        <b>£24,999</b>
                      </td>
                      <td style={{ width: "20%" }}>
                        <b>£6,000</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%" }}>4</td>
                      <td style={{ width: "25%" }}>
                        <b>£30,350</b>
                      </td>
                      <td style={{ width: "20%" }}>
                        <b>£7,284</b>
                      </td>
                      <td style={{ width: "25%" }}>
                        <b>£49,999</b>
                      </td>
                      <td style={{ width: "20%" }}>
                        <b>£9,000</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "10%" }}>5</td>
                      <td style={{ width: "25%" }}>
                        <b>£55,000</b>
                      </td>
                      <td style={{ width: "20%" }}>
                        <b>£9,900</b>
                      </td>
                      <td style={{ width: "25%" }}>
                        <b>£100,000</b>
                      </td>
                      <td style={{ width: "20%" }}>
                        <b>£12,000</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ paddingTop: 10 }}>
                  If you want to see how much we would charge for a specific amount, please
                  visit our online fee calculator at&nbsp;
                  <a href="https://allegiant.co.uk/unaffordable-lending-claim-fees" target="_blank">
                    https://allegiant.co.uk/unaffordable-lending-claim-fees.
                  </a>
                </p>
                <p>
                  Please note that the examples in the tables are for illustration purposes
                  only. They are not an estimate of the likely outcome or success fee.
                </p>
                <h4 style={{ color: "#2b8e0a" }}>Cancellation</h4>
                <h5>Cooling-off period</h5>
                <p>
                  You can cancel with us for free within a 14-day cooling-off period, which
                  starts on the day after we receive your signed terms of engagement.
                </p>
                <h5>Outside the cooling-off period</h5>
                <p>
                  After the 14-day cooling-off period, you can still cancel. You will not need
                  to pay a cancellation fee, but you will still have to pay a success fee
                  calculated on any offer we obtain for you and where you later accept that
                  offer or beat it. Where this happens, our success fee will be calculated
                  based on the offer we obtained.
                </p>
                <h4 style={{ color: "#2b8e0a" }}>Complaints</h4>
                <p>
                  If you need to make a complaint, you can do this by letter, email or phone,
                  or any other reasonable way.
                </p>
                <p style={{ paddingTop: 10 }}>
                  We will aim to acknowledge your complaint within five working days.
                </p>
                <p style={{ paddingTop: 10 }}>
                  We will also send you a link to the process we will follow.
                </p>
                <p style={{ paddingTop: 10 }}>
                  We will then write to you with a final response or to tell you that we are
                  not in a position to give you a final response within eight weeks.
                </p>
                <p style={{ paddingTop: 10 }}>
                  In either case, we will tell you about your right to refer the matter to the
                  Financial Ombudsman Service.
                </p>
                <p style={{ paddingTop: 10 }}>
                  For full details, please see the complaints section of the terms of
                  engagement or go to&nbsp;
                  <a href="https://allegiant.co.uk/compliance/complaints-regulated/" target="_blank">
                    allegiant.co.uk/compliance/complaints-regulated/.
                  </a>
                </p>
                <h4 style={{ color: "#2b8e0a" }}>Privacy</h4>
                <p>
                  Our privacy policy is available at&nbsp;
                  <a href="https://allegiant.co.uk/compliance/privacy-policy/" target="_blank">
                    allegiant.co.uk/compliance/privacy-policy/.
                  </a>
                </p>
                <section style={{ maxWidth: 800, margin: "0 auto" }}>
                  {/* Page One */}
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ padding: "0 8px" }}>
                          <h1
                            style={{
                              marginBottom: 0,
                              marginTop: 10,
                              fontSize: 28,
                              fontWeight: "bold",
                              color: "#312783",
                              textAlign: "center"
                            }}
                          >
                            {" "}
                            Terms of Engagement
                          </h1>
                          <table
                            className="border tablepdf"
                            style={{
                              width: "74%",
                              margin: "auto",
                              fontWeight: "bold",
                              marginTop: 12
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    padding: 9,
                                    fontSize: 11,
                                    color: "#5d646f",
                                    width: "40%"
                                  }}
                                >
                                  {" "}
                                  <b>Issue Date</b>{" "}
                                </td>
                                <td style={{ padding: 9 }}>

                                  {currentDate}

                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: 9, color: "#5d646f" }}>
                                  {" "}
                                  <b> Contract ID:</b>
                                </td>
                                <td style={{ padding: 9 }}>

                                  {userId}

                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: 9, color: "#5d646f" }}>
                                  {" "}
                                  <b>Lender</b>
                                </td>
                                <td style={{ padding: 9 }}>

                                  {bankName}

                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <p style={{ marginTop: 7 }}>
                            Signing this document makes a legally binding contract between you
                            and Allegiant Finance Services Limited.
                          </p>
                          <br />
                          <p>
                            {" "}
                            If you change your mind about using our service, you have a 14-day
                            cooling-off period during which you can cancel the contract without
                            charge (see below).
                          </p>
                          <br />
                          <p>
                            When you enter into the contract, you are confirming that you have
                            read our pre-contract information and the chance to read our Privacy
                            Policy at allegiant.co.uk/compliance/privacy-policy. You are also
                            confirming that you are aware that:
                          </p>
                          <ul style={{ paddingLeft: 10 }}>
                            <li style={{ paddingLeft: 16, marginTop: 8 }}>
                              {" "}
                              we charge for our service;
                            </li>
                            <li style={{ paddingLeft: 16, marginTop: 8 }}>
                              you do not need to use a claims management company to make your
                              claim and that if your claim is unsuccessful, you can refer your
                              complaint to the Financial Ombudsman Service
                              (financial-ombudsman.org.uk) for free
                            </li>
                          </ul>
                          <h5 style={{ color: "#4e7f36", marginBottom: 4 }}>
                            {" "}
                            1. What we mean
                          </h5>
                          <p> In this contract:- </p>
                          <ul style={{ paddingLeft: 10 }}>
                            <li style={{ paddingLeft: 16, marginTop: 8 }}>
                              {" "}
                              where we say ‘you’, we mean the person who took out the loan or
                              credit and who is appointing us to make a claim or a person who is
                              authorised to act for that person and is entering into this
                              contract on their behalf;
                            </li>
                            <li style={{ paddingLeft: 16, marginTop: 8 }}>
                              where we say ‘lender’, we mean the credit provider, anyone who
                              legally takes over its rights, whose rights it legally took over,
                              or who is authorised to act for it (including any insolvency
                              practitioner who may be appointed from time to time);
                            </li>
                            <li style={{ paddingLeft: 16, marginTop: 8 }}>
                              when we say ‘our service’, we mean the claims management service
                              we provide to you to claim compensation from your lender. This
                              service generally includes providing advice, investigating the
                              facts of your complaint, making a claim and negotiating a
                              settlement, and other related activities;
                            </li>
                            <li style={{ paddingLeft: 16, marginTop: 8 }}>
                              when we say “compensation payout”, this is the money the lender
                              transfers to you or us in response to our complaint
                            </li>
                            <li style={{ paddingLeft: 16, marginTop: 8 }}>
                              When we say ‘settlement offer’ we mean a lender offer to make a
                              compensation payout to you or a recommendation or decision made by
                              the Financial Ombudsman Service (or other body with the power to
                              make settlement recommendations).
                            </li>
                          </ul>
                          <h5 style={{ color: "#4e7f36", marginBottom: 12, fontSize: 12 }}>
                            2. What we will do{" "}
                          </h5>
                          <p>
                            2.1 We will take reasonable skill and care when providing our
                            service.{" "}
                          </p>
                          <p style={{ marginTop: 10 }}>
                            2.2 When you have given us the information and documents and where
                            we think your lender might have mis-sold credit to you, we will
                            draft the complaint and send it to them (your claim). The claim will
                            allege potential or actual mis-selling of the credit product the
                            lender gave to you{" "}
                          </p>
                          <p style={{ marginTop: 10 }}>
                            2.3 If the lender does not reply or disagrees with your claim, we
                            will consider the information again. Where reasonable, we will send
                            your claim to the Financial Ombudsman Service (or a similar service
                            if appropriate – but we will discuss this with you first).
                          </p>
                          <p style={{ marginTop: 10 }}>
                            2.4 We will keep in touch during your claim. We will do this by one
                            or more of the following: email, letter, text message or <br />{" "}
                            phone or through our website portal.{" "}
                          </p>
                          <p style={{ marginTop: 10 }}>
                            2.5 We will let you know when your claim has key developments. This
                            can include:-{" "}
                          </p>
                          <ul>
                            <li style={{ paddingLeft: 12 }}>
                              when a lender
                              <ul>
                                <li style={{ paddingLeft: 12 }}>
                                  {" "}
                                  acknowledges your claim
                                </li>
                                <li style={{ paddingLeft: 12 }}>
                                  {" "}
                                  asks for more information
                                </li>
                                <li style={{ paddingLeft: 12 }}>
                                  {" "}
                                  makes a final decision.
                                </li>
                              </ul>
                            </li>
                            <li style={{ paddingLeft: 12 }}>
                              If your claim is referred to the Financial Ombudsman Service (or
                              similar)
                              <ul>
                                <li style={{ paddingLeft: 12 }}>
                                  when we make the referral{" "}
                                </li>
                                <li style={{ paddingLeft: 12 }}>
                                  {" "}
                                  when they acknowledge it
                                </li>
                                <li style={{ paddingLeft: 12 }}>
                                  {" "}
                                  whenever there is an important development.
                                </li>
                              </ul>
                            </li>
                          </ul>
                          {/* End Table  */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* End Page One */}
                </section>
                <>
                  <p>
                    2.6 We will take instructions from you when necessary and always about any
                    settlement offer (an offer of compensation from the lender or a
                    recommendation or decision made by the Financial Ombudsman Service or
                    another organisation authorised to award compensation).
                  </p>
                  <p>
                    2.7 We will assume that account information and calculations provided by the
                    lender are the best evidence of lending, are accurate, are not misleading,
                    and are not provided in bad faith unless you provide evidence to cast
                    reasonable doubt on the accuracy of the information and calculations.
                  </p>
                  <p>
                    2.8 We will not provide financial or debt advice as we are authorised and
                    regulated only to provide claims-management services.
                  </p>
                  <h5 style={{ color: "#2b8e0a" }}>3. What we can expect from you</h5>
                  <p>3.1 You confirm that you either:</p>
                  <ul>
                    <li>have not made the same claim before; or</li>
                    <li>have told us about any previous claim.</li>
                  </ul>
                  <p>
                    3.2 You agree to send us all relevant correspondence and documents as soon
                    as reasonably possible.
                  </p>
                  <p>
                    3.3 You agree that we are the only firm or person providing representation
                    to you, and while the contract between you and us is in force, you will not:
                  </p>
                  <ul>
                    <li>
                      enter into a contract with another representative in connection with your
                      claim; or
                    </li>
                    <li>try to make a claim separately.</li>
                  </ul>
                  <p>
                    3.4 You agree not to contact the lender, the Financial Ombudsman Service or
                    any other relevant third party to withdraw our authority to provide our
                    service without first cancelling your contract with us.
                  </p>
                  <p>
                    3.5 You confirm that you are not, and have never been, declared bankrupt (in
                    Scotland, been sequestered) or had an individual voluntary arrangement (IVA)
                    or debt relief order (DRO) as a result of unaffordable debt. You must tell
                    us immediately if you may enter into an arrangement relating to bankruptcy,
                    insolvency or unaffordable debt.
                  </p>
                  <p>
                    3.6 You agree to give us the information we need about your personal details
                    and financial situation as soon as reasonably possible and immediately tell
                    us about any change of information (for example, a change of address) whilst
                    in a contract with us.
                  </p>
                  <p>
                    3.7 You must fill in and sign all paperwork you must provide to give us
                    authority to make your claim.
                  </p>
                  <p>
                    3.8 You agree to give us the documentary evidence we reasonably need to
                    investigate your complaint and make your claim, including bank statements,
                    credit reports, and relevant documents and correspondence from your lender.
                  </p>
                  <p>
                    3.9 You must tell us immediately if the lender contacts you directly to
                    discuss your claim or pays you.
                  </p>
                  <p>
                    3.10 If the lender pays you directly, you must still pay our success fee
                    (see section 4).
                  </p>
                  <p>
                    3.11 You understand that making your claim may lead to the lender
                    withdrawing your access to their lending and credit facilities.
                  </p>
                  <h5 style={{ color: "#2b8e0a" }}>4. Our success fee</h5>
                  <p>
                    4.1 Our fees become due only if your lender makes a compensation payout.
                  </p>
                  <p style={{ paddingTop: 10, paddingBottom: 15 }}>
                    4.2 The success fee depends on which band your compensation payout falls
                    into and will the amount calculated using the percentage rate of charge for
                    that band, up to the maximum band charge.
                  </p>
                  <table className="tablepdf" style={{ width: "85%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "10%" }}>
                          <b>Band</b>
                        </td>
                        <td style={{ width: "23%" }}>
                          <b>Compensation Payout</b>
                        </td>
                        <td style={{ width: "32%" }}>
                          <b>
                            Percentage rate the Success Fee is calculated on (including VAT)
                          </b>
                        </td>
                        <td style={{ width: "20%" }}>
                          <b>Maximum Success Fee in band (including VAT)</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>1</td>
                        <td style={{ width: "15%" }}>£1 to £1,499</td>
                        <td style={{ width: "25%" }}>36%</td>
                        <td style={{ width: "50%" }}>£504</td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>2</td>
                        <td style={{ width: "15%" }}>£1,500 to £9,999</td>
                        <td style={{ width: "25%" }}>33.60%</td>
                        <td style={{ width: "50%" }}>£3,000</td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>3</td>
                        <td style={{ width: "15%" }}>£10,000 to £24,999</td>
                        <td style={{ width: "25%" }}>30%</td>
                        <td style={{ width: "50%" }}>£6,000</td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>4</td>
                        <td style={{ width: "15%" }}>£25,000 to £49,999</td>
                        <td style={{ width: "25%" }}>24%</td>
                        <td style={{ width: "50%" }}>£9,000</td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>5</td>
                        <td style={{ width: "15%" }}>£50,000 or more</td>
                        <td style={{ width: "25%" }}>18%</td>
                        <td style={{ width: "50%" }}>£12,000</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>Below are examples of how this would work in practice.</p>
                  <table className="tablepdf">
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td colSpan={2}>Lower example</td>
                        <td colSpan={2}>Higher example</td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>Band</td>
                        <td style={{ width: "25%" }}>Compensation payout</td>
                        <td style={{ width: "20%" }}>Our success fee</td>
                        <td style={{ width: "25%" }}>Compensation payout</td>
                        <td style={{ width: "20%" }}>Our success fee</td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>1</td>
                        <td style={{ width: "25%" }}>£100</td>
                        <td style={{ width: "20%" }}>£36</td>
                        <td style={{ width: "25%" }}>£1,499</td>
                        <td style={{ width: "20%" }}>£504</td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>2</td>
                        <td style={{ width: "25%" }}>£1,600</td>
                        <td style={{ width: "20%" }}>£537.60</td>
                        <td style={{ width: "25%" }}>£9,999</td>
                        <td style={{ width: "20%" }}>£3,000</td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>3</td>
                        <td style={{ width: "25%" }}>£12,000</td>
                        <td style={{ width: "20%" }}>£3,600</td>
                        <td style={{ width: "25%" }}>£24,999</td>
                        <td style={{ width: "20%" }}>£6,000</td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>4</td>
                        <td style={{ width: "25%" }}>£30,350</td>
                        <td style={{ width: "20%" }}>£7,284</td>
                        <td style={{ width: "25%" }}>£49,999</td>
                        <td style={{ width: "20%" }}>£9,000</td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }}>5</td>
                        <td style={{ width: "25%" }}>£55,000</td>
                        <td style={{ width: "20%" }}>£9,900</td>
                        <td style={{ width: "25%" }}>£100,000</td>
                        <td style={{ width: "20%" }}>£12,000</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    If you want to see how much we would charge for a specific amount, please
                    visit our online fee calculator at&nbsp;
                    <a href="https://allegiant.co.uk/unaffordable-lending-claim-fees" target="_blank">
                      https://allegiant.co.uk/unaffordable-lending-claim-fees.
                    </a>
                  </p>
                  <p style={{ paddingTop: 8 }}>
                    <b>
                      Please note that the examples above are for illustration purposes only.
                      They are not an estimate of the likely outcome or the success fee you will
                      need to pay. Each claim depends on its own merits.
                    </b>
                  </p>
                  <p>
                    4.4 You agree to pay us the success fee as soon as reasonably possible after
                    the compensation payout is made by the lender, even if any other actions set
                    out in the settlement, such as the lender correcting your credit record,
                    still need to be completed. We will continue to pursue those actions on your
                    behalf, and our commitments to you will not be affected by your payment.
                  </p>
                  <p style={{ paddingTop: 8 }}>
                    4.5 If your lender makes any part payments or pays in instalments (for
                    whatever reason), we can raise a separate invoice for each compensation
                    payment you receive.
                  </p>
                  <p>
                    4.6 If we believe that you are withholding information relating to a
                    settlement offer (including details of any compensation payment made by your
                    lender), we can raise an invoice based on the previous 12 months’ aggregated
                    win statistics relating to the lender your claim is against. We will change
                    or cancel the invoice as appropriate in light of any information you provide
                    later.
                  </p>
                  <h5 style={{ color: "#2b8e0a" }}>
                    5. Compensation payout to our client account
                  </h5>
                  <p>
                    5.1 Where the&nbsp;lender&nbsp;makes a compensation
                    payout,&nbsp;we&nbsp;will hold the funds in a dedicated client account (as
                    authorised by the FCA).&nbsp;We&nbsp;will give&nbsp;you an itemised
                    breakdown of the settlement and give you notice of the invoice prior to
                    taking our success fee from it.
                  </p>
                  <p>
                    5.2&nbsp;We&nbsp;will pay you the compensation, less&nbsp;our&nbsp;success
                    fee, by bank transfer to an account&nbsp;you&nbsp;nominate.
                    If&nbsp;you&nbsp;ask for a cheque,&nbsp;we&nbsp;will only send this to an
                    address registered with&nbsp;the lender&nbsp;or&nbsp;your&nbsp;address on
                    the electoral register.&nbsp;We&nbsp;can ask you for proof of address and
                    documents we need under anti-money laundering regulations to
                    meet&nbsp;our&nbsp;fraud-prevention obligations.
                  </p>
                  <p>
                    5.3 If&nbsp;you do not nominate an account for us to transfer the
                    compensation to or&nbsp;fail to cash a cheque we send you&nbsp;after
                    we&nbsp;have made reasonable efforts to contact&nbsp;you, we reserve the
                    right to return the funds to the lender, or crown estate. We will only do
                    this in line with our regulator’s expectations about the handling of
                    unclaimed funds.
                  </p>
                  <h5 style={{ color: "#2b8e0a" }}>6. Cancellation rights</h5>
                  <p>
                    6.1 You can cancel the contract for our service, without charge, within a
                    14-day cooling-off period, which starts the day after we receive your signed
                    terms of engagement.
                  </p>
                  <p>
                    6.2 After the 14-day cooling-off period ends, you can ask us to end the
                    contract for our service. There is no cancellation fee, but you will have to
                    pay a success fee if you have received a settlement offer, regardless of
                    whether or not the compensation has been calculated or the offer yet been
                    communicated.
                  </p>
                  <ul>
                    <li>
                      If there has been no settlement offer, you will not pay a success fee.
                    </li>
                    <li>
                      If there has been a settlement offer, but you have not accepted it, you
                      will have to pay a success fee calculated using:
                      <ul style={{ listStyleType: "circle" }}>
                        <li>the highest settlement offer made; or</li>
                        <li>
                          the compensation you go on to receive without using our service;
                        </li>
                      </ul>
                    </li>
                    <p style={{ paddingLeft: 7 }}>whichever is lower.</p>
                    <li>
                      If you have accepted a settlement offer, you must pay the success fee,
                      calculated in clause 4, as usual.
                    </li>
                  </ul>
                  <p>
                    In either case, you would not need to pay the success fee until your lender
                    makes the compensation payout.
                  </p>
                  <p>6.3 You can give us notice to cancel by:</p>
                  <ul>
                    <li>
                      writing to Allegiant Finance Services Limited, Freepost RTYU–XUTZ–YKJC,
                      400 Chadwick House, Warrington Road, Birchwood Park, Warrington, WA3 6AE;
                    </li>
                    <li>emailing helpdesk@allegiant-finance.co.uk;</li>
                    <li>phoning 0345 544 1563; or</li>
                    <li>
                      going to{" "}
                      <a href="https://allegiant.co.uk/compliance/cancellation/">
                        allegiant.co.uk/compliance/cancellation/.
                      </a>
                    </li>
                  </ul>
                  <p>
                    We may ask why you are cancelling and use this information to improve our
                    service. However, you are under no obligation to tell us why you want to
                    cancel.
                  </p>
                  <p>
                    6.4 If you cancel, we will ask whether you want to withdraw your claim or
                    continue it yourself. We will then update the lender and, if necessary, the
                    Financial Ombudsman Service and any other relevant person, business or body.
                    If you are continuing the claim yourself, you are responsible for finding
                    out about relevant deadlines and steps that need to be taken.
                  </p>
                </>
                <>
                  <p>6.5 We may cancel the agreement between you and us if:</p>
                  <ul style={{ margin: 0 }}>
                    <li>we do not think your claim has merit;</li>
                    <li>your claim is uneconomical for us; or</li>
                    <li>
                      there is (or could be) a conflict of interest (that is, where acting in
                      your best interests would work against ours).
                    </li>
                  </ul>
                  <p>
                    If we cancel the service under this clause, 6.5, there will be no charge.
                  </p>
                  <h5 style={{ color: "#2b8e0a", marginBottom: 0 }}>7. Complaints</h5>
                  <p style={{ margin: 0, padding: 0 }}>7.1 You can make a complaint by:</p>
                  <ul style={{ margin: 0 }}>
                    <li>
                      writing to Allegiant Finance Services Limited, Freepost RTYU–XUTZ–YKJC,
                      400 Chadwick House, Warrington Road, Birchwood Park, Warrington, WA3 6AE;
                    </li>
                    <li>emailing allegiantcomplaints@allegiant-finance.co.uk; or</li>
                    <li>phoning 0345 544 1563.</li>
                  </ul>
                  <p style={{ marginBottom: 0, marginTop: 10 }}>
                    7.2 If you are dissatisfied with our response, or if we do not settle the
                    complaint with you after eight weeks, you can ask the Claims Management
                    Ombudsman (part of the Financial Ombudsman Service) to assess your complaint
                    independently. You can do this by:
                  </p>
                  <ul style={{ margin: 0 }}>
                    <li>
                      writing to Financial Ombudsman Service, Exchange Tower, Harbour Exchange,
                      London, E14 9SR;
                    </li>
                    <li>emailing complaint.info@financial-ombudsman.org.uk; or</li>
                    <li>phoning 0800 023 4567.</li>
                  </ul>
                  <p style={{ marginTop: 10 }}>
                    There is further information about the Claims Management Ombudsman available
                    at https://cmc.financial-ombudsman.org.uk/).
                  </p>
                  <p style={{ marginBottom: 0, marginTop: 10 }}>
                    7.3 Time limits apply to complaints. You have six months from:
                  </p>
                  <ul style={{ margin: 0 }}>
                    <li>the date of our final response; or</li>
                    <li>
                      the date which is eight weeks after you first sent us your complaint;
                    </li>
                    <p>to approach the Claims Management Ombudsman.</p>
                  </ul>
                  <p style={{ marginTop: 10 }}>
                    You can see our full complaints procedure at{" "}
                    <a href="https://allegiant.co.uk/compliance/complaints-regulated/">
                      allegiant.co.uk/compliance/complaints-regulated/
                    </a>
                    , or you can ask us for a copy.
                  </p>
                  <h5 style={{ color: "#2b8e0a", marginBottom: 0 }}>
                    8. What we are not responsible for
                  </h5>
                  <p style={{ margin: 0 }}>
                    We will not be responsible for any costs or losses caused due to the
                    following.
                  </p>
                  <ol className="pl-30" type="a" style={{ margin: 0 }}>
                    <li style={{ margin: 0 }}>Your lack of co-operation.</li>
                    <li style={{ margin: 0 }}>
                      Where you fail to read the documents provided to you or to understand them
                      if they are reasonably clear, or you could have taken steps to clarify
                      information.
                    </li>
                    <li style={{ margin: 0 }}>
                      You fail to give us accurate or up-to-date information in good time.
                    </li>
                    <li style={{ margin: 0 }}>
                      Delays or loss of information caused by postal, telecommunication or email
                      service providers.
                    </li>
                    <li style={{ margin: 0 }}>
                      Circumstances that we cannot reasonably control.
                    </li>
                    <li style={{ margin: 0 }}>
                      Losses which could not reasonably have been predicted or expected or which
                      you could have taken steps to avoid or keep to a minimum.
                    </li>
                    <li style={{ margin: 0 }}>
                      Deadlines being missed due to previous complaints you have made unless we
                      expressly agreed to work to those deadlines and you gave us all reasonable
                      information about the previous complaint.
                    </li>
                    <li style={{ margin: 0 }}>
                      Wrong information provided to us by a lender, which we reasonably rely on.
                    </li>
                  </ol>
                  <h5 style={{ color: "#2b8e0a", marginBottom: 0, paddingTop: "15px" }}>9. Ending the agreement</h5>
                  <p style={{ margin: 0 }}>
                    The contract between you and us will end when you pay all fees, charges and
                    any other amounts due to us following the final result of your claim. You
                    agree that we may contact you if there is a legitimate reason for additional
                    compensation possibly becoming due to you (for example, as a result of a
                    regulatory change, court order, audit, or the lender making a mistake or
                    withholding information). When this happens, you will need to enter into a
                    new contract with us for any further claim.
                  </p>
                  <h5 style={{ color: "#2b8e0a", marginBottom: 0, paddingTop: "15px" }}>10. General</h5>
                  <p style={{ margin: 0 }}>
                    10.1 We can recover solicitors’ fees, tracing fees, court fees and
                    enforcement-agency fees we must pay to enforce our right to the success fee.
                    If you need a replacement cheque, or we need to make an extra attempt to
                    send a bank transfer, and this is not due to our&nbsp;fault, we can deduct
                    any related fee or charge from the replacement cheque or new transfer.
                  </p>
                  <p style={{ marginTop: 10 }}>
                    10.2 If there is a legal dispute between you and us in connection with our
                    service, this agreement will be interpreted in line with relevant UK laws
                    and regulations that are in force at the time.
                  </p>
                  <p style={{ marginTop: 10 }}>
                    10.3 Nothing in this agreement is intended to benefit anyone other than you
                    or us.
                  </p>
                  <p style={{ marginTop: 10 }}>
                    10.4 We may assign or novate (legally transfer) or subcontract any or all of
                    our rights and benefits and/or liabilities arising under this contract by
                    giving you 21 days' notice in writing (or less notice if required to meet a
                    legal or regulatory requirement).
                  </p>
                </>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ padding: "0 8px" }}>
                        <p style={{ marginTop: 10 }}>
                          {" "}
                          10.5 If any part of these terms of engagement is found to be invalid
                          or cannot be enforced, the other terms of this agreement will not be
                          affected and will stay in effect in line with the intended spirit of
                          the agreement.
                        </p>
                        <p style={{ marginTop: 10 }}>
                          10.6 These terms of engagement will continue to apply after the
                          service ends when necessary to give effect to the agreement, its
                          purpose and intention.
                        </p>
                        <p style={{ marginTop: 10 }}>
                          10.7 The laws of England and Wales govern this agreement, and any
                          legal action in connection with it will be dealt with in the courts of
                          England and Wales.{" "}
                        </p>
                        <p
                          style={{ marginTop: 10, marginBottom: 10, fontWeight: "bold" }}
                        >
                          If there is anything in the pre-contract information, the service summary or
                          these terms of engagement that you do not understand, it is important that you
                          contact us before signing this document.
                        </p>
                        <table style={{ margin: "10px 0px" }}>
                          <tbody>
                            <tr>
                              <td>
                                <img style={{ width: 20 }} src={warning} />
                              </td>
                              <td
                                style={{
                                  color: "#4e7f36",
                                  marginBottom: 4,
                                  fontWeight: "bold",
                                  paddingLeft: "10px"
                                }}
                              >
                                By signing below, you are accepting these terms of engagement
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <ul style={{ margin: "12px 0px" }}>
                          <li style={{ paddingLeft: 12 }}>
                            {" "}
                            I have read the pre-contract information and service summary, and I accept
                            these terms of engagement.
                          </li>
                          <li style={{ paddingLeft: 12 }}>
                            {" "}
                            I understand that Allegiant’s privacy policy is available at
                            allegiant.co.uk/privacy-policy, and I have had the opportunity to read it
                            before signing these terms.{" "}
                          </li>
                        </ul>
                        <table
                          style={{
                            border: "1px solid black",
                            marginTop: 12,
                            paddingBottom: 12
                          }}
                        >

                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div>
                  <p id="sign011" ref={signref_1}></p>
                  <br />
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 col-6"
                    id="sign10"
                    ref={signref_2}
                  >
                    <button
                      type="button"
                      className={`efct_filed sign-btn sign01 ${button01}`}
                      onClick={signShowDiv}
                      name="sign01"
                    >
                      Click Here To Sign
                    </button>
                    <div className={`signbody1 ${sign01}`} name="sign01">
                      <p>
                        <img
                          className="efct_filed anim_ylw"
                          src={` ${userSign}`}
                          id="sign001"
                          alt=""
                        />
                      </p>
                      {currentDate}
                      <hr className="line" />
                    </div>
                  </div>
                  <div
                    className="rectbox border border border-2 border-dark p-3 mt-3"
                    id="sign01"
                  >
                    <p className="mb-0 greentext">
                      <b>Your acceptance of being aware of your options:</b>
                    </p>
                    <p className="mb-0">
                      <b>
                        I, {fName} {lName} am aware that I do not need to use
                        Claims Management Services to make my complaint, and can
                        make the claim to the relevant firm myself and if the
                        complaint is not successful, I can refer it to the
                        Financial Ombudsman Service myself for free.
                      </b>
                    </p>
                    <p>
                      <b>
                        I wish to
                        use the services of Allegiant Finance Services Ltd to
                        make a claim on my behalf.
                      </b>
                    </p>
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 col-6"
                      id="sign2"
                    >
                      <button
                        type="button"
                        className={`efct_filed sign-btn sign02 ${button02}`}
                        onClick={signShowDiv}
                        name="sign02"
                      >
                        Click Here To Sign
                      </button>
                      <div className={`signbody2 ${sign02}`} name="sign02">
                        <p>
                          <img
                            className="efct_filed anim_ylw"
                            src={` ${userSign}`}
                            alt=""
                            id="signatureimg01"
                          />
                        </p>
                        {currentDate}
                        <hr className="line" />
                      </div>
                    </div>
                  </div>
                  <h2 className="text-center my-4 title">
                    Claim Audit Survey Loan Claim
                  </h2>
                  <div
                    style={{ background: "#ccc" }}
                    className="rectbox2 border border border-2 border-dark p-3"
                  >
                    <p className="mb-0">
                      <b>File number:</b> [[______________]]
                    </p>
                    <p className="mb-0">
                      <b>Applicant Name: </b>
                      {title} {fName} {lName}
                    </p>
                    <p className="mb-0">
                      <b>Agreement Number: {agreementNo}</b>{" "}
                    </p>
                    <p className="mb-0">
                      <b>Agreement Term: {agreementTerm}</b>{" "}
                    </p>
                    <p className="mb-0">
                      <b>Agreement Type: {agreementType}</b>{" "}
                    </p>
                    <p className="mb-0">
                      <b>Complaint about all Applicant agreements with</b>{" "}
                      Moneybarn Limited Trading as Moneybarn
                    </p>
                    <p className="mb-0">
                      <b>Self-declaration of borrowing provided at sign up:</b>
                    </p>
                  </div>
                  {questionFlag ? (
                    <>
                      <p>
                        {!!carRegNo && (
                          <>
                            <br />
                            <b>What was your Car Registration Number?</b>
                            <br />
                            {carRegNo}
                            <br />
                            {`${vehicleMake},${vehicleModel}`}
                          </>
                        )}
                      </p>

                      {/* Make dynamic version from quesions array */}
                      {Object.keys(answers).map((key) => {
                        const id = key;
                        if (!id) return <></>;

                        if (
                          [
                            1, 2, 21, 145, 146, 147, 148, 149, 151, 152, 173, 168, 169
                          ].includes(+id)
                        )
                          return <></>;

                        const q = questionnaire.find((q) => q.questionId == id);

                        if (!q || !answers?.[key]) return <></>;

                        const aId = answers?.[key];
                        const answer = q.answers.length
                          ? q.answers?.find((a) => a.value == aId)
                          : aId;
                        if (!answer) return <></>;

                        return (
                          <p key={key}>
                            <b>{q?.question == "Is BANK_NAME bank account still open?" ? "Is " + bank + " bank account still open?" : q?.question}</b>
                            <br />
                            {answer.label ? answer.label : bank}
                          </p>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {!!carRegNo && (
                        <p>

                          <b>What was your Car Registration Number?"</b>
                          <br />
                          {carRegNo}
                          <br />
                          {vehicleMake + "," + vehicleModel}
                        </p>
                      )}

                      {answers.BH_Q23 ? (
                        <p>
                          <b>{questionnaire23.question}</b>
                          <br />
                          {answers.BH_Q23 ? answers.BH_Q23 : <br />}
                        </p>
                      ) : (
                        ""
                      )}

                      {/* Make dynamic version from quesions array */}
                      {Object.keys(answers).map((key) => {
                        const id = key.split("_Q")[1];
                        if (!id) return <></>;

                        if (
                          [
                            1, 2, 21, 145, 146, 147, 148, 149, 151, 152, 173, 168, 169
                          ].includes(+id)
                        )
                          return <></>;
                        questionnaire.map((q) => {
                          if (q.question.includes("Car Finance lender")) {
                            q.question = q.question.replace("your Car Finance lender", "Moneybarn");
                          }
                          return q;
                        })
                        const q = questionnaire.find((q) => q.questionId == id);

                        if (!q || !answers?.[key]) return <></>;
                        return (
                          <p key={key}>
                            <b>{q?.question == "Is BANK_NAME bank account still open?" ? "Is " + bank + " bank account still open?" : q?.question}</b>
                            <br />
                            {answers?.[key]}
                            <br />
                          </p>
                        );
                      })}
                    </>
                  )}
                  <div className="rectbox border border-dark border-2 row m-0 p-3 mb-5">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                      <img
                        src="https://dev.allegiant.claims/assets/moneybarn_preview/img/logo.png"
                        alt=""
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-6 text-end">
                      <h3 className="mt-3">
                        <span className="graytext text-secondary">
                          FCA FRN:
                        </span>{" "}
                        <b>836810</b>
                      </h3>
                    </div>
                    <h2 className="text-start title my-4">
                      Letter of Authority
                    </h2>
                    <p className="greentext">
                      <b>Genuine belief of accuracy</b>
                    </p>
                    <p>
                      I confirm that the Claim Testimony information above is
                      accurate to the best of my belief. I confirm that I
                      selected any pre-determined statements which most closely
                      fit my circumstances to the best of my knowledge. I
                      understand that Allegiant Finance Services Limited, the
                      Lender and The Financial Ombudsman Service shall each or
                      all rely upon the information set out within this document
                      when assessing my claim. I understand that if I need to
                      notify Allegiant Finance Services Limited of any changes
                      to the above Claim Testimony, I will do so promptly and
                      understand that any changes could impact on the prospects
                      of my claim being successful.
                    </p>
                    <p className="greentext">
                      <b>Authority to act</b>
                    </p>
                    <p>
                      I confirm I have entered into a contract with Allegiant
                      Finance Services Ltd (Allegiant) under which I authorise
                      Allegiant to represent me, investigate and pursue
                      compensation in respect of the mis-selling and/or
                      subsequent operation of payday loans and other such short
                      term credit taken with your company (including Hire
                      Purchase, Personal Contract Purchase and Personal Contract
                      Hire). I authorise Allegiant to refer the complaint to The
                      Financial Ombudsman Service if settlement cannot be
                      agreed. This appointment is made pursuant to FCA Rule Disp
                      2.7.2R. The appointment endures until cancellation in
                      writing or by settlement of any claim, whichever the
                      earlier. I hereby grant permission to Allegiant to obtain
                      any information it may deem necessary for the purposes of
                      establishing the existence of a claim and/or to assess the
                      level of redress that may be due. Such information
                      includes that information which must be provided pursuant
                      to ss. 77-79 of the Consumer Credit Act 1974 and/or s. 45
                      Data Protection Act 2018 as well as other confidential
                      information that Allegiant may reasonably require in
                      connection with a mis selling investigation / claim. I
                      hereby confirm that all correspondence, documentation and
                      offers of settlement should be directed or copied to
                      Allegiant. I have authorised Allegiant to communicate the
                      acceptance or declinature of any offer of compensation
                      received on the Applicant’s behalf. I hereby authorise
                      Allegiant to receive any payment made, arising out of the
                      complaint, into Allegiant's FCA regulated client bank
                      account (presently sort code 60-24-77 account number
                      28397177). I further authorise Allegiant to deduct its
                      fees as permitted within CMCOB rules (as in force).
                      Allegiant’s correspondence address is Allegiant Finance
                      Services Ltd, 400 Chadwick House, Warrington Road,
                      Birchwood Park, Warrington WA3 6AE.
                    </p>
                    <p>
                      <b>
                        Name:{fName} {lName}
                      </b>
                    </p>
                    <p id="ref3" ref={signref_3}>
                      <b>
                        Present Address:
                        {addressLine ? (
                          <>
                            {addressLine}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {addressLine2 ? (
                          <>
                            {addressLine2}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {addressLine3 ? (
                          <>
                            {addressLine3}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {town ? (
                          <>
                            {town}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {country ? (
                          <>
                            {country}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {postcode ? (
                          <>
                            {postcode}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </b>
                    </p>
                    <p>
                      <b>Date of Birth: {userDob}</b>
                    </p>
                    <p>
                      Applicant agreement details (if details have changed since
                      the Agreement was taken out)
                    </p>
                    <p id="sign031">
                      <b>Former Address:</b>
                      {prevAddressLine1 ? (
                        <>
                          {prevAddressLine1}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {prevAddressLine3 ? (
                        <>
                          {prevAddressLine3}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {prevTown ? (
                        <>
                          {prevTown}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {prevCountry ? (
                        <>
                          {prevCountry}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {prevPostcode ? (
                        <>
                          {prevPostcode}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                    <p>
                      <b>Former Name:</b> {prevName}
                    </p>
                    <p>
                      <b>Signature and date</b>
                    </p>
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 col-6"
                      id="sign03"
                    >
                      <button
                        type="button"
                        className={`efct_filed sign-btn sign03 ${button03}`}
                        name="sign03"
                        onClick={signShowDiv}
                      >
                        Click Here To Sign
                      </button>
                      <div className={`signbody3 ${sign03}`}>
                        <img
                          src={` ${userSign}`}
                          alt=""
                          className="efct_filed anim_ylw"
                          id="sign003"
                        />
                        {currentDate}
                        <hr className="line" />
                      </div>
                    </div>
                  </div>
                  <div className="qrsec text-end mb-4">
                    <p className="text-secondary"></p>
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/QR.png"
                      alt=""
                    />
                  </div>
                  <h2 className="title">Complaint form</h2>
                  <p className="para">
                    <b>
                      Please use this form to tell us about your complaint – so
                      we can help resolve it. If you’re not sure about anything,
                      or have difficulties filling in this form, just phone us
                      on 0800 023 4567. Please note all calls with our casework
                      teams are recorded.
                    </b>
                  </p>
                  <p className="mb-0">
                    If you have agreed to make this complaint on behalf of
                    someone else, you will need to ask them to check everything
                    is correct and sign and date this form in the declaration
                    section.
                  </p>
                  <h4 className="title">Your details</h4>
                  <p>
                    <b>Your details (the person complaining)</b>
                  </p>
                  <div className="table-responsive">
                    <table className="table  table-bordered">
                      <tbody>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Title</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}>
                            {title}
                          </td>
                          <td style={{ width: "13%" }}>
                            <p>First Name</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}>
                            {fName}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Surname</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}>
                            {lName}
                          </td>
                          <td style={{ width: "13%" }}>
                            <p> Date of Birth</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}>
                            {userDob}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Address for writing to you</p>
                            <p>Address line 1</p>
                            <p>Address line 2</p>
                            <p>Address line 3</p>
                          </td>
                          <td colSpan="37" style={{ width: "87%" }}>
                            <br />
                            <br />
                            {addressLine}
                            <br />
                            {addressLine2}
                            <br />
                            {addressLine3}
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>City</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}>
                            {town}
                          </td>
                          <td style={{ width: "13%" }}>
                            <p>County</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}>
                            {county}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Postcode</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}>
                            {postcode}
                          </td>
                          <td style={{ width: "13%" }}>
                            <p>Country</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}>
                            {country}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Phone number 1</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}>
                            {userPhone}
                          </td>
                          <td style={{ width: "13%" }}>
                            <p>Phone number 2</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Email</p>
                          </td>
                          <td colSpan="37" style={{ width: "87%" }}>
                            {userMail}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h3 className="fw-bold">
                    Details of anyone complaining with you (for example, a joint
                    policy/account holder)
                  </h3>

                  <div className="table-responsive">
                    <table className="table  table-bordered">
                      <tbody>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Title</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}></td>
                          <td style={{ width: "13%" }}>
                            <p>First name</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Surname</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}></td>
                          <td style={{ width: "13%" }}>
                            <p>{userDob}</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>D</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>D</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>M</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>M</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td colSpan="2" style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Address for writing to you</p>
                            <p>Address line 1</p>
                            <p>Address line 2</p>
                            <p>Address line 3</p>
                          </td>
                          <td colSpan="37" style={{ width: "87%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>City</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}></td>
                          <td style={{ width: "13%" }}>
                            <p>County</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Postcode</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}></td>
                          <td style={{ width: "13%" }}>
                            <p>County</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Phone number 1</p>
                          </td>
                          <td colSpan="18" style={{ width: "34%" }}></td>
                          <td style={{ width: "13%" }}>
                            <p>Phone number 2</p>
                          </td>
                          <td colSpan="18" style={{ width: "40%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }}>
                            <p>Email</p>
                          </td>
                          <td colSpan="37" style={{ width: "87%" }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="logosec1 text-end">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/finacial.png"
                      alt=""
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered mt-3 ">
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>
                            <p>How would you like us to contact you?</p>
                          </td>
                          <td style={{ width: "50%" }}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              checked="true"
                            />
                            &nbsp; phone &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              disabled="true"
                            />
                            &nbsp;Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault3"
                              disabled="true"
                            />
                            &nbsp;Post &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            <p>
                              There will be times when we need to write to you –
                              for example, to send you the outcome of your
                              complaint. When we do, would you prefer an email
                              or letter?
                            </p>
                          </td>
                          <td style={{ width: "50%" }}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault1"
                              id="flexRadioDefault4"
                              disabled="true"
                            />
                            &nbsp;Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault1"
                              id="flexRadioDefault5"
                              disabled=""
                              checked="true"
                            />
                            &nbsp;Post &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            <p>
                              Have you used our service before? (This is so we
                              can link our records)
                            </p>
                          </td>
                          <td style={{ width: "50%" }}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault4"
                              id="flexRadioDefault12"
                              disabled="disabled"
                            />
                            &nbsp;Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault4"
                              id="flexRadioDefault13"
                              disabled="disabled"
                            />
                            &nbsp;No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="20">
                            <p>
                              Do you have any practical needs where we could
                              help by making adjustments – like using large
                              print, Braille or a different language?
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="20" style={{ height: "80px" }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="para2 rightsec ">
                    <b>
                      If the complaint relates to a business account or you are
                      a small business, charity or trust you need to fill out
                      the following sections. Please make sure that you list the
                      names of all directors/partners/trustees authorised. The
                      person completing the form should be the director, partner
                      or trustee.
                    </b>
                  </p>
                  <p className="mb-0">
                    <b>
                      If you’re complaining on behalf of a business, charity or
                      trust
                    </b>
                  </p>
                  <p className="mb-0">Please fill in these details</p>
                  <table className="table table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <p>Its full official name</p>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <p>
                            What is the status of the business, charity or
                            trust?
                          </p>
                        </td>
                        <td style={{ width: "50%" }}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            id="flexRadioDefault6"
                            disabled="disabled"
                          />
                          &nbsp; Limited company &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            disabled="disabled"
                            id="flexRadioDefault7"
                          />
                          &nbsp;Sole proprietor &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <br />
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            disabled="disabled"
                            id="flexRadioDefault8"
                          />
                          &nbsp;Partnership &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            disabled="disabled"
                            id="flexRadioDefault9"
                          />
                          &nbsp;Charity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <br />
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            disabled="disabled"
                            id="flexRadioDefault10"
                          />
                          &nbsp;Trust &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault3"
                            disabled="disabled"
                            id="flexRadioDefault11"
                          />
                          &nbsp;LLP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <p>
                            If your business, charity or trust is an
                            organisation, name all the directors/
                            partners/trustees of this organisation.
                          </p>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <p>
                            Name any staff members who you authorise to
                            represent the organisation (and provide their
                            contact details)
                          </p>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="text-secondary">
                    Financial Ombudsman Service - Complaint form
                  </p>
                  <div className="logosec1 text-end">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/finacial.png"
                      alt=""
                    />
                  </div>
                  <p className="mb-0">
                    <b>If you’re complaining on behalf of a business</b>
                  </p>
                  <p>
                    Please answer all the following questions – we may need to
                    ask you for evidence of this
                  </p>
                  <div className="table-responsive">
                    <table className="table border table-bordered border-2">
                      <tbody>
                        <tr>
                          <td style={{ width: "12%" }}>Number of employees</td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="3" style={{ width: "2%" }}></td>
                          <td colSpan="6" style={{ width: "12%" }}>
                            If a partnership, the number of partners
                          </td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                        </tr>
                        <tr>
                          <td style={{ width: "12%" }}>Its annual turnover</td>
                          <td style={{ width: "2%" }}>£</td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "2%" }}></td>
                          <td colSpan="2" style={{ width: "5%" }}></td>
                          <td style={{ width: "3%" }}></td>
                          <td colSpan="2"></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td colSpan="5"></td>
                        </tr>
                        <tr>
                          <td style={{ width: "12%" }}>
                            <p>Balance sheet</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>£</p>
                          </td>
                          <td colSpan="2"></td>
                          <td colSpan="2"></td>
                          <td colSpan="2"></td>
                          <td colSpan="2"></td>
                          <td style={{ width: "3%" }}></td>
                          <td colSpan="2"></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td style={{ width: "19.5pt" }}></td>
                          <td colSpan="5"></td>
                        </tr>
                        <tr>
                          <td>
                            <p>
                              Is this business linked to or partnered with
                              another business?
                            </p>
                          </td>
                          <td colSpan="22">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault5"
                              id="flexRadioDefault14"
                              disabled="disabled"
                            />
                            Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault5"
                              id="flexRadioDefault15"
                              disabled="disabled"
                            />
                            No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <p>
                              If yes, we’ll ask you for more information to
                              ensure we’re able to look into this complaint
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>List the businesses linked to or partnered</td>
                          <td colSpan="22"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="mb-0 mt-4">
                    <b>If you’re complaining on behalf of a charity or trust</b>
                  </p>
                  <p>Please answer the relevant following questions</p>
                  <table className="table border table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Annual income (if you’re complaining on behalf of a
                          charity)
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>£</p>
                        </td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Annual income (if you’re complaining on behalf of a
                          charity)
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>£</p>
                        </td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                        <td style={{ width: "5%" }}></td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 className="mt-4 title">
                    The business you’re complaining about
                  </h4>
                  <p>Which financial business are you complaining about?</p>

                  <table className="table  border-2 border table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>
                          <p>Their name</p>
                        </td>
                        <td style={{ width: "60%" }}>
                          <p>Moneybarn Limited Trading as Moneybarn</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          <p className="pb-5">Their address</p>
                        </td>
                        <td style={{ width: "60%" }}>
                          Athena House, Bedford Road, Petersfield, Hampshire,
                          GU32 3LJ
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 className="mt-4 title">What’s happened so far</h4>
                  <div className="table-responsive">
                    <table className="table border border-2 table-bordered ">
                      <tbody>
                        <tr>
                          <td style={{ width: "60%" }}>
                            <p>Have you already complained to the business?</p>
                          </td>
                          <td colSpan="2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault6"
                              id="flexRadioDefault16"
                              defaultChecked=""
                              readOnly=""
                            />
                          </td>
                          <td colSpan="2">
                            <p>Yes</p>
                          </td>
                          <td colSpan="2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault6"
                              id="flexRadioDefault17"
                              disabled=""
                            />
                          </td>
                          <td colSpan="2">
                            <p>No</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "60%" }}>
                            <p>
                              If yes, See Letter of Complaint to the business?
                              (The business usually has up to eight weeks from
                              this date to send you its final written answer –
                              before we can investigate the complaint)
                            </p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>See</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>enc.</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>letter</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>of</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>complaint</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p></p>
                          </td>
                          <td style={{ width: "5%" }}></td>
                          <td style={{ width: "5%" }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p className="text-secondary">
                    Financial Ombudsman Service - Complaint form
                  </p>
                  <div className="logosec1 text-end">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/finacial.png"
                      alt=""
                    />
                  </div>
                  <table className="table border border-2 table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <p>
                            Has the business you’re complaining about sent you
                            its final written answer? If yes, please send us a
                            copy
                          </p>
                        </td>
                        <td colSpan="2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault7"
                            id="flexRadioDefault18"
                            disabled="disabled"
                          />
                        </td>
                        <td colSpan="2">
                          <p>Yes</p>
                        </td>
                        <td colSpan="2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault7"
                            id="flexRadioDefault19"
                            disabled="disabled"
                          />
                        </td>
                        <td colSpan="2">
                          <p>No</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <p>
                            Has there been any court action relating to your
                            complaint (or is any planned)? If yes, please
                            enclose copies of relevant paperwork
                          </p>
                        </td>
                        <td colSpan="2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault8"
                            id="flexRadioDefault20"
                            disabled="disabled"
                          />
                        </td>
                        <td colSpan="2">
                          <p>Yes</p>
                        </td>
                        <td colSpan="2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault8"
                            id="flexRadioDefault21"
                            disabled="disabled"
                          />
                        </td>
                        <td colSpan="2">
                          <p>No</p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <p>
                            When did the advice, claim, service or transaction
                            you’re complaining about take place?
                          </p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>D</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>D</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>M</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>M</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>Y</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>Y</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>Y</p>
                        </td>
                        <td style={{ width: "5%" }}>
                          <p>Y</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 className="title">Your complaint</h4>
                  <p className="mb-0">
                    <b>
                      Please tell us about the product or service you would like
                      to complain about
                    </b>
                  </p>
                  <p>
                    (including the reference number of the
                    account/policy/product if you have one)
                  </p>
                  <table className="table border-2  border table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <p className="pb-5">
                            <b>Car Finance affordability claim</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <p>
                            Do you have a complaint reference number that the
                            business gave you? If yes, please provide
                          </p>
                        </td>
                        <td style={{ width: "50%" }}></td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 className="my-3 fw-bold">
                    Tell us about your complaint – what happened?
                  </h5>
                  <div className="rectbox border border-2 border-dark p-4 pb-5">
                    <p>
                      Our client approached Allegiant to pursue an Unaffordable
                      Lending complaint against this lender.
                    </p>
                    <p>
                      We are pursuing this complaint with the Financial
                      Ombudsman Service because the lender has not provided a
                      satisfactory Final Response letter.
                    </p>
                    <p>
                      We find that many lenders do not carry out the
                      affordability checks they should have done. Or, whilst
                      proportionate checks were carried out, the lender chose to
                      overlook information which ought to have meant they
                      declined the lending. For example, this might be because a
                      customer was already in severe financial difficulty before
                      the lending decision was even made – so the lender ought
                      to have known that any additional borrowing was simply not
                      going to be sustainable. We’ve also seen examples of
                      lenders repeatedly lending to the same customer time and
                      time again – when the lender ought to have known that a
                      harmful pattern of repeat borrowing – and persistent
                      reliance on credit – had emerged.
                    </p>
                    <p className="mb-5 pb-5">
                      Any additional information our client has provided in
                      support of their complaint will be enclosed with this
                      submission.
                    </p>
                  </div>
                  <p className="text-secondary">
                    Financial Ombudsman Service - Complaint form
                  </p>
                  <div className="logosec1 text-end">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/finacial.png"
                      alt=""
                    />
                  </div>
                  <h5 className="my-3 fw-bold">
                    How have you been affected – financially or otherwise?
                  </h5>
                  <div className="rectbox border border-2 border-dark p-4 pb-5">
                    <p className="mb-5 pb-5">
                      Our client contends that, as a result of this unaffordable
                      lending, a financial situation (which was already poor)
                      has been made worse.
                    </p>
                  </div>
                  <h5 className="my-3 fw-bold">
                    How would you like the business to put things right for you?
                  </h5>
                  <div className="rectbox  border border-2 border-dark p-4 pb-5 mb-2">
                    <p className="mb-5 pb-5">
                      Refund of interest and charges, together with 8% interest,
                      and amendments to be made to our client’s credit file for
                      any negative information as a result of the unaffordable
                      lending. Any alternative offers, such as a write-off of
                      capital or lump sum cash awards will be communicated to
                      our client.
                    </p>
                  </div>
                  <p>Please continue on a separate sheet if needed.</p>
                  <p>
                    If your complaint is about the sale of payment protection
                    insurance (PPI) or a packaged bank account, you will also
                    need to complete a separate questionnaire. You may have done
                    this already – if you have already complained directly to
                    the business you think is responsible. If not, you can
                    download the questionnaire off our website – or phone us for
                    a copy on 0800 023 4567.
                  </p>
                  <p className="text-secondary">
                    Financial Ombudsman Service - Complaint form
                  </p>
                  <div className="logosec1 text-end" id="sign04">
                    <img
                      src="https://dev.allegiant.claims/assets/moneybarn_preview/img/finacial.png"
                      alt=""
                    />
                  </div>
                  <h2 className="title">Declaration</h2>
                  <p className="mb-0 para2" id="5" ref={signref_4}>
                    Finally, please agree to this declaration. By signing below,
                    you are agreeing to it.
                  </p>
                  <p>
                    “I would like the Financial Ombudsman Service to look into
                    my complaint. I confirm to the best of my knowledge
                    everything I have told you is correct.”
                  </p>
                  <p>
                    <b>Your details (the person complaining)</b>
                  </p>
                  <div className=" table-responsive">
                    <table
                      className="mb-3 table border-2 border table-bordered "
                      id="tbldata"
                    >
                      <tbody id="tblbody">
                        <tr>
                          <td style={{ width: "10%" }}>
                            <p>Name</p>
                          </td>
                          <td style={{ width: "40%" }}>
                            {fName} {lName}
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>Job title*</p>
                          </td>
                          <td style={{ width: "40%" }}></td>
                        </tr>
                        <tr id="sign4">
                          <td style={{ width: "10%" }}>
                            <p>Signature</p>
                          </td>
                          <td style={{ width: "40%" }}>
                            <div id="sign04">
                              <button
                                type="button"
                                className={`efct_filed sign-btn sign04 ${button04}`}
                                name="sign04"
                                onClick={signShowDiv}
                              >
                                Click Here To Sign
                              </button>
                              <div className={`signbody4 ${sign04} `}>
                                <p>
                                  <img
                                    src={` ${userSign}`}
                                    alt=""
                                    className="efct_filed anim_ylw"
                                  />
                                </p>
                              </div>
                            </div>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>Date</p>
                          </td>
                          <td style={{ width: "40%" }}>{currentDate}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p>
                    <b>
                      Details of anyone complaining with you (for example, a
                      joint policy/account holder)
                    </b>
                  </p>
                  <div className="table-responsive">
                    <table
                      className="table  border-2 table border table-bordered table-responsive"
                      id="sign05"
                    >
                      <tbody>
                        <tr>
                          <td style={{ width: "10%" }}>
                            <p>Name</p>
                          </td>
                          <td style={{ width: "40%" }}></td>
                          <td style={{ width: "10%" }}>
                            <p>Job title*</p>
                          </td>
                          <td colSpan="8"></td>
                        </tr>
                        <tr>
                          <td style={{ width: "10%" }}>
                            <p>Signature</p>
                          </td>
                          <td style={{ width: "40%" }}>
                            <p>
                              <img src="" alt="" id="signatureimg05" />
                            </p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>Date</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>D</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>D</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>M</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>M</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                          <td style={{ width: "5%" }}>
                            <p>Y</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p>
                    If someone is complaining on your behalf, you still need to
                    sign your agreement to the declaration above.
                  </p>
                  <p>
                    For complaints involving accounts or policies held jointly,
                    we usually need each person to sign – and we may share
                    details about the complaint with both signatories. Please
                    tell us if there’s any reason this might be a problem for
                    you.
                  </p>
                  <p>
                    If you have agreed to make this complaint on someone else’s
                    behalf, you will need to ask them to sign and date this form
                    in the space above. You will need to add your own details
                    and signature where prompted below. If the person
                    complaining can’t sign for any reason please let us know.
                  </p>
                  <p>
                    * If you’re complaining on behalf of a business, charity or
                    trust, please provide your job title.
                  </p>
                  <p className="mb-0">
                    <b>Representative information</b>
                  </p>
                  <p>
                    Please complete this section if you want to authorise
                    another person to act on your behalf. You could ask a
                    friend, relative, Claims Management Company or solicitor but
                    check first whether they will charge you for this. You can
                    change or cancel this authority at any time by contacting
                    us.
                  </p>
                  <div className="table-responsive ">
                    <table className="table table border-2 border table-bordered ">
                      <tbody>
                        <tr>
                          <td style={{ width: "15%" }} className="bg-pr">
                            <p>Their name</p>
                          </td>
                          <td colSpan="8">
                            <p>
                              <b>Allegiant Finance Services Ltd</b>
                            </p>
                          </td>
                          <td colSpan="2" className="td-bg">
                            <p>Their relationship to you</p>
                          </td>
                          <td colSpan="11">
                            <p>
                              <b>CMC</b>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan="4" className="td-bg">
                            <p>
                              Their address Address line 1<br />
                              Address line 2<br />
                              Address line 3<br /> City
                              <br />
                              County
                              <br />
                              Country
                            </p>
                          </td>
                          <td colSpan="8" rowSpan="4">
                            <p>
                              <b>
                                400 Chadwick House <br />
                                Birchwood Park
                                <br />
                                Warrington
                                <br />
                                Cheshire
                                <br />
                                England
                              </b>
                            </p>
                          </td>
                          <td style={{ width: "13%" }} className="td-bg">
                            <p>Their phone number 1</p>
                          </td>
                          <td style={{ width: "13%" }}></td>
                          <td style={{ width: "2%" }}>
                            <p>0</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>3</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>4</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>5</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>5</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>4</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>4</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>1</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>5</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>6</p>
                          </td>
                          <td style={{ width: "2%" }}>
                            <p>3</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "13%" }} className="td-bg">
                            <p>Their phone number 2</p>
                          </td>
                          <td style={{ width: "13%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                          <td style={{ width: "2%" }}></td>
                        </tr>
                        <tr>
                          <td colSpan="13" className="td-bg">
                            <p>Their email</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="13">
                            <a
                              href="mailto:helpdesk@allegiant-finance.co"
                              target="_blank"
                              className="text-dark"
                            >
                              <b>helpdesk@allegiant-finance.co.uk</b>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "15%" }} className="td-bg">
                            <p>Postcode</p>
                          </td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>W</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>A</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>3</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}></td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>6</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>A</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}>
                            <p>
                              <b>E</b>
                            </p>
                          </td>
                          <td style={{ width: "4%" }}></td>
                          <td style={{ width: "15%" }} className="td-bg">
                            <p>Their reference</p>
                          </td>
                          <td colSpan="12" style={{ textAlign: "center" }}>
                            [[______________]]
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row m-0">
                    <div className="col-xl-6 col-lg-6 col-md-6 para ">
                      <p className="mb-0">
                        <b>Post to:</b>
                      </p>
                      <p className="mb-0">Financial Ombudsman Service</p>
                      <p className="mb-0">Exchange Tower</p>
                      <p className="mb-0">London E14 9SR</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <p className="mb-0">You can also get in touch at</p>
                      <a
                        href="mailto:complaint.info@financial-ombudsman.org.uk"
                        target="_blank"
                      >
                        <u>complaint.info@financial-ombudsman.org.uk</u>
                      </a>
                    </div>
                  </div>
                  <h2 className="my-4  title">Complainant privacy notice</h2>
                  <p>
                    This privacy notice summarises what to expect us to do with
                    your personal information when you contact us or bring a
                    complaint to our service. A more detailed version of this
                    privacy notice is available on our website.
                  </p>
                  <p className="fonter mb-0">
                    <b>About us</b>
                  </p>
                  <p>
                    The Financial Ombudsman Service is a free and easy-to-use
                    service that settles complaints between complainants and
                    businesses that provide financial services. You can contact
                    us{" "}
                    <a href="https://www.financial-ombudsman.org.uk/contact-us"></a>
                    here.
                  </p>
                  <p>
                    <b>
                      We use your personal information to investigate and
                      resolve individual complaints and prevent unfairness. We
                      also analyse complaints data to make our services and
                      processes more effective for you and others. More detail
                      is set out in the ‘Why we process your personal
                      information’ section.
                    </b>
                  </p>
                  <p className="fonter mb-0">
                    <b>The personal information we use</b>
                  </p>
                  <p>
                    Personal information means information that is about an
                    individual or can identify them in some way. The amount and
                    type of personal information we process varies depending on
                    the individual circumstances of the complaint and why we are
                    processing personal information. Examples of the type of
                    information we process are your name, date of birth,
                    financial details, phone recordings and special category
                    data, such as health information.
                  </p>
                  <p className="fonter mb-0">
                    <b>How we collect personal data</b>
                  </p>
                  <p>
                    We have a range of channels that individuals can use to get
                    in contact with us. Generally, we receive personal
                    information from the individual directly, their
                    representative or from the financial business the complaint
                    is against. But sometimes, where it is necessary for
                    resolving a complaint or fulfilling our legal functions, we
                    may also gather information from other individuals or
                    organisations, such as a loss adjuster hired by an insurance
                    company, a medical expert or a credit reference agency.
                  </p>
                  <p className="fonter mb-0">
                    <b>Why we process personal information</b>
                  </p>
                  <p>
                    We primarily collect and process personal information to
                    investigate and resolve individual complaints brought to our
                    service and respond to enquiries and redirect individuals to
                    the correct organisation if necessary. In addition to this
                    we also process personal data in the following way:
                  </p>
                  <ul>
                    <li>Prevent complaints and unfairness</li>
                    <li>
                      Improve the effectiveness of our service and processes
                    </li>
                    <li>Meeting your needs and making adjustments</li>
                    <li>Work with the regulator and other bodies</li>
                    <li>
                      Dealing with contact you may make with us through social
                      media
                    </li>
                    <li>Complying with a legal duty.</li>
                  </ul>
                  <p>
                    We have a legal obligation to publish final decisions made
                    by our ombudsmen. These are published on our website. We
                    remove the name of the person making the complaint as well
                    as any other personal information that would be likely to
                    identify them.
                  </p>
                  <p>
                    We conduct regular surveys to understand your views on the
                    service we have provided to you. You can let your
                    Investigator know at any time if you do not want us to
                    contact you for our surveys.
                  </p>
                  <p className="fonter mb-0">
                    <b>Who we share personal information with?</b>
                  </p>
                  <p>
                    We will not share your information with anyone for the
                    purpose of direct marketing. We will not sell your data.
                    When an enquiry is brought to us, we need to contact the
                    financial business and make them aware that an enquiry has
                    been received and ask them what has happened so far. The
                    personal details of the complainant, any representative and
                    details of the complaint are shared during this initial
                    process.
                  </p>
                  <p className="text-secondary">
                    Financial Ombudsman Service - Complaint form
                  </p>
                  <p>
                    In order to investigate a complaint, we need to share
                    information with both parties of the complaint to get both
                    sides of the story. Sometimes, depending on the nature of
                    the complaint, we may also need to share relevant
                    information with other individuals or organisations, for
                    example: another financial business, medical experts or
                    credit reference agencies.
                  </p>
                  <p>
                    If you have a complaint about the standard of service we’ve
                    provided to you, and we’ve not been able to resolve this,
                    you can ask the Independent Assessor to investigate this
                    complaint. We will pass on relevant details to the
                    Independent Assessor so that they can investigate and
                    respond to your service complaint.
                  </p>
                  <p className="fonter mb-0">
                    <b>Lawful bases for processing personal information</b>
                  </p>
                  <p>
                    The law on data protection sets out a number of different
                    reasons for which an organisation may collect and process
                    your personal information.
                  </p>
                  <p>
                    Our lawful basis for processing personal information will
                    usually be because it’s necessary for our statutory
                    function, a reason of substantial public interest or
                    compliance with our legal obligations. On those occasions
                    where we are not relying on any of the above, we will ensure
                    that a suitable alternative lawful basis is used, which is
                    likely to be where the processing is in our legitimate
                    interests.
                  </p>
                  <p className="fonter mb-0">
                    <b>Where your data is processed and stored</b>
                  </p>
                  <p>
                    We store your personal information in the UK or the European
                    Union (EU). Our technical support teams in India may process
                    your information to provide technical advice and assistance.
                  </p>
                  <p>
                    Where we allow access to your information from countries
                    outside the UK, we ensure that we comply with all our data
                    protection obligations.
                  </p>
                  <p className="fonter mb-0">
                    <b>How we store personal information and for how long</b>
                  </p>
                  <p>
                    We know that data security is important to us all. When we
                    receive personal information, we take steps to ensure that
                    it is stored securely, both physically and electronically,
                    in accordance with the internal policies that we have in
                    place for the effective and secure processing of your
                    personal information. We will keep your case file for 6
                    years after your case closes (or 3 years if we did not go on
                    to fully investigate your case or if we transferred your
                    case to another organisation). Paper documents sent by post
                    are destroyed 6 months after the date they are scanned into
                    our system. More information is in Annex A{" "}
                    <a href="https://www.financial-ombudsman.org.uk/privacy-policy/consumer-privacy-notice">
                      here.
                    </a>
                  </p>
                  <p className="fonter mb-0">
                    <b>What are your rights over your personal information?</b>
                  </p>
                  <p>
                    Under data protection law, you have rights we need to make
                    you aware of. The rights available to you depend on our
                    reason for processing your information. Details of each of
                    these rights are set out in Annex B{" "}
                    <a href="https://www.financial-ombudsman.org.uk/privacy-policy/consumer-privacy-notice">
                      here.
                    </a>
                  </p>
                  <p className="fonter mb-0">
                    <b>
                      What to do if you’re unhappy with how we’ve handled your
                      personal information
                    </b>
                  </p>
                  <p>
                    If you have any questions about this notice or are concerned
                    about how we are processing your personal data, you can
                    contact our Data Protection Officer at:{" "}
                    <a
                      href="mailto:data.protection@financial-ombudsman.org.uk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      data.protection@financial-ombudsman.org.uk
                    </a>{" "}
                    Details of how to raise a complaint are available here.
                  </p>
                  <p>
                    We hope we’ll be able to resolve your concerns, but if you
                    still remain unhappy with our response, you can contact the
                    <a href="https://ico.org.uk/" target="_blank">
                      Information Commissioner’s Office
                    </a>{" "}
                    at{" "}
                    <a href="mailto:casework@ico.org.uk" target="_blank">
                      casework@ico.org.uk
                    </a>{" "}
                    or 01625 545 745.
                  </p>
                  <p className="fonter mb-0">
                    <b>Changes to this privacy notice</b>
                  </p>
                  <p>
                    We may change this privacy policy. In that case, the ‘last
                    updated’ date at the bottom of this page will also change.
                    Any changes to this privacy policy will apply to you and
                    your data immediately.
                  </p>
                  <p>Last updated April 2022</p>
                  <p
                    className="text-secondary"
                    style={{ paddingBottom: "90px" }}
                  >
                    Financial Ombudsman Service - Complaint form
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <footer
          className="fixed-bottom"
          id="myDiv2"
          style={{ display: "block" }}
        >
          <div className="container">
            <div className="row spl_font">
              <div
                className="col-lg-10 col-md-8 col-sm-12  text-center custom_content"
                style={{ padding: "5px 0" }}
              >
                <p className={`futp ft-text1 mb-0 ${nextButton1}`}>
                  {" "}
                  Please read the Service Summary and then click Next to
                  continue.
                </p>
                <p className={`futp ft-text2 mb-0 ${nextButton2}`}>
                  {fName} {lName} Please read through the document and when you
                  are happy, click where prompted to add your signature.{" "}
                </p>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12 text-center">
                <p className="mb-0">
                  <span>
                    <button
                      className="btn btn-success btn-next next-btn tocontent anim_ylw"
                      data-labelname={`click_${clickCount}`}
                      onClick={showSign}
                      id="sign111"
                    >
                      {text}
                    </button>
                  </span>
                </p>
                {/* <p className="mb-0"><span><a href="#sign011" className="btn btn-success  next-btn tocontent anim_ylw" id="sign2" style={{ display: "none" }}>Next</a></span></p> */}
                {/* <p className="mb-0"><span><a href="#sign01" className="btn btn-success  next-btn tocontent anim_ylw" id="sign3" style={{ display: "none" }}>Next</a></span></p> */}
                <p className="mb-0">
                  <span>
                    <a
                      href="#sign031"
                      className="btn btn-success  next-btn tocontent anim_ylw"
                      id="sign4"
                      style={{ display: "none" }}
                    >
                      Next
                    </a>
                  </span>
                </p>

                <p className="mb-0">
                  <span>
                    <a
                      href="#sign04"
                      className="btn btn-success  next-btn tocontent anim_ylw"
                      id="sign5"
                      style={{ display: "none" }}
                    >
                      Next
                    </a>
                  </span>
                </p>
                <p className="mb-0">
                  <span>
                    <a
                      href="#sign0310"
                      className="btn btn-success  next-btn tocontent anim_ylw"
                      id="sign6"
                      style={{ display: "none" }}
                    >
                      Next
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
export default Preview;
