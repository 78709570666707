import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";


const RadioQuestionnaireFlp = (props) => {
  const mainQuestion = props.questionnaire.question;
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + props.questionnaire.questionId;

  const radioId1 = props.questionnaire.answers[0].label + '_' + props.questionnaire.questionId;
  const radioId2 = props.questionnaire.answers[1].label + '_' + props.questionnaire.questionId;

  const radioValue1 = props.questionnaire.answers[0].value;
  const radioValue2 = props.questionnaire.answers[1].value;

  const radioLabel1 = props.questionnaire.answers[0].label;
  const radioLabel2 = props.questionnaire.answers[1].label;


  return (
    <>
        <div className={`radio-box question5  animated fadeInUp  ${props.showQuestionSlide}`} id="breakdown3">
        <h3 style={{color: '#444'}}>{mainQuestion}{props.vehicleSecureYear && <span>{props.vehicleSecureYear} ?</span>}</h3>
        {question_id == '21' &&
            <h2>{props.vehicleModel}</h2>
        }
         {question_id == '135' &&
            <ul>
            <li>You have ever been declared bankrupt; or</li>
            <li>You are currently subject to a bankruptcy petition</li>
            <li>You have been subject to a debt relief order</li>
            <li>You have an adverse County Court Debt Judgement</li>
            <li>Sequestration</li>
          </ul>
        }
        <input type="radio" id={radioId1} name={radioName} value={radioValue1} data-answertext={radioLabel1} className=" " data-question_id={question_id} onClick={(e) => { props.slideChange(e, radioName) }} style={{display:"none"}}/>
        <label htmlFor={radioId1} className="br radioa l_g next3">
        <span className="span-tag">a</span>
          {radioLabel1}
        </label>

        <input type="radio" id={radioId2} name={radioName} value={radioValue2} data-answertext={radioLabel2} className=" " data-question_id={question_id} onClick={(e) => { props.slideChange(e, radioName) }} style={{display:"none"}}/>
        <label htmlFor={radioId2} className="br radioa l_g next3">
        <span className="span-tag">b</span>
          {radioLabel2}
        </label>
        </div>
    </>
  );
};

export default RadioQuestionnaireFlp;
