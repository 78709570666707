import { Api } from "../api/Api";

export const useFollowupUserSignature = () => {
  const saveFollowupUserSignature = async (
    data,
    followup_data,
    message_type
  ) => {
    const signatureSubmit = await Api.post("/v1/data-ingestion-pipeline", {
      data,
      followup_data,
      message_type,
    });
    return signatureSubmit;
  };
  return {
    saveFollowupUserSignature,
  };
};
