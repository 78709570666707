import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import info from "../../../../assets/moneybarn/img/info.jpg";
import line from "../../../../assets/moneybarn/img/line.png";
import rating from "../../../../assets/moneybarn/img/rating-star.png";
import tqIcon from "../../../../assets/moneybarn/img/tq-icon.png";
import { followUpUserInfo } from "../../../../Hooks/FollowUpUseInfo";
import { useAgreeTermsIngestion } from "../../../../Hooks/useAgreeTermsIngestion";
import { UrlParams } from "../../../../Utility/UrlParams";
import Loader from "../../../Includes/Layouts/Common/Loader";
import GTMDataLayer from "../../../Includes/Layouts/GTMDataLayer";
import { DynamicRoutingSplit } from "../../../../Utility/DynamicRoutingSplit";

const MoneybarnFollowupConfirmation = () => {
  const [userId, setUserId] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [isQualified, setIsQualified] = useState();
  const [modalOne, setModalOne] = useState("d-none");
  const [modalTwo, setModalTwo] = useState("d-none");
  const [modalThree, setModalThree] = useState("d-none");
  const [fieldName, setFieldName] = useState("cancel_load");
  const { saveConfirmation } = useAgreeTermsIngestion();
  const history = useHistory();
  const { getfollowUpUserInfo } = followUpUserInfo();
  const { getUrlParams } = UrlParams();
  const { customUuid, customSource, token } = getUrlParams();
  const [loader, setLoader] = useState("d-block");
  const  {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('Followup_MB','moneybarn/followup/confirmation');

  useEffect(() => {
    (async () => {
      const response = await getfollowUpUserInfo(token);
      let dataLength = response.data.response.length;
      if (dataLength > 0) {
        const isQualified = response.data.response[0].is_qualified
          ? response.data.response[0].is_qualified
          : "";
        if (isQualified == 0) {
          history.push(
            `/thankyou-unqualified?uuid=${customUuid}&user_token=${token}&source=${customSource}`
          );
        } else {
          setLoader("d-none");
          setModalOne("show");
          const userId = response.data.response[0].id
            ? response.data.response[0].id
            : " ";
          const firstName = response.data.response[0].first_name
            ? Capitalize(response.data.response[0].first_name)
            : " ";
          const lastName = response.data.response[0].last_name
            ? response.data.response[0].last_name
            : " ";
          setUserId(userId);
          setFirstName(firstName);
          setLastName(lastName);
          setIsQualified(isQualified);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const previewstats = await saveConfirmation(
        userId,
        customUuid,
        fieldName
      );
    })();
    if (fieldName == "sign_status") {
      history.push(
        `/${DynamicRouteNextPage}?uuid=${customUuid}&user_token=${token}&source=${customSource}`
      );
    }
  }, [fieldName, userId]);

  const gtmDataLayer = () => {
    let question = "Complaint proceeded";
    let answer = "";

    GTMDataLayer({
      question: question,
      answer: answer,
    });
  };

  const gtmDataLayerCancel = () => {
    let question = "Complaint cancelled";
    let answer = "";

    GTMDataLayer({
      question: question,
      answer: answer,
    });
  };

  const handleSubmit = (e) => {
    let targetName = e.target.name;
    if (targetName == "proceed") {
      gtmDataLayer(e.target.value);
      setFieldName("sign_status");
    } else if (targetName == "cancel-btn") {
      setModalOne("d-none");
      setModalTwo("show");
    } else if (targetName == "yes-btn") {
      setFieldName("cancel_status");
      setModalTwo("d-none");
      setModalThree("show");
      gtmDataLayerCancel(e.target.value);
    } else if (targetName == "no-btn") {
      setModalTwo("d-none");
      setModalOne("show");
    }
  };
  function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return (
    <>
      {/* -----------first modal--------- */}
      <div className="moneybarn_preview">
        <Loader show={loader}></Loader>
        <div
          className={`modal fade ${modalOne} `}
          id="previews"
          style={{
            background: "rgba(0, 0, 0, 0.9) !important",
            paddingLeft: "0px",
          }}
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body ">
                <div>
                  <h2 className="text-center">IMPORTANT</h2>
                  <p className="text-center f_name">
                    {" "}
                    {firstName}, we believe your complaint against Moneybarn has
                    merit and are ready to assist you with pursuing this matter
                    further.{" "}
                  </p>
                  {/* <p className="mt-3 text-center " style={{fontSize:"16px"}}> <b className="client_1">Our clients average wins against Moneybarn.</b></p> */}
                  <p>
                    Some companies use the advent of electronic documents and
                    signatures to mislead their clients by encouraging them to
                    agree to terms that are unfair.
                  </p>
                  <p>
                    Not us! We require every one of our clients to confirm they
                    understand our terms of engagement and fees, and as a result
                    we have a very long list of very happy clients!
                  </p>
                  <p>
                    <img src={line} alt="" />
                  </p>
                  <ul className="options mb-2">
                    <li className="popupLink">
                      <input
                        type="submit"
                        id="preDetails1"
                        className="custom-checkbox1 bank-name"
                        name="proceed"
                        onClick={handleSubmit}
                      />
                      <label for="preDetails1">
                        SIGN & Proceed with Complaint{" "}
                      </label>
                    </li>
                    <li className="popupLink">
                      <input
                        type="submit"
                        id="preDetails2"
                        name="cancel-btn"
                        className="custom-checkbox2 bank-name "
                        onClick={handleSubmit}
                      />
                      <label for="preDetails2">
                        Please CANCEL My Complaint{" "}
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left py-1  mt-5 text-center">
                  <div className=" justify-content-center  mt-5">
                    {/* <TrustBox/> */}
                    <TrustpilotReviews
                      language="en"
                      culture="US"
                      theme="light"
                      width="100%"
                      height="30px"
                      template="5419b6a8b0d04a076446a9ad"
                      business="5ee0ec97b8f1fa0001e57983"
                      username="USERNAME"
                    />
                    {/* <span className="review-cntnt">See our <strong>1,752</strong> reviews on </span> */}
                    {/* <img src={trust} style={{position: "relative", height: "18px",  borderStyle: "none",  overflow: "hidden"}} alt=""/> */}
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="col-lg-12 col-12 mb-2 text-center">
                  <p>
                    <img
                      src={rating}
                      className="rating-str trustpilot"
                      alt=""
                    />
                    <b className="sidespace mx-1">4.8</b>
                    <img
                      src={info}
                      className=""
                      alt="The TrustScore isn’t just a simple average of all reviews. It’s based on multiple factors like the age and number of reviews.
                        Whether or not a business actively asks customers to write reviews also impacts the TrustScore."
                    />
                  </p>
                  <p className="review1-cntnt">
                    Independent Service Rating based on verified reviews.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* -----------second modal--------- */}
        <div
          className={`modal fade ${modalTwo}`}
          id="canceledConfirmPopup"
          style={{
            background: "rgba(0, 0, 0, 0.9) !important",
            paddingLeft: "0px",
          }}
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content brdr-no">
              <div className="modal-body tq-pad">
                <h1 className="tq-head fw-bold text-center">
                  Are you sure that you want to cancel your claim request?
                </h1>
              </div>

              <div style={{ marginBottom: "16px" }}>
                <button
                  className="btn btn-danger cnfrm-styl mx-1"
                  id="confirmYes"
                  name="yes-btn"
                  onClick={handleSubmit}
                >
                  Yes
                </button>
                <button
                  className="btn btn-success cnfrm-styl mx-1"
                  variant="danger"
                  name="no-btn"
                  id="confirmNo"
                  onClick={handleSubmit}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* -----------third modal--------- */}
        <div
          className={`modal fade ${modalThree}`}
          id="canceledPopup"
          tabindex="-1"
          aria-labelledby="canceledPopupLabel"
          aria-hidden="true"
          style={{
            background: "rgba(0, 0, 0, 0.9) !important",
            paddingLeft: "0px",
          }}
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content brdr-no">
              <div className="modal-head text-center img-head">
                <img src={tqIcon} className="tq-icon" alt="" />
              </div>

              <div className="modal-body tq-pad">
                <h1 className="tq-head fw-bold text-center">Thank You!</h1>
                <h3 className="tq-cnt">
                  Your request for complaint cancellation has been initiated.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoneybarnFollowupConfirmation;
