import React, { useEffect, useReducer, useState, useRef } from "react";
import background_video from "../../../../assets/img/male.mp4";
import logo_loanclaims from "../../../../assets/img/logo-loanclaims.png";
import "../../../../assets/css/CatalogueClaim_Questionnaire.scss"
import { useHistory } from "react-router-dom";
import { useQuestionnaire } from "../../../../Hooks/useQuestionnaire";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { userInfo } from "../../../../Hooks/userInfo";

import {
  questionnaire124,
  questionnaire125,
  questionnaire126,
  questionnaire127,
  questionnaire128,
  questionnaire129,
  questionnaire130,
  questionnaire131,
  questionnaire132,
  questionnaire133,
  questionnaire134,
  questionnaire135,
} from "../../../../Constants/CatalogueClaim_Question";
import { useForm } from "react-hook-form";
import { useContext } from "react";
import SelectQuestiannaireCC from "../Common/SelectQuestionnaireCC";
import RadioQuestiannaireCC from "../Common/RadioQuestionnaireCC";
import LoaderCC from "../Common/LoaderCC";
import { Helmet } from "react-helmet";
import GTMDataLayer from "../GTMDataLayer";
import { CatalogueClaimsQualificationCheck } from "../../../../Utility/CatalogueClaimsQualificationCheck";
import TopSection from "../Common/TopSection";
import GetVisitorsParams from "../../../../Utility/GetVisitorsParams";
import { DynamicRoutingSplit } from "../../../../Utility/DynamicRoutingSplit";



const Questionnaire_CC = () => {
  const { register, errors, clearErrors, trigger, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });


  const initialState = {
    showQuestionSlide124 : "d-block",
    showQuestionSlide125: "d-none",
    showQuestionSlide126: "d-none",
    showQuestionSlide127: "d-none",
    showQuestionSlide128: "d-none",
    showQuestionSlide129: "d-none",
    showQuestionSlide130: "d-none",
    showQuestionSlide131: "d-none",
    showQuestionSlide132: "d-none",
    showQuestionSlide133: "d-none",
    showQuestionSlide134: "d-none",
    showQuestionSlide135: "d-none",
  };
  const QuestionReducer = (state, action) => {
    switch (action.type) {
      case "nextQuestionSlide": {
         if (action.payload.clickedSlide.slide == "questionnaire124") {
          return {
            ...state,
            showQuestionSlide124: "d-none",
            showQuestionSlide125: "d-block",
          };
        } else if (action.payload.clickedSlide.slide == "questionnaire125") {
          return {
            ...state,
            showQuestionSlide125: "d-none",
            showQuestionSlide126: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire125_yes") {
          return {
            ...state,
            showQuestionSlide125: "d-none",
            showQuestionSlide127: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire126") {
          return {
            ...state,
            showQuestionSlide126: "d-none",
            showQuestionSlide127: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire127") {
          return {
            ...state,
            showQuestionSlide127: "d-none",
            showQuestionSlide128: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire128") {
          return {
            ...state,
            showQuestionSlide128: "d-none",
            showQuestionSlide129: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire129") {
          return {
            ...state,
            showQuestionSlide129: "d-none",
            showQuestionSlide130: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire130") {
          return {
            ...state,
            showQuestionSlide130: "d-none",
            showQuestionSlide131: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire131") {
          return {
            ...state,
            showQuestionSlide131: "d-none",
            showQuestionSlide132: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire132") {
          return {
            ...state,
            showQuestionSlide132: "d-none",
            showQuestionSlide133: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire133") {
          return {
            ...state,
            showQuestionSlide133: "d-none",
            showQuestionSlide134: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire134") {
          return {
            ...state,
            showQuestionSlide134: "d-none",
            showQuestionSlide135: "d-block",
          };
        }
        else if (action.payload.clickedSlide.slide == "questionnaire135") {
          return {
            ...state,
            showQuestionSlide135: "d-none",
            showQuestionSlide136: "d-block",
          };
        }else {
          return {
            ...state,
          };
        }
      }
      case "backQuestionSlide": {
        if (action.payload.prevSlide.slide == "back_125") {
          return {
            ...state,
            showQuestionSlide125: "d-none",
            showQuestionSlide124: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_126") {
          return {
            ...state,
            showQuestionSlide126: "d-none",
            showQuestionSlide125: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_127") {
          return {
            ...state,
            showQuestionSlide127: "d-none",
            showQuestionSlide126: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_127_yes") {
          return {
            ...state,
            showQuestionSlide127: "d-none",
            showQuestionSlide125: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_128") {
          return {
            ...state,
            showQuestionSlide128: "d-none",
            showQuestionSlide127: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_129") {
          return {
            ...state,
            showQuestionSlide129: "d-none",
            showQuestionSlide128: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_130") {
          return {
            ...state,
            showQuestionSlide130: "d-none",
            showQuestionSlide129: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_131") {
          return {
            ...state,
            showQuestionSlide131: "d-none",
            showQuestionSlide130: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_132") {
          return {
            ...state,
            showQuestionSlide132: "d-none",
            showQuestionSlide131: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_133") {
          return {
            ...state,
            showQuestionSlide133: "d-none",
            showQuestionSlide132: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_134") {
          return {
            ...state,
            showQuestionSlide134: "d-none",
            showQuestionSlide133: "d-block",
          };
        }
        else if (action.payload.prevSlide.slide == "back_135") {
          return {
            ...state,
            showQuestionSlide135: "d-none",
            showQuestionSlide134: "d-block",
          };
        }
        else {
          return {
            ...state,
          };
        }
      }
    }
  };
  //
  const [clickedSlide, setClickedSlide] = useState();
  const splitForm = useRef(null);
  const history = useHistory();
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [cAccountOpen, setCAccountOpen] = useState(false);
  const [prevSlide, setPrevSlide] = useState();
  const [quest1Ans, setQuest1Ans] = useState(null);
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const query_string = localStorage.getItem("querystring");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const [state, dispatch] = useReducer(QuestionReducer, initialState);
  const { updateUserQualifiedStatus } = userInfo();
  const {DynamicRouteSplitName,DynamicRouteNextPage}   = DynamicRoutingSplit('UNFR_0602UFVCC_A1','0602UFVCC_A1/questionnaire');

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);
  useEffect(() => {
    if (prevSlide) {
      dispatch({ type: "backQuestionSlide", payload: { prevSlide } });
    }
  }, [prevSlide]);

   const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };
  const getLabelValue = (questionId, value) => {
    let questionnaire = {};
    switch (questionId) {
      case "124":
        questionnaire = questionnaire124;
        break;
      case "125":
        questionnaire = questionnaire125;
        break;
      case "126":
        questionnaire = questionnaire126;
        break;
      case "127":
        questionnaire = questionnaire127;
        break;
      case "128":
        questionnaire = questionnaire128;
        break;
      case "129":
        questionnaire = questionnaire129;
        break;
      case "130":
        questionnaire = questionnaire130;
        break;
      case "131":
        questionnaire = questionnaire131;
        break;
      case "132":
        questionnaire = questionnaire132;
        break;
      case "133":
        questionnaire = questionnaire133;
        break;
      case "134":
        questionnaire = questionnaire134;
        break;
      case "135":
        questionnaire = questionnaire135;
        break;
    }

    let labelValue = questionnaire.answers
          .filter((obj) => obj.value === value)
          .map((obj) => obj.label);

    return labelValue;

  }
  const slideChange = async (e, fieldName) => {
    
    if (e.target.value == '250' || e.target.value == '252') {
      setShowModal(true);
      return false;
    }
    else if (e.target.value === "124") {
      var currentSlide = 'questionnaire124';
    }
    else if (e.target.value === "231" || e.target.value === "232") {
      if(e.target.value === "231"){
        setCAccountOpen(true);
        currentSlide = 'questionnaire125_yes';  
      }else{
        currentSlide = 'questionnaire125';
      }      
    }
    else if (e.target.value == '126') {
      var currentSlide = 'questionnaire126';
    }
    else if (e.target.value == '127') {
      var currentSlide = 'questionnaire127';
    }
    else if (e.target.value === "233" || e.target.value === "234" ||
      e.target.value === "235" || e.target.value === "236" || e.target.value === "237") {
      var currentSlide = 'questionnaire128';
    }
    else if (e.target.value === "238" ||
      e.target.value === "239" ||
      e.target.value === "240" ||
      e.target.value === "241") {
      var currentSlide = 'questionnaire129';
    }
    else if (e.target.value === "242" ||
      e.target.value === "243" ||
      e.target.value === "244" ||
      e.target.value === "245") {
      var currentSlide = 'questionnaire130';
    }
    else if (e.target.value === "131") {
      var currentSlide = 'questionnaire131';
    }
    else if (e.target.value === "132") {
      var currentSlide = 'questionnaire132';
    }
    else if (e.target.value === "246" ||
      e.target.value === "247" ||
      e.target.value === "248" ||
      e.target.value === "249") {
      var currentSlide = 'questionnaire133';
    }
    else if (e.target.value === "250" ||
      e.target.value === "251" ) {
      var currentSlide = 'questionnaire134';
    }
    else {
      var currentSlide = e.target.name;
    }
    gtmDataLayer(e.target.name, e.target);
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    setClickedSlide({ slide: currentSlide });
    var question_id = e.target.dataset.question_id;
    ///all dropdown questionnaire
    var input_questions = ['124', '126', '127', '131', '132'];
    var optionId = (input_questions.includes(question_id)) ? '' : form[fieldName].value;
    var inputAnswer = (input_questions.includes(question_id)) ? form[fieldName].value : '';
    let labelValue = getLabelValue(question_id, form[fieldName].value);
    let question_data = {
      question_id: question_id,
      option_id: optionId,
      answer_text: labelValue.toString(),
      input_answer: inputAnswer,
      bankId  : formdata.lender
    };
    ///save questionData in localstorage
    var existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (existingEntries == null) existingEntries = {};
    var newEntries = (existingEntries == null) ? existingEntries = {} : existingEntries;
    Object.assign(newEntries, { [question_id]: (optionId) ? optionId : inputAnswer });
    localStorage.setItem('questionData', JSON.stringify(newEntries));
    ///send questionData to api
    storeQuestionnire(question_data);
    if (question_id == '135' && optionId == '253') {
      var isQualified = CatalogueClaimsQualificationCheck();
      let is_qualified = (isQualified) ? 1 : 0;
      const response = await updateUserQualifiedStatus(
        visitorParameters.visitor_parameters.uuid,
        is_qualified
      )
      if (is_qualified) {
        history.push("/" + DynamicRouteNextPage +"?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ DynamicRouteSplitName);
      }
      else {
        history.push("/thankyou-unqualified?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
    }
  };
  const previousSlide = (e) => {
    if (e.target.name == "back_127" && cAccountOpen) {
      var previous_slide = e.target.name + "_yes";
      setPrevSlide({ slide: previous_slide });
      setCAccountOpen(false);
    } else {
      var previous_slide = e.target.name;
      setPrevSlide({ slide: previous_slide });
    }
 
  };
  const gtmDataLayer = (current_question, element = null) => {

    let question = "";
    let answer = "";
    switch (current_question) {
      case "next_124":
        question = "Account opened year";
        break;
      case "question_125":
        question = "Account still opened";
        break;
      case "next_126":
        question = "Account closed year";
        break;
      case "next_127":
        question = "Highest balance";
        break;
     
      case "question_128":
        question = "Credit limit increased times";
        break;
      case "question_129":
        question = "Close or exceeding balance";
        break;
      case "question_130":
        question = "Only minimum payment due";
        break;
      case "next_131":
        question = "Monthly income";
        break;
      case "next_132":
        question = "Credit commitments";
        break;
      case "question_133":
        question = "Q1 Circumstances";
        break;
      case "question_134":
        question = "Subject to IVA?";
        break;
      case "question_135":
        question = "Scenarios";
        break;
      
    }
    GTMDataLayer({
      question: question,
      answer: answer
    });
  };
  return (
    <>
    <GetVisitorsParams />
    <div className="UFVCC_Questionnaire">
      <Helmet>
        <title>Catalogue Claims</title>
      </Helmet>
      <TopSection/>
      {/*  */}
      {loading ? (<LoaderCC/>
       
      ) : (
        <section className="loanclaims formsection">
          <section className="container-fluid">
            <div className="row">
              <div className=" col-lg-6 col-md-12 col-12 text-center my-auto">
                <div className="video-div">
                  <video autoPlay muted playsInline loop tabIndex="0">
                    <source src={background_video} type="video/mp4" />
                  </video>
                </div>
                <div className="text-center">
                  <img src={logo_loanclaims} className="logo" alt="" />
                </div>
              </div>
            </div>
            <form ref={splitForm}>
              {/* <!-- slide-1 --> */}
                <SelectQuestiannaireCC showQuestionSlide={state.showQuestionSlide124}
                  questionnaire={questionnaire124}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {false}
                />

              {/* <!-- slide-2 --> */}
                <RadioQuestiannaireCC
                  showQuestionSlide={state.showQuestionSlide125}
                  questionnaire={questionnaire125}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
              
              {/* <!-- slide-3 --> */}
              <SelectQuestiannaireCC showQuestionSlide={state.showQuestionSlide126}
                  questionnaire={questionnaire126}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
              {/* <!-- slide-4 --> */}
              <SelectQuestiannaireCC showQuestionSlide={state.showQuestionSlide127}
                  questionnaire={questionnaire127}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
              {/* <!-- slide-5 --> */}
              <RadioQuestiannaireCC
                  showQuestionSlide={state.showQuestionSlide128}
                  questionnaire={questionnaire128}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
              {/* <!-- slide-6 --> */}
              <RadioQuestiannaireCC
                  showQuestionSlide={state.showQuestionSlide129}
                  questionnaire={questionnaire129}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
              {/* <!-- slide-7 --> */}
              <RadioQuestiannaireCC
                  showQuestionSlide={state.showQuestionSlide130}
                  questionnaire={questionnaire130}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
              {/* <!-- slide-8 --> */}
              <SelectQuestiannaireCC showQuestionSlide={state.showQuestionSlide131}
                  questionnaire={questionnaire131}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
              {/* <!-- slide-9 --> */}
              <SelectQuestiannaireCC showQuestionSlide={state.showQuestionSlide132}
                  questionnaire={questionnaire132}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
              {/* <!-- slide-10 --> */}
              <RadioQuestiannaireCC
                  showQuestionSlide={state.showQuestionSlide133}
                  questionnaire={questionnaire133}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
              {/* <!-- slide-11 --> */}
              <RadioQuestiannaireCC
                  showQuestionSlide={state.showQuestionSlide134}
                  questionnaire={questionnaire134}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
              {/* <!-- slide-12 --> */}
              <RadioQuestiannaireCC
                  showQuestionSlide={state.showQuestionSlide135}
                  questionnaire={questionnaire135}
                  slideChange={slideChange}
                  previousSlide={previousSlide}
                  backBtn      = {true}
                />
            </form>
          </section>
          {/* modal */}
          {showModal ? (
            <div
              className="modal"
              id="modal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              style={{
                zIndex: "999999",
                display: "block",
                background: "#00000080",
              }}
            >
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-body">
                    <p> Sorry we cannot accept your application. </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      id="unqlfyBtn"
                      data-bs-dismiss="modal"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </section>
      )}
    </div>
    </>
  );
};

export default Questionnaire_CC;
