import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import LandingPage from "./Components/Pages/LandingPage";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import BlackHorseFollowup from "./Components/Pages/Followup/Blackhorse/BlackHorseFollowup";
import MoneybarnFollowup from "./Components/Pages/Followup/Moneybarn/MoneybarnFollowup";
import BlackHorseFollowupThankyou from "./Components/Pages/Followup/Blackhorse/BlackHorseFollowupThankyou";
import MoneybarnFollowupThankyou from "./Components/Pages/Followup/Moneybarn/MoneybarnFollowupThankyou";
import BlackHorseFollowupUnqualified from "./Components/Pages/Followup/Blackhorse/BlackHorseFollowupUnqualified";
import MoneybarnFollowupUnqualified from "./Components/Pages/Followup/Moneybarn/MoneybarnFollowupUnqualified";
import BlackHorseFollowupPreview from "./Components/Pages/Followup/Blackhorse/BlackHorseFollowupPreview";
import MoneybarnFollowupPreview from "./Components/Pages/Followup/Moneybarn/MoneybarnFollowupPreview";
import Preview from "./Components/Pages/Preview";
import MoneybarnPreview from "./Components/Pages/MoneybarnPreview";
import CapitalOnePreview from "./Components/Pages/CapitalOnePreview";
import Preview_0602UFMB_A1_5 from "./Components/Pages/Preview_0602UFMB_A1_5";
import Preview_0602UFMB_A1_6 from "./Components/Pages/Preview_0602UFMB_A1_6";
import Preview_0602UFMB_A1_6_2 from "./Components/Pages/Preview_0602UFMB_A1_6_2";
import Proceed from "./Components/Pages/Proceed";
import Unqualified from "./Components/Pages/Unqualified";
import Signature from "./Components/Pages/Signature";
import Thankyou from "./Components/Pages/Thankyou";
import NotFound from "./Components/Pages/NotFound";
import VerificationPage from "./Components/Pages/Verification";
import Split_0602UFBH_A1 from "./Components/Pages/Splits/Blackhorse/Split_0602UFBH_A1";
import Questionnaire_BH from "./Components/Pages/Blackhorse/Questionnaire_BH";
import SignaturePreview from "./Components/Pages/SignaturePreview";
import Confirmation from "./Components/Pages/Blackhorse/Confirmation";
import MoneybarnThankyou from "./Components/Pages/MoneybarnThankyou";
import CapitalOneThankyou from "./Components/Pages/CapitalOneThankyou";
import Thankyou_0602UFMB_A1_5 from "./Components/Pages/Thankyou_0602UFMB_A1_5";
import Thankyou_0602UFMB_A1_6 from "./Components/Pages/Thankyou_0602UFMB_A1_6";
import Thankyou_0602UFMB_A1_6_2 from "./Components/Pages/Thankyou_0602UFMB_A1_6_2";
import Split_moneybarn from "./Components/Pages/Splits/Moneybarn/Split_moneybarn";
import Split_0602UFMB_A1_5 from "./Components/Pages/Splits/Moneybarn/Split_0602UFMB_A1_5";
import Split_0602UFMB_A1_6 from "./Components/Pages/Splits/Moneybarn/Split_0602UFMB_A1_6";
import Split_0602UFMB_A1_6_2 from "./Components/Pages/Splits/Moneybarn/Split_0602UFMB_A1_6_2";
import Split_0602UFCO_A1 from "./Components/Pages/Splits/CapitalOne/Split_0602UFCO_A1";
import Questionnaire_moneybarn from "./Components/Pages/Moneybarn/Questionnaire_Moneybarn";
import Questionnaire_0602UFMB_A1_5 from "./Components/Pages/Moneybarn/Questionnaire_0602UFMB_A1_5";
import Questionnaire_0602UFMB_A1_6 from "./Components/Pages/Moneybarn/Questionnaire_0602UFMB_A1_6";
import Questionnaire_0602UFMB_A1_6_2 from "./Components/Pages/Moneybarn/Questionnaire_0602UFMB_A1_6_2";
import OTP_BH from "./Components/Pages/Blackhorse/OTP_BH";
import OTP_Moneybarn from "./Components/Pages/Moneybarn/OTP_Moneybarn";
import OTP_0602UFMB_A1_5 from "./Components/Pages/Moneybarn/OTP_0602UFMB_A1_5";
import OTP_0602UFMB_A1_6 from "./Components/Pages/Moneybarn/OTP_0602UFMB_A1_6";
import MoneybarnSignaturePreview from "./Components/Pages/MoneybarnSignaturePreview";
import CapitalOneSignaturePreview from "./Components/Pages/CapitalOneSignatutrePreview";
import SignaturePreview_0602UFMB_A1_5 from "./Components/Pages/SignaturePreview_0602UFMB_A1_5";
import SignaturePreview_0602UFMB_A1_6 from "./Components/Pages/SignaturePreview_0602UFMB_A1_6";
import SignaturePreview_0602UFMB_A1_6_2 from "./Components/Pages/SignaturePreview_0602UFMB_A1_6_2";
import MoneybarnConfirmation from "./Components/Pages/Moneybarn/Confirmation";
import CapitalOneConfirmation from "./Components/Pages/Followup/CapitalOne/Confirmation";
import Confirmation_0602UFMB_A1_5 from "./Components/Pages/Moneybarn/Confirmation_0602UFMB_A1_5";
import Confirmation_0602UFMB_A1_6 from "./Components/Pages/Moneybarn/Confirmation_0602UFMB_A1_6";
import Confirmation_0602UFMB_A1_6_2 from "./Components/Pages/Moneybarn/Confirmation_0602UFMB_A1_6_2";
import BlackHorseFollowupConfirmation from "./Components/Pages/Followup/Blackhorse/BlackHorseFollowupConfirmation";
import MoneybarnFollowupConfirmation from "./Components/Pages/Followup/Moneybarn/MoneybarnFollowupConfirmation";
import CapitalOneFollowupConfirmation from "./Components/Pages/Followup/CapitalOne/CapitalOneFollowupConfirmation";
import BlackHorseFollowupSignaturePreview from "./Components/Pages/Followup/Blackhorse/BlackHorseFollowupSignaturePreview";
import Split_0602UFVCC_A1 from "./Components/Pages/Splits/CatalogueClaims/Split_0602UFVCC_A1";
import Split_0602UFVCC_A2 from "./Components/Pages/Splits/CatalogueClaims/Split_0602UFVCC_A2";
import CatalogueClaimsPreview from "./Components/Pages/CatalogueClaims/CatalogueClaimsPreview"
import CatalogConfirmation from "./Components/Pages/CatalogueClaims/CatalogConfirmation";
import CatalogSignaturePreview from "./Components/Pages/CatalogueClaims/CatalogSignaturePreview";
import Questionnaire_CC from "./Components/Includes/Layouts/CatalogueClaims/Questionnaire_CC";
import Questionnaire_CC_A2 from "./Components/Includes/Layouts/CatalogueClaims/Questionnaire_CC_A2";
import CatalogueClaimsFollowup from "./Components/Pages/Followup/CatalogueClaims/CatalogueClaimsFollowup";
import CC_Thankyou from "./Components/Includes/Layouts/CatalogueClaims/CC_Thankyou";
import CatalogueClaimsFollowupConfirmation from "./Components/Pages/Followup/CatalogueClaims/CatalogueClaimsFollowupConfirmation";
import CatalogueClaimsFollowupSignaturePreview from "./Components/Pages/Followup/CatalogueClaims/CatalogueClaimsFollowupSignaturePreview";
import CatalogueClaimsFollowupPreview from "./Components/Pages/Followup/CatalogueClaims/CatalogueClaimsFollowupPreview";
import CatalogueClaimsFollowupThankyou from "./Components/Pages/Followup/CatalogueClaims/CatalogueClaimsFollowupThankyou";
import CatalogueClaimsFollowupUnqualified from "./Components/Pages/Followup/CatalogueClaims/CatalogueClaimsFollowupUnqualified";
import MoneybarnFollowupSignaturePreview from "./Components/Pages/Followup/Moneybarn/MoneybarnFollowupSignaturePreview";
import Questionnaire_0602UFCO_A1 from "./Components/Pages/CapitalOne/Questionnaire_0602UFCO_A1";
import UnsubscribeSmsEmail from "./Components/Pages/Moneybarn/UnsubscribeSmsEmail";





const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    // document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/home" component={HomePage} />
              {/* <Route exact path={["/0602UFBH_A1"]} component={Split_0602UFBH_A1} /> */}
              <Route exact path={["/0602UFBH_A1"]} component={NotFound} />
              {/* <Route exact path={["/0602UFVCC_A1"]} component={Split_0602UFVCC_A1} /> */}
              <Route exact path={["/0602UFVCC_A1"]} component={NotFound} />
              <Route exact path={["/0602UFBH_A1/questionnaire"]} component={Questionnaire_BH} />
              <Route exact path={["/0602UFVCC_A1/questionnaire"]} component={Questionnaire_CC} />
              {/* <Route exact path={["/0602UFVCC_A2"]} component={Split_0602UFVCC_A2} /> */}
              <Route exact path={["/0602UFVCC_A2"]} component={NotFound} />
              <Route exact path={["/0602UFVCC_A2/questionnaire"]} component={Questionnaire_CC_A2} />
              {/* <Route exact path={"/thankyou_catalogueclaims"} component={CC_Thankyou} /> */}
              {/* <Route exact path={"/catalogueclaims/thankyou-unqualified"} component={Thankyou_Unqualified} /> */}
              <Route exact path={["/otp_blackhorse"]} component={OTP_BH} />
               <Route exact path={["/0602UFMB_A1_1"]} component={Split_moneybarn} />
              {/*<Route exact path={["/0602UFMB_A1_1"]} component={NotFound} />*/}
               <Route exact path={["/0602UFMB_A1_5"]} component={Split_0602UFMB_A1_5} />
              {/*<Route exact path={["/0602UFMB_A1_5"]} component={NotFound} />*/}
              {/* <Route exact path={["/0602UFMB_A1_6"]} component={Split_0602UFMB_A1_6} />*/}
              <Route exact path={["/0602UFMB_A1_6"]} component={NotFound} />
               <Route exact path={["/0602UFMB_A1_6_2"]} component={Split_0602UFMB_A1_6_2} />
              {/*<Route exact path={["/0602UFMB_A1_6_2"]} component={NotFound} />*/}
              <Route exact path={["/0602UFMB_A1_1/questionnaire"]} component={Questionnaire_moneybarn} />
              <Route exact path={["/0602UFMB_A1_5/questionnaire"]} component={Questionnaire_0602UFMB_A1_5} />
              <Route exact path={["/0602UFMB_A1_6/questionnaire"]} component={Questionnaire_0602UFMB_A1_6} />
              <Route exact path={["/0602UFMB_A1_6_2/questionnaire"]} component={Questionnaire_0602UFMB_A1_6_2} />
              <Route exact path={["/otp_blackhorse"]} component={OTP_BH} />
              <Route exact path={["/otp_0602UFMB_A1_1"]} component={OTP_Moneybarn} />
              <Route exact path={["/otp_0602UFMB_A1_5"]} component={OTP_0602UFMB_A1_5} />
              <Route exact path={["/otp_0602UFMB_A1_6"]} component={OTP_0602UFMB_A1_6} />
              <Route exact path={"/blackhorse/followup"} component={BlackHorseFollowup} />
              <Route exact path={"/moneybarn/followup"} component={MoneybarnFollowup} />
              <Route exact path={ ["/splash-page"]} component={Preview} />
              <Route exact path={"/proceed"} component={Proceed} />
              <Route exact path={"/signature"} component={Signature} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              <Route exact path={"/0602UFMB_A1_1/thankyou"} component={MoneybarnThankyou} />
              <Route exact path={"/0602UFMB_A1_5/thankyou"} component={Thankyou_0602UFMB_A1_5} />
              <Route exact path={"/0602UFMB_A1_6/thankyou"} component={Thankyou_0602UFMB_A1_6} />
              <Route exact path={"/0602UFMB_A1_6_2/thankyou"} component={Thankyou_0602UFMB_A1_6_2} />
              <Route exact path={"/0602UFCO_A1/thankyou"} component={CapitalOneThankyou} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/google_verification"]} component={VerificationPage}/>
              <Route exact path={"/thankyou-unqualified"} component={Unqualified} />
              <Route exact path={"/blackhorse/preview"} component={Preview} />
              <Route exact path={"/0602UFMB_A1_1/preview"} component={MoneybarnPreview} />
              <Route exact path={"/0602UFMB_A1_5/preview"} component={Preview_0602UFMB_A1_5} />
              <Route exact path={"/0602UFMB_A1_6/preview"} component={Preview_0602UFMB_A1_6} />
              <Route exact path={"/0602UFMB_A1_6_2/preview"} component={Preview_0602UFMB_A1_6_2} />
              <Route exact path={"/0602UFCO_A1/preview"} component={CapitalOnePreview} />
              <Route exact path={"/blackhorse/followup/thankyou"} component={BlackHorseFollowupThankyou} />
              <Route exact path={"/moneybarn/followup/thankyou"} component={MoneybarnFollowupThankyou} />
              <Route excat path={"/blackhorse/followup/preview"} component={BlackHorseFollowupPreview} />
              <Route excat path={"/moneybarn/followup/preview"} component={MoneybarnFollowupPreview} />
              <Route exact path={"/blackhorse/followup/thankyou-unqualified"} component={BlackHorseFollowupUnqualified} />
              <Route exact path={"/moneybarn/followup/thankyou-unqualified"} component={MoneybarnFollowupUnqualified} />
              <Route exact path={"/blackhorse/signature-preview"} component={SignaturePreview} />
              <Route exact path={"/blackhorse/confirmation"} component={Confirmation} />
              <Route exact path={"/0602UFMB_A1_1/signature-preview"} component={MoneybarnSignaturePreview} />
              <Route exact path={"/0602UFMB_A1_5/signature-preview"} component={SignaturePreview_0602UFMB_A1_5} />
              <Route exact path={"/0602UFMB_A1_6/signature-preview"} component={SignaturePreview_0602UFMB_A1_6} />
              <Route exact path={"/0602UFMB_A1_6_2/signature-preview"} component={SignaturePreview_0602UFMB_A1_6_2} />
              <Route exact path={"/0602UFCO_A1/signature-preview"} component={CapitalOneSignaturePreview} />
              <Route exact path={"/blackhorse/confirmation"} component={Confirmation} />
              <Route exact path={"/0602UFMB_A1_1/confirmation"} component={MoneybarnConfirmation} />
              <Route exact path={"/0602UFCO_A1/confirmation"} component={CapitalOneConfirmation} />
              <Route exact path={"/0602UFMB_A1_5/confirmation"} component={Confirmation_0602UFMB_A1_5} />
              <Route exact path={"/0602UFMB_A1_6/confirmation"} component={Confirmation_0602UFMB_A1_6} />
              <Route exact path={"/0602UFMB_A1_6_2/confirmation"} component={Confirmation_0602UFMB_A1_6_2} />
              <Route exact path={"/unsubscribe"} component={UnsubscribeSmsEmail} />
              <Route exact path={"/blackhorse/followup/confirmation"} component={BlackHorseFollowupConfirmation} />
              <Route exact path={"/moneybarn/followup/confirmation"} component={MoneybarnFollowupConfirmation} />
              <Route exact path={"/capitalone/followup/confirmation"} component={CapitalOneFollowupConfirmation} />
              <Route exact path={"/blackhorse/followup/signature-preview"} component={BlackHorseFollowupSignaturePreview} />
              <Route exact path={"/catalogue-claims/preview"} component={CatalogueClaimsPreview} />
              <Route exact path={"/catalogue-claims/confirmation"} component={CatalogConfirmation} />
              <Route exact path={"/catalogue-claims/signature-preview"} component={CatalogSignaturePreview} />
              <Route exact path={"/catalogue-claims/followup"} component={CatalogueClaimsFollowup} />
              <Route exact path={"/catalogue-claims/followup/confirmation"} component={CatalogueClaimsFollowupConfirmation} />
              <Route exact path={"/catalogue-claims/followup/signature-preview"} component={CatalogueClaimsFollowupSignaturePreview} />
              <Route excat path={"/catalogue-claims/followup/preview"} component={CatalogueClaimsFollowupPreview} />
              <Route exact path={"/catalogue-claims/followup/thankyou"} component={CatalogueClaimsFollowupThankyou} />
              <Route exact path={"/catalogue-claims/followup/thankyou-unqualified"} component={CatalogueClaimsFollowupUnqualified} />
              <Route exact path={"/moneybarn/followup/signature-preview"} component={MoneybarnFollowupSignaturePreview} />

            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;