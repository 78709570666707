import { Api } from "../api/Api";
export const userInfo = () => {
  const getUserInfo = async (uuid) => {
    const response = await Api.get("v1/user-info?uuid=" + uuid, {});
    return response;
  };
  const updateUserQualifiedStatus = async (uuid,is_qualified) => {
    const response = await Api.post("v1/user-qualified-status-update", {
        uuid,
        is_qualified
    });
  }
  return { getUserInfo, updateUserQualifiedStatus};
};
