import React, {
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
  } from "react";
  import { BrowserView, MobileView, deviceType } from "react-device-detect";
  import { Helmet } from "react-helmet";
  import { useHistory } from "react-router-dom";
  import SignatureCanvas from "react-signature-canvas";
  import loader from "../../../src/assets/img/loader-review2.gif";
  import VisitorParamsContext from "../../Contexts/VisitorParams";
  import { useSignature } from "../../Hooks/useSignature";
  import { userInfo } from "../../Hooks/userInfo";
  import GTMDataLayer from "../Includes/Layouts/GTMDataLayer";
  import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";
  
  const initialState = {
    isChecked: false,
    isAgreed: false,
    isCanvasEmpty: false,
    isValid: false,
    canvasValue: false,
  };
  const signatureReducer = (state, action) => {
    switch (action.type) {
      case "signPadChange": {
        return {
          ...state,
          isCanvasEmpty: action.payload.value,
        };
      }
  
      case "validSignature": {
        return {
          ...state,
          isValid: action.payload.value,
        };
      }
      default:
        return state;
    }
  };
  
  const SignaturePreview_0602UFMB_A1_6_2 = () => {
    const [sec1, setSec1] = useState("show");
    const [sec2, setSec2] = useState("d-none");
    const [signImg, setSignImg] = useState("show");
    const [canvasShow, setCanvasShow] = useState("d-none");
    const [state, dispatch] = useReducer(signatureReducer, initialState);
    const { updateSignature, isLoading } = useSignature();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const history = useHistory();
    const signPadRef = useRef();
    const { getUserInfo } = userInfo();
    const [signatureData, setSignatureData] = useState("");
    const [isSigned, setIsSigned] = useState(false);
    const [retake, setRetake] = useState(false);
    const [canvasSign, setCanvasSign] = useState(false);
    let customUuid = "";
    let formData = JSON.parse(localStorage.getItem("formData"));
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_uuid = localStorage.getItem("uuid");
    const {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('UNFR_0602UFMB_A1_6_2','0602UFMB_A1_6_2/signature-preview');
  
    customUuid = query_uuid ? query_uuid : local_uuid;
    useEffect(async () => {
      let signature = "";
      if (formData) {
        signature = formData.signature_data;
        setSignatureData(signature);
      } else {
        const response = await getUserInfo(customUuid);
        signature = response.data.response[0].signature_image;
        setSignatureData(signature);
      }
    }, []);
  
    const signValidate = async () => {
      try {
        const signPad = signPadRef.current;
        const rpdata = signPad.toData();
        var signLength = 0;
        for (var i = 0; i < rpdata.length; i++) {
          signLength += Object.keys(rpdata[i]).length;
        }
        if (signLength > 13) {
          var signData = signPad.getCanvas().toDataURL("image/png");
          setSignatureData(signData);
          setCanvasSign(true);
          return dispatch({ type: "validSignature", payload: { value: false } });
        } else {
          setCanvasSign(false);
          dispatch({ type: "validSignature", payload: { value: true } });
          dispatch({ type: "signPadChange", payload: { value: false } });
        }
      } catch (e) {
        console.warn(e);
      }
    };
  
    setTimeout(() => {
      setSec1("d-none");
      setSec2("show");
    }, 3000);
  
    const handleClick = () => {
      if (retake) {
        setCanvasSign(false);
        clearSign();
      } else {
        setRetake(true);
        setCanvasShow("show");
        setSignImg("d-none");
      }
    };
  
    useEffect(() => {
      if (state.isCanvasEmpty) {
        signValidate();
      }
    }, [state.isCanvasEmpty, isSigned]);
  
    const clearSign = () => {
      setSignatureData("");
      dispatch({ type: "signPadChange", payload: { value: false } });
      if (signPadRef.current) signPadRef.current.clear();
    };
  
    const handleSubmit = async (e) => {
      gtmDataLayer(e.target.value);
      const signPad = signPadRef.current;
      if (retake) {
        if (signPad.isEmpty()) {
          dispatch({ type: "validSignature", payload: { value: true } });
        } else if (canvasSign) {
          if (formData) {
            formData.signature_data = signatureData;
          } else {
            formData = { signature_data: signatureData };
          }
          localStorage.setItem("formData", JSON.stringify(formData));
          visitorParameters.data = { signature_data: signatureData };
          visitorParameters.visitor_parameters = { uuid: customUuid };
  
          const response = await updateSignature(
            visitorParameters.visitor_parameters,
            visitorParameters.data,
            "signature_update"
          );
          if (response.data.status == "Success") {
            history.push("/"+DynamicRouteNextPage+"?uuid=" + customUuid+"&splitName="+DynamicRouteSplitName);
          }
        }
      } else {
        history.push("/"+DynamicRouteNextPage+"?uuid=" + customUuid+"&splitName="+DynamicRouteSplitName);
      }
    };
    const gtmDataLayer = () => {
      let question = "signature";
      let answer = "";
  
      GTMDataLayer({
        question: question,
        answer: answer,
      });
    };
  
    return (
      <>
        <Helmet>
          <title>Moneybarn</title>
        </Helmet>
  
        <div className="signature_preview">
          <section className={` questionspart ${sec1}`}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 l_bg">
                  <form>
                    <div id="myDiv" className="space text-center">
                      <img src={loader} alt="" />
                      <h3>
                        Thanks for providing your information. To finish off your
                        application, we now just need you to have a read over our
                        claims pack and make sure you are happy with it. Check
                        your signature on the forms and once complete, we will get
                        started for you.
                      </h3>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <div
            className={`modal fade ${sec2}`}
            id="signaturePop"
            data-keyboard="false"
            data-backdrop="static"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-body sign-sec">
                  <h3 className="modal-title text-center">
                    {" "}
                    Please sign your signature in the box below
                  </h3>
                  <p className="text-center">
                    <i>
                      To prevent possible delay, do your best to mimic your
                      handwritten signature to ensure it matches the lenders
                      records.
                    </i>
                  </p>
                  <div className="sign-div">
                    <form name="sign_submit" id="sign_submit" method="post">
                      <div className="mb-3">
                        <div className="sign-text text-center">
                          <div id="myDiv2" className={`slide2 ${signImg}`}>
                            <div id="canvasImg">
                              <img
                                src={signatureData}
                                alt="SignaturePreview"
                                id="signImg"
                              />
                            </div>
                          </div>
                          <div
                            className={`signature-div ${canvasShow}`}
                            id="canvasSign"
                          >
                            {(deviceType === "mobile" ||
                              deviceType === "tablet") && (
                              <MobileView>
                                <SignatureCanvas
                                  canvasProps={{
                                    width: 300,
                                    height: 200,
                                    name: "signature_pad",
                                    className: "signature-pad anim_bt d-sm-block",
                                    id: "signature-pad",
                                  }}
                                  ref={signPadRef}
                                  onEnd={() => {
                                    setIsSigned(!isSigned);
                                    dispatch({
                                      type: "signPadChange",
                                      payload: { value: true },
                                    });
                                  }}
                                />
                              </MobileView>
                            )}
                            {deviceType === "browser" && (
                              <BrowserView>
                                <SignatureCanvas
                                  canvasProps={{
                                    height: 200,
                                    width: 400,
                                    name: "signature_pad",
                                    className:
                                      "signature-pad anim_bt d-lg-block ",
                                    id: "signature-pad",
                                  }}
                                  ref={signPadRef}
                                  onEnd={() => {
                                    setIsSigned(!isSigned);
                                    dispatch({
                                      type: "signPadChange",
                                      payload: { value: true },
                                    });
                                  }}
                                />
                              </BrowserView>
                            )}
                          </div>
                        </div>
                        <div className=" col-lg-12 pad0">
                          {state.isValid && (
                            <p id="signatures_valid" className="error_msg">
                              Draw valid signature!
                            </p>
                          )}
                        </div>
                      </div>
                      <input
                        type="button"
                        id="clearSign"
                        name="clearSign"
                        className="btn-clear"
                        value="Retake"
                        onClick={handleClick}
                      />
                      <input
                        type="button"
                        id="sign-pop-submit"
                        name=""
                        className="submitpop signmain"
                        value="Submit"
                        onClick={handleSubmit}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default SignaturePreview_0602UFMB_A1_6_2;
  