import React,{useEffect} from 'react';
import Footer from '../../../Includes/Layouts/0602UFBH_A1/Footer';
import logo from '../../../../assets/img/logo-white1.png';

const CatalogueClaimsFollowupUnqualified = () => {
  localStorage.clear();
  return (
    <>
      <div className='success'>
        <div class="clearfix"></div>
        <header class="header_success">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 text-center py-3">
                <a href="" class="logo">
                  <img src={logo} alt="" />
                </a>
              </div>
        
            </div>
          </div>
        </header>
        <div class="clearfix"></div>
        <section class="py-4 py-lg-5 py-md-5 py-sm-5   mb-lg-2 mb-md-2 mb-sm-0 ">
          <div class="container text-center">
              <div class="row">
                <div class="col-lg-8 offset-lg-2">
              <h1 class="mt-4 fw-bold   text-mesg">Thank you for your enquiry, having reviewed the information you provided, we are sorry to inform you that we are unable to act for you in relation to your claim.</h1>
          </div>
                </div>
              </div>
        </section>
      
        <div class="clearfix"></div>          
        <Footer/>
    </div>
     </>
  )
}
export default CatalogueClaimsFollowupUnqualified;
