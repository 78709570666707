const questions = [
   // {
   //   question: "Is this your Vehicle?",
   //   questionId: 152,
   //   answers: [
   //     {
   //       label: "Yes",
   //       value: 439,
   //     },
   //     {
   //       label: "No",
   //       value: 440,
   //     },
   //   ],
   // },
   // {
   //   question: "Did you secure finance for this vehicle in YYYY?",
   //   questionId: 140,
   //   answers: [
   //     {
   //       label: "Yes",
   //       value: 415,
   //     },
   //     {
   //       label: "No",
   //       value: 416,
   //     },
   //   ],
   // },
   // {
   //   question: "When did you secure finance for this vehicle?",
   //   questionId: 150,
   //   answers: [],
   //   options: [
   //     { value: "", label: "Select Year" },
   //     { value: "1998", label: "1998" },
   //     { value: "1999", label: "1999" },
   //     { value: "2000", label: "2000" },
   //     { value: "2001", label: "2001" },
   //     { value: "2002", label: "2002" },
   //     { value: "2003", label: "2003" },
   //     { value: "2004", label: "2004" },
   //     { value: "2005", label: "2005" },
   //     { value: "2006", label: "2006" },
   //     { value: "2007", label: "2007" },
   //     { value: "2008", label: "2008" },
   //     { value: "2009", label: "2009" },
   //     { value: "2010", label: "2010" },
   //     { value: "2011", label: "2011" },
   //     { value: "2012", label: "2012" },
   //     { value: "2013", label: "2013" },
   //     { value: "2014", label: "2014" },
   //     { value: "2015", label: "2015" },
   //     { value: "2016", label: "2016" },
   //     { value: "2017", label: "2017" },
   //     { value: "2018", label: "2018" },
   //     { value: "2019", label: "2019" },
   //     { value: "2020", label: "2020" },
   //   ],
   // },
   // {
   //   question: "What is your finance agreement number?",
   //   questionId: 151,
   //   answers: [],
   // },
   {
     question: "Approximate Amount of the Finance agreement?",
     questionId: 136,
     answers: [],
   },
   {
     question: "Is your finance with Moneybarn  still in place?",
     questionId: 137,
     answers: [
       {
         label: "Yes",
         value: 413,
       },
       {
         label: "No",
         value: 414,
       },
     ],
   },
   {
     question: "Do you still have this vehicle?",
     questionId: 142,
     answers: [
       {
         label: "Yes",
         value: 420,
       },
       {
         label: "No",
         value: 421,
       },
     ],
   },
   {
     question: "What is your preference on what happens to this vehicle?",
     questionId: 141,
     answers: [
       {
         label:
           "Happy for the car to be returned if asked to as a result of this claim",
         value: 417,
       },
       {
         label: "I want to keep the vehicle for the foreseeable future",
         value: 418,
       },
       // {
       //   label: "I'm not sure and would appreciate further advice",
       //   value: 419,
       // },
     ],
   },
   {
     question: "What happened to the vehicle?",
     questionId: 153,
     answers: [
       {
         label:
           "The vehicle has been returned to the finance company as per my statutory right under the Consumer Credit Act ('Voluntary Termination')",
         value: 441,
       },
       {
         label:
           "The vehicle has been returned to the finance company under an informal arrangement ('Voluntary Surrender')",
         value: 442,
       },
       {
         label: "The vehicle was repossessed by the finance company",
         value: 443,
       },
       {
         label:
           "I made my own arrangements to surrender possession of the vehicle, which had nothing to do with the finance company - for example, I made a private sale or I sold the vehicle for scrap",
         value: 444,
       },
     ],
   },
  //  {
  //    question:
  //      "Have you struggled to repay your MoneyBarn loan while managing all your other financial commitments?",
  //    questionId: 154,
  //    answers: [
  //      {
  //        label: "Yes",
  //        value: 445,
  //      },
  //      {
  //        label: "No",
  //        value: 446,
  //      },
  //    ],
  //  },
   {
     question:
       "What was your average or usual monthly income (after tax) when your finance agreement began?",
     questionId: 138,
     answers: [],
     options: [
       { value: "", label: "Select your average or usual monthly income" },
       { value: "Less than £100", label: "Less than £100" },
       { value: "£100 - £200", label: "Between £100 and £200" },
       { value: "£200 - £300", label: "Between £200 and £300" },
       { value: "£300 - £400", label: "Between £300 and £400" },
       { value: "£400 - £500", label: "Between £400 and £500" },
       { value: "£500 - £600", label: "Between £500 and £600" },
       { value: "£600 - £700", label: "Between £600 and £700" },
       { value: "£700 - £800", label: "Between £700 and £800" },
       { value: "£800 - £900", label: "Between £800 and £900" },
       { value: "£900 - £1000", label: "Between £900 and £1000" },
       { value: "£1000 - £1100", label: "Between £1000 and £1100" },
       { value: "£1100 - £1200", label: "Between £1100 and £1200" },
       { value: "£1200 - £1300", label: "Between £1200 and £1300" },
       { value: "£1300 - £1400", label: "Between £1300 and £1400" },
       { value: "£1400 - £1500", label: "Between £1400 and £1500" },
       { value: "£1500 - £1600", label: "Between £1500 and £1600" },
       { value: "£1600 - £1700", label: "Between £1600 and £1700" },
       { value: "£1700 - £1800", label: "Between £1700 and £1800" },
       { value: "£1800 - £1900", label: "Between £1800 and £1900" },
       { value: "£1900 - £2000", label: "Between £1900 and £2000" },
       { value: "£2000 - £2100", label: "Between £2000 and £2100" },
       { value: "£2100 - £2200", label: "Between £2100 and £2200" },
       { value: "£2200 - £2300", label: "Between £2200 and £2300" },
       { value: "£2300 - £2400", label: "Between £2300 and £2400" },
       { value: "£2400 - £2500", label: "Between £2400 and £2500" },
       { value: "£2500 - £2600", label: "Between £2500 and £2600" },
       { value: "£2600 - £2700", label: "Between £2600 and £2700" },
       { value: "£2700 - £2800", label: "Between £2700 and £2800" },
       { value: "£2800 - £2900", label: "Between £2800 and £2900" },
       { value: "£2900 - £3000", label: "Between £2900 and £3000" },
       { value: "£3000 - £3100", label: "Between £3000 and £3100" },
       { value: "£3100 - £3200", label: "Between £3100 and £3200" },
       { value: "£3200 - £3300", label: "Between £3200 and £3300" },
       { value: "£3300 - £3400", label: "Between £3300 and £3400" },
       { value: "£3400 - £3500", label: "Between £3400 and £3500" },
       { value: "£3500 - £3600", label: "Between £3500 and £3600" },
       { value: "£3600 - £3700", label: "Between £3600 and £3700" },
       { value: "£3700 - £3800", label: "Between £3700 and £3800" },
       { value: "£3800 - £3900", label: "Between £3800 and £3900" },
       { value: "£3900 - £4000", label: "Between £3900 and £4000" },
       { value: "£4000 - £4100", label: "Between £4000 and £4100" },
       { value: "£4100 - £4200", label: "Between £4100 and £4200" },
       { value: "£4200 - £4300", label: "Between £4200 and £4300" },
       { value: "£4300 - £4400", label: "Between £4300 and £4400" },
       { value: "£4400 - £4500", label: "Between £4400 and £4500" },
       { value: "£4500 - £4600", label: "Between £4500 and £4600" },
       { value: "over £4600", label: "More than £4600" },
     ],
   },
   {
     question:
       "When your finance agreement began, approximately how much per month were you typically paying towards other credit commitments (loans and card accounts, etc)?",
     questionId: 139,
     answers: [],
     options: [
       { value: "", label: "Select amount" },
       { value: "Less than £100", label: "Less than £100" },
       { value: "£100 - £200", label: "Between £100 and £200" },
       { value: "£200 - £300", label: "Between £200 and £300" },
       { value: "£300 - £400", label: "Between £300 and £400" },
       { value: "£400 - £500", label: "Between £400 and £500" },
       { value: "£500 - £600", label: "Between £500 and £600" },
       { value: "£600 - £700", label: "Between £600 and £700" },
       { value: "£700 - £800", label: "Between £700 and £800" },
       { value: "£800 - £900", label: "Between £800 and £900" },
       { value: "£900 - £1000", label: "Between £900 and £1000" },
       { value: "£1000 - £1100", label: "Between £1000 and £1100" },
       { value: "£1100 - £1200", label: "Between £1100 and £1200" },
       { value: "£1200 - £1300", label: "Between £1200 and £1300" },
       { value: "£1300 - £1400", label: "Between £1300 and £1400" },
       { value: "£1400 - £1500", label: "Between £1400 and £1500" },
       { value: "£1500 - £1600", label: "Between £1500 and £1600" },
       { value: "£1600 - £1700", label: "Between £1600 and £1700" },
       { value: "£1700 - £1800", label: "Between £1700 and £1800" },
       { value: "£1800 - £1900", label: "Between £1800 and £1900" },
       { value: "£1900 - £2000", label: "Between £1900 and £2000" },
       { value: "over £2000", label: "More than £2000" },
     ],
   },
   {
    question: "When my finance agreement began, I was...",
    questionId: 144,
    answers: [
      {
        label:
          "Regularly using 'bad credit' lending (payday, guarantor, doorstep and high-interest)",
        value: 425,
      },
      {
        label: "Using a lot of my income for gambling or online betting",
        value: 426,
      },
      {
        label:
          "Receiving a very low and unreliable income (e.g. due to job loss, state benefits, being a student,etc)",
        value: 427,
      },
      {
        label:
          "Managing my income and outgoings ok, but still believe the finance agreement was mis-sold to me",
        value: 428,
      },
    ],
  },
   {
    question: "Did you take other finance agreements with Moneybarn for other vehicles, at other times?",
    questionId: 167,
    answers: [
      {
        label: "No – Just this one",
        value: 470,
      },
      {
        label: "1 Other",
        value: 471,
      },
      {
        label: "2 Others",
        value: 472,
      },
      {
        label: "3 Or more others",
        value: 473,
      },
    ],
  },
  {
    question: "Which bank did you usually pay the Car Finance from?",
    questionId: 168,
    answers: [],
    options: [
      { value: "", label: "" },
      { value: "1", label: "Abn Amro NL" },
      { value: "2", label: "AIB GB Personal" },
      { value: "3", label: "AIB NI (First Trust) Personal" },
      { value: "4", label: "Amex" },
      { value: "5", label: "Bank of Ireland UK" },
      { value: "6", label: "Bank of Scotland (Personal)" },
      { value: "7", label: "Barclaycard" },
      { value: "8", label: "Barclays Personal" },
      { value: "9", label: "Capital One" },
      { value: "10", label: "Cash Plus" },
      { value: "11", label: "Cater Allen" },
      { value: "12", label: "Chelsea Building Society" },
      { value: "13", label: "Cooperative Bank" },
      { value: "14", label: "Coutts & Company" },
      { value: "15", label: "Coutts ClearSpend" },
      { value: "16", label: "Cumberland Building Society" },
      { value: "17", label: "Danske Bank" },
      { value: "18", label: "First Direct" },
      { value: "19", label: "Halifax Personal" },
      { value: "20", label: "Handelsbanken UK" },
      { value: "21", label: "HSBC Personal" },
      { value: "22", label: "ING" },
      { value: "23", label: "Lloyds (Personal)" },
      { value: "24", label: "M&S Bank" },
      { value: "25", label: "MBNA Personal" },
      { value: "26", label: "Mettle" },
      { value: "27", label: "Monzo" },
      { value: "28", label: "Nationwide" },
      { value: "29", label: "Natwest" },
      { value: "30", label: "Natwest Bankline" },
      { value: "31", label: "NatWest ClearSpend" },
      { value: "32", label: "Payoneer - UK" },
      { value: "33", label: "PayPal" },
      { value: "34", label: "RBS International ClearSpend" },
      { value: "35", label: "Revolut" },
      { value: "36", label: "Royal Bank ClearSpend" },
      { value: "37", label: "Royal Bank of Scotland" },
      { value: "38", label: "Royal Bank of Scotland Bankline" },
      { value: "39", label: "Sainsbury's Bank" },
      { value: "40", label: "Santander" },
      { value: "41", label: "Silicon Valley Bank" },
      { value: "42", label: "SMBC - UK" },
      { value: "43", label: "Soldo UK" },
      { value: "44", label: "Starling" },
      { value: "45", label: "Starling Bank" },
      { value: "46", label: "Starling Personal Access" },
      { value: "47", label: "Tesco Bank" },
      { value: "48", label: "thinkmoney" },
      { value: "49", label: "Tide" },
      { value: "50", label: "TSB Bank" },
      { value: "51", label: "Ulster Bank (UK)" },
      { value: "52", label: "Unity Trust" },
      { value: "53", label: "Vanquis Bank" },
      { value: "54", label: "Virgin Money" },
      { value: "55", label: "Virgin Money (Essential)" },
      { value: "56", label: "Wise" },
      { value: "57", label: "Yorkshire Building Society" },
      { value: "58", label: "Hoares Bank" }
      ],
  },
  
    {
      question: "Is BANK_NAME bank account still open?",
      questionId: 169,
      answers: [
        {
          label: "Open",
          value: 474,
        },
        {
          label: "Closed",
          value: 475,
        },
      ],
    },
    {
      question: "Do you feel your lending was unaffordable, and that your Car Finance lender should have known this?",
      questionId: 170,
      answers: [
        {
          label: "Yes",
          value: 476,
        },
        {
          label: "No",
          value: 477,
        },
        {
          label: "Not Sure",
          value: 478,
        },
      ],
    },
    {
      question: "Have you made an Unaffordable Lending claim against your Car Finance lender before?",
      questionId: 171,
      answers: [
        {
          label: "Yes, I have",
          value: 479,
        },
        {
          label: "No, I haven't",
          value: 480,
        },
      ],
    },
    {
      question: "Do you understand that making this Unaffordable Lending claim may mean that you won't be able to borrow from this lender anymore?",
      questionId: 172,
      answers: [
        {
          label: "Yes, I understand",
          value: 481,
        },
        {
          label: "No, I don't want future lending to be declined",
          value: 482,
        },
      ],
    },
    {
      question: "Do you want Allegiant to tell you about other claims which may be worthwhile?",
      questionId: 173,
      answers: [
        {
          label: "Yes, I am interested in hearing about other claims",
          value: 483,
        },
        {
          label: "No, I am only interested in making this claim",
          value: 484,
        },
      ],
    }
  //  {
  //    question:
  //      "When the finance agreement began, were these things typically happening in your bank account?",
  //    questionId: 145,
  //    answers: [
  //      {
  //        label: "Yes",
  //        value: 429,
  //      },
  //      {
  //        label: "No",
  //        value: 430,
  //      },
  //    ],
  //  },
  
   // {
   //   question: "Did you arrange finance via the dealer or a broker?",
   //   questionId: 146,
   //   answers: [
   //     {
   //       label: "Yes",
   //       value: 431,
   //     },
   //     {
   //       label: "No",
   //       value: 432,
   //     },
   //   ],
   // },
   // {
   //   question:
   //     "Would you like us to investigate and, where appropriate, claim for unfair/hidden commission inappropriately charged to you? ",
   //   questionId: 147,
   //   answers: [
   //     {
   //       label: "Yes",
   //       value: 433,
   //     },
   //     {
   //       label: "No",
   //       value: 434,
   //     },
   //   ],
   // },
   // {
   //   question:
   //     "Are you subject to or have you ever been subject to an Individual Voluntary Arrangement (IVA) or have you proposed an IVA yet to be approved or rejected by creditors?",
   //   questionId: 148,
   //   answers: [
   //     {
   //       label: "Yes",
   //       value: 435,
   //     },
   //     {
   //       label: "No",
   //       value: 436,
   //     },
   //   ],
   // },
   // {
   //   question: "Do any of these scenarios apply to you?",
   //   questionId: 149,
   //   answers: [
   //     {
   //       label: "Yes",
   //       value: 437,
   //     },
   //     {
   //       label: "No",
   //       value: 438,
   //     },
   //   ],
   // },
 ];
 
 export default questions;
 