import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";
import RadioButton from "../../../UI/TextField";
import Button from "../../../UI/Button";


const RadioQuestiannaireC2 = (props) => {
  const mainQuestion = props.questionnaire.question;
  const radioName = 'question_' + props.questionnaire.questionId;
  const question_id = props.questionnaire.questionId;

 
  const radioLabel = props.questionnaire.answers.map((item, index) => {
    return (
      <div className="mb-2 mb-lg-3 nextgoBtn13" id="">
        <div className="btn-date ">
        <input type="radio" id={`question_${props.questionnaire.questionId}_${item.value}`} name={`question_${ props.questionnaire.questionId}`} value={item.value} className="" data-question_id={question_id} onClick={(e) => { props.slideChange(e, radioName) }} />
        <label htmlFor={`question_${props.questionnaire.questionId}_${item.value}`} className=" btn-cml min-wid-300">{item.label}</label>
       </div>
    </div>
     );
    });

  return (
    <>
      <section className={`question_block slide-top  slideSeptember ${props.showQuestionSlide}`}>
        <div className="row text-div" >
          <div className="col-lg-6 ps-lg-5 pe-lg-5 my-auto pe-4 ps-4 text-block d-flex align-items-center justify-content-center justify-content-lg-left">
            <div>
              <h1>
                {mainQuestion}
              </h1>
              {question_id == '166' &&
                <ul class="ulsec ul-1a">
                <li>You have ever been declared bankrupt; or</li>
                <li>You are currently subject to a bankruptcy petition</li>
                <li>You have been subject to a debt relief order</li>
                <li>You have an adverse County Court Debt Judgement</li>
                <li>Sequestration</li>
            </ul>}
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5 pe-lg-5 my-auto pe-4 ps-4 ">
            <div className="  d-flex align-items-center inner-text justify-content-center">
              <div className="text-center">
                
                {radioLabel}
                
                <div className="mb-3 mt-4" id=" ">
                  <div className="">
                    <button type="button" className="btn-back " id={`back_${question_id}`} name={`back_${question_id}`} onClick={props.previousSlide}>Back</button>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RadioQuestiannaireC2;
