import React from 'react';

const RadioButtonQuest = ({
  id,
  value,
  name,
  className,
  labelName,
  labelClassName,
  onClick,
  onLabelClick,
  validation,
  labelCountString,
  dataKeeperData
}) => {
  return (
    <>
      <input type="radio" className={className} name={name} id={id} data-ans-id={value} data-quest-id={name} data-ans-label={labelName}
        value={value} onClick={onClick} data-keeper_data={dataKeeperData}
      />
      <label htmlFor={id} data-ans-id={value} data-quest-id={name} className={labelClassName}
        onClick={onLabelClick}>
        <span className="line-break-span" >{labelCountString}</span>{labelName}
      </label>
    </>
  );
}

export default RadioButtonQuest;