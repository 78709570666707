import React from "react";
import Modal from "react-bootstrap/Modal";

const DEFAULT_CONTENT = `Sorry we cannot accept your application. This is because damages,
compensation or settlement money might, in certain circumstances, be
set-off against your outstanding liabilities, meaning that you will
not receive a cash benefit.`;

const CONTENT2 = `Sorry we cannot accept your application`;

const QuestionnaireModal = ({
  popDisplay,
  handleClose,
  content = DEFAULT_CONTENT,
  popupContent
}) => {
  return (
    <Modal show={popDisplay} dialogClassName="modalReact">
      <div className="">
        <Modal.Body>
          <p>{popupContent ? CONTENT2 : content}</p>
        </Modal.Body>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default QuestionnaireModal;
