import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";

const CheckBoxQuestionnaireFlp = (props) => {
  const mainQuestion = props.questionnaire.question;
  const question_id = props.questionnaire.questionId;
  const checkboxName = "question_" + props.questionnaire.questionId + "[]";

  const checkboxId0 = props.questionnaire.answers[0]
    ? "question_" +
      props.questionnaire.questionId +
      "_" +
      props.questionnaire.answers[0].value
    : "";
  const checkboxId1 = props.questionnaire.answers[1]
    ? "question_" +
      props.questionnaire.questionId +
      "_" +
      props.questionnaire.answers[1].value
    : "";
  const checkboxId2 = props.questionnaire.answers[2]
    ? "question_" +
      props.questionnaire.questionId +
      "_" +
      props.questionnaire.answers[2].value
    : "";
  const checkboxId3 = props.questionnaire.answers[3]
    ? "question_" +
      props.questionnaire.questionId +
      "_" +
      props.questionnaire.answers[3].value
    : "";

  const checkboxValue0 = props.questionnaire.answers[0]
    ? props.questionnaire.answers[0].value
    : "";
  const checkboxValue1 = props.questionnaire.answers[1]
    ? props.questionnaire.answers[1].value
    : "";
  const checkboxValue2 = props.questionnaire.answers[2]
    ? props.questionnaire.answers[2].value
    : "";
  const checkboxValue3 = props.questionnaire.answers[3]
    ? props.questionnaire.answers[3].value
    : "";

  const checkboxLabel0 = props.questionnaire.answers[0]
    ? props.questionnaire.answers[0].label
    : "";
  const checkboxLabel1 = props.questionnaire.answers[1]
    ? props.questionnaire.answers[1].label
    : "";
  const checkboxLabel2 = props.questionnaire.answers[2]
    ? props.questionnaire.answers[2].label
    : "";
  const checkboxLabel3 = props.questionnaire.answers[3]
    ? props.questionnaire.answers[3].label
    : "";

  const [checkboxValues, setCheckboxValues] = useState([]);
  const [initError, setInitError] = useState();
  const [showError, setShowError] = useState("d-none");

  const insertValues = (e) => {
    var checkbox = document.getElementsByClassName("custom-checkbox");
    var values = e.target.value;
    if(values == "428"){
        for(let i=0; i<3; i++) {
          checkbox[i].checked = false;
        }
        checkboxValues.includes(values)
        ? setCheckboxValues(checkboxValues.filter((item) => item !== values))
        : setCheckboxValues([values]);
    } else {
      if (checkboxValues.includes('428')) {
        setCheckboxValues([values]);
        checkbox[3].checked = false;
      } else {
        checkboxValues.includes(values)
        ? setCheckboxValues(checkboxValues.filter((item) => item !== values))
        :setCheckboxValues([...checkboxValues, values]);
      }
    }
    checkboxValues != "" ? setShowError("d-none") : setShowError("d-block");
    setInitError("1");
  };

  const pageValidate = (e) => {
    checkboxValues != ""
      ? props.slideChange(e, checkboxValues)
      : setShowError("d-block");
  };

  useEffect(() => {
    checkboxValues == "" && initError == "1"
      ? setShowError("d-block")
      : setShowError("d-none");
  }, [checkboxValues]);

  return (
    <>
    <div className={`${props.showQuestionSlide}`}>
      <div
        class={`radio-box question14  animated fadeInUp`}
        id="breakdown12"
      >
        <h3 style={{ color: "#444" }}>{mainQuestion}</h3>

        {checkboxId0 && (
          <div className="mb-2 mb-lg-3">
            <div className="btn-date-checkbox">
              <input
                type="checkbox"
                id={checkboxId0}
                name={checkboxName}
                value={checkboxValue0}
                className="custom-checkbox"
                onClick={(e) => {
                  insertValues(e);
                }}
              />
              <label
                htmlFor={checkboxId0}
                className="br radioa l_g radiola radioa-multiple next12"
              >
                <span className="radioa-span">a</span>
                {checkboxLabel0}
              </label>
            </div>
          </div>
        )}

        {checkboxId1 && (
          <div className="mb-2 mb-lg-3 " id=" ">
            <div className="btn-date-checkbox">
              <input
                type="checkbox"
                id={checkboxId1}
                name={checkboxName}
                value={checkboxValue1}
                className="custom-checkbox"
                onClick={(e) => {
                  insertValues(e);
                }}
              />
              <label
                htmlFor={checkboxId1}
                className="br radioa l_g radiola  radioa-multiple next12"
              >
              <span className="radioa-span2">b</span>
                {checkboxLabel1}
              </label>
            </div>
          </div>
        )}

        {checkboxId2 && (
          <div className="mb-2 mb-lg-3 " id=" ">
            <div className="btn-date-checkbox">
              <input
                type="checkbox"
                id={checkboxId2}
                name={checkboxName}
                value={checkboxValue2}
                className="custom-checkbox"
                onClick={(e) => {
                  insertValues(e);
                }}
              />
              <label
                htmlFor={checkboxId2}
                className="br radioa l_g radiola radioa-multiple next12"
              >
              <span className="radioa-span">c</span>
                {checkboxLabel2}
              </label>
            </div>
          </div>
        )}

        {checkboxId3 && (
          <div className="mb-2 mb-lg-3 " id=" ">
            <div className="btn-date-checkbox">
              <input
                type="checkbox"
                id={checkboxId3}
                name={checkboxName}
                value={checkboxValue3}
                className="custom-checkbox"
                onClick={(e) => {
                  insertValues(e);
                }}
              />
              <label
                htmlFor={checkboxId3}
                className="br radioa l_g radiola radioa-multiple next12"
              >
              <span className="radioa-span">d</span>
                {checkboxLabel3}
              </label>
            </div>
          </div>
        )}
        
      </div>
      <span className={`error_msg error_msg_custom ${showError}`} id="email_err">Please select an option</span>
      <button type="button" className="btn-next next11" data-question_id={question_id} id={`next_${question_id}`} name={`next_${question_id}`} onClick={(e) => { pageValidate(e) }}> Next</button>
      </div>
    </>
  );
};

export default CheckBoxQuestionnaireFlp;
