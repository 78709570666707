import React, { useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useFollowupLoad } from "../../../../Hooks/useFollowupLoad";
import { queryString, urlParams } from "../../../../Utility/QueryString";
import { Api } from "../../../../api/Api";
import img01 from "../../../../assets/moneybarn/img/y-but.png";
import Loader from "../../../Includes/Layouts/Followup/Moneybarn/Loader";
import QuestionsModal from "../../../Includes/Layouts/Followup/Moneybarn/QuestionsModal";
import SignatureModal from "../../../Includes/Layouts/Followup/Moneybarn/SignatureModal";
import InputButton from "../../../UI/InputButton";
import { DynamicRoutingSplit } from "../../../../Utility/DynamicRoutingSplit";
import { QualificationCheck } from "../../../../Utility/QualificationCheck_0602UFMB_A1_6";

const PAGE_NAME = "moneybarn";

var complete_arr = [];
const initialState = {
  showButton1: "hide",
  showButton2: "hide",
  showTick1: "hide",
  showTick2: "hide",
};

const FormReducer = (state, action) => {
  switch (action.type) {
    case "showButton": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showButton1: "show",
          showTick1: "hide",
        };
      } else if (
        action.payload.pendingStatus.status_btn == "pending_question"
      ) {
        return {
          ...state,
          showButton2: "show",
          showTick2: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "showTick": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showTick1: "show",
          showButton1: "hide",
        };
      } else if (
        action.payload.pendingStatus.status_btn == "pending_question"
      ) {
        return {
          ...state,
          showTick2: "show",
          showButton2: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
};
const MoneybarnFollowup = () => {
  const [signatureClass, SetSignatureClass] = useState(false);
  const [questionnaireClass, SetQuestionnaireClass] = useState(false);
  const [pendingQuestionnaire, setPendingQuestionnaire] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [pendingStatus, setPendingStatus] = useState([]);
  const [showLoad, setshowLoad] = useState("show");
  const [showUserName, setUserName] = useState("");
  const [allFollowupData, setFollowupData] = useState({});
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const { loadFollowup, isuseFollowupLoad } = useFollowupLoad();
  const [pending, setPending] = useState(true);
  const [followupStatus, setFollowupStatus] = useState("Pending");
  const [currentUuid, setCurrentUuid] = useState("");
  const history = useHistory();
  const  {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('Followup_MB','moneybarn/followup');
  const userToken = urlParams.get("atp_sub2");
  const atpSub6 = urlParams.get("atp_sub6") ? urlParams.get("atp_sub6") : "FLP";
  localStorage.setItem("user_token", userToken);
  localStorage.setItem("atp_sub6", atpSub6);
  const setLoader = (e) => {
    setshowLoad("show");
  };
  const hideLoader = (e) => {
    setshowLoad("hide");
  };
  const showThankYou = async (uuid) => {
    const is_qualified = QualificationCheck();
      let isQualified = is_qualified ? 1 : 0 ;

    if(isQualified){
    history.push(
      `/${DynamicRouteNextPage}?uuid=${uuid}&user_token=${userToken}&source=${atpSub6}`
    );
    }
    else{
      history.push("/thankyou-unqualified");
    }
  };
  useEffect(() => {
    (async () => {
      const response = await Api.get(
        `/v1/followup/get-pending-details?query_string=${queryString}`
      );
      const followupDetails = response.data.response.followup_data;
      const is_qualified = response.data.response.user_info.is_qualified;
      const is_user_complete =
        response.data.response.pending_details.is_user_complete;
        console.log("usercomplete"+is_user_complete);
      const loa_preview = response.data.response.pending_details.loa_preview;
      const signin_status =
        response.data.response.pending_details.signin_status;
      const uuid = response.data.response.user_info.uuid;
      const email = response.data.response.user_info.email;
      localStorage.setItem("user_email", email);
      const split_name = response.data.response.split_name;
      localStorage.setItem("split_name", split_name);
      localStorage.setItem("questionData", JSON.stringify(response.data.response.user_answers));
      if (userToken == null) {
        localStorage.setItem("user_token", followupDetails.atp_sub2);
      }
      setUserName(response.data.response.user_info.user_name);
      const keeper_year = response.data.response.vehicle_details.keeper_year;
      setVehicleDetails(keeper_year);
      setFollowupData({
        flpData: response.data.response,
      });
      let pendingQuestions =
        response.data.response.pending_details.money_barn_pending_question;

      if (pendingQuestions.includes("2") && !pendingQuestions.includes("1")) {
        pendingQuestions.unshift("1");
      }

      setPendingQuestionnaire({ pendingQuestions });
      localStorage.setItem(
        "user_info",
        JSON.stringify(response.data.response.user_info)
      );
      setCurrentUuid(uuid);
      if (response.data.status === "Success") {
        const flpResponse = await loadFollowup(
          "followup_load",
          followupDetails,
          PAGE_NAME,
          queryString,
          "v2"
        );
        setTimeout(() => {
          hideLoader();
        }, 2000);

        if (response.data.response.pending_details.is_user_sign === 0) {
          setPendingStatus({
            status_btn: "signature",
            status_flag: "showButton",
          });
        } else {
          setPendingStatus({
            status_btn: "signature",
            status_flag: "showTick",
          });
        }
        if (
          response.data.response.pending_details.money_barn_pending_question
            .length == 0
        ) {
          setPendingStatus({
            status_btn: "pending_question",
            status_flag: "showTick",
          });
        } else {
          setPendingStatus({
            status_btn: "pending_question",
            status_flag: "showButton",
          });
        }
        if (is_qualified == 1 && is_user_complete == 1 && loa_preview == 1) {
          history.push(
            `/moneybarn/followup/thankyou?uuid=${uuid}&user_token=${userToken}&source=${atpSub6}&email=${email}`
          );
          return false;
        } else if (
          is_qualified == 1 &&
          is_user_complete == 1 &&
          loa_preview == 0 &&
          signin_status == 0
        ) {
          history.push(
            `/moneybarn/followup/confirmation?uuid=${uuid}&user_token=${userToken}&source=${atpSub6}`
          );
          return false;
        } else if (
          is_qualified == 1 &&
          is_user_complete == 1 &&
          loa_preview == 0 &&
          signin_status == 1
        ) {
          history.push(
            `/moneybarn/followup/preview?uuid=${uuid}&user_token=${userToken}&source=${atpSub6}`
          );
          return false;
        } else if (is_qualified == 0) {
          history.push(
            `/thankyou-unqualified?uuid=${uuid}&user_token=${userToken}&source=${atpSub6}`
          );
          return false;
        }
      }
    })();
  }, []);
  useEffect(() => {
    if (pendingStatus) {
      dispatch({ type: pendingStatus.status_flag, payload: { pendingStatus } });
      if (pendingStatus.status_flag == "showTick") {
        complete_arr.push(pendingStatus.status_btn);
        if (
          complete_arr.indexOf("pending_question") > -1 &&
          complete_arr.indexOf("signature") > -1
        ) {
          setPending(false);
          setFollowupStatus("Review Stage");
        }
      }
    }
  }, [pendingStatus]);

  return (
    <>
      <Helmet>
        <title>Moneybarn</title>
      </Helmet>

      <div className="followupPage">
        <section className="questionspart">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 l_bg">
                <form>
                  <div id="myDiv" className="space text-center">
                    <Loader showLoad={showLoad}></Loader>
                    <div
                      id="myDiv2"
                      className="slide2"
                      style={
                        showLoad === "hide"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="blue-bg col-12">
                        <h4>Claim Status: {followupStatus}</h4>
                        <p>
                          Almost done! We need a few more details to being
                          processing your claim.
                        </p>
                      </div>
                      <div className="col-12 data-div mb20 animated fadeInUp">
                        <div className=" col-12 nopad">
                          <table className="table table-bordered">
                            <thead className="thead-light">
                              <tr>
                                <th className="align-middle" width="60%">
                                  Account Holder{" "}
                                </th>
                                <th width="40%">{showUserName}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Digital Signature</td>
                                <td
                                  className={
                                    state
                                      ? `text-center align-middle blink_me  ${state.showButton1}`
                                      : `hide`
                                  }
                                >
                                  <InputButton
                                    id=""
                                    value="Sign here"
                                    btnType="button"
                                    onClick={() => SetSignatureClass(true)}
                                    className="add-btn signbut"
                                  />
                                </td>
                                <td
                                  className={
                                    state
                                      ? `text-center align-middle signyes ${state.showTick1}`
                                      : `hide`
                                  }
                                >
                                  <img src={img01} alt="" />
                                </td>
                              </tr>
                              <tr>
                                <td>Questionnaire</td>
                                <td
                                  className={
                                    state
                                      ? `text-center align-middle blink_me  ${state.showButton2}`
                                      : `hide`
                                  }
                                >
                                  <InputButton
                                    id=""
                                    value="Add now"
                                    btnType="button"
                                    onClick={() => SetQuestionnaireClass(true)}
                                    className="add-btn signbut"
                                  />
                                </td>
                                <td
                                  className={
                                    state
                                      ? `text-center align-middle signyes ${state.showTick2}`
                                      : `hide`
                                  }
                                >
                                  <img src={img01} alt="" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div>Click here to <a href={"/unsubscribe/?uuid="+currentUuid} target="_blank" > unsubscribe </a></div>
                      </div>
                      
                      <div id="mybut">
                        <InputButton
                          id=""
                          value="SUBMIT"
                          btnType="button"
                          onClick={() => showThankYou(currentUuid)}
                          className={`btn-submit button1 ${
                            pending == true ? "" : "animated-effect"
                          }`}
                          disabled={pending}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <SignatureModal
          popDisplay={signatureClass}
          allFollowupData={allFollowupData}
          setPendingStatus={setPendingStatus}
          setLoader={setLoader}
          showUserName={showUserName}
        />
        <QuestionsModal
          questionsPop={questionnaireClass}
          allFollowupData={allFollowupData}
          setPendingStatus={setPendingStatus}
          pendingQuestionnaire={pendingQuestionnaire}
          uuid={currentUuid}
          vehicleDetails={vehicleDetails}
        />
      </div>
    </>
  );
};

export default MoneybarnFollowup;
