import {Api} from '../api/Api';

export const useCarRegisterValidation = () => {

    const registrationvalidation = async (carreg,uuid,page_name) => {
        // const getValidation = await Api.post(
        //     "/api/v1/ukvd-validation?carRegNo=" + carreg+"&uuid=" +uuid+"&page_name="+page_name,
        //     {}
        //   );
          const getValidation = await Api.post(
            "/v1/ukvd-validation",
            {
                'carRegNo':carreg,
                'uuid':uuid,
                'page_name':page_name
            }
          );
        return getValidation;
    }
    return { registrationvalidation }
}