import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";

const SelectQuestiannaireFlp = (props) => {
  const mainQuestion = props.questionnaire.question;
  const selectName = "question_" + props.questionnaire.questionId;
  const dropValues = props.questionnaire.answers[0].options;
  const question_id = props.questionnaire.questionId;
  const [inputValue, setInputValue] = useState();
  const [showError, setShowError] = useState("d-none");

  const setValue = (e) => {
    setInputValue(e.target.value);
    e.target.value != "" ? setShowError("d-none") : setShowError("d-block");
  };

  const validatePage = (evt) => {
    inputValue ? props.slideChange(evt, selectName) : setShowError("d-block");
  };
  return (
    <>
      <div
        className={`radio-box question13  animated fadeInUp ${props.showQuestionSlide}`}
        id="breakdown11"
      >
        <h3 style={{ color: "#444" }}>{mainQuestion}</h3>
        <select
          className="form-select carRegNo"
          aria-label="Default select example"
          name={selectName}
          id={selectName}
          onChange={(e) => {
            setValue(e);
          }}
        >
          {dropValues.map((dropValue, index) => (
            <option key={index} value={dropValue.value}>
              {dropValue.label}
            </option>
          ))}
        </select>
        <span className={`error_msg error_msg_custom ${showError}`} id="email_err">Please select amount</span>
         <button type="button" class="btn-next next11" id={`next_${question_id}`} name={`next_${question_id}`} data-question_id={question_id} onClick={(e) => { validatePage(e) }}> NEXT</button>
      </div>
    </>
  );
};

export default SelectQuestiannaireFlp;
