import React from 'react';
const TrustBox = () => {
  const ref = React.useRef(null);
  React.useEffect(() => {
if (window.Trustpilot) {
  window.Trustpilot.loadFromElement(ref.current, true);
}
  }, []);
  return (
<div
  ref={ref} 
 className="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-style-height="40px" data-businessunit-id="5ee0ec97b8f1fa0001e57983" data-style-width="100%" data-theme="light" data-stars="4,5"
 
>
  <a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener"> Trustpilot
  </a>
</div>
  );
};
export default TrustBox;