import React from "react";
const PreFooter = () => {
  return (
    <>
      <div className="bottom_strip">
        <div className="container">
          <p className="mb-2">
            {" "}
            You do not need to use a claims management company to pursue a claim
            against a lender, you can do this yourself for free.{" "}
          </p>
          <p className="mb-0">
            {" "}
            If unsuccessful with your claim you also have the option to refer
            the claim to the Financial Ombudsman Service yourself without
            incurring a fee{" "}
          </p>
        </div>
      </div>
      <div className="clearfix"></div>
    </>
  );
};

export default PreFooter;
