import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';
import logo from '../../../../assets/img/logo.png'

const Header = () => {
  return (
    <>
   
        <header>
        <div className="container">
        <div className="mn-wrapper" style={{maxWidth: "900px",margin: "0 auto"}}>
        <div className="row">
          <div className=" col-xl-6 col-lg-6 text-lg-start text-md-center text-sm-center text-center">
            <a href="" className="logo">
              <img src={logo} alt=""/>
            </a>
          </div>
          <div className="col-xl-6 col-lg-6"></div>
        </div>
        </div>
      </div>
      </header>
      <div className="clearfix"></div>
    </>
  );
};

export default Header;