const questionnaire21 = {
  question: "Is this your Vehicle?",
  questionId: 21,
  answers: [
    {
      label: "Yes",
      value: "382",
    },
    {
      label: "No",
      value: "383",
    },
  ],
};
const questionnaire22 = {
  question: "Did you secure finance for this vehicle in ",
  questionId: 22,
  answers: [
    {
      label: "Yes",
      value: "384",
    },
    {
      label: "No",
      value: "385",
    },
  ],
};
const questionnaire23 = {
  question: "When did you secure finance for this vehicle?",
  questionId: 23,
  answers: [
    {
      label: "",
      value: "",
      options: [
        { value: "", label: "Select Year" },
        { value: "1998", label: "1998" },
        { value: "1999", label: "1999" },
        { value: "2000", label: "2000" },
        { value: "2001", label: "2001" },
        { value: "2002", label: "2002" },
        { value: "2003", label: "2003" },
        { value: "2004", label: "2004" },
        { value: "2005", label: "2005" },
        { value: "2006", label: "2006" },
        { value: "2007", label: "2007" },
        { value: "2008", label: "2008" },
        { value: "2009", label: "2009" },
        { value: "2010", label: "2010" },
        { value: "2011", label: "2011" },
        { value: "2012", label: "2012" },
        { value: "2013", label: "2013" },
        { value: "2014", label: "2014" },
        { value: "2015", label: "2015" },
        { value: "2016", label: "2016" },
        { value: "2017", label: "2017" },
        { value: "2018", label: "2018" },
        { value: "2019", label: "2019" },
        { value: "2020", label: "2020" }
      ]
    },
  ],
};
const questionnaire24 = {
  question: "Approximate amount of the finance agreement?",
  questionId: 24,
  answers: [
    {
      label: "",
      value: "",
    },
  ],
};
const questionnaire25 = {
  question: "Is your finance with Black Horse still in place?",
  questionId: 25,
  answers: [
    {
      label: "Yes",
      value: "386",
    },
    {
      label: "No",
      value: "387",
    },
  ],
};
const questionnaire26 = {
  question: "Do you still have this vehicle?",
  questionId: 26,
  answers: [
    {
      label: "Yes",
      value: "388",
    },
    {
      label: "No",
      value: "389",
    },
  ],
};
const questionnaire27 = {
  question: "What happened to the vehicle?",
  questionId: 27,
  answers: [
    {
      label:
        "The vehicle has been returned to the finance company as per my statutory right under the Consumer Credit Act ('Voluntary Termination')",
      value: "390",
    },
    {
      label:
        "The vehicle has been returned to the finance company under an informal arrangement ('Voluntary Surrender')",
      value: "391",
    },
    {
      label: "The vehicle was repossessed by the finance company",
      value: "392",
    },
    {
      label:
        "I made my own arrangements to surrender possession of the vehicle, which had nothing to do with the finance company - for example, I made a private sale or I sold the vehicle for scrap",
      value: "393",
    },
  ],
};
const questionnaire28 = {
  question: "What is your preference on what happens to this vehicle?",
  questionId: 28,
  answers: [
    {
      label:
        "Happy for the car to be returned if asked to as a result of this claim",
      value: "394",
    },
    {
      label: "I want to keep the vehicle for the foreseeable future",
      value: "395",
    },
  ],
};
const questionnaire29 = {
  question:
    "Did Black Horse check you had enough disposable income to afford the monthly repayments of the new finance agreement?",
  questionId: 29,
  answers: [
    {
      label: "They didn't check and I couldn't afford it",
      value: "396",
    },
    {
      label: "They may have checked but I still couldn't afford it",
      value: "397",
    },
    {
      label: "I had enough disposable income to afford the monthly repayments",
      value: "398",
    },
  ],
};
const questionnaire30 = {
  question:
    "Approximately what was your average or usual monthly income (after tax) when your finance agreement began?",
  questionId: 30,
  answers: [
    {
      label: "",
      value: "",
      options: [
        { value: "", label: "Select your average or usual monthly income" },
        { value: "Less than £100", label: "Less than £100" },
        { value: "£100 - £200", label: "Between £100 and £200" },
        { value: "£200 - £300", label: "Between £200 and £300" },
        { value: "£300 - £400", label: "Between £300 and £400" },
        { value: "£400 - £500", label: "Between £400 and £500" },
        { value: "£500 - £600", label: "Between £500 and £600" },
        { value: "£600 - £700", label: "Between £600 and £700" },
        { value: "£700 - £800", label: "Between £700 and £800" },
        { value: "£800 - £900", label: "Between £800 and £900" },
        { value: "£900 - £1000", label: "Between £900 and £1000" },
        { value: "£1000 - £1100", label: "Between £1000 and £1100" },
        { value: "£1100 - £1200", label: "Between £1100 and £1200" },
        { value: "£1200 - £1300", label: "Between £1200 and £1300" },
        { value: "£1300 - £1400", label: "Between £1300 and £1400" },
        { value: "£1400 - £1500", label: "Between £1400 and £1500" },
        { value: "£1500 - £1600", label: "Between £1500 and £1600" },
        { value: "£1600 - £1700", label: "Between £1600 and £1700" },
        { value: "£1700 - £1800", label: "Between £1700 and £1800" },
        { value: "£1800 - £1900", label: "Between £1800 and £1900" },
        { value: "£1900 - £2000", label: "Between £1900 and £2000" },
        { value: "£2000 - £2100", label: "Between £2000 and £2100" },
        { value: "£2100 - £2200", label: "Between £2100 and £2200" },
        { value: "£2200 - £2300", label: "Between £2200 and £2300" },
        { value: "£2300 - £2400", label: "Between £2300 and £2400" },
        { value: "£2400 - £2500", label: "Between £2400 and £2500" },
        { value: "£2500 - £2600", label: "Between £2500 and £2600" },
        { value: "£2600 - £2700", label: "Between £2600 and £2700" },
        { value: "£2700 - £2800", label: "Between £2700 and £2800" },
        { value: "£2800 - £2900", label: "Between £2800 and £2900" },
        { value: "£2900 - £3000", label: "Between £2900 and £3000" },
        { value: "£3000 - £3100", label: "Between £3000 and £3100" },
        { value: "£3100 - £3200", label: "Between £3100 and £3200" },
        { value: "£3200 - £3300", label: "Between £3200 and £3300" },
        { value: "£3300 - £3400", label: "Between £3300 and £3400" },
        { value: "£3400 - £3500", label: "Between £3400 and £3500" },
        { value: "£3500 - £3600", label: "Between £3500 and £3600" },
        { value: "£3600 - £3700", label: "Between £3600 and £3700" },
        { value: "£3700 - £3800", label: "Between £3700 and £3800" },
        { value: "£3800 - £3900", label: "Between £3800 and £3900" },
        { value: "£3900 - £4000", label: "Between £3900 and £4000" },
        { value: "£4000 - £4100", label: "Between £4000 and £4100" },
        { value: "£4100 - £4200", label: "Between £4100 and £4200" },
        { value: "£4200 - £4300", label: "Between £4200 and £4300" },
        { value: "£4300 - £4400", label: "Between £4300 and £4400" },
        { value: "£4400 - £4500", label: "Between £4400 and £4500" },
        { value: "£4500 - £4600", label: "Between £4500 and £4600" },
        { value: "over £4600", label: "More than £4600" }
      ],
    },
  ],
};
const questionnaire31 = {
  question:
    "When your finance agreement began, approximately how much per month were you typically paying towards other credit commitments (loans and card accounts, etc)",
  questionId: 31,
  answers: [
    {
      label: "",
      value: "",
      options: [
        { value: "", label: "Select amount" },
        { value: "Less than £100", label: "Less than £100" },
        { value: "£100 - £200", label: "Between £100 and £200" },
        { value: "£200 - £300", label: "Between £200 and £300" },
        { value: "£300 - £400", label: "Between £300 and £400" },
        { value: "£400 - £500", label: "Between £400 and £500" },
        { value: "£500 - £600", label: "Between £500 and £600" },
        { value: "£600 - £700", label: "Between £600 and £700" },
        { value: "£700 - £800", label: "Between £700 and £800" },
        { value: "£800 - £900", label: "Between £800 and £900" },
        { value: "£900 - £1000", label: "Between £900 and £1000" },
        { value: "£1000 - £1100", label: "Between £1000 and £1100" },
        { value: "£1100 - £1200", label: "Between £1100 and £1200" },
        { value: "£1200 - £1300", label: "Between £1200 and £1300" },
        { value: "£1300 - £1400", label: "Between £1300 and £1400" },
        { value: "£1400 - £1500", label: "Between £1400 and £1500" },
        { value: "£1500 - £1600", label: "Between £1500 and £1600" },
        { value: "£1600 - £1700", label: "Between £1600 and £1700" },
        { value: "£1700 - £1800", label: "Between £1700 and £1800" },
        { value: "£1800 - £1900", label: "Between £1800 and £1900" },
        { value: "£1900 - £2000", label: "Between £1900 and £2000" },
        { value: "over £2000", label: "More than £2000" }
      ],
    },
  ],
};
const questionnaire32 = {
  question: "When my finance agreement began, I was…",
  questionId: 32,
  answers: [
    {
      label:
        "Regularly using 'bad credit' lending (payday, guarantor, doorstep and high-interest)",
      value: "399",
    },
    {
      label: "Using a lot of my income for gambling or online betting",
      value: "400",
    },
    {
      label:
        "Receiving a very low and unreliable income (e.g. due to job loss, state benefits, being a student,etc)",
      value: "401",
    },
    {
      label:
        "Managing my income and outgoings ok, but still believe the finance agreement was mis-sold to me",
      value: "402",
    },
  ],
};
const questionnaire33 = {
  question:
    "When the finance agreement began, were these things typically happening in your bank account?",
  questionId: 33,
  answers: [
    {
      label: "Yes",
      value: "403",
    },
    {
      label: "No",
      value: "404",
    },
  ],
};
const questionnaire34 = {
  question:
    "Are you subject to or have you ever been subject to an Individual Voluntary Arrangement (IVA) or have you proposed an IVA yet to be approved or rejected by creditors?",
  questionId: 34,
  answers: [
    {
      label: "Yes",
      value: "405",
    },
    {
      label: "No",
      value: "406",
    },
  ],
};
const questionnaire35 = {
  question: "Do any of these scenarios apply to you?",
  questionId: 35,
  answers: [
    {
      label: "Yes",
      value: "407",
    },
    {
      label: "No",
      value: "408",
    },
  ],
};

const questionnaire36 = {
  question: "Did you arrange finance via the dealer or a broker?",
  questionId: 36,
  answers: [
    {
      label: "Yes",
      value: "409",
    },
    {
      label: "No",
      value: "410",
    },
  ],
};

const questionnaire37 = {
  question: "Would you like us to investigate and, where appropriate, claim for unfair/hidden commission inappropriately charged to you?",
  questionId: 37,
  answers: [
    {
      label: "Yes",
      value: "411",
    },
    {
      label: "No",
      value: "412",
    },
  ],
};

const questionnaire38 = {
  question: "What is your finance agreement number?",
  questionId: 38,
  answers: [
    {
      label: "",
      value: "",
    },
  ],
};
export {
  questionnaire21,
  questionnaire22,
  questionnaire23,
  questionnaire24,
  questionnaire25,
  questionnaire26,
  questionnaire27,
  questionnaire28,
  questionnaire29,
  questionnaire30,
  questionnaire31,
  questionnaire32,
  questionnaire33,
  questionnaire34,
  questionnaire35,
  questionnaire36,
  questionnaire37,
  questionnaire38,
};
