import React, { useEffect, useState } from "react";

const EXCLUSIVE_OPTION = "428";

const CheckBoxQuestionnaire = ({
  questionnaire,
  slideChange,
  showQuestionSlide,
  previousSlide,
}) => {
  const mainQuestion = questionnaire.question;
  const { answers, questionId } = questionnaire;
  const checkboxName = "question_" + questionId + "[]";

  const checkboxId0 = answers[0]
    ? `question_${questionId}_${answers[0].value}`
    : "";
  const checkboxId1 = answers[1]
    ? `question_${questionId}_${answers[1].value}`
    : "";
  const checkboxId2 = answers[2]
    ? `question_${questionId}_${answers[2].value}`
    : "";
  const checkboxId3 = answers[3]
    ? `question_${questionId}_${answers[3].value}`
    : "";

  const checkboxValue0 = answers[0] ? answers[0].value : "";
  const checkboxValue1 = answers[1] ? answers[1].value : "";
  const checkboxValue2 = answers[2] ? answers[2].value : "";
  const checkboxValue3 = answers[3] ? answers[3].value : "";

  const checkboxLabel0 = answers[0] ? answers[0].label : "";
  const checkboxLabel1 = answers[1] ? answers[1].label : "";
  const checkboxLabel2 = answers[2] ? answers[2].label : "";
  const checkboxLabel3 = answers[3] ? answers[3].label : "";

  const [checkboxValues, setCheckboxValues] = useState([]);
  const [initError, setInitError] = useState();
  const [showError, setShowError] = useState("d-none");

  const insertValues = (e) => {
    const values = e.target.value;

    if (values == EXCLUSIVE_OPTION) {
      checkboxValues.includes(values)
        ? setCheckboxValues(checkboxValues.filter((item) => item !== values))
        : setCheckboxValues([values]);
      document.getElementById(checkboxId0).checked = false;
      document.getElementById(checkboxId1).checked = false;
      document.getElementById(checkboxId2).checked = false;
    } else {
      if (checkboxValues.includes(values)) {
        setCheckboxValues(checkboxValues.filter((item) => item !== values));
      } else {
        if (checkboxValues.includes(EXCLUSIVE_OPTION)) {
          setCheckboxValues([values]);
          document.getElementById(checkboxId3).checked = false;
        } else {
          setCheckboxValues([...checkboxValues, values]);
        }
      }
    }

    checkboxValues != "" ? setShowError("d-none") : setShowError("d-block");
    setInitError("1");
  };

  const pageValidate = (e) => {
    checkboxValues != ""
      ? slideChange(e, checkboxValues)
      : setShowError("d-block");
  };

  useEffect(() => {
    checkboxValues == "" && initError == "1"
      ? setShowError("d-block")
      : setShowError("d-none");
  }, [checkboxValues]);

  return (
    <>
      <section
        className={`question_block slide-top  slideSeptember ${showQuestionSlide}`}
      >
        <div className="row questionnaire_row">
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block d-flex align-items-center justify-content-center justify-content-lg-left">
            <div>
              <h1>{mainQuestion}</h1>
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
            <div className="d-flex align-items-center inner-text justify-content-center">
              <div className="text-center">
                {checkboxId0 && (
                  <div className="mb-2 mb-lg-3">
                    <div className="btn-date-checkbox">
                      <input
                        type="checkbox"
                        id={checkboxId0}
                        name={checkboxName}
                        value={checkboxValue0}
                        className="custom-checkbox"
                        onClick={(e) => {
                          insertValues(e);
                        }}
                      />
                      <label htmlFor={checkboxId0} className=" btn-cml">
                        {checkboxLabel0}
                      </label>
                    </div>
                  </div>
                )}

                {checkboxId1 && (
                  <div className="mb-2 mb-lg-3 " id=" ">
                    <div className="btn-date-checkbox">
                      <input
                        type="checkbox"
                        id={checkboxId1}
                        name={checkboxName}
                        value={checkboxValue1}
                        className="custom-checkbox"
                        onClick={(e) => {
                          insertValues(e);
                        }}
                      />
                      <label htmlFor={checkboxId1} className=" btn-cml">
                        {checkboxLabel1}
                      </label>
                    </div>
                  </div>
                )}

                {checkboxId2 && (
                  <div className="mb-2 mb-lg-3 " id=" ">
                    <div className="btn-date-checkbox">
                      <input
                        type="checkbox"
                        id={checkboxId2}
                        name={checkboxName}
                        value={checkboxValue2}
                        className="custom-checkbox"
                        onClick={(e) => {
                          insertValues(e);
                        }}
                      />
                      <label htmlFor={checkboxId2} className=" btn-cml">
                        {checkboxLabel2}
                      </label>
                    </div>
                  </div>
                )}

                {checkboxId3 && (
                  <div className="mb-2 mb-lg-3 " id=" ">
                    <div className="btn-date-checkbox">
                      <input
                        type="checkbox"
                        id={checkboxId3}
                        name={checkboxName}
                        value={checkboxValue3}
                        className="custom-checkbox"
                        onClick={(e) => {
                          insertValues(e);
                        }}
                      />
                      <label htmlFor={checkboxId3} className=" btn-cml">
                        {checkboxLabel3}
                      </label>
                    </div>
                  </div>
                )}

                <span
                  className={`error_msg error_msg_custom ${showError}`}
                  id="email_err"
                >
                  Please select an option
                </span>

                <div className="mb-2 mb-lg-3  mt-4">
                  <button
                    type="button"
                    className="btn com_bnt "
                    data-question_id={questionId}
                    id={`next_${questionId}`}
                    name={`next_${questionId}`}
                    onClick={(e) => {
                      pageValidate(e);
                    }}
                  >
                    {" "}
                    Next
                  </button>
                </div>
                <div className="mb-2 mb-lg-3" id=" ">
                  <div className="">
                    <button
                      type="button"
                      className="btn-back "
                      id={`back_${questionId}`}
                      name={`back_${questionId}`}
                      onClick={previousSlide}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckBoxQuestionnaire;
