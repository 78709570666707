import { Api } from "../api/Api";

export const useSkipAgreementNumber = () => {
  const skipAgreementNumber = async (uuid, page_name) => {
    return await Api.post("v1/ukvd-delete-agreementno-on-skip", {
      uuid,
      page_name,
    });
  };

  return { skipAgreementNumber };
};
