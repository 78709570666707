import React from "react";
import car_front_fill from "../../../../assets/moneybarn/img/car-front-fill.png";
import questions from "../../../../Constants/moneybarnQuestions";
import { useState } from "react";

const RadioQuestiannaireMB = (props) => {
  
  const mainQuestion = props.questionnaire.question;
  const question_id = props.questionnaire.questionId;
  const radioName = `question_${props.questionnaire.questionId}`;
  const options = props.questionnaire.answers.map((option) => ({
    id: option.label + "_" + props.questionnaire.questionId,
    label: option.label,
    value: option.value,
  }));
  if(radioName == 'question_140' ){
    let s_keeperDate = localStorage.getItem("selected_keeper_date");
    let split_keeperDate = s_keeperDate.split(/(\s+)/);
    let keeper_year = split_keeperDate[4]; 
    var question_140 = "Did you secure finance for this vehicle in "+keeper_year+"?";
  }
  if (radioName == 'question_169'){
    const bank_name = localStorage.getItem('question_168');
    const bnk_name =bank_name ? questions[13].options[bank_name].label : "";
    var question_169 = "Is "+ bnk_name + " bank account still open?";
}
  return (
    <>
      <section
        className={`question_block slide-top  slideSeptember ${props.showQuestionSlide}`}
      >
        <div
          className={`row ${options.length > 10 ? "" : "questionnaire_row"}`}
        >
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 text-block d-flex align-items-center justify-content-center justify-content-lg-left">
            <div>
              <h1>
                <span
                  style={
                    mainQuestion?.length > 100
                      ? {
                          // fontSize: "calc(0.2rem + 4vmin)",
                          // lineHeight: "calc(0.2rem + 5vmin)",
                        }
                      : {}
                  }
                >
                  {radioName == "question_169" ? question_169 :( radioName == "question_140" ? question_140: mainQuestion )}
                </span>
                {props.vehicleSecureYear && (
                  <span>{props.vehicleSecureYear} ?</span>
                )}
              </h1>

              {props.description}

              {!!props.vehicleModel && (
                <div className="car-type mb-2">
                  <i className="bi bi-car-front-fill">
                    <img src={car_front_fill} alt="" />
                  </i>
                  {props.vehicleModel}
                </div>
              )}
              {radioName == 'question_173' && (
                <div className="list mb-4">
                <li>
                    <p>This is only an expression of interest. We will only start a new claim on your behalf with your full consent</p>
                </li>
                </div>
              )}
              {question_id == "33" && (
                <ul className="list_ck mb-3  ms-4">
                  <li>
                    Direct Debits or Standing Order payments were bouncing and
                    being rejected
                  </li>
                  <li>Late payment or overlimit fees were being applied</li>
                  <li>
                    Money spent was more than money received in any one month
                  </li>
                </ul>
              )}

              {question_id == "35" && (
                <ul className="list_ck mb-3   ms-4 ">
                  <li>
                    Declared bankrupt or are currently subject to a bankruptcy
                    petition
                  </li>
                  <li>Subject to a debt relief order</li>
                  <li>
                    {" "}
                    Had an adverse County Court Debt Judgment or Sequestration
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5 pe-lg-5   pe-4 ps-4 ">
            <div className="  d-flex align-items-center inner-text justify-content-center">
              <div className="text-center">
                <div className="flex flex-column" style={{ gap: "1rem" }}>
                  {options.map((option) => (
                    <div
                      key={radioName + option.value}
                      className="mb-3 "
                      id=" "
                    >
                      <div className="btn-date">
                        <input
                          type="radio"
                          id={option.id}
                          name={radioName}
                          value={option.value}
                          data-answertext={option.label}
                          className=" "
                          data-question_id={question_id}
                          onClick={(e) => {
                            props.slideChange(e, radioName);
                          }}
                        />
                        <label
                          htmlFor={option.id}
                          className={props.size == "sm" ? "option-btn-sm" : ""}
                          style={
                            props.size == "sm"
                              ? {
                                  fontSize:
                                    option.label?.length > 90
                                      ? "0.9rem"
                                      : "1rem",
                                }
                              : {}
                          }
                        >
                          {option.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="mb-3 mt-4" id=" ">
                  <div className="">
                    <button
                      type="button"
                      className="btn-back"
                      id={`back_${question_id}`}
                      name={`back_${question_id}`}
                      onClick={props.previousSlide}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RadioQuestiannaireMB;
